import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {isUndefined} from 'util';
import {HalUsersService} from '../../services/hal-users.service';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {User} from '../../models/user';
import {DeleteUserModalComponent} from '../delete-user-modal/delete-user-modal.component';
import {SavedUserModalComponent} from '../saved-user-modal/saved-user-modal.component';

@Component({
  selector: 'c360-users-detail',
  templateUrl: './detail.component.html'
})

export class UsersDetailComponent implements OnInit, OnDestroy {
  private _isLoading: boolean;
  private _subscriptions: Subscription = new Subscription();
  private _user: User;
  private _updated: boolean;

  /**
   *
   * @param {ActivatedRoute} _activatedRoute
   * @param {Router} router
   * @param {NgbModal} _modalService
   * @param {HalUsersService} _halUsersService
   * @param {LoggerService} _logger
   * @param {Location} _location
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private _modalService: NgbModal,
    private _halUsersService: HalUsersService,
    private _logger: LoggerService,
    private _location: Location,
  ) {
    this._isLoading = false;
    this._updated = false;
  }

  ngOnInit(): void {
    this._isLoading = true;
    this._subscriptions.add(
      this._activatedRoute
        .data
        .subscribe((data: { user: User }) => {
            this._isLoading = false;
            this._logger.info('UserDetails Route Resolver', data);
            this._user = data.user;
          }
        )
    );
    this._subscriptions.add(
      this._halUsersService
        .data
        .subscribe((userHalData) => {
            if (this._updated && userHalData['users'].data) {
              this._user = userHalData['users'].data[this._user.id];
              this._updated = false;
            }
          }
        )
    );
  }

  ngOnDestroy(): void {
    if (!isUndefined(this._subscriptions)) {
      this._subscriptions.unsubscribe();
    }
  }

  /**
   *
   * @param {User} user
   */
  public onSumbit(user: User): void {
    this._isLoading = true;
    this._updated = true;
    this._halUsersService
      .updateUser(user)
      .then(
        (data) => {
          this._isLoading = false;
          this._modalService.open(SavedUserModalComponent, {size: 'lg'});
        },
        () => {
          this._isLoading = false;
        }
      );
  }

  /**
   *
   * @param {{externalId: string; newEmailAddress: string; onlyCheck: boolean}} data
   */
  public onEmailSubmit(data: {
    externalId: string;
    newEmailAddress: string;
    onlyCheck: boolean;
  }) {
    this._isLoading = true;
    this._halUsersService
      .changeEmailAddress(data)
      .subscribe(
        (response) => {
          this._isLoading = false;
          this._modalService.open(SavedUserModalComponent, {size: 'lg'});
        },
        (error) => {
          this._isLoading = false;
        }
      );
  }

  /**
   *
   * @param {User} user
   */
  public onDelete(user: User): void {
    if (!user.id) {
      return;
    }
    const modalRef = this._modalService.open(DeleteUserModalComponent, {size: 'lg'});
    modalRef.componentInstance.user = user;

    modalRef.result.then(
      (data) => {
        this._isLoading = true;
        this._halUsersService
          .deleteUser(user)
          .then(
            () => {
              this._isLoading = false;
              this.router.navigate(['/', 'users']);
            },
            (error) => {
              this._isLoading = false;
            }
          );
      },
      () => {

      }
    );
  }

  /**
   *
   */
  public onCancel() {
    this._location.back();
  }

  /**
   *
   * @returns {User}
   */
  get user(): User {
    return this._user;
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }
}
