import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalTransactionsService} from '../hal-transactions.service';

@Injectable()
export class TransactionsRootLinkResolver implements Resolve<boolean> {

  constructor(private _halTransactionService: HalTransactionsService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this._halTransactionService.getRootLink();
  }
}
