<div class="row">
  <div class="col-md-4 form-inline">
    <a class="btn btn-primary"
       [routerLink]="['./','add']">
      <i class="fa fa-plus"></i>
      {{'MODUL_USERS.COMPONENT_FILTER.ADD' | translate}}</a>
  </div>
  <div class="col-md-8 form-inline justify-content-end">

    <div class="mr-sm-2 my-2" *ngIf="roleListKey && roleListKey.length > 0">
      {{ 'MODUL_USERS.COMPONENT_FILTER.ROLES' | translate}}:
      <select class="form-control custom-select"
              aria-label="Filter"
              (change)="onFilter()"
              [(ngModel)]="selectedRole">
        <option [ngValue]="null">{{ 'MODUL_USERS.COMPONENT_FILTER.SELECT_ROLES' | translate}}</option>
        <option [ngValue]="roleList[key]"
                *ngFor="let key of roleListKey">
          {{'MODUL_USERS.ROLES.'+roleList[key] | translate}}
        </option>
      </select>
    </div>

    <input class="form-control mr-sm-2 my-2"
           type="search"
           placeholder="{{ 'MODUL_USERS.COMPONENT_FILTER.SEARCH_PLACEHOLDER' | translate}}"
           aria-label="Search"
           (keydown.enter)="onFilter()"
           [(ngModel)]="searchString">
    <button class="btn btn-outline-success my-2 mr-sm-2"
            type="button"
            (click)="onFilter()">
      <i class="fa fa-search"></i> {{ 'MODUL_USERS.COMPONENT_FILTER.SEARCH' | translate}}
    </button>
<!--
    <div class="mr-sm-2 my-2">
      {{ 'MODUL_USERS.COMPONENT_FILTER.FILTER' | translate}}:
      <select class="form-control"
              aria-label="Filter"
              (change)="onFilter()"
              [(ngModel)]="selectedFilterOption">
        <option [value]="filter" *ngFor="let filter of filterOptions">
          {{ 'MODUL_USERS.COMPONENT_FILTER.' + filter | translate}}
        </option>
      </select>
    </div>
-->
  </div>
</div>
