import {Observable, of, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {isObject} from '../../shared/utils';
import {AuthResponse} from '../models/auth-response.interface';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {UserService} from './user.service';
import {PrinterService} from '../../shared/services/printer.service';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthenticationService {

  private _jwtHelper: JwtHelperService;

  constructor(private http: HttpClient,
              private _userService: UserService,
              private _alertService: AlertService,
              private _printerService: PrinterService,
              private _logger: LoggerService) {
    this._jwtHelper = new JwtHelperService();
  }

  static serializeData(data) {
    // If this is not an object, defer to native stringification.
    if (!isObject(data)) {
      return ((data == null) ? '' : data.toString());
    }
    const buffer = [];
    // Serialize each key in the object.
    for (const name in data) {
      if (!data.hasOwnProperty(name)) {
        continue;
      }
      const value = data[name];
      buffer.push(
        encodeURIComponent(name) +
        '=' +
        encodeURIComponent((value == null) ? '' : value)
      );
    }
    // Serialize the buffer and clean it up for transportation.
    const source = buffer
      .join('&')
      .replace(/%20/g, '+');
    return (source);
  }

  /**
   * return a boolean reflecting
   * whether or not the token is expired
   * @returns {Observable<boolean>}
   */
  public isAuthenticated(): boolean {

    // get the token
    const currentUser = this._userService.getUser();

    // check internal
    if (currentUser == null || currentUser.accessToken == null || this._jwtHelper.isTokenExpired(currentUser.accessToken)) {
      return false;
    }

    // finish
    return true;
  }

  login(username: string, password: string, superLogin: boolean): Observable<boolean> {
    const url = environment.urls.gateway + environment.urls.auth;
    const data = {
      username,
      password,
      client_id: superLogin ? environment.oauth.clientIdForAdmin : environment.oauth.clientId,
      grant_type: environment.oauth.grant_type_login,
      sales_counter_id: (this._printerService.salesCounterId != null && this._printerService.salesCounterId !== '') ?
        this._printerService.salesCounterId :
        null
    };

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(url, AuthenticationService.serializeData(data), {headers}).pipe(
      map((response: AuthResponse) => {
          // Create a User from Response. This will fail, if tokens are invalid
          return this._userService.setUser(response);
        },
        (err) => {
        }), catchError((err, caught) => {
        if (err.status != null && err.status >= 400 && err.status < 500) {
          return observableThrowError(err);
        } else {
          this.handleError(err);
        }
      }),);
  }

  refreshToken(): Observable<boolean> {
    // get the token
    const currentUser = this._userService.getUser();
    if (currentUser == null || currentUser.refreshToken == null) {
      return of(false);
    }
    const url = environment.urls.gateway + environment.urls.auth;
    const data = {
      client_id: currentUser.clientId,
      grant_type: environment.oauth.grant_type_refresh,
      refresh_token: currentUser.refreshToken
    };

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http
      .post(url, AuthenticationService.serializeData(data), {headers}).pipe(
        map((response: any) => {
          if (response instanceof HttpErrorResponse) {
            // 500 is actually okay for refreshToken. This can be skipped
            return (response.status / 100) >= 5 || response.status === 0;
          }

          if (response.refresh_token != null && response.access_token) {

            this._userService.updateUser({
              refresh_token: response.refresh_token,
              access_token: response.access_token
            });

            // return true to indicate successful login
            return true;
          } else {
            // return false to indicate failed login
            return false;
          }
        }),
        catchError((err, caught) => {
          if (err instanceof HttpErrorResponse) {
            // 500 is actually okay for refreshToken. This can be skipped
            return of(
              (err.status / 100) >= 5 || err.status === 0
            );
          }
          return of(false);
        }),);
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this._userService.setUser(null);
  }

  public forgotPassword(emailAddress: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = environment.urls.gateway + environment.urls.passwordReset;

    return this.http
      .post(
        url,
        {
          clientId: environment.oauth.clientId,
          emailAddress
        },
        {
          headers
        }
      );
  }

  private handleError(error: any): void {

    const errorMessage = (error.message) ? error.message :
      error.status ? '${error.status} - ${error.statusText}' : 'Server error';

    this._logger.error('HAL ServiceError', errorMessage);

    this._alertService.addAlert(error);
  }
}
