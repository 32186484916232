import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'c360-adminboard',
  templateUrl: './adminboard.component.html'
})
export class AdminboardComponent implements OnInit {

  serviceKeys: string[];
  serviceUrls: any = {};

  constructor(private _sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.serviceKeys = Object.keys(environment.services);
    this.serviceKeys.forEach(serviceKey => this.serviceUrls[serviceKey] = this._sanitizer.bypassSecurityTrustResourceUrl(environment.urls['gateway'] + environment.services[serviceKey]));
  }

}
