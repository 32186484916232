import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {HalEventsService} from '../../services/hal-events.service';
import {UserService} from '../../../login/services/user.service';
import {FilmHalData} from '../../models/film-hal-data';
import {Film} from '../../models/film.model';
import {FilmDeleteModalComponent} from '../film-delete-modal/film-delete-modal.component';
import {BaseListComponent} from '../../../shared/components/base-list.component';

@Component({
  selector: 'c360-events-film-list',
  templateUrl: './films-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilmsListComponent extends BaseListComponent implements OnInit, OnDestroy {
  private _filmHalData: FilmHalData = null;
  private _subscription: Subscription = null;
  private _isLoading: boolean;
  private _isSearchLoading: boolean;
  private _lastSearchTitle: string;

  /**
   *
   * @param {HalEventsService} _halEventsService
   * @param {NgbModal} _modalService
   * @param {LoggerService} _logger
   * @param {UserService} _userService
   * @param {ChangeDetectorRef} _ref
   */
  constructor(private _halEventsService: HalEventsService,
              private _modalService: NgbModal,
              private _logger: LoggerService,
              private _userService: UserService,
              private _ref: ChangeDetectorRef) {

    super();
    this._isSearchLoading = false;
  }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this._subscription = this._halEventsService.films.subscribe((halData: FilmHalData) => {
      if (null != halData && null != halData.data) {

        this._filmHalData = halData;
        if (null != halData.page) {
          this.currentPage = halData.page.currentPage;
        }
        this._isLoading = false;
        this._isSearchLoading = false;
        this._ref.markForCheck();
      }
    });
    this.getAll();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  /**
   *
   * @param {number} page
   */
  private getAll(page: number = null): void {
    if (!this._lastSearchTitle) {
      this._halEventsService.getAllFilms(page);
    } else {
      this._isSearchLoading = true;
      this._halEventsService
        .findAllFilmsByMandatorIdAndTitle(this._userService.getUser().mandatorId, this._lastSearchTitle, page)
        .then(
          (data) => {
            const halData = this._halEventsService.films.value;
            this._filmHalData = halData;
            if (halData && null != halData.page) {
              this.currentPage = halData.page.currentPage;
            }
            this._isSearchLoading = false;
            this._ref.markForCheck();
          }
        );
    }
  }

  /**
   *
   * @param {number} pageNumber
   */
  getAllByPage(pageNumber: number): void {
    this.getAll(pageNumber);
  }

  /**
   *
   * @param {Film} film
   */
  onDelete(film: Film): void {
    const modalRef = this._modalService.open(FilmDeleteModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.film = film;
    modalRef.result.then(
      (data) => {
        if (data === true) {
          this._logger.info('onDelete', film);
          this._halEventsService
            .deleteFilm(film)
            .then(() => {
              this.init();
            });
        }
      }
    );
  }

  public onSearch(event: string): void {
    this._isSearchLoading = true;
    this._lastSearchTitle = event;
    this.getAllByPage(1);
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {boolean}
   */
  get isSearchLoading(): boolean {
    return this._isSearchLoading;
  }

  /**
   *
   * @returns {FilmHalData}
   */
  get filmHalData(): FilmHalData {
    return this._filmHalData;
  }
}
