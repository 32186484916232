import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScheduleService} from '../../services/schedule.service';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {CounterScheduleScopes} from '../../enums/schedule-scope.enum';
import {Subscription} from 'rxjs';
import {ScheduleExport} from '../../models/schedule-export.response.models';
import {CounterScheduleViewModes} from '../../enums/schedule-view-modes.enum';
import {isString} from 'util';
import {UserService} from '../../../login/services/user.service';

@Component({
  selector: 'c360-counter-schedule',
  templateUrl: './counter-schedule.component.html'
})
export class CounterScheduleComponent implements OnInit, OnDestroy {

  public schedule: ScheduleExport;
  public error;

  public isLoading = true;
  public isRefreshing = false;

  private _subscription: Subscription;
  private _scope: CounterScheduleScopes = null;
  private _viewMode: CounterScheduleViewModes = null;
  private _viewModes = CounterScheduleViewModes;
  public isToday = false;

  private _filmFilter: string = null;
  private _refreshInterval = null;
  private _lastUpdate: string;

  get lastUpdate(): string {
    return this._lastUpdate;
  }

  get filmFilter(): string {
    return this._filmFilter;
  }

  get scope(): CounterScheduleScopes {
    return this._scope;
  }

  get viewMode(): CounterScheduleViewModes {
    return this._viewMode;
  }

  get viewModes() {
    return this._viewModes;
  }

  constructor(private _scheduleService: ScheduleService,
              private _logger: LoggerService,
              private _userService: UserService) {
  }


  ngOnInit(): void {
    this._viewMode = this._scheduleService.viewMode;
    this._subscription = this._scheduleService
      .isLoading$()
      .subscribe(isLoading => this.isRefreshing = isLoading);

    this._subscription.add(
      this._scheduleService.getSchedule$().subscribe(
        (schedule: ScheduleExport) => {
          this._scope = this._scheduleService.scope;
          this._viewMode = this._scheduleService.viewMode;

          if (schedule != null) {
            this.schedule = schedule;

            this.isLoading = false;
            this._lastUpdate = this.schedule.timestamp.fromNow();
            /*
            if (this._refreshInterval != null) {
              clearInterval(this._refreshInterval);
            }
            this._refreshInterval = setInterval(() => {
              this._lastUpdate = this.schedule.timestamp.fromNow();
              if (moment().diff(this.schedule.timestamp, 'minutes') >= 15) {
                this.getSchedule(this._scope);
              }
            }, 15000);
            */
          }
        }
      )
    );
    this._scope = this._scheduleService.scope;
    this._viewMode = this._scheduleService.viewMode;
    this.getSchedule(this._scope);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._refreshInterval = null;
  }

  refresh(): void {
    this.getSchedule(this._scope);
  }

  onFilmFilterChanged(filter): void {
    this._filmFilter = filter;
  }

  onSetScope(scope): void {
    this._scope = scope;
    this.getSchedule(scope);
  }

  onSetViewMode(viewMode: CounterScheduleViewModes): void {
    this._scheduleService.viewMode = viewMode;
    this._viewMode = viewMode;
  }

  private getSchedule(scope = null) {
    this._scheduleService.askForSchedule(scope);
  }

  /**
   *
   * @returns {boolean}
   */
  get existsLocationId(): boolean {
    return this._userService.getUser().locationId && isString(this._userService.getUser().locationId);
  }
}
