import {Component, Input} from '@angular/core';
import {ScheduleExport, ScheduleExportPerformance} from '../../../models/schedule-export.response.models';
import {environment} from '../../../../../environments/environment';
import {AcrossTabsParentService} from '../../../../core/services/across-tabs.service';
import {PerformanceStatus} from '../../../../performances/enums/performance-status.enum';

@Component({
  selector: 'c360-counter-schedule-list-day',
  templateUrl: './counter-schedule-list-day.component.html'
})
export class CounterScheduleListDayComponent {

  @Input() schedule: ScheduleExport = null;

  public performanceStatus = PerformanceStatus;

  constructor(private _acrossTabsParentService: AcrossTabsParentService) {
  }

  gotoBooking(performance: ScheduleExportPerformance): void {
    if (performance.status !== this.performanceStatus.SOLD_OUT) {
      this._acrossTabsParentService.openNewTab(
        '' + environment.urls.booking + 'checkout/' + performance.id
      );
    }
  }

}
