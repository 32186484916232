import {TransactionProductType} from '../../enums/transaction-product-type.enum';
import {TransactionProductStatus} from '../../enums/transaction-product-status.enum';

export class TransactionProduct {

  private _id: string;
  private _externalId: string;
  private _type: TransactionProductType;
  private _name: string;
  private _code: string;
  private _netAmount: number;
  private _grossAmount: number;
  private _vatSet: number;
  private _status: TransactionProductStatus;

  constructor() {
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get externalId(): string {
    return this._externalId;
  }

  set externalId(value: string) {
    this._externalId = value;
  }

  get type(): TransactionProductType {
    return this._type;
  }

  set type(value: TransactionProductType) {
    this._type = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get netAmount(): number {
    return this._netAmount;
  }

  set netAmount(value: number) {
    this._netAmount = value;
  }

  get grossAmount(): number {
    return this._grossAmount;
  }

  set grossAmount(value: number) {
    this._grossAmount = value;
  }

  get vatSet(): number {
    return this._vatSet;
  }

  set vatSet(value: number) {
    this._vatSet = value;
  }

  get status(): TransactionProductStatus {
    return this._status;
  }

  set status(value: TransactionProductStatus) {
    this._status = value;
  }
}
