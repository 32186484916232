import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalCounterTransactionsService} from '../hal-counter-transactions.service';

@Injectable()
export class CounterTransactionsRootLinkResolver implements Resolve<boolean> {

  constructor(private _halTransactionService: HalCounterTransactionsService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this._halTransactionService.getRootLink();
  }
}
