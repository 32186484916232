import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ConfirmationService, ICounterConfirmationCart} from '../../services/confirmation.service';
import {TranslateService} from '@ngx-translate/core';
import {isArray} from 'util';
import {CounterConfirmationCartStatus} from '../../enums/counter-confirmation-cart-status.enum';
import {ConfirmRefundModalComponent} from './confirm-refund-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmPayModalComponent} from './confirm-pay-modal.component';

@Component({
  selector: 'c360-counter-confirmation-cart',
  templateUrl: './counter-confirmation-cart.component.html',
  styleUrls: [
    'counter-confirmation-cart.component.scss'
  ]
})
export class CounterConfirmationCartComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  private _counterConfirmationCartCurrencyGrouped: Array<{ currency: string; items: Array<ICounterConfirmationCart>; total: number; }>;
  private _isLoading: boolean;
  private _isActive: boolean;

  constructor(
    private readonly _confirmationService: ConfirmationService,
    private readonly _translateService: TranslateService,
    private readonly _modalService: NgbModal
  ) {
    this._isLoading = false;
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
    this.initData();
    this._subscriptions
      .add(
        this._confirmationService
          .counterConfirmationCartEvent
          .subscribe(
            (data) => {
              this.initData();
              if (data === CounterConfirmationCartStatus.LOADING) {
                this._isLoading = true;
              } else {
                this._isLoading = false;
              }
            },
            () => {

            }
          )
      ).add(
      this._confirmationService
        .isActive$()
        .subscribe(
          (data) => {
            this._isActive = data;
          },
          () => {

          }
        )
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private initData(): void {
    const counterConfirmationCartItems: Array<ICounterConfirmationCart> = this._confirmationService.getCounterConfirmationCart();
    if (!counterConfirmationCartItems || !isArray(counterConfirmationCartItems)) {
      this._counterConfirmationCartCurrencyGrouped = [];
      return;
    }
    const currencies: Array<string> = counterConfirmationCartItems.map((item) => item.currency).reduce((x, y) => x.includes(y) ? x : [...x, y], []);
    this._counterConfirmationCartCurrencyGrouped = [];
    currencies.map((currency) => {
      const items = counterConfirmationCartItems.filter((item) => item.currency === currency);
      this._counterConfirmationCartCurrencyGrouped.push(
        {
          currency: currency,
          items: items,
          total: items.map((item) => {
            if (item.skipSaleFee || !item.fullAmountWithFee) {
              return +item.fullAmount;
            }
            return +item.fullAmountWithFee;
          }).reduce((a, b) => a + b, 0)
        }
      );
    });
  }

  public refundTransaction(): void {
    const confirmModal = this._modalService.open(ConfirmRefundModalComponent, {backdrop: 'static'});
    confirmModal.result.then(
      (data) => {
        this._confirmationService
          .removeCounterConfirmationCart(true)
          .subscribe();
        if(this._isActive) {
          this._confirmationService
            .toggleActive()
            .subscribe();
        }
      },
      () => {
      }
    );
  }

  public pay(): void {
    const confirmModal = this._modalService.open(ConfirmPayModalComponent, {backdrop: 'static'});
    confirmModal.componentInstance.counterConfirmationCartCurrencyGrouped = this._counterConfirmationCartCurrencyGrouped;

    confirmModal.result.then(
      (data) => {
        this._confirmationService
          .removeCounterConfirmationCart(false)
          .subscribe();
        if(this._isActive) {
          this._confirmationService
            .toggleActive()
            .subscribe();
        }
      },
      () => {
      }
    );
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get currentLang(): string {
    return this._translateService.currentLang;
  }

  get counterConfirmationCartCurrencyGrouped(): Array<{ currency: string; items: Array<ICounterConfirmationCart>; total: number }> {
    return this._counterConfirmationCartCurrencyGrouped;
  }

  get emptyList(): boolean {
    return !this._counterConfirmationCartCurrencyGrouped || this._counterConfirmationCartCurrencyGrouped.length === 0;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  public toggleActive(): void {
    this._confirmationService
      .toggleActive()
      .subscribe();
  }
}
