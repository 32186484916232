<div class="modal-header">
  <h4 class="modal-title" [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.CONFIRM_REFUND_MODAL.HEADER'"></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body clearfix">
  <p [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.CONFIRM_REFUND_MODAL.CONETENT'"></p>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-dark"
          (click)="activeModal.dismiss()"
          [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.BUTTONS.CANCEL'">
  </button>
  <button type="button"
          class="btn btn-danger"
          (click)="activeModal.close()"
          [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.BUTTONS.REFUND'">
  </button>
</div>
