import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TransactionTicket} from '../../../models/transaction/transaction-ticket.model';
import {TranslateService} from '@ngx-translate/core';
import {Transaction} from '../../../models/transaction/transaction.model';

@Component({
  selector: '[c360-counter-transactions-update-ticket-type-item]',
  templateUrl: './counter-transactions-update-ticket-type-item.component.html'
})
export class CounterTransactionsUpdateTicketTypeItemComponent implements OnInit {

  @Input() transaction: Transaction;
  @Input() ticket: TransactionTicket;
  @Input() priceList: any;
  @Input() currency: string;
  @Output() onPriceChange: EventEmitter<any> = new EventEmitter<any>();

  calcAmount: number = 0;
  calcDifference: number = 0;
  amount: number = 0;

  /**
   * constructor
   * @param {TranslateService} _translationService
   * @param {ChangeDetectorRef} _ref
   */
  constructor(private _translationService: TranslateService,
              private _ref: ChangeDetectorRef) {
  }

  /**
   *  Init
   */
  ngOnInit(): void {

    this.amount = this.ticket.fullAmountWithFee;

    if (this.transaction.skipSaleFee) {
      this.amount = this.ticket.fullAmount;
    }

    this.calcAmount = this.amount;
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }

  /**
   * changePrice
   * @param event
   */
  public changePrice(event): void {

    const priceId = event.target.value;
    const priceAmount: number = this.getAmonutFromPrice(priceId);

    if (priceId !== this.ticket.priceId) {

      this.calcDifference = priceAmount - this.amount;
      this.calcAmount = priceAmount;

    } else {

      this.calcAmount = this.amount;
      this.calcDifference = 0;
    }

    const obj = {calcDifference: this.calcDifference, calcAmount: this.calcAmount, ticketId: this.ticket.id, priceId: priceId};

    this._ref.markForCheck();
    this.onPriceChange.emit(obj);
  }

  /**
   *
   * @param priceId
   * @returns {number}
   */
  private getAmonutFromPrice(priceId): number {

    let _amount = 0;
    this.priceList.forEach(price => {

      if (priceId === price.id) {
        if (this.transaction.skipSaleFee) {

          _amount = price.fullAmount;
        } else {

          _amount = price.fullAmountWithFee;
        }
      }
    });

    return _amount;
  }
}
