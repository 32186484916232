<td [innerHtml]="voucher.name"></td>
<td [class.created]="voucher.status === 'CREATED'"
    [class.available]="voucher.status === 'AVAILABLE'"
    [class.emitted]="voucher.status === 'EMITTED'"
    [class.redeemed]="voucher.status === 'REDEEMED'"
    [class.revoked]="voucher.status === 'REVOKED'"
    [innerHtml]="'MODUL_COUNTER.VOUCHER.STATUS.' + voucher.status | translate"></td>
<td [innerHtml]="voucher.description"></td>
<td>
  <!-- VALUE -->
  <span *ngIf="voucher.type === 'VALUE' || voucher.type === 'TICKET_VALUE'"
        [innerHtml]="voucher.valueInitial + '€'"></span>
  <!-- TICKET_PERCENT -->
  <ng-container *ngIf="voucher.type === 'TICKET_PERCENT'">
    <span [class.created]="voucher.status === 'CREATED'"
          [class.available]="voucher.status === 'AVAILABLE'"
          [class.emitted]="voucher.status === 'EMITTED'"
          [class.redeemed]="voucher.status === 'REDEEMED'"
          [class.revoked]="voucher.status === 'REVOKED'"
          translate="MODUL_COUNTER.VOUCHER.LIST.VALUE.TICKET_PERCENT"></span>
  </ng-container>
</td>
<td>
  <!-- VALUE or TICKET_VALUE -->
  <span [class.created]="voucher.status === 'CREATED'"
        [class.available]="voucher.status === 'AVAILABLE'"
        [class.emitted]="voucher.status === 'EMITTED'"
        [class.redeemed]="voucher.status === 'REDEEMED'"
        [class.revoked]="voucher.status === 'REVOKED'"
        [innerHtml]="voucher.valueCurrent + '€'"
        *ngIf="voucher.type === 'VALUE' || voucher.type === 'TICKET_VALUE'"></span>
  <!-- TICKET_PERCENT -->
  <span *ngIf="voucher.type === 'TICKET_PERCENT'">-</span>
</td>
<ng-container *ngIf="!isLoading">
  <td class="text-right" *ngIf="voucher.status === 'CREATED' || voucher.status === 'AVAILABLE'">
    <form *ngIf="paymentForm"
          [formGroup]="paymentForm"
          (ngSubmit)="callBookingTransactionCompletionProductSimpleRequest()">
      <label for="paymentProviderType"></label>
      <select class="form-control custom-select w-100"
              id="paymentProviderType"
              formControlName="paymentProviderType">
        <option *ngFor="let key of paymentTypesListKey" [value]="key">
          {{'MODUL_TRANSACTIONS.ENUMS.PAYMENT_TYPES.' + paymentTypesList[key] | translate}}
        </option>
      </select>
      <div class="alert alert-danger mt-1"
           *ngIf="paymentForm.get('paymentProviderType').errors && (paymentForm.get('paymentProviderType').touched || paymentForm.get('paymentProviderType').dirty)">
        <c360-form-validation-messages
          [control]="paymentForm.get('paymentProviderType')"></c360-form-validation-messages>
      </div>
      <button class="btn btn-success">
        {{ 'MODUL_COUNTER.VOUCHER.BUTTONS.SET-STATUS-EMITTED' | translate}}
      </button>
    </form>
  </td>
  <td class="text-right" *ngIf="voucher.status === 'EMITTED'">
    <button class="btn btn-danger"
            (click)="callBookingTransactionRefundProductSimpleRequest()">
      {{ 'MODUL_COUNTER.VOUCHER.BUTTONS.SET-STATUS-REVOKED' | translate}}
    </button>
  </td>
  <td class="text-right" *ngIf="voucher.status === 'REDEEMED' || voucher.status === 'REVOKED'">
    <button class="btn btn-danger"
            (click)="revertStatus()">
      {{ 'MODUL_COUNTER.VOUCHER.BUTTONS.SET-STATUS-AVAILABLE' | translate}}
    </button>
  </td>
</ng-container>
<td class="text-right" *ngIf="isLoading">
  <button class="btn"
          [class.btn-success]="voucher.status === 'CREATED' || voucher.status === 'AVAILABLE'"
          [class.btn-danger]="voucher.status === 'EMITTED' || voucher.status === 'REDEEMED' || voucher.status === 'REVOKED'"
          disabled="disabled"
  >
    {{ 'LOADING.SHORT_TEXT' | translate }}
  </button>
</td>
