import * as moment from 'moment';
import {Moment} from 'moment';
import {FilmAgeRatings} from '../../events/enums/film-age-ratings.enum';
import {PerformanceStatus} from '../../performances/enums/performance-status.enum';
import {ScheduleExportOccupationStats} from '../../counter/models/schedule-export.response.models';

export interface StatsExportResponse {
  countOfSessions: number;
  countOfProcesses: number;
  performanceStats: StatsExportPerformanceResponse[];
}

export interface StatsExportPerformanceResponse {
  countOfProcesses: number;
  performance: StatsExportPerformance;
  occupationStats: ScheduleExportOccupationStats;
}

export interface StatsExportPerformance {
  id: string;
  absoluteRuntime: number;
  areaLayoutId: string;
  areaLayoutName: string;
  attributeIds: string[];
  auditoriumId: string;
  auditoriumLayoutId: string;
  auditoriumLayoutName: string;
  auditoriumName: string;
  eventId: string;
  filmAgeRating: FilmAgeRatings;
  filmId: string;
  filmTitle: string;
  lastStatus: PerformanceStatus;
  locationId: string;
  locationName: string;
  mandatorId: string;
  priceLayoutId: string;
  priceLayoutName: string;
  startDate: string;
  status: PerformanceStatus;
  ticketSystemType: string;
}

export class StatsExport {
  response: StatsExportResponse;
  timestamp: Moment = null;

  constructor(statsExportResponse: StatsExportResponse, timestamp?: Moment) {

    if (null != timestamp) {
      this.timestamp = timestamp;
    } else {
      this.timestamp = moment();
    }

    this.response = statsExportResponse;

    // Object.keys(statsExportResponse.performanceStats).forEach((i) => {
    //   statsExportResponse.performanceStats[i].performance.startDate = moment(statsExportResponse.performanceStats[i].performance.startDate);
    // });
  }
}

