<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h1 [translate]="'MODUL_COUNTER.SCHEDULE.TITLE'"></h1>
    </div>
    <div class="card-header" *ngIf="existsLocationId">
        <c360-counter-schedule-control
          (filmFilterChanged)="onFilmFilterChanged($event)"
          (setScope)="onSetScope($event)"
          (setViewMode)="onSetViewMode($event)"
          (onRefresh)="refresh()"
          [scope]="scope"
          [viewMode]="viewMode"
          [isRefreshing]="isRefreshing">
        </c360-counter-schedule-control>
    </div>
    <div class="card-body" *ngIf="!existsLocationId">
      {{ 'COMMON.CHOOSE_LOCATION' | translate }}
    </div>
    <div class="card-body" *ngIf="existsLocationId">
      <div class="row">
        <div class="col text-right">
          <i class="fa fa-refresh" *ngIf="isRefreshing" [ngClass]="{'fa-spin': isRefreshing}"></i>&nbsp;<small class="text-center">Zuletzt aktualisiert: <b>{{lastUpdate}}</b></small>
        </div>
        <div class="col-12">
          <ng-container *ngIf="schedule && schedule.startDate && schedule.endDate && schedule.daysList.length > 0">
            <p class="text-center">Freigebene Vorstellungen in der Zeit von <strong>{{schedule.startDate.format('DD.MM.YYYY HH:mm')}} Uhr</strong> bis <strong>{{schedule.endDate.format('DD.MM.YYYY HH:mm')}} Uhr.</strong></p>
          </ng-container>
          <ng-container *ngIf="schedule && schedule.startDate && schedule.endDate && schedule.daysList.length == 0">
            <p class="text-center">Wir konnten in der Zeit vom <strong>{{schedule.startDate.format('DD.MM.YYYY HH:mm')}} Uhr</strong> bis <strong>{{schedule.endDate.format('DD.MM.YYYY HH:mm')}} Uhr</strong> keine freigebenen Vorstellungen finden.</p>
          </ng-container>
          <ng-container *ngIf="schedule && !schedule.startDate && !schedule.endDate && schedule.daysList.length == 0">
            <p class="text-center">Wir konnten keine freigebenen Vorstellungen finden.</p>
          </ng-container>
          <ng-container *ngIf="!schedule">
            <p class="text-center">Es konnten keine Veranstaltungen abgerufen werden.</p>
          </ng-container>
        </div>
      </div>



      <ng-container *ngIf="isLoading">
        <c360-loading [loadingMessage]="'Lade Spielplan'"></c360-loading>
      </ng-container>

      <ng-container *ngIf="!isLoading && schedule">
        <ng-container [ngSwitch]="viewMode">
          <ng-container *ngSwitchCase="viewModes.DAY">
            <c360-counter-schedule-list-day [schedule]="schedule"></c360-counter-schedule-list-day>
          </ng-container>
          <ng-container *ngSwitchCase="viewModes.FILM">
            <c360-counter-schedule-list-film [schedule]="schedule"></c360-counter-schedule-list-film>
          </ng-container>
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>
