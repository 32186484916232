<td>{{film.title}} [{{ 'MODUL_EVENTS.PRESENTATION_TYPE.' + film.presentationType | translate}} - {{'MODUL_EVENTS.LANGUAGE_VERSION.' + film.languageVersion | translate}}] <span class="runtime" *ngIf="film.runtime">, {{film.runtime}} Minuten</span><span class="rating" *ngIf="film.ageRating">, {{'MODUL_EVENTS.MODEL.FILM.AGERATINGS.' + film.ageRating | translate}}</span></td>
<td>{{performance.startDate.format('dd., DD.MM.YYYY')}}</td>
<td>{{performance.startDate.format('HH:mm')}}</td>
<td>{{performance.auditoriumName}}</td>
<ng-container *ngIf="performance.status !== performanceStatus.SOLD_OUT">
  <td *ngIf="performance.occupationStats" ngClass="{{getClassForPercentageOfFreeSeats(performance)}}">{{performance.occupationStats.free + performance.occupationStats.blocked}}</td>
  <td *ngIf="!performance.occupationStats">N/A</td>
</ng-container>
<ng-container *ngIf="performance.status === performanceStatus.SOLD_OUT">
  <td><img src="/assets/img/sold-out.png"></td>
</ng-container>

