import {HalData} from '../../shared/models/halData';
import {User} from './user';
import {isArray} from 'util';

export class UserHalData extends HalData {

  data: { [id: string]: User } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.users) {
      response._embedded.users.forEach((user: User) => {
        this.parseToObject(user);
      });
    } else if (response._embedded && response._embedded.userSearches) {
      response._embedded.userSearches.forEach((user: User) => {
        this.parseToObject(user);
      });
    } else if (isArray(response)) {
      response.forEach((user: User) => {
        this.parseToObject(user);
      });
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): User {
    if (response != null) {
      const user = new User(
        response._links,
        response.id,
        response.mandatorId,
        response.locationId,
        response.externalId,
        response.accountEnabled,
        response.accountExpiryDate,
        response.accountLocked,
        response.emailAddress,
        response.emailAddressApproved,
        response.passwordExpiryDate,
        response.gender,
        response.firstName,
        response.lastName,
        response.phoneNumber,
        response.mobileNumber,
        response.streetName,
        response.houseNumber,
        response.postCode,
        response.cityName,
        response.role,
        response.fullName,
        response.userId,
        response.country
      );

      this.data['' + response.id + ''] = user;
    }
    return this.data['' + response.id + ''];
  }
}
