import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Role} from '../../models/role';
import {UserRoles} from '../../enums/user-roles.enum';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../login/services/user.service';

@Component({
  selector: 'c360-user-filter',
  templateUrl: './filter.component.html'
})

export class UserFilterComponent implements OnInit, OnDestroy {

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  /* Enums */
  private _roleList = UserRoles;
  private _roleListKey;

  private _selectedRole: Role;
  private _searchString: string;

  filterOptions: Array<any> = ['OPTIONAL_FILTER', 'ACTIVE_USER', 'BLOCKED_USER', 'UNCONFIRMED_USER'];
  selectedFilterOption: String = 'OPTIONAL_FILTER';

  constructor(private _userService: UserService) {
    this._searchString = null;
    this._selectedRole = null;
    this._roleListKey = [];
    for (const key in environment.userPermissionOrder) {
      if (environment.userPermissionOrder.hasOwnProperty(key)) {
        if (environment.userPermissionOrder[key] === this._userService.getUser().authorityBase || this._roleListKey.length > 0) {
          this._roleListKey.push(environment.userPermissionOrder[key]);
        }
      }
    }
    if (this._userService.getUser().authorityBase !== 'ROLE_ADMIN') {
      this._roleListKey.shift();
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

  }

  public onFilter() {
    const searchArray = [];
    if (this._selectedRole) {
      searchArray.push(`roles=${this._selectedRole}`);
    }
    if (this._searchString) {
      searchArray.push(`searches=${this._searchString}`);
    }
    const searchString = searchArray.length > 0 ? searchArray.join('&') : '';

    this.onSearch.emit(searchString);
  }

  get roleList() {
    return this._roleList;
  }

  get roleListKey(): Array<string> {
    return this._roleListKey;
  }

  get searchString(): string {
    return this._searchString;
  }

  set searchString(value: string) {
    this._searchString = value;
  }

  get selectedRole(): Role {
    return this._selectedRole;
  }

  set selectedRole(value: Role) {
    this._selectedRole = value;
  }
}
