import {Observable, of as observableOf, Subscription} from 'rxjs';
import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {HalTransactionsService} from '../../../transactions/services/hal-transactions.service';
import * as moment from 'moment';
import {ReportsTypes} from '../../../transactions/enums/report-types.enum';
import {ReportsGenerationStatus} from '../../../transactions/enums/report-generation-status.enum';
import {UserService} from '../../../login/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {concatMap} from 'rxjs/operators';

@Component({
  selector: 'c360-counter-report',
  templateUrl: './counter-report.component.html'
})
export class CounterReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;

  private _salesCounterId: string;

  private _reportType: string;
  private _currentStartDate: moment.Moment;
  private _currentEndDate: moment.Moment;

  private _generationStatus: string;
  private _isLoading: boolean;
  private _isLocation: boolean;

  @ViewChild('iframe', {static: true}) iframe: ElementRef;

  constructor(private _halTransactionsService: HalTransactionsService,
              private _userService: UserService,
              private _renderer2: Renderer2,
              private _translate: TranslateService) {
    this._isLoading = false;
    this._reportType = null;
  }

  ngOnInit(): void {
    this._isLocation = this._userService.getUser().authorityBase === 'ROLE_LOCATION';

    this._subscriptions = new Subscription();

    this._salesCounterId = this._userService.getUser().salesCounterId;

    this._reportType = this.canSalesCounterReport ? ReportsTypes.SALES_COUNTER_REPORT : ReportsTypes.DISTRIBUTOR_REPORT;

    this.iframe.nativeElement.srcdoc = '';
    this._renderer2.setStyle(this.iframe.nativeElement, 'height', `0px`);
    this.iframe.nativeElement.addEventListener('load', (object) => {
      this._isLoading = false;
      this._renderer2.setStyle(this.iframe.nativeElement, 'height', `${this.iframe.nativeElement.contentWindow.document.body.scrollHeight}px`);
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public getReport(): void {
    this._generationStatus = null;
    this._renderer2.setStyle(this.iframe.nativeElement, 'height', `0px`);

    let reportObject: Observable<any>;

    if (this._reportType === ReportsTypes.SALES_COUNTER_REPORT) {
      if (moment().get('hour') < 5) {
        this._currentStartDate = moment().add({
          days: -1
        }).set({
          hours: 5,
          minutes: 0,
          seconds: 0,
          milliseconds: 0
        });

        this._currentEndDate = moment().set({
          hours: 4,
          minutes: 59,
          seconds: 59,
          milliseconds: 0
        });
      } else {
        this._currentStartDate = moment().set({
          hours: 5,
          minutes: 0,
          seconds: 0,
          milliseconds: 0
        });

        this._currentEndDate = moment()
          .add({
            days: 1
          })
          .set({
            hours: 4,
            minutes: 59,
            seconds: 59,
            milliseconds: 0
          });
      }
    } else {
      this._currentStartDate = moment().set({
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      });

      this._currentEndDate = moment().set({
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 0
      });
    }
    const startDate = this._currentStartDate.format('YYYY-MM-DDTHH:mm:ss');
    const endDate = this._currentEndDate.format('YYYY-MM-DDTHH:mm:ss');

    if (this._reportType === ReportsTypes.SALES_COUNTER_REPORT && this._salesCounterId) {
      reportObject = this._halTransactionsService.generateReports(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + this._reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDate, endDate, true, this._salesCounterId);
    } else if (this._reportType === ReportsTypes.DISTRIBUTOR_REPORT) {
      reportObject = this._halTransactionsService.generateDistributorReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + this._reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDate, endDate, true);
    } else if (this._reportType === ReportsTypes.PERFORMANCE_REPORT) {
      reportObject = this._halTransactionsService.generatePerformanceReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + this._reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDate, endDate, true);
    } else if (this._reportType === ReportsTypes.PERFORMANCE_REPORT_INCLUDE_SELLING_POINT) {
      reportObject = this._halTransactionsService.generatePerformanceReportIncludeSellingPoint(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + this._reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDate, endDate, true);
    } else if (this._reportType === ReportsTypes.TOTAL_REPORT) {
      reportObject = this._halTransactionsService.generateTotalReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + this._reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDate, endDate, true);
    }
    if (!reportObject) {
      return;
    }
    this._isLoading = true;
    this._subscriptions
      .add(
        reportObject
          .pipe(
            concatMap(
              (result) => {
                if (result.generationStatus === ReportsGenerationStatus.GENERATION_IS_COMPLETED) {
                  return this._halTransactionsService.getReportsHtml(result.id);
                }
                this._generationStatus = result.generationStatus;
                return this._halTransactionsService
                  .getReportObjectById(result.id)
                  .pipe(
                    concatMap(
                      (response) => {
                        this._generationStatus = response.generationStatus;
                        if (response.generationStatus === ReportsGenerationStatus.GENERATION_IS_COMPLETED) {
                          return this._halTransactionsService
                            .getReportsHtml(response.id);
                        }
                        return observableOf(null);
                      }
                    )
                  );
              }
            )
          ).subscribe(
            (html) => {
              if (html) {
                this.iframe.nativeElement.srcdoc = html;
                this._isLoading = false;
              }
            },
            () => {
              this._isLoading = false;
            }
          )
      );
  }

  /**
   *
   * @return {boolean}
   */
  get canSalesCounterReport(): boolean {
    return !!(this._salesCounterId && this._userService.getUser().locationId);
  }

  /**
   *
   * @return {boolean}
   */
  get canPerformanceReport(): boolean {
    return this._userService.getUser().locationId && this._isLocation;
  }

  /**
   *
   * @enum {string}
   */
  get reportsTypes() {
    return ReportsTypes;
  }

  /**
   *
   * @return {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @return {string}
   */
  get generationStatus(): string {
    return this._generationStatus;
  }

  /**
   *
   * @return {string}
   */
  get reportType(): string {
    return this._reportType;
  }

  /**
   *
   * @param value
   */
  set reportType(value: string) {
    this._reportType = value;
  }
}
