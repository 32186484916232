export enum SpaceStatus {
  BLOCKED = 'BLOCKED',
  BLOCKED_BY_DISTANCING_RULES = 'BLOCKED_BY_DISTANCING_RULES',
  BROKEN = 'BROKEN',
  FREE = 'FREE',
  LOCKED_FOR_RESERVATION = 'LOCKED_FOR_RESERVATION', // No Reservation allowed,
  LOCKED_FOR_SALE = 'LOCKED_FOR_SALE', // No sale allowed,
  LOCKED_FOR_SALE_AND_RESERVATION = 'LOCKED_FOR_SALE_AND_RESERVATION', // No Sale or Reservation allowed,
  RESERVED = 'RESERVED',

  SELECTED = 'SELECTED', // A seat which is currently selected by some customer
  SELECTED_BY_USER = 'SELECTED_BY_USER', // A seat which is currently selected by current customer
  SELECTED_TEMP = 'SELECTED_TEMP', // A seat which is temporarily selected by customer, SeatingRules pending

  SOLD = 'SOLD',
  TAKEN_BY_FRIEND = 'TAKEN_BY_FRIEND',
  TEMPORARILY_SELECTED = 'TEMPORARILY_SELECTED', // A seat which is not yet sold but marked from someone else
  INVISIBLE = 'INVISIBLE',
  UNKNOWN = 'UNKNOWN'
}
