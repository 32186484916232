import {Component, OnDestroy, OnInit} from '@angular/core';
import {HalPaymentService} from '../../services/hal-payment.service';
import {UserService} from '../../../login/services/user.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'c360-voucher-status',
  templateUrl: './voucher-status.component.html'
})
export class VoucherStatusComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  private _voucherStatusForm: FormGroup;
  private _searchVoucherError: string;

  /**
   *
   * @param {HalPaymentService} _halPaymentService
   * @param {FormBuilder} _formBuilder
   * @param {UserService} _userService
   */
  constructor(private readonly _halPaymentService: HalPaymentService,
              private readonly _formBuilder: FormBuilder,
              private readonly _userService: UserService) {
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
    this._halPaymentService.destroyDataById('vouchers');

    this._voucherStatusForm = this._formBuilder
      .group({
        voucherCode: [
          '',
          [Validators.required]
        ]
      });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public searchSubmit(): void {
    this._searchVoucherError = null;
    if (this._voucherStatusForm.valid) {
      this._subscriptions
        .add(
          this._halPaymentService
            .searchVoucherByMandatorIdAndVoucherCode(this._userService.getUser().mandatorId, this._voucherStatusForm.get('voucherCode').value)
            .subscribe(
              (data) => {
              },
              (response: HttpErrorResponse) => {
                if (response && response.error && response.error.errorUserMessage) {
                  this._searchVoucherError = response.error.errorUserMessage;
                }
              }
            )
        );
    } else {
      this.validateAllFormFields(this._voucherStatusForm);
    }
  }

  /**
   *
   * @param {FormGroup} formGroup
   */
  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   *
   * @return {FormGroup}
   */
  get voucherStatusForm(): FormGroup {
    return this._voucherStatusForm;
  }

  /**
   *
   * @return {string}
   */
  get searchVoucherError(): string {
    return this._searchVoucherError;
  }

  /**
   *
   * @return {boolean}
   */
  get hasMandatorId(): boolean {
    return !!this._userService.getUser().mandatorId;
  }
}
