import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'c360-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
