import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HalResponse} from './halResponse';
import {LoggerService} from './logger/logger.service';
import {HalData} from '../models/halData';
import {Page} from '../models/page';
import {AlertService} from './alert.service';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UserService} from '../../login/services/user.service';
import {Params} from '../models/search/params.model';
import {ErrorMessage} from '../models/error.model';
import {isUndefined} from 'util';

@Injectable()
export class HalService {

  protected gatewayUrl = environment.urls.gateway;
  protected gatewayName: string;

  // Data
  protected _data: { [listName: string]: HalData } = {};
  public data = new BehaviorSubject<{ [listName: string]: HalData }>(null);

  protected headers = new HttpHeaders({
    'Content-Type': 'application/hal+json;charset=UTF-8'
  });

  protected requestHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
  }

  getAll(domainName: string, page: number = null, params: Params = null, sort: string = 'asc', totalElementsForPage: number = 25): void {
    this.getAllByMandatorId(this._userService.getUser().mandatorId, domainName, page, params, sort, totalElementsForPage);
  }

  getAllByMandatorId(mandatorId: string, domainName: string, page: number = null, params: Params = null, sort: string = 'asc', totalElementsForPage: number = 25): void {

    // Create new Page Object or set CurrentPage
    if (this._data[domainName].page == null) {
      this._data[domainName].page = new Page();
      this._data[domainName].page.sort = sort;
      this._data[domainName].page.totalElementsForPage = totalElementsForPage;
    } else if (page != null) {
      this._data[domainName].page.currentPage = page;
      this._data[domainName].page.sort = sort;
      this._data[domainName].page.totalElementsForPage = totalElementsForPage;
    }

    // Read all _data from gateway and return HalData
    const url: string = this.getUrlWithOptionalPagingParams(mandatorId, domainName, this._data[domainName], params);

    this.http.get(url, {headers: this.headers})
      .toPromise()
      .then((response: HalResponse) => {
        this._data[domainName].parseToArrayObjects(response);
        this.data.next(this._data);
      })
      .catch((reason) => {
        this.handleError(reason);
      });
  }

  get(halData: HalData, _id): Promise<any> {
    this._logger.info('_get', halData, _id);
    return this.http.get(halData.href + '/' + _id, {headers: this.headers})
      .toPromise()
      .then((response: any) => {

        const halParsedData = halData.parseToObject(response);
        this.data.next(this._data);

        return halParsedData;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  getByExternalId(halData: HalData, _id): Promise<any> {
    this._logger.info('_get', halData.href, _id);

    return this.http.get(halData.href + '/search/findByExternalId?externalId=' + _id, {headers: this.headers})
      .toPromise()
      .then((response: any) => {

        const halParsedData = halData.parseToObject(response);
        this.data.next(this._data);

        return halParsedData;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  search(url: string): Promise<any> {
    return this.http.get(url, {headers: this.headers})
      .toPromise()
      .catch((reason) => {
        this.handleError(reason);
      });
  }

  save(halData: HalData, _data: any): Promise<any> {
    return this.http.post(halData.href, _data, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {
        halData.parseToObject(response);
        this.data.next(this._data);
        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  update(halData: HalData, id: any, _data: any): Promise<any> {
    return this.http.patch(halData.href + '/' + id, _data, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        const responseObject = halData.parseToObject(response);
        this.data.next(this._data);
        return responseObject;
      })
      .catch((reason) => {
        this.handleError(reason);
      });
  }

  delete(halData: HalData, id): Promise<any> {
    this._logger.info('Deleting', halData, id);
    return this.http
      .delete(halData.href + '/' + id, {headers: this.requestHeaders})
      .toPromise()
      .then((success) => {
        halData.removeById(id);
        this.data.next(this._data);
      })
      .catch((reason) => {
        this._logger.error('Err reason', reason);
        this.handleError(reason);
      });
  }

  getRootLink(): Promise<boolean> | boolean {

    if (this._data != null && Object.keys(this._data).length > 0) {
      return true;
    }

    if (this._userService.getUser() == null) {
      return false;
    }

    return this.http
      .get(this.gatewayUrl + this.gatewayName + '/', {headers: this.headers})
      .toPromise()
      .then((response: any) => {
        this.parseRootLinks(response);
        return true;
      })
      .catch((reason) => {
        this.handleError(reason);
        return false;
      });

    /*return new Promise((resolvePromise, reject) => {
      // Already stored endpoints

      if (this._data != null && Object.keys(this._data).length > 0) {
        resolvePromise(true);
      }

      this.http
        .get(this.gatewayUrl + this.gatewayName + '/', {headers: this.headers})
        .toPromise()
        .then((response: any) => {
          this.parseRootLinks(response);
          resolvePromise(true);
        })
        .catch((reason) => {
          this.handleError(reason);
          reject(reason);
        });
    });*/
  }

  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Srtip page params
      this._data[id] = new HalData(id, link);
      this.data.next(this._data);
    });
  }

  /** Remove options {} from url */
  getRawLink(link: string): string {
    const tmp = link.split('{');
    return tmp[0];
  }

  protected handleError(error: any): void {

    if (error.error && error.error.errorUserMessage) {

      const _errorMessage: ErrorMessage = new ErrorMessage(error.error.errorCode, error.error.errorMessage, error.error.errorUserMessage);

      this._logger.error('HAL ServiceError', _errorMessage);
      this._alertService.addAlert(_errorMessage);
    } else if (!isUndefined(error.error)) {

      this._logger.error('HAL ServiceError', error);
      this._alertService.addAlert(error);
    }

    if (error.error && error.error.errorValidationList) {
      for (const key in error.error.errorValidationList) {
        if (error.error.errorValidationList.hasOwnProperty(key)) {
          const messageObject = error.error.errorValidationList[key];
          this._alertService.addAlert(messageObject.message);
        }
      }
    } else {

//      this._alertService.addAlert(error);
    }
  }

  private getUrlWithOptionalPagingParams(mandatorId: string, domainName: string, endpoint: any, params: Params = null): string {

    if (mandatorId != null) {

      if (domainName === 'findSepaFiles') {

        if (params === null) {

          return endpoint.href + '?mandatorId=' + mandatorId + '&' + endpoint.page.getParamsForPage();
        }

        return endpoint.href + '?mandatorId=' + mandatorId + '&' + endpoint.page.getParamsForPage() + params.getParamsForSearch();
      } else {
        if (params === null) {

          return endpoint.href + '/search/findAllByMandatorId?mandatorId=' + mandatorId + '&' + endpoint.page.getParamsForPage();
        }

        return endpoint.href + '/search/searchAll?mandatorId=' + mandatorId + '&' + endpoint.page.getParamsForPage() + params.getParamsForSearch();
      }
    }
    return endpoint.href + '?' + endpoint.page.getParamsForPage();
  }
}
