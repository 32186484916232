import {Injectable, OnDestroy} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {Auditorium} from '../models/auditorium.model';
import {AuditoriumHalData} from '../models/auditorium-hal-data';
import {HalData} from '../../shared/models/halData';
import {AuditoriumLayout} from '../models/auditorium-layout.model';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AlertService} from '../../shared/services/alert.service';
import {UserService} from '../../login/services/user.service';
import {AuditoriumLayoutHalData} from '../models/auditorium-layout-hal-data';

@Injectable()
export class HalAuditoriumsService extends HalService implements OnDestroy {

  protected gatewayName = 'auditorium';

  protected subscribtions: Subscription = new Subscription();
  public auditoriumLayouts = new BehaviorSubject<AuditoriumHalData>(null);
  public auditoriums = new BehaviorSubject<AuditoriumHalData>(null);

  constructor(protected http: HttpClient,
              protected _alertService: AlertService,
              protected _userService: UserService,
              protected _logger: LoggerService) {
    super(http, _alertService, _userService, _logger);

    this.subscribtions = this.data.subscribe((data) => {
      if (data != null && data['auditoriumLayouts'] != null) {
        this.auditoriumLayouts.next(data['auditoriumLayouts']);
      }
      if (data != null && data['auditoriums'] != null) {
        this.auditoriums.next(data['auditoriums']);
      }
    });
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }

  /**
   *
   * @param {number} page
   */
  getAllAuditoriums(page: number = null) {
    this.getAll('auditoriums', page);
  }

  /**
   *
   * @param {string} id
   * @returns {Promise<Auditorium>}
   */
  getAuditorium(id: string): Promise<Auditorium> {
    return super.get(this._data['auditoriums'], id);
  }

  /**
   *
   * @param {number} page
   */
  getAllAuditoriumLayouts(page: number = null) {
    this.getAll('auditoriumLayouts', page);
  }

  /**
   *
   * @param {string} id
   * @returns {Promise<Auditorium>}
   */
  getAuditoriumLayout(id: string): Promise<AuditoriumLayout> {
    return super.get(this._data['auditoriumLayouts'], id);
  }

  /**
   *
   * @param response
   */
  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Srtip page params
      if (id === 'auditoriums') {
        this._data[id] = new AuditoriumHalData(id, link);
      } else if (id === 'auditoriumLayouts') {
        this._data[id] = new AuditoriumLayoutHalData(id, link);
      } else {
        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   * Save
   * @param {Auditorium} data
   * @returns {Observable<Auditorium>}
   */
  saveAuditorium(data: Auditorium): Promise<Auditorium> {
    return this.save(this._data['auditoriums'], data);
  }

  /**
   *
   * @param {Auditorium} data
   * @returns {Promise<any>}
   */
  updateAuditorium(data: Auditorium): Promise<any> {
    return this.update(this._data['auditoriums'], data.id, data);
  }

  /**
   *
   * @param {Auditorium} data
   * @returns {Promise<any>}
   */
  deleteAuditorium(data: Auditorium): Promise<any> {
    return this.delete(this._data['auditoriums'], data.id);
  }

  /**
   *
   * @param {AuditoriumLayout} data
   * @returns {Promise<AuditoriumLayout>}
   */
  saveAuditoriumLayout(data: AuditoriumLayout): Promise<AuditoriumLayout> {
    return this.save(this._data['auditoriumLayouts'], data);
  }

  /**
   *
   * @param {AuditoriumLayout} data
   * @returns {Promise<any>}
   */
  updateAuditoriumLayout(data: AuditoriumLayout): Promise<any> {
    return this.update(this._data['auditoriumLayouts'], data.id, data);
  }

  /**
   *
   * @param {AuditoriumLayout} data
   * @returns {Promise<any>}
   */
  deleteAuditoriumLayout(data: AuditoriumLayout): Promise<any> {
    return this.delete(this._data['auditoriumLayouts'], data.id);
  }

  /**
   *
   * @param {string} locationId
   * @returns {Promise<Auditorium[]>}
   */
  findAllAuditoriumsByLocationId(locationId: string): Promise<Auditorium[]> {
    if (this._data['auditoriums'] != null && this._data['auditoriums'].href != null) {
      return this.search(
        `${this._data['auditoriums'].href}/search/findAllByLocationId?locationId=${locationId}`
      ).then((response: any) => {
        if (response != null) {
          this._data['auditoriums'].parseToArrayObjects(response);
          return this._data['auditoriums'].data;
        }
      });
    }
  }

  streamAllAuditoriumsByLocationId(locationId: string, mandatorId: string): Promise<{ [key: string]: Auditorium }> {
    const url = `${this._data['auditoriums'].href}/search/streamAllByMandatorIdAndLocationId?locationId=${locationId}&mandatorId=${mandatorId}`;

    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['auditoriums'].parseToArrayObjects(response);
        return this._data['auditoriums'].data;
      }
      return response;
    });
  }

  streamAllAuditoriumLayoutsByAuditoriumId(mandatorId: string,
                                           locationId: string,
                                           auditoriumId: string): Promise<{ [key: string]: AuditoriumLayout }> {
    const url = `${this._data['auditoriumLayouts'].href}/search/streamAllByMandatorIdAndLocationIdAndAuditoriumId?mandatorId=${mandatorId}&locationId=${locationId}&auditoriumId=${auditoriumId}`;

    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['auditoriumLayouts'].parseToArrayObjects(response);
        return this._data['auditoriumLayouts'].data;
      }
      return response;
    });
  }
}
