import {locale_base_de} from '../de';
import {mergeDeep} from '../../../shared/utils';

const locale_client_de = {
  'data': {
    'MODUL_COUNTER' : {
    },
  }
};

export const german = mergeDeep({}, locale_client_de, locale_base_de);
