<div class="modal-header">
  <h4 class="modal-title">
    {{ 'MODUL_TRANSACTIONS.COMPONENT_TRANSACTION_PAYMENT_EDIT_MODAL.MODAL_TITLE' | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ngIf="paymentForm"
        [formGroup]="paymentForm"
        (ngSubmit)="submitForm()">
    <div class="col-md-12" *ngIf="isLoading">
      <c360-loading></c360-loading>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="alert alert-danger" *ngIf="errorMessage" [innerHtml]="errorMessage"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group row">
          <label for="paymentProviderType"
                 class="col-sm-3 col-form-label">
            {{ 'MODUL_TRANSACTIONS.COMPONENT_TRANSACTION_PAYMENT_EDIT_MODAL.FIELDS.PAYMENT_PROVIDER_TYPE' | translate}}
          </label>
          <div class="col-sm-9">
            <select class="form-control custom-select w-100"
                    id="paymentProviderType"
                    formControlName="paymentProviderType">
              <option>
                {{ 'MODUL_TRANSACTIONS.COMPONENT_TRANSACTION_PAYMENT_EDIT_MODAL.SELECT_TYPE' | translate}}
              </option>
              <option *ngFor="let key of paymentTypesListKey" [value]="key">
                {{'MODUL_TRANSACTIONS.ENUMS.PAYMENT_TYPES.' + paymentTypesList[key] | translate}}
              </option>
            </select>
            <div class="alert alert-danger mt-1"
                 *ngIf="paymentForm.get('paymentProviderType').errors && (paymentForm.get('paymentProviderType').touched || paymentForm.get('paymentProviderType').dirty)">
              <c360-form-validation-messages
                [control]="paymentForm.get('paymentProviderType')"></c360-form-validation-messages>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group row">
          <label for="providerTypeCustomerName"
                 class="col-sm-3 col-form-label">
            {{ 'MODUL_TRANSACTIONS.COMPONENT_TRANSACTION_PAYMENT_EDIT_MODAL.FIELDS.PROVIDER_TYPE_CUSTOMER_NAME' |
            translate}}
          </label>
          <div class="col-sm-9">
            <input type="text"
                   class="form-control"
                   id="providerTypeCustomerName"
                   autocomplete="off"
                   formControlName="providerTypeCustomerName"
                   placeholder="{{ 'MODUL_TRANSACTIONS.COMPONENT_TRANSACTION_PAYMENT_EDIT_MODAL.FIELDS.PROVIDER_TYPE_CUSTOMER_NAME' | translate}}">
            <div class="alert alert-danger mt-1"
                 *ngIf="paymentForm.get('providerTypeCustomerName').errors && (paymentForm.get('providerTypeCustomerName').touched || paymentForm.get('providerTypeCustomerName').dirty)">
              <c360-form-validation-messages
                [control]="paymentForm.get('providerTypeCustomerName')"></c360-form-validation-messages>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group row">
          <label for="providerTypeId"
                 class="col-sm-3 col-form-label">
            {{ 'MODUL_TRANSACTIONS.COMPONENT_TRANSACTION_PAYMENT_EDIT_MODAL.FIELDS.PROVIDER_TYPE_ID' | translate}}
          </label>
          <div class="col-sm-9">
            <input type="text"
                   mask='SS00 0000 0000 0000 0000 00 00*'
                   class="form-control"
                   [placeholder]="providerTypeIdPlaceholder"
                   id="providerTypeId"
                   autocomplete="off"
                   formControlName="providerTypeId"
                   (focus)="onFocusRoviderTypeId()"
                   (blur)="onBlurRoviderTypeId()">
            <div class="alert alert-danger mt-1"
                 *ngIf="paymentForm.get('providerTypeId').errors && (paymentForm.get('providerTypeId').touched || paymentForm.get('providerTypeId').dirty)">
              <c360-form-validation-messages
                [control]="paymentForm.get('providerTypeId')"></c360-form-validation-messages>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">
    {{'FORMS.BUTTONS.CANCEL' | translate}}
  </button>
  <button type="button" class="btn btn-danger" (click)="submitForm()">
    {{'FORMS.BUTTONS.SAVE' | translate}}
  </button>
</div>
