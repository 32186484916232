<form autocomplete="off"
      [formGroup]="resendMailForm"
      (ngSubmit)="submitForm()">
  <div class="form-group row">
    <div class="col-md-12">
      <label for="change-email">E-Mail Adresse ändern</label>
      <div class="input-group">
          <input class="form-control"
                 id="change-email"
                 formControlName="email"
                 placeholder="{{transaction?.customer?.emailAddress}}"
                 type="email">
          <span class="input-group-append">
            <button type="submit" class="btn btn-primary"
                    [disabled]="!isResendableEmail()">
              <i class="fa fa-repeat" aria-hidden="true"></i> E-Mail senden
            </button>
          </span>
      </div>
      <c360-form-validation-messages [control]="resendMailForm.controls.email"></c360-form-validation-messages>
    </div>
  </div>
</form>
