import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {HalEventsService} from '../../services/hal-events.service';
import {EventHalData} from '../../models/event-hal-data';
import {Event} from '../../models/event.model';
import {EventDeleteModalComponent} from '../event-delete-modal/event-delete-modal.component';

@Component({
  selector: 'c360-events-list',
  templateUrl: './events-list.component.html'
})

export class EventsListComponent implements OnInit, OnDestroy {
  private _eventHalData: EventHalData;
  private _subscription: Subscription;
  private _currentPage = 1;
  private _isLoading: boolean;

  /**
   *
   * @param {HalEventsService} _halEventsService
   * @param {NgbModal} _modalService
   * @param {LoggerService} _logger
   * @param {ChangeDetectorRef} _ref
   */
  constructor(private _halEventsService: HalEventsService,
              private _modalService: NgbModal,
              private _logger: LoggerService,
              private _ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private init(): void {
    this._subscription = this._halEventsService
      .events
      .subscribe((halData: EventHalData) => {
        if (null != halData && null != halData.data) {
          this._eventHalData = halData;
          if (null != halData.page) {
            this._currentPage = halData.page.currentPage;
          }
          this._isLoading = false;
          this._ref.markForCheck();
        }
      });
    this.getAll();
  }

  /**
   *
   * @param {number} page
   */
  private getAll(page: number = null): void {
    this._isLoading = true;
    this._halEventsService.getAllEvents(page);
  }

  /**
   *
   * @param {number} pageNumber
   */
  getAllByPage(pageNumber: number): void {
    this.getAll(pageNumber);
  }

  /**
   *
   * @param {Event} event
   */
  onDelete(event: Event) {
    const modalRef = this._modalService.open(EventDeleteModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.event = event;
    modalRef.result.then(
      (data) => {
        if (data === true) {
          this._logger.info('onDelete', event);
          this._isLoading = true;
          this._halEventsService
            .deleteEvent(event)
            .then(
              () => {
                this.init();
              }
            );
        }
      }
    );
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {EventHalData}
   */
  get eventHalData(): EventHalData {
    return this._eventHalData;
  }

  /**
   *
   * @returns {number}
   */
  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }
}
