import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Alert} from '../models/alert.model';
import {ErrorMessage} from '../models/error.model';
import {HttpErrorResponse} from '@angular/common/http';
import {isString} from 'util';

@Injectable()
export class AlertService {

  private _alerts: Array<Alert> = [];

  public alerts = new BehaviorSubject<Alert[]>([]);

  constructor() { }

  alerts$(): Observable<Alert[]> {
    return this.alerts.asObservable();
  }


  addAlert(error: any, type: string = 'danger') {
    let alert: Alert = null;
    if (error instanceof HttpErrorResponse) {

      alert = this.createAlertFromHttpErrorReponse(error);
    } else if (isString(error)) {

      alert = new Alert(error.toString());
    } else if (error instanceof ErrorMessage) {

      alert = new Alert(error.errorCode, error.errorUserMessage, [error.errorMessage]);
    }

    if (alert != null) {
      this._alerts.push(alert);
      this.alerts.next(this._alerts);
    }
  }

  /**
   * Create an ALert from a generic HttpErrorResponse
   * @param {HttpErrorResponse} error
   * @returns {any}
   */
  createAlertFromHttpErrorReponse(error: HttpErrorResponse) {
    let alert;
    if (error.error != null && error.error.errorUserMessage) {
      alert = new Alert('Error', error.error.errorCode, [error.error.errorUserMessage, error.error.errorMessage], true);
    } else {
      alert = new Alert(error.name, error.status.toString(), [error.message, error.url], true);
    }
    return alert;
  }

  acknowledgeAlert(acknowledgedAlert: Alert) {
    const index = this._alerts.findIndex((alert: Alert) => alert === acknowledgedAlert);
    if (index > -1) {
      this._alerts.splice(index, 1);
      this.alerts.next(this._alerts);
    }
  }
  acknowledgeAlerts() {
    this._alerts.splice(0, this._alerts.length);
    this.alerts.next(this._alerts);
  }
}
