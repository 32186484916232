<div class="row" *ngIf="isLoading">
  <div class="col-md-12">
    <c360-loading></c360-loading>
  </div>
</div>
<form novalidate autocomplete="off" *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="submitForm()">
  <div class="row">
    <div class="col-6">
      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('id').invalid && userForm.get('id').dirty,
        'has-success': userForm.get('id').valid && userForm.get('id').dirty
        }">
        <label for="user-id"
               class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.USER_ID' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 readonly
                 class="form-control-plaintext"
                 formControlName="id"
                 id="user-id">
          <c360-form-validation-messages [control]="userForm.get('id')"></c360-form-validation-messages>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-lg-6">

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('emailAddress').invalid && userForm.get('emailAddress').dirty,
        'has-success': userForm.get('emailAddress').valid && userForm.get('emailAddress').dirty
        }">
        <label for="user-emailAdress" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.EMAIL' | translate}}
        </label>
        <div class="col-7 col-lg-8">

          <input *ngIf="!user.id"
                 type="text"
                 class="form-control"
                 formControlName="emailAddress"
                 id="user-emailAdress"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.EMAIL' | translate}}">

          <input *ngIf="user.id"
                 type="text"
                 class="form-control-plaintext"
                 readonly
                 [value]="userForm.get('emailAddress').value"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.EMAIL' | translate}}">

          <c360-form-validation-messages [control]="userForm.get('emailAddress')"></c360-form-validation-messages>
        </div>
      </div>

      <!--
      <div class="form-group row"
           *ngIf="userForm.get('id').value">
        <label for="user-emailAdress" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.CONFIRMED' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <span class="alert alert-success" *ngIf="userForm.get('emailAddressApproved').value"
                translate="MODUL_USERS.COMPONENT_DETAIL.EMAIL_CONFIRMED">
          </span>
          <span class="alert alert-danger" *ngIf="!userForm.get('emailAddressApproved').value"
                translate="MODUL_USERS.COMPONENT_DETAIL.EMAIL_UNCONFIRMED">
          </span>
        </div>
      </div>
      -->
    </div>
    <div class="col-sm-12 col-lg-6">
      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('passwordPlain').invalid && userForm.get('passwordPlain').dirty,
        'has-success': userForm.get('passwordPlain').valid && userForm.get('passwordPlain').dirty
        }">
        <label for="user-password" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.PASSWORD' | translate}}</label>
        <div class="col-7 col-lg-8">
          <div class="input-group">
            <input type="{{passwordGenerated ? 'text' : 'password' }}"
                   class="form-control"
                   formControlName="passwordPlain"
                   id="user-password"
                   (keyup)="passwordGenerated = false;"
                   placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.PASSWORD' | translate}}">
            <span class="input-group-btn">
              <button type="button" class="btn btn-success" (click)="generatePassword()">
                 {{ 'MODUL_USERS.COMPONENT_DETAIL.GENERATE_NEW_PASSWORD' | translate}}
              </button>
            </span>
          </div>
          <c360-form-validation-messages [control]="userForm.get('passwordPlain')"></c360-form-validation-messages>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-lg-6">
      <div class="form-group row" [ngClass]="{
          'has-danger': userForm.get('gender').invalid && userForm.get('gender').dirty,
          'has-success': userForm.get('gender').valid && userForm.get('gender').dirty
          }">
        <label for="user-salutation"
               class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.SALUTATION' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <select class="form-control custom-select w-100"
                  id="user-salutation"
                  formControlName="gender">
            <option [ngValue]="null">{{ 'MODUL_USERS.COMPONENT_DETAIL.SELECT_GENDER_PLACEHOLDER' | translate}}</option>
            <option *ngFor="let key of genderKeys" [value]="key" [label]="genders[key]">{{genders[key]}}</option>
          </select>
          <c360-form-validation-messages [control]="userForm.get('gender')"></c360-form-validation-messages>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-lg-6">

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('firstName').invalid && userForm.get('firstName').dirty,
        'has-success': userForm.get('firstName').valid && userForm.get('firstName').dirty
        }">
        <label for="user-firstname" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.FIRSTNAME' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="firstName"
                 id="user-firstname"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.FIRSTNAME' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('firstName')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('lastName').invalid && userForm.get('lastName').dirty,
        'has-success': userForm.get('lastName').valid && userForm.get('lastName').dirty
        }">
        <label for="user-lastname" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.LASTNAME' | translate}}</label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="lastName"
                 id="user-lastname"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.LASTNAME' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('lastName')"></c360-form-validation-messages>
        </div>
      </div>

      <!--
      <div class="form-group row" [ngClass]="{
          'has-danger': userForm.get('accountLocked').invalid && userForm.get('accountLocked').dirty,
          'has-success': userForm.get('accountLocked').valid && userForm.get('accountLocked').dirty
          }">
        <label for="user-account-locked" class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.ACCOUNT_LOCKED' | translate}}</label>
        <div class="col-7 col-lg-8">
          <select class="form-control custom-select w-100"
                  id="user-account-locked"
                  formControlName="accountLocked">
            <option [ngValue]="false">{{'MODUL_USERS.COMPONENT_DETAIL.UNLOCKED' | translate}}</option>
            <option [ngValue]="true">{{'MODUL_USERS.COMPONENT_DETAIL.LOCKED' | translate}}</option>
          </select>
          <c360-form-validation-messages [control]="userForm.get('accountLocked')"></c360-form-validation-messages>
        </div>
      </div>
      -->
      <div *ngIf="!isLoggedInUser">

      </div>
      <div class="form-group row" [ngClass]="{
          'has-danger': userForm.get('role').invalid && userForm.get('role').dirty,
          'has-success': userForm.get('role').valid && userForm.get('role').dirty
          }">
        <label class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.SET_ROLE' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <div class="form-check" *ngFor="let key of roleList;let i = index">
            <label class="form-check-label"
                   [class.disabled]="isLoggedInUser || (userRoleIndex != 0 && userRoleIndex+1 > i === true) || null">
              <input class="form-check-input"
                     type="radio"
                     name="role"
                     [attr.disabled]="isLoggedInUser || (userRoleIndex != 0 && userRoleIndex+1 > i === true) || null"
                     id="gridRadios-{{key}}"
                     [value]="key"
                     formControlName="role">
              {{'MODUL_USERS.ROLES.'+key | translate}}
            </label>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="!existsMandatorId" [ngClass]="{
          'has-danger': userForm.get('mandatorId').invalid && userForm.get('mandatorId').dirty,
          'has-success': userForm.get('mandatorId').valid && userForm.get('mandatorId').dirty
          }" [hidden]="!selectMandatorByRolesAllowed(userForm.get('role').value)">
        <label for="schedule-select-mandator" class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.MANDATOR' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <select class="form-control custom-select w-100"
                  id="schedule-select-mandator"
                  formControlName="mandatorId">
            <option [ngValue]="null">{{ 'MODUL_USERS.COMPONENT_DETAIL.SELECT_MANDATOR_PLACEHOLDER' | translate}}
            </option>
            <option *ngFor="let key of mandatorListKey"
                    [ngValue]="mandatorList[key].id"
                    [label]="mandatorList[key].name">{{mandatorList[key].name}}
            </option>
          </select>
          <c360-form-validation-messages [control]="userForm.get('mandatorId')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row"
           *ngIf="existsMandatorId &&
           mandator &&
           userForm.get('mandatorId').value &&
           selectMandatorByRolesAllowed(userForm.get('role').value)">
        <label for="schedule-select-mandator-readonly" class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.MANDATOR' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 readonly
                 class="form-control-plaintext"
                 [value]="mandator.name"
                 id="schedule-select-mandator-readonly">
        </div>
      </div>

      <div class="form-group row" *ngIf="!existsLocationId" [ngClass]="{
          'has-danger': userForm.get('locationId').invalid && userForm.get('locationId').dirty,
          'has-success': userForm.get('locationId').valid && userForm.get('locationId').dirty
          }" [hidden]="!selectLocationByRolesAllowed(userForm.get('role').value)">
        <label for="schedule-select-location" class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.LOCATION' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <select class="form-control custom-select w-100"
                  id="schedule-select-location"
                  formControlName="locationId">
            <option [ngValue]="null">{{ 'MODUL_USERS.COMPONENT_DETAIL.SELECT_LOCATION_PLACEHOLDER' | translate}}
            </option>
            <option *ngFor="let key of locationListKey"
                    [ngValue]="locationList[key].id"
                    [label]="locationList[key].name">{{locationList[key].name}}
            </option>
          </select>
          <c360-form-validation-messages [control]="userForm.get('locationId')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row"
           *ngIf="existsLocationId &&
           location &&
           userForm.get('locationId').value &&
           selectLocationByRolesAllowed(userForm.get('role').value)">
        <label for="schedule-select-location-readonly" class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.LOCATION' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 readonly
                 class="form-control-plaintext"
                 [value]="location.name"
                 id="schedule-select-location-readonly">
        </div>
      </div>

      <div class="form-group row">
        <label for="account-enabled" class="col-4 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.ACCOUNT_ENABLED' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <label class="form-check-label"
                 [class.disabled]="isLoggedInUser || null">
            <input type="checkbox"
                   id="account-enabled"
                   formControlName="accountEnabled"
                   [attr.disabled]="isLoggedInUser || null">
            {{ 'MODUL_USERS.COMPONENT_DETAIL.USER_ACTIVE' | translate}}
          </label>
        </div>
      </div>

    </div>

    <div class="col-sm-12 col-lg-6">

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('streetName').invalid && userForm.get('streetName').dirty,
        'has-success': userForm.get('streetName').valid && userForm.get('streetName').dirty
        }">
        <label for="user-streetName" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.STREET' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="streetName"
                 id="user-streetName"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.STREET' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('streetName')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('houseNumber').invalid && userForm.get('houseNumber').dirty,
        'has-success': userForm.get('houseNumber').valid && userForm.get('houseNumber').dirty
        }">
        <label for="user-houseNumber" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.HOUSE_NO' | translate}}</label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="houseNumber"
                 id="user-houseNumber"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.HOUSE_NO' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('houseNumber')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('postCode').invalid && userForm.get('postCode').dirty,
        'has-success': userForm.get('postCode').valid && userForm.get('postCode').dirty
        }">
        <label for="user-postCode" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.ZIP_CODE' | translate}}</label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="postCode"
                 id="user-postCode"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.ZIP_CODE' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('postCode')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('cityName').invalid && userForm.get('cityName').dirty,
        'has-success': userForm.get('cityName').valid && userForm.get('cityName').dirty
        }">
        <label for="user-cityName" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.CITY' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="cityName"
                 id="user-cityName"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.CITY' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('cityName')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('country').invalid && userForm.get('country').dirty,
        'has-success': userForm.get('country').valid && userForm.get('country').dirty
        }">
        <label for="user-country" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.COUNTRY' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <select class="form-control custom-select w-100"
                  id="user-country"
                  formControlName="country">
            <option [ngValue]="null">{{ 'MODUL_USERS.COMPONENT_DETAIL.SELECT_COUNTRY_PLACEHOLDER' | translate}}</option>
            <option *ngFor="let key of countriesKeys" [value]="key">{{countries[key] | translate}}</option>
          </select>
          <c360-form-validation-messages [control]="userForm.get('country')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('phoneNumber').invalid && userForm.get('phoneNumber').dirty,
        'has-success': userForm.get('phoneNumber').valid && userForm.get('phoneNumber').dirty
        }">
        <label for="user-phoneNumber" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.PHONE' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="phoneNumber"
                 id="user-phoneNumber"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.PHONE' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('phoneNumber')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': userForm.get('mobileNumber').invalid && userForm.get('mobileNumber').dirty,
        'has-success': userForm.get('mobileNumber').valid && userForm.get('mobileNumber').dirty
        }">
        <label for="user-mobileNumber" class="col-5 col-lg-4 col-form-label">
          {{ 'MODUL_USERS.COMPONENT_DETAIL.MOBILE' | translate}}
        </label>
        <div class="col-7 col-lg-8">
          <input type="text"
                 class="form-control"
                 formControlName="mobileNumber"
                 id="user-mobileNumber"
                 placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.MOBILE' | translate}}">
          <c360-form-validation-messages [control]="userForm.get('mobileNumber')"></c360-form-validation-messages>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group row">
        <div class="col-sm-12">
          <button type="submit"
                  class="btn btn-success">
            {{ 'FORMS.BUTTONS.SAVE' | translate}}
          </button>
          <button type="button"
                  class="btn btn-primary"
                  (click)="cancel()">
            {{ 'FORMS.BUTTONS.CANCEL' | translate}}
          </button>
          <button type="button"
                  class="btn btn-danger"
                  *ngIf="userForm.get('id').value"
                  (click)="onDelete.emit(user)">{{ 'FORMS.BUTTONS.DELETE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
