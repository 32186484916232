<div class="card" [hidden]="!canSalesCounterReport && !canPerformanceReport">
  <div class="card-header">
    <h1>Berichte</h1>
  </div>
  <div class="card-body">
    <div class="row">
      <c360-loading *ngIf="isLoading"></c360-loading>
      <div class="col-12">
        <div *ngIf="generationStatus && generationStatus !== 'GENERATION_IS_COMPLETED'"
             class="alert alert-warning">
          {{ 'MODUL_TRANSACTIONS.COUNTER_REPORT_GENERATION_STATUS.' + generationStatus | translate }}
        </div>
      </div>
      <div class="col-12">
        <div class="filter-row mt-2 mb-2">

          <div class="mr-sm-2 mb-2">
            <label for="reports-select-type">
              {{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_TYPE' | translate}}
            </label>
            <div class="input-group date">
              <select id="reports-select-type"
                      class="form-control custom-select"
                      name="reports-select-type"
                      attr.aria-label="{{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_TYPE' | translate}}"
                      [(ngModel)]="reportType">
                <option *ngIf="canSalesCounterReport" [ngValue]="reportsTypes.SALES_COUNTER_REPORT">
                  {{ 'MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.SALES_COUNTER_REPORT' | translate }}
                </option>
                <option *ngIf="canPerformanceReport" [ngValue]="reportsTypes.DISTRIBUTOR_REPORT">
                  {{ 'MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.DISTRIBUTOR_REPORT' | translate }}
                </option>
                <option *ngIf="canPerformanceReport" [ngValue]="reportsTypes.PERFORMANCE_REPORT">
                  {{ 'MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.PERFORMANCE_REPORT' | translate }}
                </option>
                <option *ngIf="canPerformanceReport" [ngValue]="reportsTypes.PERFORMANCE_REPORT_INCLUDE_SELLING_POINT">
                  {{ 'MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.PERFORMANCE_REPORT_INCLUDE_SELLING_POINT' | translate }}
                </option>
              </select>
            </div>
          </div>
          <div class="mr-sm-2 mb-2">
            <button type="submit"
                    class="btn btn-success"
                    [disabled]="!reportType"
                    (click)="getReport()"
                    [translate]="'FORMS.BUTTONS.SEND'">
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <iframe #iframe
                style="border: none;height: -webkit-fill-available;"
                width="100%">
        </iframe>
      </div>
    </div>
  </div>
</div>

