import {HalData} from '../../shared/models/halData';
import {isArray, isObject} from 'util';
import {Voucher} from './voucher.model';

export class VoucherHalData extends HalData {

  data: { [id: string]: Voucher } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.vouchers) {
      response._embedded.vouchers.forEach((voucher: Voucher) => {
        this.parseToObject(voucher);
      });
    } else if (isArray(response)) {
      response.map((voucher: Voucher) => {
        this.parseToObject(voucher);
      });
    } else if (isObject(response)) {
      this.parseToObject(response);
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): Voucher {
    if (response != null) {
      const voucher = new Voucher(
        response._links,
        response.id,
        response.mandatorId,
        response.locationId,
        response.name,
        response.code,
        response.valueInitial,
        response.valueCurrent,
        response.type,
        response.status,
        response.validTo,
        response.description,
        response.dateValid,
        response.transactionExternalId
      );
      this.data['' + response.id + ''] = voucher;
    }
    return this.data['' + response.id + ''];
  }
}
