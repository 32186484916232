import {EventEmitter, Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user.model';
import {AuthResponse} from '../models/auth-response.interface';
import {UserRoles} from '../../users/enums/user-roles.enum';
import {environment} from '../../../environments/environment';
import {AuthorizationRules} from '../models/authorization.rules.interface';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class UserService {

  private loggedIn: BehaviorSubject<boolean>;
  private _currentUser: User = null;
  public authorizationRules: AuthorizationRules = environment.authorization;
  private _userMandatorChangedEmitter: EventEmitter<string> = new EventEmitter<string>();
  private _userLocationChangedEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(@Inject('LOCALSTORAGE') private localStorage: any,
              private _cookieService: CookieService) {
    this.initUser();
    this.loggedIn = new BehaviorSubject<boolean>(this.getUser() != null);
  }

  private initUser() {
    let storedDetails = null;
    try {
      storedDetails = JSON.parse(atob(this.localStorage.getItem('currentUser')));
      if (storedDetails != null) {
        this.storeUser(
          new User(storedDetails._accessToken, storedDetails._refreshToken)
        );
        if (this._currentUser.authorityBase === 'ROLE_ADMIN') {
          this._cookieService.set(
            'JWT-TOKEN',
            this._currentUser.accessToken,
            null,
            '/',
            null,
            true,
            'Lax'
          );
        }
        this.setAuthorizationRules();
      }
    } catch (e) {
    }
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  private storeUser(user: User): void {
    this._currentUser = user;
    // store username and jwt token in local storage to keep user logged in between page refreshes
    this.localStorage.setItem('currentUser', btoa(JSON.stringify(this._currentUser)));
  }

  setUser(userResponse: AuthResponse = null): boolean {
    if (userResponse == null) {
      this.localStorage.removeItem('currentUser');
      this._currentUser = null;
      this._cookieService.delete('JWT-TOKEN');
    } else {
      this.storeUser(
        new User(userResponse.access_token, userResponse.refresh_token)
      );
      if (this._currentUser.authorityBase === 'ROLE_ADMIN') {
        this._cookieService.set(
          'JWT-TOKEN',
          this._currentUser.accessToken,
          null,
          '/',
          null,
          true,
          'Lax'
        );
      }
    }
    this.setAuthorizationRules();

    this.loggedIn.next(this._currentUser != null);
    return (this._currentUser != null);
  }

  getUser(): User {
    return this._currentUser;
  }

  updateUser(data: object) {
    if (this._currentUser !== null) {
      Object.keys(data).forEach((keyToUpdate) => {
        if (this._currentUser.hasOwnProperty(keyToUpdate)) {
          this._currentUser[keyToUpdate] = data[keyToUpdate];
        }
      });

      localStorage.setItem('currentUser', btoa(JSON.stringify(this._currentUser)));

      if (this._currentUser.authorityBase === 'ROLE_ADMIN') {
        this._cookieService.set(
          'JWT-TOKEN',
          this._currentUser.accessToken,
          null,
          '/',
          null,
          true,
          'Lax'
        );
      }
    }
    this.setAuthorizationRules();
  }

  private setAuthorizationRules(): void {
    Object.keys(this.authorizationRules).forEach(domain => {
      if (this.authorizationRules[domain] == null) {
        this.authorizationRules[domain] = {};
      }
      if (this.authorizationRules[domain].hasOwnProperty('minRole')) {
        this.authorizationRules[domain].allowed = this.hasRole(this.authorizationRules[domain].minRole);
      } else {
        this.authorizationRules[domain].allowed = false;
      }
    });
  }

  getAuthorizationRules(): AuthorizationRules {
    return this.authorizationRules;
  }

  isloggedIn$(): Observable<boolean> {
    return this.loggedIn;
  }

  hasRole(role: string): boolean {
    return (this.getUser() != null) ?
      environment.userPermissionOrder.indexOf(role) >= this.getUser().userRoleIndex :
      false;
  }

  isSalesCounter(includePreSalesCounter: boolean = true): boolean {
    if (includePreSalesCounter) {
      return this.hasRole(UserRoles.ROLE_PRE_SALES_COUNTER);
    }
    return this.hasRole(UserRoles.ROLE_SALES_COUNTER);
  }

  isLocationAdmin(): boolean {
    return this.hasRole(UserRoles.ROLE_LOCATION);
  }

  isMandator(): boolean {
    return this.hasRole(UserRoles.ROLE_MANDATOR);
  }

  isAdmin(): boolean {
    return this.hasRole(UserRoles.ROLE_ADMIN);
  }

  get userMandatorChangedEmitter(): EventEmitter<string> {
    return this._userMandatorChangedEmitter;
  }

  get userLocationChangedEmitter(): EventEmitter<string> {
    return this._userLocationChangedEmitter;
  }
}
