import {Component} from '@angular/core';

@Component({
  selector: 'c360-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {
  constructor() {
  }
}
