import {Component} from '@angular/core';
import {UserService} from '../../login/services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'c360-user-menu-icon',
  templateUrl: './user-menu-icon.component.html'
})

export class UserMenuIconComponent {

  constructor(private _userService: UserService,
              private router: Router) {
  }

  getUser() {
    return this._userService.getUser();
  }

  logout() {
    this._userService.setUser(null);
    this.router.navigate(['login']);
  }
}
