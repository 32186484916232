import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseListComponent} from '../../../shared/components/base-list.component';
import {Subscription} from 'rxjs';
import {User} from '../../models/user';
import {HalTransactionsService} from '../../../transactions/services/hal-transactions.service';
import {Params} from '../../../shared/models/search/params.model';
import {Param} from '../../../shared/models/search/param.model';
import {TransactionSearch} from '../../../transactions/models/transaction-search.model';
import {Page} from '../../../shared/models/page';

@Component({
  selector: 'c360-user-detail-transaction-list',
  templateUrl: './user-detail-transaction-list.component.html'
})

export class UserDetailTransactionListComponent extends BaseListComponent implements OnInit, OnDestroy {

  @Input() user: User;
  private _subscriptions: Subscription = new Subscription();
  private _isLoading: boolean;
  private _transactionList: { [key: string]: TransactionSearch };
  private _transactionListKey;
  private _page: Page;


  constructor(private _halTransactionsService: HalTransactionsService,
              private _changeDetectorRef: ChangeDetectorRef) {
    super();
    this._transactionList = null;
    this._transactionListKey = [];
  }

  ngOnInit() {
    this._subscriptions.add(
      this._halTransactionsService
        .data
        .subscribe(
          (value) => {
            if (value && value['transactionSearches'] && value['transactionSearches'].data) {
              this._transactionList = value['transactionSearches'].data;
              this._page = value['transactionSearches'].page;
              this._transactionListKey = Object.keys(this._transactionList);
            } else {
              this._transactionList = null;
              this._transactionListKey = [];
            }
            this._isLoading = false;
            this._changeDetectorRef.detectChanges();
          }
        )
    );
    if (this.user.mandatorId && this.user.externalId) {
      this.getAll(1);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  /**
   *
   * @param {number} page
   */
  private getAll(page: number = null): void {
    this._isLoading = true;
    const params = new Params();
    params.add(new Param('customerUserExternalId', this.user.externalId));
    this._halTransactionsService.getAllByMandatorId(this.user.mandatorId, 'transactionSearches', page, params, 'desc');
  }

  /**
   *
   * @param {number} pageNumber
   */
  public getAllByPage(pageNumber: number): void {
    this.getAll(pageNumber);
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {{[p: string]: Transaction}}
   */
  get transactionList(): { [p: string]: TransactionSearch } {
    return this._transactionList;
  }

  /**
   *
   * @returns {any}
   */
  get transactionListKey() {
    return this._transactionListKey;
  }

  /**
   *
   * @returns {Page}
   */
  get page(): Page {
    return this._page;
  }
}
