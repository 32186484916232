import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CalendarModule} from 'angular-calendar';
import {AppComponent} from './core/app.component';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {TranslationLoaderService} from './shared/services/translation-loader.service';
import {german} from './i18/client/de';
import localeDe from '@angular/common/locales/de';
import {english} from './i18/client/en';
import localeEn from '@angular/common/locales/en';
import {CoreModule} from './core/core.module';
import {LoginModule} from './login/login.module';
import {EventsModule} from './events/events.module';
import {UsersModule} from './users/users.module';
import {TransactionsModule} from './transactions/transactions.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {NavigationModule} from './navigation/navigation.module';
import {AuditoriumsModule} from './auditoriums/auditoriums.module';
import {AreasModule} from './areas/areas.module';
import {TagInputModule} from 'ngx-chips';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PricesModule} from './prices/prices.module';
import {InitModule} from './init/init.module';
import * as moment from 'moment';
import 'moment/locale/de';
import {TypeaheadModule} from 'ngx-bootstrap';
import {CounterModule} from './counter/counter.module';
import {AdminModule} from './admin/admin.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CalendarModule.forRoot(),
    SharedModule.forRoot(),
    CoreModule.forRoot(),
    UsersModule.forRoot(),
    CounterModule.forRoot(),
    InitModule,
    TagInputModule,
    LoginModule,
    EventsModule,
    TransactionsModule,
    DashboardModule,
    NavigationModule,
    AppRoutingModule,
    AuditoriumsModule,
    AreasModule,
    PricesModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    TypeaheadModule.forRoot(),
    AdminModule,
  ],
  exports: [
    LoginModule,
    EventsModule,
    TransactionsModule,
    DashboardModule,
    UsersModule,
    NavigationModule,
    AdminModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

  title: string;

  constructor(
    private translate: TranslateService,
    private translationLoader: TranslationLoaderService,
    private titleService: Title
  ) {
    /* Config translation in root module */
    this.translate.addLangs(['de', 'en']);
    this.translate.setDefaultLang('de');

    // this.translate.use((this.translate.getBrowserLang()) ? this.translate.getBrowserLang() : this.translate.getDefaultLang());
    this.translate.use(this.translate.getDefaultLang());

    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEn, 'en');

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);

    this.title = this.translate.instant('CLIENT.NAME');

    this.titleService.setTitle(this.title);

    moment().locale('de');

    TagInputModule.withDefaults({
      tagInput: {
        placeholder: this.translate.instant('FORMS.ADD_NEW_TAG'),
        secondaryPlaceholder: this.translate.instant('FORMS.ADD_NEW_TAG')
      }
    });
  }
}
