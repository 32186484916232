import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {Event} from '../../models/event.model';

@Component({
  selector: '[c360-events-list-item]',
  templateUrl: './events-list-item.component.html'
})
export class EventsListItemComponent {

  @Input() event: Event;
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _logger: LoggerService
  ) {
  }

  delete(event: Event): void {
    this.onDelete.emit(event);
  }
}
