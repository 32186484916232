export class Area {

  public id: string;
  public name: string;
  public spaces: Array<string>;

  constructor(id?: string,
              name?: string,
              spaces?: Array<string>) {
    this.id = id;
    this.name = name;
    this.spaces = spaces;
  }

}
