import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {InitService} from '../services/init.service';
import {PrinterService} from '../../shared/services/printer.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../login/services/user.service';

@Component({
  selector: 'c360-init',
  templateUrl: './init.component.html',
})
export class InitComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  private initStartTime: number;

  constructor(protected _router: Router,
              protected _route: ActivatedRoute,
              private _userService: UserService,
              private _printerService: PrinterService,
              private _initService: InitService) {
  }

  ngOnInit(): void {
    this.initStartTime = performance.now();
    this.loadingFinished();
  }

  ngOnDestroy(): void {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  /**
   * Loading Finished
   * Send the user to the desired page
   * This is either a initialUrl (First request) or the info page
   */
  loadingFinished() {
    this._initService.initComplete = true;

    if (
      this._initService.initialUrl != null &&
      this._initService.initialUrl.indexOf('/init') === -1 &&
      this._initService.initialUrl.indexOf('/dashboard') === -1 &&
      this._initService.initialUrl.indexOf('/adminboard') === -1
    ) {
      return this.navigateTo([this._initService.initialUrl], {relativeTo: this._route});
    } else {
      if (this._userService.isAdmin()) {
        return this.navigateTo(['/adminboard'], {relativeTo: this._route});
      } else if (this._userService.isLocationAdmin()) {
        return this.navigateTo(['/dashboard'], {relativeTo: this._route});
      } else if (this._userService.isSalesCounter()) {
        return this.navigateTo(['/counter'], {relativeTo: this._route});
      } else if (this._userService.getUser() == null) {
        return this.navigateTo(['/login'], {relativeTo: this._route});
      }
    }
  }

  protected navigateTo(commands: any[], extras?: NavigationExtras) {
    return this._router.navigate(commands, extras).then(
      () => {
        this._initService.initialUrl = null;
      }
    );
  }
}
