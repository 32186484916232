import {Component, Input} from '@angular/core';
import {SepaFile} from '../../../models/sepa-file.model';
import {FileManipulatorService} from '../../../../shared/services/file-manipulator.service';

@Component({
  selector: '[c360-sepa-list-item-exported]',
  templateUrl: './sepa-list-item-exported.component.html'
})
export class SepaListItemExportedComponent {

  @Input() sepaFile: SepaFile;

  private fileManipulatorService: FileManipulatorService;

  constructor(private _fileManipulatorService: FileManipulatorService) {
    this.fileManipulatorService = _fileManipulatorService;
  }

  /**
   * On click download file
   */
  public onClickDownload(): void {

    this.fileManipulatorService.downloadXML(this.sepaFile.cdnReference, true);
  }
}
