<form novalidate autocomplete="off"
      [formGroup]="emailAddressForm"
      (ngSubmit)="submitForm()">
  <div class="form-group row" [ngClass]="{
        'has-danger': emailAddressForm.get('newEmailAddress').invalid && emailAddressForm.get('newEmailAddress').dirty,
        'has-success': emailAddressForm.get('newEmailAddress').valid && emailAddressForm.get('newEmailAddress').dirty
        }">
    <label for="email-address"
           class="col-12 col-form-label">
      {{ 'MODUL_USERS.COMPONENT_DETAIL.EMAIL' | translate}}
    </label>
    <div class="col-8">
      <input type="text"
             class="form-control"
             id="email-address"
             formControlName="newEmailAddress"
             placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.EMAIL' | translate}}">
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-12">
      <button type="submit"
              class="btn btn-success">
        {{ 'MODUL_USERS.COMPONENT_DETAIL.SET_EMAIL' | translate}}
      </button>
    </div>
  </div>
</form>
