import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslationLoaderService} from './services/translation-loader.service';
import {UserService} from '../login/services/user.service';
import {ConsoleLoggerService} from './services/logger/console-logger.service';
import {LoggerService} from './services/logger/logger.service';
import {ValidationService} from './services/validation.service';
import {LayoutService} from './services/layout.service';
import {AlertService} from './services/alert.service';
import {LoadingComponent} from './components/loading/loading.component';
import {FormValidationMessagesComponent} from './components/form-validation-messages/form-validation-messages.component';
import {AlertComponent} from './components/alerts/alerts.component';
import {DateTimePickerComponent} from './components/date-picker/date-picker.component';
import {KeysPipe} from './pipes/keys.pipe';
import {OrderbyPipe} from './pipes/orderby.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmModalComponent, ConfirmService, ConfirmState, ConfirmTemplateDirective} from './services/confirmation-modal.service';
import {FileManipulatorService} from './services/file-manipulator.service';
import {HttpService} from './services/http.service';
import {PrinterService} from './services/printer.service';
import {HasPrinterGuard} from './guards/has-printer.guard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HeaderInterceptor} from './header.interceptor';
import {PluralatePipe} from './pipes/pluralate.pipe';
import {NotPrintingGuard} from './guards/not-printing.guard';
import {NgxMaskModule} from 'ngx-mask';

const COMPONENTS = [
  LoadingComponent,
  AlertComponent,
  DateTimePickerComponent,
  FormValidationMessagesComponent,
  ConfirmTemplateDirective,
  ConfirmModalComponent
];

const PIPES = [
  KeysPipe,
  OrderbyPipe,
  PluralatePipe
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {

    return {
      ngModule: SharedModule,
      providers: [
        TranslationLoaderService,
        AlertService,
        UserService,
        ValidationService,
        LayoutService,
        ConfirmService,
        ConfirmState,
        FileManipulatorService,
        PrinterService,
        HasPrinterGuard,
        NotPrintingGuard,
        HttpService,
        {
          provide: LoggerService,
          useClass: ConsoleLoggerService
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptor,
          multi: true
        },
        {provide: 'LOCALSTORAGE', useFactory: getLocalStorage}
      ]
    };
  }

}

export function getLocalStorage() {
  return (typeof window !== 'undefined') ? window.localStorage : null;
}
