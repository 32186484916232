import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalPaymentService} from '../hal-payment.service';

@Injectable()
export class PaymentRootLinkResolver implements Resolve<boolean> {

  constructor(private _halPaymentService: HalPaymentService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this._halPaymentService.getRootLink();
  }
}
