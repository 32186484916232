<form novalidate autocomplete="off" [formGroup]="eventFilmForm" (ngSubmit)="submitForm()">
  <div class="form-group row mt-3">
    <div class="col-sm-12">
      <button type="submit" class="btn btn-success">Speichern</button>
      <button type="button" class="btn btn-primary" (click)="cancel()">Abbrechen</button>
      <button type="button" class="btn btn-danger" (click)="submitForm('delete')">Löschen</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row" *ngIf="!eventFilmForm.get('id').value && filmsAsArray.length > 0">
        <label for="typeChildId" class="col-sm-3 pr-0 col-form-label">
          Daten übernehmen aus:
        </label>
        <div class="col-sm-9">
          <div class="input-group">
            <input formControlName="selectedFilm"
                   typeaheadOptionField="name"
                   [typeahead]="filmsAsArray"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)"
                   class="form-control">
            <span class="input-group-btn">
               <button type="button" class="btn btn-success" (click)="takeFrom()" [disabled]="!selectedFilmItem">Übernehmen</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.get('typeChildId').invalid && eventFilmForm.get('typeChildId').dirty,
        'has-success': eventFilmForm.get('typeChildId').valid && eventFilmForm.get('typeChildId').dirty
        }">
        <label for="typeChildId" class="col-sm-3 pr-0 col-form-label">
          {{ 'MODUL_EVENTS.MODEL.FILM.TYPE-CHILD-ID' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="typeChildId"
                   id="typeChildId">
            <span class="input-group-btn" *ngIf="event.type === 'FILM_CUSTOM'">
               <button type="button" class="btn btn-success" (click)="generateNewTypeChildId()">Generate</button>
            </span>
          </div>
          <c360-form-validation-messages [control]="eventFilmForm.get('typeChildId')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.title.invalid && eventFilmForm.controls.title.dirty,
        'has-success': eventFilmForm.controls.title.valid && eventFilmForm.controls.title.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.TITLE' | translate}}</label>
        <div class="col-sm-9">
          <input type="text"
                 class="form-control"
                 formControlName="title"
                 id="title">
          <c360-form-validation-messages [control]="eventFilmForm.get('title')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.description.invalid && eventFilmForm.controls.description.dirty,
        'has-success': eventFilmForm.controls.description.valid && eventFilmForm.controls.description.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.DESCRIPTION' | translate}}</label>
        <div class="col-sm-9">
          <textarea type="text"
                    class="form-control"
                    formControlName="description"
                    id="description"></textarea>
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['description']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.directors.invalid && eventFilmForm.controls.directors.dirty,
        'has-success': eventFilmForm.controls.directors.valid && eventFilmForm.controls.directors.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.DIRECTORS' | translate}}</label>
        <div class="col-sm-9">
          <tag-input
            theme="bootstrap"
            [addOnBlur]="true"
            [modelAsStrings]="true"
            formControlName="directors"
            secondaryPlaceholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.DIRECTORS_ADD' | translate}}"
            placeholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.DIRECTORS_ADD' | translate}}"></tag-input>
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['directors']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.actors.invalid && eventFilmForm.controls.actors.dirty,
        'has-success': eventFilmForm.controls.actors.valid && eventFilmForm.controls.actors.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.ACTORS' | translate}}</label>
        <div class="col-sm-9">
          <tag-input
            theme="bootstrap"
            [addOnBlur]="true"
            [modelAsStrings]="true"
            formControlName="actors"
            secondaryPlaceholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.ACTORS_ADD' | translate}}"
            placeholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.ACTORS_ADD' | translate}}"></tag-input>
          <c360-form-validation-messages [control]="eventFilmForm.controls['actors']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.cameraOperators.invalid && eventFilmForm.controls.cameraOperators.dirty,
        'has-success': eventFilmForm.controls.cameraOperators.valid && eventFilmForm.controls.cameraOperators.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.CAMERAOPERATORS' | translate}}</label>
        <div class="col-sm-9">
          <tag-input
            theme="bootstrap"
            [addOnBlur]="true"
            [modelAsStrings]="true"
            formControlName="cameraOperators"
            secondaryPlaceholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.CAMERAOPERATORS_ADD' | translate}}"
            placeholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.CAMERAOPERATORS_ADD' | translate}}"></tag-input>
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['cameraOperators']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.cutters.invalid && eventFilmForm.controls.cutters.dirty,
        'has-success': eventFilmForm.controls.cutters.valid && eventFilmForm.controls.cutters.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.CUTTERS' | translate}}</label>
        <div class="col-sm-9">
          <tag-input
            theme="bootstrap"
            [addOnBlur]="true"
            [modelAsStrings]="true"
            formControlName="cutters"
            secondaryPlaceholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.CUTTERS_ADD' | translate}}"
            placeholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.CUTTERS_ADD' | translate}}"></tag-input>
          <c360-form-validation-messages [control]="eventFilmForm.controls['cutters']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.books.invalid && eventFilmForm.controls.books.dirty,
        'has-success': eventFilmForm.controls.books.valid && eventFilmForm.controls.books.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.BOOKS' | translate}}</label>
        <div class="col-sm-9">
          <tag-input
            theme="bootstrap"
            [addOnBlur]="true"
            [modelAsStrings]="true"
            formControlName="books"
            secondaryPlaceholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.BOOKS_ADD' | translate}}"
            placeholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.BOOKS_ADD' | translate}}"></tag-input>
          <c360-form-validation-messages [control]="eventFilmForm.controls['books']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.runtime.invalid && eventFilmForm.controls.runtime.dirty,
        'has-success': eventFilmForm.controls.runtime.valid && eventFilmForm.controls.runtime.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.RUNTIME' | translate}}</label>
        <div class="col-sm-9">
          <input type="number"
                 class="form-control"
                 formControlName="runtime"
                 id="runtime">
          <c360-form-validation-messages [control]="eventFilmForm.controls['runtime']"></c360-form-validation-messages>
        </div>
      </div>

      <fieldset class="event-check event-check--fsk">
        <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.ageRating.invalid && eventFilmForm.controls.ageRating.dirty,
          'has-success': eventFilmForm.controls.ageRating.valid && eventFilmForm.controls.ageRating.dirty
          }">
          <legend class="col-sm-3 pr-0 col-form-label" for="event-runningtime">{{ 'MODUL_EVENTS.MODEL.FILM.AGERATING' |
            translate}}
          </legend>
          <div class="col-sm-9 age-ratings">
            <label class="form-check-label" *ngFor="let key of ageRatingsKeys">
              <input type="radio"
                     class="form-check-input"
                     [value]="key"
                     (click)="onClickAgeRating(key)"
                     formControlName="ageRating">
              <img src="assets/img/{{key}}.svg"
                   alt="{{'MODUL_EVENTS.MODEL.FILM.AGERATINGS.' + ageRatings[key] | translate}}">
            </label>
            <c360-form-validation-messages
              [control]="eventFilmForm.controls['ageRating']"></c360-form-validation-messages>
          </div>
        </div>
      </fieldset>

    </div>

    <div class="col-md-6">

      <div class="form-group row" [ngClass]="{
        'has-danger': eventFilmForm.controls.releaseDate.invalid && eventFilmForm.controls.releaseDate.dirty,
        'has-success': eventFilmForm.controls.releaseDate.valid && eventFilmForm.controls.releaseDate.dirty
        }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.RELEASEDATA' | translate}}</label>
        <div class="col-sm-9">

          <div class="input-group">
            <input class="form-control"
                   placeholder="YYYY-MM-DD"
                   name="dp"
                   ngbDatepicker
                   formControlName="releaseDate"
                   #dpReleaseData="ngbDatepicker">
            <div class="input-group-append">
              <button type="button"
                      class="btn btn-outline-secondary"
                      (click)="dpReleaseData.toggle()">
                <i aria-hidden="true" class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
          <!--
          <input type="text"
                 class="form-control"
                 formControlName="releaseDate"
                 id="releaseDate">
                 -->
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['releaseDate']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.countryOfOrigin.invalid && eventFilmForm.controls.countryOfOrigin.dirty,
          'has-success': eventFilmForm.controls.countryOfOrigin.valid && eventFilmForm.controls.countryOfOrigin.dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.COUNTRYOFORIGIN' | translate}}</label>
        <div class="col-sm-9">
          <input type="text"
                 class="form-control"
                 formControlName="countryOfOrigin"
                 id="countryOfOrigin">
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['countryOfOrigin']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.creationYear.invalid && eventFilmForm.controls.creationYear.dirty,
          'has-success': eventFilmForm.controls.creationYear.valid && eventFilmForm.controls.creationYear.dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.CREATIONYEAR' | translate}}</label>
        <div class="col-sm-9">
          <input type="number"
                 class="form-control"
                 formControlName="creationYear"
                 id="creationYear">
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['creationYear']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.spokenLanguage.invalid && eventFilmForm.controls.spokenLanguage.dirty,
          'has-success': eventFilmForm.controls.spokenLanguage.valid && eventFilmForm.controls.spokenLanguage.dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.SPOKENLANGUAGE' | translate}}</label>
        <div class="col-sm-9">
          <input type="text"
                 class="form-control"
                 formControlName="spokenLanguage"
                 id="spokenLanguage">
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['spokenLanguage']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.subtitleLanguage.invalid && eventFilmForm.controls.subtitleLanguage.dirty,
          'has-success': eventFilmForm.controls.subtitleLanguage.valid && eventFilmForm.controls.subtitleLanguage.dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.SUBTITLELANGUAGE' | translate}}</label>
        <div class="col-sm-9">
          <input type="text"
                 class="form-control"
                 formControlName="subtitleLanguage"
                 id="subtitleLanguage">
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['subtitleLanguage']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.websiteUrl.invalid && eventFilmForm.controls.websiteUrl.dirty,
          'has-success': eventFilmForm.controls.websiteUrl.valid && eventFilmForm.controls.websiteUrl.dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.WEBSITEURL' | translate}}</label>
        <div class="col-sm-9">
          <input type="text"
                 class="form-control"
                 formControlName="websiteUrl"
                 id="websiteUrl">
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['websiteUrl']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.genres.invalid && eventFilmForm.controls.genres.dirty,
          'has-success': eventFilmForm.controls.genres.valid && eventFilmForm.controls.genres.dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.GENRES' | translate}}</label>
        <div class="col-sm-9">
          <tag-input
            theme="bootstrap"
            [addOnBlur]="true"
            [modelAsStrings]="true"
            formControlName="genres"
            secondaryPlaceholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.GENRES_ADD' | translate}}"
            placeholder="+ {{ 'MODUL_EVENTS.MODEL.FILM.GENRES_ADD' | translate}}"></tag-input>
          <c360-form-validation-messages [control]="eventFilmForm.controls['genres']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.controls.publisher.invalid && eventFilmForm.controls.publisher.dirty,
          'has-success': eventFilmForm.controls.publisher.valid && eventFilmForm.controls.publisher.dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.PUBLISHER' | translate}}</label>
        <div class="col-sm-9">
          <input type="text"
                 class="form-control"
                 formControlName="publisher"
                 id="publisher">
          <c360-form-validation-messages
            [control]="eventFilmForm.controls['publisher']"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.get('presentationType').invalid && eventFilmForm.get('presentationType').dirty,
          'has-success': eventFilmForm.get('presentationType').valid && eventFilmForm.get('presentationType').dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.PRESENTATION-TYPE' | translate}}</label>
        <div class="col-sm-9">
          <select class="form-control custom-select w-100"
                  id="presentation-type"
                  formControlName="presentationType">
            <option>--- Präsentationstyp auswählen ---</option>
            <option *ngFor="let key of presentationTypesKeys" [value]="key" [label]="presentationTypes[key]">
              {{presentationTypes[key]}}
            </option>
          </select>
          <c360-form-validation-messages
            [control]="eventFilmForm.get('presentationType')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
          'has-danger': eventFilmForm.get('languageVersion').invalid && eventFilmForm.get('languageVersion').dirty,
          'has-success': eventFilmForm.get('languageVersion').valid && eventFilmForm.get('languageVersion').dirty
          }">
        <label class="col-sm-3 pr-0 col-form-label">{{ 'MODUL_EVENTS.MODEL.FILM.LANGUAGE-VERSION' | translate}}</label>
        <div class="col-sm-9">
          <select class="form-control custom-select w-100"
                  id="language-version"
                  formControlName="languageVersion">
            <option>--- Sprachversion auswählen ---</option>
            <option *ngFor="let key of languageVersionsKeys" [value]="key">
              {{'MODUL_EVENTS.LANGUAGE_VERSION.'+languageVersions[key] | translate}}
            </option>
          </select>
          <c360-form-validation-messages
            [control]="eventFilmForm.get('languageVersion')"></c360-form-validation-messages>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row mt-3">
    <div class="col-sm-12">
      <button type="submit" class="btn btn-success">Speichern</button>
      <button type="button" class="btn btn-primary" (click)="cancel()">Abbrechen</button>
      <button type="button" class="btn btn-danger" (click)="submitForm('delete')">Löschen</button>
    </div>
  </div>
</form>
