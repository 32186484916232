import {HalData} from '../../shared/models/halData';
import {Auditorium} from './auditorium.model';
import {isArray} from 'util';

export class AuditoriumHalData extends HalData {

  data: { [id: string]: Auditorium } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.auditoriums) {
      response._embedded.auditoriums.forEach((auditorium: Auditorium) => {
        this.parseToObject(auditorium);
      });
    } else if (isArray(response)) {
      response.map((auditorium: Auditorium) => {
        this.parseToObject(auditorium);
      });
    }


    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): Auditorium {
    if (response != null) {
      const auditorium = new Auditorium(
        response._links,
        response.id,
        response.mandatorId,
        response.locationId,
        response.name
      );

      this.data['' + response.id + ''] = auditorium;
    }
    return this.data['' + response.id + ''];
  }
}
