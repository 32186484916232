import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'c360-navigation-footer',
  templateUrl: './navigation-footer.component.html',
})

export class FooterNavigationComponent implements OnInit {

  public version = null;

  ngOnInit(): void {
    this.version = environment.version;
  }
}
