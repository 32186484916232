import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Transaction} from '../../models/transaction/transaction.model';
import {TransactionStatus} from '../../enums/transaction-status.enum';

@Component({
  selector: 'c360-transaction-resend-mail-form',
  templateUrl: './transaction-resend-mail-form.component.html'
})
export class TransactionResendMailFormComponent implements OnInit {

  @Output() onFormSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Input() transaction: Transaction = null;

  private _resendMailForm: FormGroup;
  private _email: FormControl;

  constructor() {
  }

  /**
   * Init
   */
  ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }

  /**
   * Create form controls
   */
  createFormControls(): void {
    this._email = new FormControl(this.transaction.customer.emailAddress, [
      Validators.email
    ]);
  }

  /**
   * Create form
   */
  createForm(): void {
    this._resendMailForm = new FormGroup({
      email: this._email
    });
  }

  /**
   * Submit form
   */
  submitForm() {
    if (this.isResendableEmail() && this._resendMailForm.valid) {

      this.onFormSubmit.emit(this._resendMailForm.value.email);
    }
  }

  /**
   * ResendMailForm
   * @returns {FormGroup}
   */
  get resendMailForm(): FormGroup {
    return this._resendMailForm;
  }

  /**
   * Gets email
   * @returns {FormControl}
   */
  get email(): FormControl {
    return this._email;
  }

  /**
   * Is Booking Email resendable
   * @returns {boolean}
   */
  public isResendableEmail(): boolean {

    if (this.transaction.status === TransactionStatus.COMPLETE ||
      this.transaction.status === TransactionStatus.REFUNDED_PARTIAL ||
      this.transaction.status === TransactionStatus.REFUND_FAILED
    ) {

      if (!this._resendMailForm.valid) {
        return false;
      }

      return true;
    }
    return false;
  }
}
