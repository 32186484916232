import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalSepaService} from '../hal-sepa.service';

@Injectable()
export class SepaRootLinkResolver implements Resolve<boolean> {

  constructor(private halSepaService: HalSepaService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this.halSepaService.getRootLink();
  }
}
