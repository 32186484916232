import {Hal} from '../../shared/models/hal';

export class SepaFile extends Hal {

  public id: string;
  public mandatorId: string;
  public locationId: string;
  public cdnReference: string;
  public fileName: string;
  public date: string;
  public _links: any;

  constructor(
    _links: any,
    id: string,
    mandatorId: string,
    locationId: string,
    cdnReference: string,
    fileName: string,
    date: string
  ) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.locationId = locationId;
    this.cdnReference = cdnReference;
    this.fileName = fileName;
    this.date = date;
  }
}
