import {BehaviorSubject, forkJoin as observableForkJoin, from as observableFrom, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {Event} from '../models/event.model';
import {HalService} from '../../shared/services/halService';
import {HalData} from '../../shared/models/halData';
import {Film} from '../models/film.model';
import {EventHalData} from '../models/event-hal-data';
import {FilmHalData} from '../models/film-hal-data';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../login/services/user.service';
import {Media} from '../models/media.model';
import {AWSService} from '../../core/services/aws.service';
import {environment} from '../../../environments/environment';
import {Page} from '../../shared/models/page';

@Injectable()
export class HalEventsService extends HalService implements OnDestroy {

  protected gatewayName = 'event';
  protected subscribtions: Subscription = new Subscription();

  public events = new BehaviorSubject<EventHalData>(null);
  public films = new BehaviorSubject<FilmHalData>(null);

  /**
   * Constructor
   *
   * @param {HttpClient} http
   * @param {AlertService} _alertService
   * @param {UserService} _userService
   * @param {LoggerService} _logger
   */
  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _AWSService: AWSService,
    protected _logger: LoggerService
  ) {
    super(http, _alertService, _userService, _logger);

    this.subscribtions = this.data.subscribe((data) => {
      if (data != null && data['events'] != null) {
        this.events.next(data['events']);
      }
      if (data != null && data['films'] != null) {
        this.films.next(data['films']);
      }
    });
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }

  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Script page params
      if (id === 'events') {
        this._data[id] = new EventHalData(id, link);
      } else if (id === 'films') {
        this._data[id] = new FilmHalData(id, link);
      } else {
        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   * Get All
   * @param {number} page
   * @returns {Observable<HalData>}
   */
  getAllEvents(page: number = null): void {
    this.getAll('events', page);
  }

  getAllFilms(page: number = null): void {
    if (this._data['films'].page == null) {
      this._data['films'].page = new Page(page);
    } else if (page != null) {
      this._data['films'].page.currentPage = page;
    }
    this._data['films'].page.sort = 'title,asc';
    this.getAll('films', page);
  }

  /**
   * Get Single
   * @param {string} id
   * @returns {Promise<Event>}
   */
  getEvent(id: string): Promise<Event> {
    return this.get(this._data['events'], id);
  }

  getFilm(id: string): Promise<Film> {
    return this.get(this._data['films'], id);
  }

  /**
   * Save
   * @param {Event} data
   * @returns {Observable<Event>}
   */
  saveEvent(data: Event): Promise<Event> {
    return this.save(this._data['events'], data);
  }

  saveFilm(data: Film): Promise<Film> {
    return this.save(this._data['films'], data);
  }

  /**
   * Update
   * @param {Event} data
   * @returns {Observable<Event>}
   */
  updateEvent(data: Event): Promise<any> {

    return this.update(this._data['events'], data.id, data);
  }

  updateFilm(data: Film): Promise<any> {

    return this.update(this._data['films'], data.id, data);
  }

  /**
   * Delete
   * @param {Event} data
   * @returns {Promise<boolean>}
   */
  deleteEvent(data: Event): Promise<any> {
    return this.delete(this._data['events'], data.id);
  }

  deleteFilm(data: Film): Promise<any> {
    // return this.delete(this._data['films'], data.id);
    // TODO
    return observableFrom(
      this.delete(this._data['films'], data.id)
    ).pipe(
      map(
        () => {
          if (data.media && data.media.length > 0) {
            const mediaList = data.media.filter((item: Media) => {
              return environment.s3.types.indexOf(item.type) !== -1;
            });
            const mediaListObservable = mediaList.map((item: Media) => {
              return this._AWSService.deleteMedia(item);
            });
            return observableForkJoin(mediaListObservable);
          }
          return Promise.resolve({});
        }
      ))
      .toPromise();
  }

  findAllFilmsByEventId(eventId: string): Promise<{ [key: string]: Film }> {
    if (this._data['events'] != null && this._data['events'].href != null) {
      return this.search(
        `${this._data['films'].href}/search/streamAllByMandatorIdAndEventId?mandatorId=${this._userService.getUser().mandatorId}&eventId=${eventId}`
      ).then((response: any) => {
        if (response != null) {
          this._data['films'].parseToArrayObjects(response);
          return this._data['films'].data;
        }
      });
    }
  }

  streamAllEventsByMandatorId(mandatorId: string): Promise<{ [key: string]: Event }> {
    const url = `${this._data['events'].href}/search/streamAllByMandatorId?mandatorId=${mandatorId}`;

    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['events'].parseToArrayObjects(response);
        return this._data['events'].data;
      }
      return response;
    });
  }

  findAllFilmsByMandatorIdAndTitle(mandatorId: string, title: string, page?: number, size: number = 20): Promise<any> {
    if (this._data['films'].page == null) {
      this._data['films'].page = new Page(page);
    } else if (page != null) {
      this._data['films'].page.currentPage = page;
    }
    const paramArray = [
      `${this._data['films'].page.getParamsForPage('title,asc')}`
    ];

    if (mandatorId) {
      paramArray.push(`mandatorId=${mandatorId}`);
    }
    if (title) {
      paramArray.push(`title=${title}`);
    }
    if (size) {
      this._data['films'].page.totalElementsForPage = size;
    }
    return this.search(
      `${this._data['films'].href}/search/findAllByMandatorIdAndTitle?${paramArray.join('&')}`
    ).then((response: any) => {
      if (response != null) {
        this._data['films'].parseToArrayObjects(response);
        return this._data['films'].data;
      }
    });
  }

  findAllEvntsByMandatorIdAndName(mandatorId: string, name: string, size: number = 20): Promise<any> {
    return this.search(
      `${this._data['events'].href}/search/findAllByMandatorIdAndName?mandatorId=${mandatorId}&name=${name}&size=${size}`
    ).then((response: any) => {
      if (response != null) {
        this._data['events'].parseToArrayObjects(response);
        return this._data['events'].data;
      }
    });
  }
}
