import {HalData} from './halData';
import {isArray} from 'util';
import {SalesCounter} from './sales-counter.model';

export class SalesCounterHalData extends HalData {

  data: { [id: string]: SalesCounter } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.salesCounters) {
      response._embedded.salesCounters.forEach((salesCounter: SalesCounter) => {
        this.parseToObject(salesCounter);
      });
    } else if (isArray(response)) {
      response.map((salesCounter: SalesCounter) => {
        this.parseToObject(salesCounter);
      });
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): SalesCounter {
    if (response != null) {
      const salesCounter = new SalesCounter(
        response._links,
        response.id,
        response.mandatorId,
        response.locationId,
        response.externalId,
        response.type,
        response.name,
        response.description,
        response.nextTicketId,
        response.lastTicketId
      );

      this.data['' + response.id + ''] = salesCounter;
    }
    return this.data['' + response.id + ''];
  }
}
