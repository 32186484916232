import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './components/not-found/not-found.component';
import {NavigationModule} from '../navigation/navigation.module';
import {SharedModule} from '../shared/shared.module';
import {AWSService} from './services/aws.service';
import {AcrossTabsParentService} from './services/across-tabs.service';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    NavigationModule,
    RouterModule
  ],
  exports: [
    AppComponent,
    PageNotFoundComponent
  ]
})
export class CoreModule {

  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [
        AWSService,
        AcrossTabsParentService
      ]
    };
  }
}

