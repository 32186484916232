<div class="container-fluid">
  <div class="card">
    <div class="card-header">
        <h1>{{ 'MODUL_TRANSACTIONS.NAME' | translate}}</h1>
    </div>
    <div class="card-body" *ngIf="!existsLocationId">
      {{ 'COMMON.CHOOSE_LOCATION' | translate }}
    </div>
    <div class="card-body" *ngIf="existsLocationId">
    <c360-loading *ngIf="isLoading"></c360-loading>

      <ngb-tabset #transactionTabs="ngbTabset"
                  (tabChange)="onTabChange($event)"
                  [activeId]="TAB_OVERVIEW_ID">

        <!-- Start Overview Tab -->
        <ngb-tab title="Übersicht"
                 (select)="onTabSelect($event)"
                 [id]="TAB_OVERVIEW_ID">
          <ng-template ngbTabContent>

              <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                <form *ngIf="transactionExternalIdToPrintCount != null && transactionHalData" class="text-center">
                  <h1 class="mt-3 mb-3">Prüfe Transaktion {{transactionExternalIdToPrintCount}} von {{transactionHalData.page.totalElements}}</h1>
                </form>
                <form *ngIf="(transactionExternalIdToPrintIsFinished != null && transactionExternalIdToPrintIsFinished) && transactionHalData" class="text-center">
                  <h1 class="mt-3 mb-3">Alles erfolgreich abgeschlossen!</h1>
                  <button href="#" class="btn btn-primary btn-lg mb-3" type="button" aria-pressed="true" (click)="transactionExternalIdToPrintDownload()" [disabled]="transactionExternalIdToPrintDownloadRunning">Excel-Datei herunterladen</button>
                </form>
                <form *ngIf="(transactionExternalIdToPrintHasError != null && transactionExternalIdToPrintHasError) && transactionHalData" class="text-center">
                  <h1 class="mt-3 mb-3">Leider ist ein Fehler aufgetreten!</h1>
                  <button href="#" class="btn btn-danger btn-lg mb-3" type="button" aria-pressed="true" (click)="transactionExternalIdToPrintRetry()">Erneut versuchen</button>
                </form>
                <c360-counter-confirmation
                  *ngIf="performanceId && transactionExternalIdToPrint"
                  [externalTransactionIdToPrint]="transactionExternalIdToPrint"
                  [onlyShowPrintButtons]="true"
                  (ticketsPrinted)="transactionExternalIdToPrintNext($event)"
                  [style.display]="transactionExternalIdToPrintHasError ? '' : 'none'"
                ></c360-counter-confirmation>

                <c360-counter-transactions-search *ngIf="!performanceId" (searchParams)="searchWithParams($event)"></c360-counter-transactions-search>
                <form *ngIf="(transactionExternalIdToPrintIsFinished == null || !transactionExternalIdToPrintIsFinished) && transactionHalData">
                      <table class="table table-striped table-bordered table--selectable">
                        <caption hidden><h2>Liste aller Buchunge</h2></caption>
                        <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th class="nobr" scope="col">Pickup Code</th>
                          <th scope="col">Status</th>
                          <th scope="col">Name/Email</th>
                          <th scope="col">Buchung</th>
                          <th scope="col">Vorstellung</th>
                          <th scope="col">Ort</th>
                          <th scope="col">Zahlung</th>
                        </tr>
                        </thead>

                        <tbody>

                        <tr *ngFor="let transaction of transactionHalData.data | keys"
                            c360-counter-transactions-list-item [transaction]="transaction.value"
                            (click)="onTransactionClick(transaction.value)"
                            [ngClass]="{'table-info': transactionExternalIdToPrint != null && transaction.value.id == transactionExternalIdToPrint}"
                        >
                        </tr>

                          </tbody>

                      </table>

                      <!-- Start Pagination -->
                      <ngb-pagination *ngIf="!performanceId && transactionHalData.page"
                                      [maxSize]="5"
                                      [rotate]="true"
                                      [ellipses]="true"
                                      [boundaryLinks]="true"
                                      [collectionSize]="transactionHalData.page.totalElements"
                                      [pageSize]="transactionHalData.page.totalElementsForPage"
                                      [(page)]="currentPage"
                                      (pageChange)="getAllByPage($event)">
                      </ngb-pagination>
                      <!-- End Pagination -->

                </form>
              </div>

          </ng-template>
        </ngb-tab>
        <!-- End Overview Tab -->

        <!-- Start Transactions Tabs -->
        <ngb-tab *ngFor="let transaction of activeTransactions"
                 (select)="onTabSelect($event)"
                 [id]="createTabId(TAB_TRANSACTION_PREFIX, transaction.id)">

          <ng-template ngbTabTitle>{{transaction.getFullNameForTab()}} {{transaction.id}} <button aria-label="Close" (click)="closeTabById($event, transaction.id)" class="close closeTab ml-2" type="button" >×</button></ng-template>

          <ng-template ngbTabContent>

            <c360-counter-transactions-detail [transaction]="transaction"
                                      (onChangeTransaction)="openTabTransaction($event)"
                                      [reOpenRefundModal]="getReOpenRefundModal()"
                                      (onRefundTickets)="onRefundTickets($event)">
            </c360-counter-transactions-detail>

          </ng-template>
        </ngb-tab>
        <!-- End Transactions Tabs -->

      </ngb-tabset>
  </div>
</div>
</div>
