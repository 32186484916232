<div class="row">
  <div class="col-12 content login">
    <div class="row justify-content-md-center mt-5">
      <div class="card p-4">
        <div class="card-title center" style="text-align: center;">
          <a href="https://krankikom.de" target="_blank"><img src="assets/img/ticket360_logo.svg" alt="T360 Logo" style="height: 100px;"></a>
        </div>
        <div class="card-body">
          <ng-container *ngIf="!showForgotPasswordForm">
            <c360-login-form (onFormSubmit)="logIn($event)" (onEmailChange)="changeEmail($event)" [error]="error" [preEmail]="preEmail"></c360-login-form>
            <a (click)="showForgotPasswordForm=true;"><small class="form-text" [translate]="'LOGIN.PASSWORD_RESET.QUESTION'"></small></a>
          </ng-container>
          <ng-container *ngIf="showForgotPasswordForm">
            <c360-login-forgot-password [preEmail]="preEmail" (done)="showForgotPasswordForm=false;"></c360-login-forgot-password>
          </ng-container>
        </div>

        <div class="card-footer text-center">
          <span class="ml-auto align-middle"><a class="text-secondary" href="https://krankikom.de" target="_blank">T360 © 2024 Krankikom {{version}}</a></span>
        </div>
      </div>
    </div>
  </div>
</div>
