import {HalData} from '../../shared/models/halData';
import {PriceLayout} from './price-layout.model';
import {isArray} from 'util';

export class PriceLayoutHalData extends HalData {

  data: { [id: string]: PriceLayout } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.priceLayouts) {
      response._embedded.priceLayouts.forEach((priceLayout: PriceLayout) => {
        this.parseToObject(priceLayout);
      });
    } else if (isArray(response)) {
      response.map((priceLayout: PriceLayout) => {
        this.parseToObject(priceLayout);
      });
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): PriceLayout {
    if (response != null) {
      const priceLayout = new PriceLayout(
        response._links,
        response.id,
        response.name,
        response.mandatorId,
        response.transactionFee,
        response.areaPricesList
      );

      this.data['' + response.id + ''] = priceLayout;
    }
    return this.data['' + response.id + ''];
  }
}
