import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbModal, NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {isUndefined} from 'util';
import {LoggerService} from '../../../../shared/services/logger/logger.service';
import {Params} from '../../../../shared/models/search/params.model';
import {Param} from '../../../../shared/models/search/param.model';
import {HalSepaService} from '../../../services/hal-sepa.service';
import {TransactionSearchHalData} from '../../../models/transaction-search-hal-data';
import {Transaction} from '../../../models/transaction/transaction.model';
import {SepaExportModalComponent} from './sepa-export-modal.component';
import {TransactionType} from '../../../enums/transaction-type.enum';
import {PaymentTypes} from '../../../enums/payment-types.enum';
import {SepaFileHalData} from '../../../models/sepa-file-hal-data';
import {UserService} from '../../../../login/services/user.service';

@Component({
  selector: 'c360-sepa-list',
  templateUrl: './sepa-list.component.html'
})
export class SepaListComponent implements OnInit, OnDestroy {

  @ViewChild('sepaTabs', {static: true}) sepaTabs: NgbTabset;

  public selectedAllRowsFormControl: any = false;
  public selectedLocationId: string = null;
  public activeSepa: Transaction[] = [];
  public _transactionSearchHalData: TransactionSearchHalData;
  public readonly TAB_OPEN_POSITIONS = 'tab-open-positions';
  public readonly TAB_EXPORTED_POSITIONS = 'tab-exported-positions';

  private _userLocationId: string;
  private _sepaFileHalDat: SepaFileHalData;
  private _subscription: Subscription = null;
  private _currentTransactionsPage = 1;
  private _currentSepaFilesPage = 1;
  private _isLoading: boolean;
  private _currentTab = null;
  private _params: Params = new Params();
  private _searchParams: Params = new Params();

  /**
   * Constructor
   *
   * @param {HalSepaService} _halTransactionsService
   * @param {LoggerService} _logger
   * @param {ChangeDetectorRef} _ref
   * @param {NgbModal} _modalService
   * @param {UserService} _userService
   */
  constructor(
    private _halTransactionsService: HalSepaService,
    private _logger: LoggerService,
    private _ref: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _userService: UserService) {
  }

  ngOnInit() {
    this.isUserLocation();
    this._params = this.addParamsMandatoryForSepa(this._params);
    this.init();
  }

  private init(): void {

    this._isLoading = false;

    this._subscription =
      this._halTransactionsService.transactionsSearch.subscribe((halData: TransactionSearchHalData) => {

        if (null != halData && null != halData.data) {

          this._transactionSearchHalData = halData;

          if (null != halData.page) {
            this._currentTransactionsPage = halData.page.currentPage;
          }

          this._isLoading = false;
          this._ref.markForCheck();
          this.selectAllRows();
        }
      });

    this._subscription.add(
      this._halTransactionsService.transactionSepaFiles.subscribe((halData: SepaFileHalData) => {

        if (null != halData && null != halData.data) {

          this._sepaFileHalDat = halData;

          if (null != halData.page) {
            this._currentSepaFilesPage = halData.page.currentPage;
          }

          this._isLoading = false;
          this._ref.markForCheck();
        }
      }));

    this.getAllSearch(1);
  }

  /**
   * OnDestroy
   */
  ngOnDestroy() {

    if (null != this._subscription && !isUndefined(this._subscription)) {
      this._subscription.unsubscribe();
    }
  }

  /**
   *
   * @param {Params} params
   */
  searchWithParams(params: Params): void {

    this._isLoading = true;
    this._currentTransactionsPage = 1;
    this.selectedLocationId = null;

    if (!this.isUserLocation()) {

      const locationParam: Param = params.params.find(function(param) {
        return param.key === 'locationId';
      });

      if (!isUndefined(locationParam && null !== locationParam)) {
        this.selectedLocationId = locationParam.value;
      }
    }

    this._searchParams = params;

    this._halTransactionsService.getAllTransactionsSearch(this._currentTransactionsPage, this._params.concats(this._searchParams));
  }

  /**
   *
   * @param {number} page
   */
  getAllSearch(page: number = null, params: Params = new Params()): void {

    this._isLoading = true;
    this.selectedLocationId = null;

    if (null === params) {
      params = new Params();
    }

    if (!this.isUserLocation()) {

      const locationParam: Param = params.params.find(function(param) {
        return param.key === 'locationId';
      });

      if (!isUndefined(locationParam) && null !== locationParam) {
        this.selectedLocationId = locationParam.value;
      }
    }

    this._halTransactionsService.getAllTransactionsSearch(page, this._params.concats(this._searchParams));
  }

  /**
   *
   * @param {number} pageNumber
   */
  getAllByPage(pageNumber: number): void {

    this.getAllSearch(pageNumber);
  }

  /**
   *
   * @param {number} page
   */
  getAllSepaFiles(page: number = null): void {

    this._isLoading = true;

    const params: Params = this.addParamsMandatoryForSepaFiles(new Params());

    this._isLoading = !this._halTransactionsService.getAllSepaFiles(page, params);
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Gets transactionSearchHalData
   * @returns {TransactionSearchHalData}
   */
  get transactionHalData(): TransactionSearchHalData {
    return this._transactionSearchHalData;
  }

  /**
   * Gets sepaFileHalData
   * @returns {SepaFileHalData}
   */
  get sepaFileHalDat(): SepaFileHalData {
    return this._sepaFileHalDat;
  }

  /**
   * Gets current sepa files list Page
   * @returns {number}
   */
  get currentSepaFilesPage(): number {
    return this._currentSepaFilesPage;
  }

  /**
   * Gets current transaction list Page
   * @returns {number}
   */
  get currentTransactionPage(): number {
    return this._currentTransactionsPage;
  }

  /**
   * Sets currentTransactionPage
   * @param {number} value
   */
  set currentTransactionPage(value: number) {
    this._currentTransactionsPage = value;
  }

  /**
   * Sets currentSepaFilesPage
   * @param {number} value
   */
  set currentSepaFilesPage(value: number) {
    this._currentSepaFilesPage = value;
  }

  /**
   *
   * @param tab
   */
  onTabSelect(tab): void {

    this._currentTab = tab;
  }

  /**
   *
   * @param event
   */
  onTabChange(event: NgbTabChangeEvent): void {

    if (event.nextId === this.TAB_EXPORTED_POSITIONS) {
      this.getAllSepaFiles();
    }
  }

  /**
   * Select all rows for sepa export
   */
  selectAllRows(): void {

    Object.keys(this._transactionSearchHalData.data).forEach((key) => {
      this._transactionSearchHalData.data[key].selected = this.selectedAllRowsFormControl;
    });
  }

  /**
   * Select one rows for sepa export
   */
  selectOneRows(id: string): void {

    if (!this.isSelectedLocationId()) {
      return;
    }

    if (!isUndefined(this._transactionSearchHalData.data[id])) {
      this._transactionSearchHalData.data[id].selected = !this._transactionSearchHalData.data[id].selected;

      if (!this._transactionSearchHalData.data[id].selected) {
        this.selectedAllRowsFormControl = false;
      } else {

        this.selectedAllRowsFormControl = Object.keys(this._transactionSearchHalData.data).every((t) => {
          return this._transactionSearchHalData.data[t].selected === true;
        });

      }
    }
  }

  public exportTransactionToSepa(): void {

    const transactionIds: string[] = [];

    Object.keys(this._transactionSearchHalData.data).forEach((key) => {
      if (this._transactionSearchHalData.data[key].selected) {
        transactionIds.push(this._transactionSearchHalData.data[key].transactionId);
      }
    });

    if (transactionIds.length > -1) {

      this._isLoading = true;

      const _params = {
        'locationId': this.selectedLocationId,
        'transactionIds': transactionIds
      };

      this._halTransactionsService.exportTransactionToSepa(_params).then((response) => {

        this._isLoading = false;

        if (null !== response && !isUndefined(response)) {

          const modalRef = this._modalService.open(SepaExportModalComponent, {backdrop: 'static'});
          modalRef.componentInstance.response = response;

          this.getAllSearch(1, this._params);
        }
      });
    }
  }

  /**
   * Sets to params mandatory search params
   * @param {Params} params
   * @returns {Params}
   */
  private addParamsMandatoryForSepa(params: Params): Params {

    params.add(new Param('sort', 'performanceStartDate,ASC'));
    params.add(new Param('intendedForSepaFile', 'true'));
    params.add(new Param('paymentProviderType', PaymentTypes.DIRECT_DEBIT));
    params.add(new Param('transactionType', TransactionType.SALE));

    if (this.isUserLocation()) {
      params.add(new Param('locationId', this._userService.getUser().locationId));
    }

    return params;
  }

  /**
   * Sets to params mandatory search sepa files
   * @param {Params} params
   * @returns {Params}
   */
  private addParamsMandatoryForSepaFiles(params: Params): Params {

    params.add(new Param('sort', 'id,desc'));

    if (this.isUserLocation()) {
      params.add(new Param('locationId', this._userService.getUser().locationId));
    }

    return params;
  }

  /**
   * @returns {boolean}
   */
  public isSelectedLocationId(): boolean {

    if (this.selectedLocationId === null && this._userService.getUser().locationId == null) {
      return false;
    }

    return true;
  }

  /**
   * Test if the user is location user
   * @returns {boolean}
   */
  public isUserLocation(): boolean {

    this._userLocationId = this._userService.getUser().locationId;
    this.selectedLocationId = this._userService.getUser().locationId;

    if (!isUndefined(this._userLocationId) && this._userLocationId !== null) {
      return true;
    }

    return false;
  }
}
