import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PrinterService} from '../services/printer.service';
import {Injectable} from '@angular/core';

@Injectable()
export class HasPrinterGuard implements CanActivate {

  constructor(private _printerService: PrinterService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
    /*return this._printerService.connectionState$().map(isConnected => {
      return isConnected === PrinterConnectionState.CONNECTED;
    });*/
  }
}
