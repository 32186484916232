import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Auditorium} from '../../models/auditorium.model';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {HalAuditoriumsService} from '../hal-auditoriums.service';

@Injectable()
export class AuditoriumsDetailResolver implements Resolve<any> {

  constructor(private _halAuditoriumsService: HalAuditoriumsService,
              private _logger: LoggerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {

    return new Promise((resolve, reject) => {
      this._halAuditoriumsService
        .getAuditorium(route.params.id)
        .then((auditorium: Auditorium) => {
          this._logger.info('edit', auditorium.id);
          resolve(auditorium);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}
