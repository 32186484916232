export class TicketVoucher {

  private _id: string;
  private _name: string;
  private _code: string;
  private _type: string;
  private _amount: number;
  private _amountWithFee: number;
  private _valueInitial: string;
  private _valueCurrent: string;
  private _description: string;
  private _skipSaleFee: boolean;
  private _absoluteAmount: string;


  constructor(_id: string,
              _name: string,
              _code: string,
              _type: string,
              _amount: number,
              _amountWithFee: number,
              _valueInitial: string,
              _valueCurrent: string,
              _description: string,
              _skipSaleFee: boolean
  ) {

    this._id = _id;
    this._name = _name;
    this._code = _code;
    this._type = _type;
    this._amount = _amount;
    this._amountWithFee = _amountWithFee;
    this._valueInitial = _valueInitial;
    this._valueCurrent = _valueCurrent;
    this._description = _description;
    this._skipSaleFee = _skipSaleFee;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get amount(): number {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = value;
  }

  get amountWithFee(): number {
    return this._amountWithFee;
  }

  set amountWithFee(value: number) {
    this._amountWithFee = value;
  }

  get valueInitial(): string {
    return this._valueInitial;
  }

  set valueInitial(value: string) {
    this._valueInitial = value;
  }

  get valueCurrent(): string {
    return this._valueCurrent;
  }

  set valueCurrent(value: string) {
    this._valueCurrent = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get skipSaleFee(): boolean {
    return this._skipSaleFee;
  }

  set skipSaleFee(value: boolean) {
    this._skipSaleFee = value;
  }

  get absoluteAmount(): number {

    if (this.skipSaleFee) {
      return this.amount;
    }

    return this.amountWithFee;
  }
}
