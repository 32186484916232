<div class="container-fluid">
    <span onclick="window.history.back();" class="back-to-overview mb-3">
        <i class="fa fa-arrow-circle-o-left mr-1" aria-hidden="true"></i>
        <span>Zurück</span>
    </span>
    <c360-loading *ngIf="isLoading"></c360-loading>
      <div class="card">
        <!-- Kundenprofil Bearbeitung -->
        <div class="card-header">
            <h2>{{ 'MODUL_USERS.COMPONENT_DETAIL.PROFILE_DATA' | translate}}</h2>
        </div>
        <div class="card-body">
          <c360-user-details-form
            [user]="user"
            (onFormSubmit)="onSumbit($event)"
            (onCancel)="onCancel()"></c360-user-details-form>
        </div>
      </div>
    </div>

<ng-template #modalContent let-c="close" let-d="close">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'MODUL_USERS.COMPONENT_DETAIL.DELETE_TEXT' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'MODUL_USERS.COMPONENT_DETAIL.DELETE_TEXT' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Yes')">{{ 'MODUL_USERS.COMPONENT_DETAIL.DELETE_YES' |
      translate}}
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="d('No')">{{ 'MODUL_USERS.COMPONENT_DETAIL.DELETE_NO' |
      translate}}
    </button>
  </div>
</ng-template>
