<th>{{event?.id}}</th>
<td>{{event?.type}}</td>
<td>{{event?.typeMasterId}}</td>
<td>{{event?.mandatorId}}</td>
<td>
  <a class="btn btn-primary" role="button" ngbTooltip="{{ 'FORMS.BUTTONS.EDIT' | translate}}"
     [routerLink]="['/','events','detail', event.id]">
    <i class="fa fa-pencil"></i>
    <span class="text-hide" [translate]="'FORMS.BUTTONS.EDIT'"></span>
  </a>
  <button class="btn btn-danger" ngbTooltip="{{ 'FORMS.BUTTONS.DELETE' | translate}}" (click)="delete(event)">
    <i class="fa fa-times-circle"></i>
    <span class="text-hide">{{ 'FORMS.BUTTONS.DELETE' | translate}}</span>
  </button>
</td>
