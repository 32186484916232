<div class="container-fluid" *ngIf="!existsLocationId">
  <div class="card">
    <div class="card-header">
      <h1>Berichte</h1>
    </div>
    <div class="card-body">
      {{ 'COMMON.CHOOSE_LOCATION' | translate }}
    </div>
  </div>
</div>
<div class="container-fluid" [hidden]="!existsLocationId">
  <div class="card">
    <div class="card-header">
      <h1>Berichte</h1>
    </div>
    <c360-loading *ngIf="isLoading"></c360-loading>
    <div class="card-body">
      <c360-reports-filter (onSubmit)="onSubmitFilter($event)"
                                   (onLoading)="onLoadingFilter($event)"></c360-reports-filter>
      <div *ngIf="generationStatus && generationStatus !== 'GENERATION_IS_COMPLETED'"
           class="alert alert-warning">
        {{ 'MODUL_TRANSACTIONS.COUNTER_REPORT_GENERATION_STATUS.' + generationStatus | translate }}
      </div>
      <iframe #iframe
              style="border: none;height: -webkit-fill-available;"
              width="100%">
      </iframe>
    </div>
  </div>
</div>
