export const locale_base_de = {
  'lang': 'de',
  'data': {
    'CLIENT' : {
      'NAME': 'T360 CMS'
    },
    'LOADING': {
      'TEXT': 'Lade Daten...',
      'SHORT_TEXT': 'Lade...'
    },
    'PAGE_NOT_FOUND': {
      'TITLE': 'Oops!',
      'SUBTITLE': '404 Nicht gefunden',
      'GO_BACK': 'Zum Dashboard'
    },
    'FORMS': {
      'BUTTONS': {
        'EDIT': 'Bearbeiten',
        'DELETE': 'Löschen',
        'DUPLICATE': 'Duplizieren',
        'NEW': 'Neu',
        'ADD': 'Hinzufügen',
        'CANCEL': 'Abbrechen',
        'SAVE': 'Speichern',
        'RESET': 'Zurücksetzen',
        'CHANGE_OCCUPATIONS': 'Belegung ändern',
        'MANUAL_ENTRY': 'Manueller Einlass',
        'SEND': 'Senden'
      },
      'ADD_NEW_TAG': '+ Tag hinzufügen'
    },
    'TABLES': {
      'HEADINGS': {
        'TH_EDIT_BUTTONS': 'Bearbeitungsbuttons'
      },
    },
    'VALIDATION_ERROR': {
      'REQUIRED': 'Dieses Feld wird benötigt',
      'INVALID_EMAIL': 'Bitte eine gültige E-Mailadresse eingeben.',
      'INVALID_PASSWORD': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'INVALID_LENGTH': 'Minimum length {{requiredLength}}',
      'MATCH_PASSWORD': 'Die Passwörter stimmen nicht überein',
      'SECURE_PASSWORD': 'Das Passwort muss mindestes 8 Zeichen lang sein und ' +
        'mindestens einen Buchstaben, eine Zahl und ein Sonderzeichen enthalten.',
      'IBAN': 'Das Format der eingegebenen IBAN ist fehlerhaft.',
      'INVALID_DATE': 'Das Datum ist ungültig',
      'INVALID_PICKUPCODE': 'Der Buchungscode ist ungültig.'
    },
    'COUNTRIES': {
      'ALBANIA': 'Albanien',
      'ANDORRA': 'Andorra',
      'ARMENIA': 'Armenien',
      'AUSTRIA': 'Österreich',
      'AZERBAIJAN': 'Aserbaidschan',
      'BELARUS': 'Weißrussland',
      'BELGIUM': 'Belgien',
      'BOSNIA_AND_HERZEGOVINA': 'Bosnien und Herzegowina',
      'BULGARIA': 'Bulgarien',
      'CROATIA': 'Kroatien',
      'CYPRUS': 'Zypern',
      'CZECH_REPUBLIC': 'Tschechische Republik',
      'DENMARK': 'Dänemark',
      'ESTONIA': 'Estland',
      'FINLAND': 'Finnland',
      'FRANCE': 'Frankreich',
      'GEORGIA': 'Georgien',
      'GERMANY': 'Deutschland',
      'GREECE': 'Griechenland',
      'HUNGARY': 'Ungarn',
      'ICELAND': 'Island',
      'IRELAND': 'Irland',
      'ITALY': 'Italien',
      'KAZAKHSTAN': 'Kasachstan',
      'KOSOVO': 'Kosovo',
      'LATVIA': 'Lettland',
      'LIECHTENSTEIN': 'Liechtenstein',
      'LITHUANIA': 'Litauen',
      'LUXEMBOURG': 'Luxemburg',
      'MACEDONIA': 'Mazedonien',
      'MALTA': 'Malta',
      'MOLDOVA': 'Moldawien',
      'MONACO': 'Monaco',
      'MONTENEGRO': 'Montenegro',
      'NETHERLANDS': 'Niederlande',
      'NORWAY': 'Norwegen',
      'POLAND': 'Polen',
      'PORTUGAL': 'Portugal',
      'ROMANIA': 'Rumänien',
      'RUSSIA': 'Russland',
      'SAN_MARINO': 'San Marino',
      'SERBIA': 'Serbien',
      'SLOVAKIA': 'Slowakei',
      'SLOVENIA': 'Slowenien',
      'SPAIN': 'Spanien',
      'SWEDEN': 'Schweden',
      'SWITZERLAND': 'Schweiz',
      'TURKEY': 'Türkei',
      'UKRAINE': 'Ukraine',
      'UNITED_KINGDOM': 'Vereinigtes Königreich',
      'VATICAN_CITY': 'Vatikan STADT',
    }
  }
};
