export enum PerformanceStatus {
  VISIBLE_FOR_ALL = 'VISIBLE_FOR_ALL',
  VISIBLE_FOR_ONLINE = 'VISIBLE_FOR_ONLINE',
  VISIBLE_FOR_OFFLINE = 'VISIBLE_FOR_OFFLINE',
  APPROVED_FOR_ALL = 'APPROVED_FOR_ALL',
  APPROVED_FOR_ONLINE = 'APPROVED_FOR_ONLINE',
  APPROVED_FOR_OFFLINE = 'APPROVED_FOR_OFFLINE',
  SOLD_OUT_FOR_ONLINE = 'SOLD_OUT_FOR_ONLINE',
  SOLD_OUT = 'SOLD_OUT',
  BLOCKED = 'BLOCKED',
  CREATED = 'CREATED',
}

export enum PerformanceStatusFilter {
  VISIBLE_FOR_ALL = 'VISIBLE_FOR_ALL',
  VISIBLE_FOR_ONLINE = 'VISIBLE_FOR_ONLINE',
  VISIBLE_FOR_OFFLINE = 'VISIBLE_FOR_OFFLINE',
  APPROVED_FOR_ALL = 'APPROVED_FOR_ALL',
  APPROVED_FOR_ONLINE = 'APPROVED_FOR_ONLINE',
  APPROVED_FOR_OFFLINE = 'APPROVED_FOR_OFFLINE',
  SOLD_OUT_FOR_ONLINE = 'SOLD_OUT_FOR_ONLINE',
  SOLD_OUT = 'SOLD_OUT',
  BLOCKED = 'BLOCKED',
  CREATED = 'CREATED',
}
