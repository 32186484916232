import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ITransactionPaymentUpdate} from '../../models/transaction/transaction-payment.interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PaymentTypes} from '../../enums/payment-types.enum';
import {HalTransactionsService} from '../../services/hal-transactions.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ValidationService} from '../../../shared/services/validation.service';

@Component({
  selector: 'c360-transaction-payment-edit-modal',
  templateUrl: './transaction-payment-edit-modal.component.html',
})
export class TransactionPaymentEditModalComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;

  private _payment: ITransactionPaymentUpdate;
  private _paymentForm: FormGroup;

  private _paymentTypesList = PaymentTypes;
  private _paymentTypesListKey: Array<string>;

  private _errorMessage: string;
  private _providerTypeIdPlaceholder: string;

  private _isLoading: boolean;

  /**
   *
   * @param {NgbActiveModal} _activeModal
   * @param {FormBuilder} _formBuilder
   * @param {HalTransactionsService} _halTransactionsService
   * @param {ChangeDetectorRef} _changeDetectorRef
   */
  constructor(private readonly _activeModal: NgbActiveModal,
              private readonly _formBuilder: FormBuilder,
              private readonly _halTransactionsService: HalTransactionsService,
              private readonly _changeDetectorRef: ChangeDetectorRef) {
    this._paymentTypesListKey = Object.keys(this._paymentTypesList);
    this._errorMessage = null;
    this._isLoading = false;
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
    this._providerTypeIdPlaceholder = this._payment.providerTypeId;
    this._paymentForm = this._formBuilder
      .group({
        transactionId: [
          this._payment.transactionId,
          [
            Validators.required
          ]
        ],
        externalId: [
          this._payment.externalId,
          [
            Validators.required
          ]
        ],
        paymentProviderType: [
          {
            value: this._payment.paymentProviderType,
            disabled: true
          },
          [
            Validators.required
          ],
        ],
        providerTypeCustomerName: [
          this._payment.providerTypeCustomerName,
          [
            Validators.required
          ]
        ],
        providerTypeId: [
          '',
          [
            ValidationService.iban
          ]
        ],
        paymentProvider: [
          {
            value: this._payment.paymentProvider,
            disabled: true
          },
          [
            Validators.required
          ]
        ],
      });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public submitForm(): void {
    if (this._errorMessage) {
      this._errorMessage = null;
      this._changeDetectorRef.detectChanges();
    }
    if (this._paymentForm.valid) {
      const data: ITransactionPaymentUpdate = {
        transactionId: this._paymentForm.get('transactionId').value,
        externalId: this._paymentForm.get('externalId').value,
        paymentProvider: this._paymentForm.get('paymentProvider').value,
        paymentProviderType: this._paymentForm.get('paymentProviderType').value,
        providerTypeId: this._paymentForm.get('providerTypeId').value === this._payment.providerTypeId ? null : this._paymentForm.get('providerTypeId').value,
        providerTypeCustomerName: this._paymentForm.get('providerTypeCustomerName').value,
      };
      this._isLoading = true;
      this._subscriptions
        .add(
          this._halTransactionsService
            .updateTransactionPayment(data)
            .subscribe(
              (response) => {
                this._isLoading = false;
                this._activeModal.close(true);
              },
              (response: HttpErrorResponse) => {
                console.error(response);
                this._isLoading = false;
                if (response && response.error && response.error.errorUserMessage) {
                  this._errorMessage = response.error.errorUserMessage;
                  this._changeDetectorRef.detectChanges();
                }
              }
            )
        );
    } else {
      this.validateAllFormFields(this._paymentForm);
    }
  }

  /**
   *
   * @param {FormGroup} formGroup
   */
  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        // control.updateValueAndValidity();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public onFocusRoviderTypeId(): void {
    this._providerTypeIdPlaceholder = '';
  }

  public onBlurRoviderTypeId(): void {
    this._providerTypeIdPlaceholder = this._payment.providerTypeId;
  }

  /**
   *
   * @return {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  /**
   *
   * @return {ITransactionPaymentUpdate}
   */
  get payment(): ITransactionPaymentUpdate {
    return this._payment;
  }

  /**
   *
   * @param {ITransactionPaymentUpdate} value
   */
  set payment(value: ITransactionPaymentUpdate) {
    this._payment = value;
  }

  /**
   *
   * @return {FormGroup}
   */
  get paymentForm(): FormGroup {
    return this._paymentForm;
  }

  /**
   *
   * @return {any}
   */
  get paymentTypesList(): any {
    return this._paymentTypesList;
  }

  /**
   *
   * @return {Array<string>}
   */
  get paymentTypesListKey(): Array<string> {
    return this._paymentTypesListKey;
  }

  /**
   *
   * @return {string}
   */
  get errorMessage(): string {
    return this._errorMessage;
  }

  /**
   *
   * @return {string}
   */
  get providerTypeIdPlaceholder(): string {
    return this._providerTypeIdPlaceholder;
  }

  /**
   *
   * @return {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }
}
