<div class="modal-header">
  <h4 class="modal-title">{{ 'MODUL_TRANSACTIONS.REFUND_MODAL.NAME' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div *ngIf="transactionResponseStatusOK !== null" class="alert alert-success">
    <div class="form-check">
      {{'MODUL_TRANSACTIONS.CHARGEBACK_MODAL.' + transactionResponseStatusOK | translate}}
    </div>
  </div>

  <div *ngIf="transactionResponseStatusFail !== null" class="alert alert-danger">
    <div class="form-check">
      {{'MODUL_TRANSACTIONS.CHARGEBACK_MODAL.' + transactionResponseStatusFail | translate}}
    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Ok</button>
</div>
