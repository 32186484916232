<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h1>{{ 'MODUL_EVENTS.NAME' | translate}}: {{ 'MODUL_EVENTS.COMPONENT_EVENTS_LIST.NAME' | translate}}</h1>
        </div>
        <div class="card-body">
            <c360-loading *ngIf="isLoading"></c360-loading>
            <ng-container *ngIf="eventHalData">
                <c360-events-filter></c360-events-filter>
                <form>
                    <table class="table table-striped">
                        <caption hidden>Liste aller Filme</caption>
                        <thead>
                            <tr>
                                <th scope="col"><span aria-label="ID-Nummer">#</span></th>
                                <th scope="col">Type</th>
                                <th scope="col">Type Master ID</th>
                                <th scope="col">Mandator Id</th>
                                <th scope="col"><span class="text-hide">Bearbeitungsbuttons</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let event of eventHalData.data | keys" c360-events-list-item [event]="event.value"
                            (onDelete)="onDelete($event)"></tr>
                        </tbody>
                    </table>
                </form>
                <!-- Start Pagination -->
                <ngb-pagination *ngIf="eventHalData.page"
                                [maxSize]="5"
                                [rotate]="true"
                                [ellipses]="true"
                                [boundaryLinks]="true"
                                [collectionSize]="eventHalData.page.totalElements"
                                [pageSize]="eventHalData.page.totalElementsForPage"
                                [(page)]="currentPage"
                                (pageChange)="getAllByPage($event)"></ngb-pagination>
                <!-- End Pagination -->
            </ng-container>
        </div>
    </div>
</div>
