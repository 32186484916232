export abstract class BaseListComponent {

  private _currentPage: number;

  /**
   *
   * @returns {number}
   */
  get currentPage(): number {
    return this._currentPage;
  }

  /**
   * Sets currentPage
   * @param {number} value
   */
  set currentPage(value: number) {
    this._currentPage = value;
  }
}
