import {Hal} from '../../shared/models/hal';

export class Event extends Hal {

  public id: string;
  public name: string;
  public type: string;
  public typeMasterId: string;
  public mandatorId: string;

  public _links:  any;

  constructor(
    _links?: any,
    id?: string,
    name?: string,
    type?: string,
    typeMasterId?: string,
    mandatorId?: string
  ) {
      super(_links);

      this.id = id;
      this.name = name;
      this.type = type || '';
      this.typeMasterId = typeMasterId;
      this.mandatorId = mandatorId;
  }
}
