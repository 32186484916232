import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AreasRootLinkResolver} from './services/resolver/areas-root-link.resolver';
import {AreasComponent} from './components/areas.component';
import {HalAreasService} from './services/hal-areas.service';

const appRoutes: Routes = [
  {
    path: '',
    component: AreasComponent,
    resolve: {
      rootLink: AreasRootLinkResolver
    },
    data: {
      breadcrumb: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    HalAreasService,
    AreasRootLinkResolver
  ]
})
export class AreasRoutingModule {
}
