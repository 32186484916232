import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../models/user';
import {ValidationService} from '../../../shared/services/validation.service';
import {v4 as uuid} from 'uuid';

@Component({
  selector: 'c360-user-change-email-form',
  templateUrl: './user-change-email.form.html'
})
export class UserChangeEmailFormComponent implements OnInit {

  @Input() user: User;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter<any>();

  private _emailAddressForm: FormGroup;


  constructor(private _formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this._emailAddressForm = this._formBuilder
      .group({
          externalId: [
            this.user.externalId ? this.user.externalId : uuid()
          ],
          onlyCheck: [
            false
          ],
          newEmailAddress: [
            '',
            [
              Validators.required,
              ValidationService.emailValidator,
            ]
          ]
        }
      );
  }

  /**
   *
   * @param {FormGroup} formGroup
   */
  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        // control.updateValueAndValidity();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public submitForm(action?: string) {
    if (this._emailAddressForm.valid) {
      this.onFormSubmit.emit(this._emailAddressForm.value);
      this._emailAddressForm.reset();
    } else {
      this.validateAllFormFields(this._emailAddressForm);
    }
  }

  get emailAddressForm(): FormGroup {
    return this._emailAddressForm;
  }
}
