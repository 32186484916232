<div class="modal-header">
  <h4 class="modal-title">{{ 'MODUL_TRANSACTIONS.TICKET_TYPE_UPDATE_MODAL.NAME' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-container *ngIf="transactionResponseStatusOK === null && transactionResponseStatusFail === null">

<div class="modal-body">
  <form name="updateTicketPriceForm">

    <div *ngIf="transaction.isSepaFileGeneratedForTransaction" class="alert alert-warning">
      <div class="form-check">
        <input class="form-check-input" id="exampleCheck1" type="checkbox" (click)="setMandatoryRefundConfirmation()">
        <label class="form-check-label" for="exampleCheck1"><i>Die SEPA-Datei ist bereits generiert, wollen Sie mit der Bearbeitung fortfahren?</i></label>
      </div>
    </div>

    <div *ngIf="transactionResponseStatusOK != null" class="alert alert-success">
      <div class="form-check">
        {{transactionResponseStatusOK}}
      </div>
    </div>

    <div *ngIf="transactionResponseStatusFail != null" class="alert alert-danger">
      <div class="form-check">
        {{transactionResponseStatusFail}}
      </div>
    </div>

      <table class="table table-striped table-sm table-bordered mb-0">
        <thead>
        <tr>
          <th *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'">Platz</th>
          <th>Preis-Art</th>
          <th>Betrag</th>
          <th>Differenz</th>
          <th class="nobr">Neuer Betrag</th>
        </tr>
        </thead>
        <tbody>

        <ng-container *ngIf="ticketsForUpdate != null && priceList != null">

          <tr *ngFor="let ticket of ticketsForUpdate;"
              c360-transactions-update-ticket-type-item
              [ticket]="ticket"
              [transaction]="transaction"
              [priceList]="priceList[ticket.priceId]"
              [currency]="transaction.currency"
              (onPriceChange)="changePrice($event)">
          </tr>
       </ng-container>

       </tbody>
       <tfoot>
          <tr>
            <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'" class="py-2 text-right" colspan="2">Differenz und Betrag gesamt:</td>
            <td *ngIf="transaction.performance.occupationType == 'UNRESERVED'" class="py-2 text-right">Differenz und Betrag gesamt:</td>
            <td class="py-2 text-right"><span>{{amount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</span></td>
            <td class="py-2 text-right"><span class="text-danger">{{calcDifference | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</span></td>
            <td class="py-2 text-right"><span>{{calcAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</span></td>
          </tr>
        </tfoot>
      </table>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Schließen</button>
  <button *ngIf="transactionResponseStatusOK == null" type="button" class="btn btn-danger" [disabled]="isMandatoryRefundConfirmation" (click)="activeModal.close(spaceIdToTickets)">Update</button>
</div>

</ng-container>

<ng-container *ngIf="transactionResponseStatusOK !== null ||transactionResponseStatusFail !== null">

  <div class="modal-body">

    <div *ngIf="transactionResponseStatusOK !== null" class="alert alert-success">
      <div class="form-check">
        {{'MODUL_TRANSACTIONS.TICKET_TYPE_UPDATE_MODAL.' + transactionResponseStatusOK | translate}}
      </div>
    </div>

    <div *ngIf="transactionResponseStatusFail !== null" class="alert alert-danger">
      <div class="form-check">
        {{'MODUL_TRANSACTIONS.TICKET_TYPE_UPDATE_MODAL.' + transactionResponseStatusFail | translate}}
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Ok</button>
  </div>

</ng-container>

