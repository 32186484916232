export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

export function isPlainObject(obj) {
  return isObject(obj) && (
    obj.constructor === Object  // obj = {}
    || obj.constructor === undefined // obj = Object.create(null)
  );
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (Array.isArray(target)) {
    if (Array.isArray(source)) {
      target.push(...source);
    } else {
      target.push(source);
    }
  } else if (isPlainObject(target)) {
    if (isPlainObject(source)) {
      for (const key of Object.keys(source)) {
        if (!target[key]) {
          target[key] = source[key];
        } else {
          mergeDeep(target[key], source[key]);
        }
      }
    } else {
      throw new Error(`Cannot merge object with non-object`);
    }
  } else {
    target = source;
  }

  return mergeDeep(target, ...sources);
};

export function isNumber(value: string | number): boolean {
  return !isNaN(Number(value.toString()));
}

/**
 * Generate a uuid
 * @returns {string}
 */
export function guid(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export const noop = (): any => undefined;
