import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../models/user';

@Component({
  selector: 'c360-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
})
export class DeleteUserModalComponent {

  private _user: User;

  constructor(private _activeModal: NgbActiveModal) {
  }

  /**
   *
   */
  public onDelete(): void {
    this._activeModal.close();
  }

  /**
   *
   */
  public onClose(): void {
    this._activeModal.dismiss();
  }

  /**
   *
   * @returns {User}
   */
  get user(): User {
    return this._user;
  }

  /**
   *
   * @param {User} value
   */
  set user(value: User) {
    this._user = value;
  }
}
