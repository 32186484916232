import {HttpService} from '../../shared/services/http.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {UserService} from '../../login/services/user.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../../shared/services/alert.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ScheduleExport, ScheduleExportResponse} from '../models/schedule-export.response.models';
import {CounterScheduleScopes} from '../enums/schedule-scope.enum';
import {CounterScheduleViewModes} from '../enums/schedule-view-modes.enum';

export interface ScopeDates {
  periodStartDate: Moment;
  periodEndDate: Moment;
}

@Injectable()
export class ScheduleService extends HttpService {

  protected endpoints = {
    schedule: environment.urls.gateway
  };

  private _scheduleResponseKey = 'Schedule_';
  private _scheduleScopeKey = 'Schedule_Scope';
  private _scheduleViewModeKey = 'Schedule_ViewMode';

  public schedule = new BehaviorSubject<ScheduleExport>(null);
  public loading = new BehaviorSubject<boolean>(false);

  private _scope: CounterScheduleScopes = null;
  private _viewMode: CounterScheduleViewModes = null;

  private readonly _defaultScope = CounterScheduleScopes.WEEK;
  private readonly _defaultViewMode = CounterScheduleViewModes.DAY;

  get scope(): CounterScheduleScopes {
    return parseInt(this._scope.toString(), 10);
  }

  get viewMode(): CounterScheduleViewModes {
    return parseInt(this._viewMode.toString(), 10);
  }

  set viewMode(value: CounterScheduleViewModes) {
    this.localStorage.setItem(this._scheduleViewModeKey, value);
    this._viewMode = value;
  }

  constructor(protected _http: HttpClient,
              protected _alertService: AlertService,
              protected _logger: LoggerService,
              private _userService: UserService,
              @Inject('LOCALSTORAGE') private localStorage: any) {
    super(_http, _alertService, _logger);

    this.init();
  }

  static getDatesByScope(scope): ScopeDates {
    const dates = {
      periodStartDate: null,
      periodEndDate: null
    };

    if (scope === CounterScheduleScopes.UPCOMING) {
      dates.periodStartDate = moment().subtract(1, 'hour');
      dates.periodStartDate = moment(dates.periodStartDate).add(10, 'days');
      dates.periodEndDate = moment(dates.periodStartDate).add(2, 'years');
      dates.periodEndDate = dates.periodEndDate.endOf('day').add(2, 'hour');
    } else if (scope === CounterScheduleScopes.WEEK) {
      dates.periodStartDate = moment().subtract(1, 'hour');
      dates.periodEndDate = moment(dates.periodStartDate).add(10, 'days');
      dates.periodEndDate = dates.periodEndDate.endOf('day').add(2, 'hour');
    }

    return dates;
  }

  private init() {
    this._viewMode = this.localStorage.getItem(this._scheduleViewModeKey);
    if (this._viewMode == null) {
      this.viewMode = this._defaultViewMode;
    }
    this._scope = this.localStorage.getItem(this._scheduleScopeKey);
    if (this._scope == null) {
      this._scope = this._defaultScope;
    }

    const cached = this.getCachedScheduleForScope(this._scope);
    // Pass Cached Schedule to all while waiting for new one
    if (cached != null) {
      this.schedule.next(
        cached
      );
    }
  }

  /**
   * Check local Storage for cached Schedule
   * @param {CounterScheduleScopes} scope
   * @returns {ScheduleExport}
   */
  private getCachedScheduleForScope(scope: CounterScheduleScopes): ScheduleExport {
    let stored = this.localStorage.getItem(this._scheduleResponseKey + scope);
    if (stored != null) {
      try {
        stored = JSON.parse(stored);
        if (stored.timestamp != null) {
          if (moment().diff(stored.timestamp, 'minutes') < 15) {
            return new ScheduleExport(
              stored.response,
              moment(stored.periodStartDate),
              moment(stored.periodEndDate),
              moment(stored.timestamp)
            );
          }
        }
      } catch (e) {
      }
    }
    return null;
  }

  askForSchedule(scope: CounterScheduleScopes): void {
    // Use Default Scope if none provided
    if (scope == null) {
      scope = this._defaultScope;
    }

    const cached = this.getCachedScheduleForScope(scope);
    // Pass Cached Schedule to all while waiting for new one
    if (cached != null) {
      this.schedule.next(
        cached
      );
    }

    // Schedule is Loading
    this.loading.next(true);

    // Get Location from User
    const locationId = this._userService.getUser().locationId;

    if (locationId != null) {

      // Get Dates for Scope
      const scopeDates = ScheduleService.getDatesByScope(scope);

      // Build Url
      const url = `${this.endpoints.schedule}performance/export/performances` +
        `?locationId=${locationId}` +
        (scopeDates.periodStartDate == null ? `` : `&periodStartDate=${scopeDates.periodStartDate.format('YYYY-MM-DDTHH:mm:ss')}`) +
        (scopeDates.periodEndDate == null ? `` : `&periodEndDate=${scopeDates.periodEndDate.format('YYYY-MM-DDTHH:mm:ss')}`);

      this._http
        .get(url)
        .subscribe(
          (response: ScheduleExportResponse) => {

            // Store latest Scope
            this.localStorage.setItem(this._scheduleScopeKey, scope);
            this._scope = scope;

            // Build Basic Export Model from Response
            const schedule = new ScheduleExport(
              response,
              scopeDates.periodStartDate,
              scopeDates.periodEndDate
            );

            // Pass new model to all subscribers
            this.schedule.next(
              schedule
            );

            // No longer loading
            this.loading.next(false);

            // Store latest Export Model for selected scope
            this.localStorage.setItem(
              this._scheduleResponseKey + this._scope,
              JSON.stringify({
                response: response,
                periodStartDate: scopeDates.periodStartDate,
                periodEndDate: scopeDates.periodEndDate,
                timestamp: schedule.timestamp
              })
            );
          },
          () => {
          }
        );
    }
  }

  getSchedule$(): Observable<ScheduleExport> {
    return this.schedule.asObservable();
  }

  isLoading$(): Observable<boolean> {
    return this.loading.asObservable();
  }
}
