<div class="modal-header">
    <h4 class="modal-title" [translate]="'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.TITLE'"></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body clearfix">

  <div class="alert alert-danger" *ngIf="salesCounter.nextTicketId > salesCounter.lastTicketId">
    <p>Das letzte Ticket wurde gedruckt. Bitte wechseln oder prüfen sie die eingelegten Tickets und tragen Sie die entsprechenden SPIO Nummern hier ein.</p>
  </div>

  <button class="btn btn-primary" (click)="printSetupTicket()">Einrichtungsticket drucken</button>

  <form
    novalidate
    autocomplete="off"
    [formGroup]="spioForm"
    (ngSubmit)="submitForm()">

    <div class="form-group row" [ngClass]="{
        'has-danger': spioForm.get('nextTicketId').invalid && spioForm.get('nextTicketId').dirty,
        'has-success': spioForm.get('nextTicketId').valid && spioForm.get('nextTicketId').dirty
        }">
      <label for="next-ticket-id"
             class="col-12 col-form-label">
        {{ 'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.NEXT_TICKET_ID' | translate}}
      </label>
      <div class="col-8">
        <input type="text"
               class="form-control"
               id="next-ticket-id"
               formControlName="nextTicketId"
               placeholder="{{ 'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.NEXT_TICKET_ID' | translate}}">
        <div *ngIf="
          spioForm.get('nextTicketId').errors &&
          (spioForm.get('nextTicketId').touched || spioForm.get('nextTicketId').dirty)">
          <span *ngIf="spioForm.get('nextTicketId').errors['required']">
           {{ 'MODUL_COUNTER.CONFIRMATION.VALIDATION_ERROR.REQUIRED' | translate}}
          </span>
          <span *ngIf="spioForm.get('nextTicketId').errors['required']">
           {{ 'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.NEXT_TICKET_ID_REQUIRED' | translate}}
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row" [ngClass]="{
        'has-danger': spioForm.get('lastTicketId').invalid && spioForm.get('lastTicketId').dirty,
        'has-success': spioForm.get('lastTicketId').valid && spioForm.get('lastTicketId').dirty
        }">
      <label for="last-ticket-id"
             class="col-12 col-form-label">
        {{ 'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.LAST_TICKET_ID' | translate}}
      </label>
      <div class="col-8">
        <input type="text"
               class="form-control"
               id="last-ticket-id"
               formControlName="lastTicketId"
               placeholder="{{ 'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.LAST_TICKET_ID' | translate}}">
        <div
          *ngIf="spioForm.get('lastTicketId').errors &&
            (spioForm.get('lastTicketId').touched || spioForm.get('lastTicketId').dirty)">
          <span *ngIf="spioForm.get('lastTicketId').errors['required']">
             {{ 'MODUL_COUNTER.CONFIRMATION.VALIDATION_ERROR.REQUIRED' | translate}}
          </span>
          <span *ngIf="spioForm.get('lastTicketId').errors['required']">
           {{ 'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.LAST_TICKET_ID_REQUIRED' | translate}}
          </span>
          <span *ngIf="spioForm.get('lastTicketId').errors['LastTicketIdIsAfterNextTicketId']">
           {{ 'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.LAST_TICKET_ID_INVALID' | translate}}
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <a class="btn btn-warning"
                (click)="activeModal.close(false)"
                [translate]="'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.CANCEL'"></a>
        <button type="submit"
                class="btn btn-success"
                [disabled]="!spioForm.valid"
                [translate]="'MODUL_COUNTER.CONFIRMATION.SPIO_NUMBER_CHECK.SET'"></button>
      </div>
    </div>
  </form>

</div>
<div class="modal-footer"></div>



