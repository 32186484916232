<td>{{user?.fullName}}</td>
<td>{{user?.emailAddress}}</td>
<td>{{'MODUL_USERS.ROLES.'+user?.role | translate}}</td>
<td>
  <i class="fa fa-times-circle text-danger" aria-label="Bestätigt" *ngIf="!user.emailAddressApproved"></i>
  <i class="fa fa-check-circle text-success" aria-label="Unbestätigt" *ngIf="user.emailAddressApproved"></i>
</td>
<td class="text-center">
  <i class="fa fa-times-circle text-danger" aria-label="Gesperrt" *ngIf="user.accountLocked"></i>
  <i class="fa fa-check-circle text-success" aria-label="Aktiv" *ngIf="!user.accountLocked"></i>
</td>
<td>
  <a class="btn btn-primary"
     tooltip="Kundendaten Bearbeiten"
     [routerLink]="['./', 'detail',user.userId]">
    <i class="fa fa-pencil"></i>
    <span class="text-hide">Kundendaten Bearbeiten</span>
  </a>
  <!--
  <button class="btn btn-danger"
          type="button"
          role="button"
          tooltip="Kunden entsperren"
          (click)="onAccountLocked()">
    <i class="fa fa-lock"></i>
    <span class="text-hide">Kunden entsperren</span>
  </button>
  -->
</td>
