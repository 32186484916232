import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../services/user.service';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _userService: UserService,
    private _authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot): boolean {
    if (this._authenticationService.isAuthenticated()) {
      return true;
    } else {
      this._authenticationService.logout();
      this.router.navigate(['/login']);
      return false;
    }
  }
}
