import {Injectable, OnDestroy} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {Location} from '../models/location.model';
import {LocationHalData} from '../models/location-hal-data';
import {HalData} from '../../shared/models/halData';
import {BehaviorSubject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../../shared/services/alert.service';
import {UserService} from '../../login/services/user.service';
import {LoggerService} from '../../shared/services/logger/logger.service';

@Injectable()
export class HalLocationsService extends HalService implements OnDestroy {

  protected domainName = 'locations';

  protected gatewayName = 'location';

  protected subscriptions: Subscription = new Subscription();

  public locations = new BehaviorSubject<LocationHalData>(null);

  /**
   * Constructor
   *
   * @param {HttpClient} http
   * @param {AlertService} _alertService
   * @param {UserService} _userService
   * @param {LoggerService} _logger
   */
  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
    super(http, _alertService, _userService, _logger);

    this.subscriptions = this.data.subscribe((data) => {
      if (data != null && data.locations != null) {
        this.locations.next(data.locations);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAllLocations(page: number = null) {
    this.getAll(this.domainName, page);
  }

  getLocation(id: string): Promise<Location> {
    console.warn('getLocation', id);
    return super.get(this._data[this.domainName], id);
  }

  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Srtip page params
      if (id === 'locations') {
        this._data[id] = new LocationHalData(id, link);
        this.data.next(this._data);
      } else {
        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   * Save
   * @param {Location} data
   * @returns {Observable<Location>}
   */
  saveLocation(data: Location): Promise<Location> {
    return this.save(this._data.locations, data);
  }

  /**
   *
   * @param {Location} data
   * @returns {Promise<any>}
   */
  updateLocation(data: Location): Promise<any> {
    return this.update(this._data.locations, data.id, data);
  }

  /**
   *
   * @param {Location} data
   * @returns {Promise<any>}
   */
  deleteLocation(data: Location): Promise<any> {
    return this.delete(this._data.locations, data.id);
  }

  /**
   *
   * @param {string} mandatorId
   * @returns {Promise<{[p: string]: Location}>}
   */
  streamAllLocationsByMandatorId(mandatorId: string): Promise<{ [key: string]: Location }> {
    if (this._data.locations) {
      const url = `${this._data.locations.href}/search/streamAllByMandatorId?mandatorId=${mandatorId}`;

      return this.search(
        url
      ).then((response: any) => {
        if (response != null) {
          this._data.locations.parseToArrayObjects(response);
          return this._data.locations.data;
        }
        return response;
      });
    }
  }
}
