<div class="container-fluid counter-confirmation-component">
  <div class="content">
    <div class="card">
      <div class="card-header">
        <h1 [translate]="'MODUL_COUNTER.DASHBOARD.TITLE'" [translateParams]="{name: salesCounter.name}" *ngIf="hasPrinter && salesCounter"></h1>
        <h1 [translate]="'MODUL_COUNTER.DASHBOARD.TITLE_NO_PRINTER'" *ngIf="!hasPrinter"></h1>
        <span class="float-right" *ngIf="salesCounter">
          <span [translate]="'MODUL_COUNTER.CONFIRMATION.NEXT_SPIO'"></span>
          <span class="badge badge-pill pb-1" [ngClass]="spioBadgeStatus"><h6 class="mb-0 px-1">{{salesCounter.nextTicketId}}</h6></span>
          <span class="ml-3 link" (click)="showSpioNumberCheck()">
            <i class="fa fa-edit" aria-hidden="true"></i>
            <span [translate]="'MODUL_COUNTER.CONFIRMATION.SPIO_WRONG'"></span>
          </span>
        </span>
      </div>
      <div class="card-body">
        <div class="row" *ngIf="transaction">
          <div class="col-12" *ngIf="oldPickupCode">
            <div class="alert alert-warning">
              <h1>Achtung!</h1>
              <p>Diese Buchungsbestätigung ist ungültig.</p>
              <p>Bitte die Identität prüfen.</p>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <h2 class="pb-3 pt-1 bb-1">{{'MODUL_COUNTER.CONFIRMATION.TITLE' | translate}} <span class="badge badge-pill badge-danger nobr">{{oldPickupCode}}</span></h2>
                </div>
                <div class="col-12">
                  <h4>Vorstellung</h4>
                  <p>
                    <strong>Film: </strong><span>{{transaction.performance.filmTitle}}</span>
                    <br/><strong>Ort / Start: </strong><span>{{transaction.performance.locationName}}</span> im <strong>{{transaction.performance.auditoriumName}}</strong> am <strong>{{transaction.performance.startDate.format('dddd, [den] DD.MM.YYYY')}}</strong> um <strong>{{transaction.performance.startDate.format('HH:mm [Uhr]')}}</strong>
                    <br/><strong>Buchungsdatum: </strong><span>Gebucht am <strong>{{transaction.date.format('dddd, [den] DD.MM.YYYY')}}</strong> um <strong>{{transaction.date.format('HH:mm [Uhr]')}}</strong></span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12" *ngIf="transaction.customer">
                  <h4>Kundendaten</h4>
                  <p>
                    <strong>E-Mail-Adresse: </strong><span>{{transaction.customer.emailAddress}}</span>
                    <br/><strong>Vorname: </strong><span>{{transaction.customer.firstName}}</span>
                    <br/><strong>Nachname: </strong><span>{{transaction.customer.lastName}}</span>
                    <br/><strong>Strasse: </strong><span>{{transaction.customer.streetName}} {{transaction.customer.houseNumber}}</span>
                    <br/><strong>PLZ / Ort: </strong><span>{{transaction.customer.postCode}} {{transaction.customer.cityName}}</span>
                    <ng-container *ngIf="transaction.customer.phoneNumber">
                      <br/><strong>Telefon: </strong><span>{{transaction.customer.phoneNumber}}</span>
                    </ng-container>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12" *ngIf="uniquePayments">
                  <h4>Zahlungsmittel</h4>
                  <div>
                    <div *ngFor="let payment of uniquePayments">
                      <p>
                        <strong [translate]="'MODUL_TRANSACTIONS.ENUMS.PAYMENT_TYPES.'+payment.providerType"></strong><ng-container *ngIf="payment.providerTypeIdMasked"><strong>: </strong><span>{{payment.providerTypeIdMasked}}</span></ng-container>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    href="#"
                    class="btn btn-primary btn-lg mb-3 mt-2"
                    type="button"
                    aria-pressed="true"
                    (click)="gotoNew()">
                    <span [translate]="'MODUL_COUNTER.CONFIRMATION.IDENTITY_OK'"></span>
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!oldPickupCode">
            <ng-container *ngIf="hasPrinter">
              <div class="col-xl-12" *ngIf="printError">
                <div class="alert alert-danger">
                  <button type="button" class="close" aria-label="Close" (click)="closePrintError()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4>Achtung</h4>
                  <p>{{printError.reason}}</p>
                </div>
              </div>
              <div class="col-xl-12" *ngIf="numberOfTicketsToBePrinted > 0 && !enoughPaperForNextPrint && ticketsAvailableInPrinter > 0">
                <div class="alert alert-warning">
                  <h4>Achtung</h4>
                  <p>Die Tickets im Drucker reichen für diesen Druckauftrag nicht aus. Es können maximal {{ticketsAvailableInPrinter}} von {{numberOfTicketsToBePrinted}} Tickets gedruckt werden.</p>
                </div>
              </div>
              <div class="col-xl-12" *ngIf="ticketsAvailableInPrinter <= 0">
                <div class="alert alert-warning">
                  <h4>Achtung</h4>
                  <p>Es sind keine Tickets mehr im Drucker. Bitte legen Sie neue Tickets ein und prüfen Sie die SPIO Nummern.</p>
                </div>
              </div>
            </ng-container>
            <div class="col-xl-8">
              <div class="row">
                <div class="col-12">
                  <h2 class="pb-3 pt-1 bb-1">{{'MODUL_COUNTER.CONFIRMATION.TITLE' | translate}} <span class="badge badge-pill badge-primary nobr">{{transaction.pickupCode}}</span></h2>
                </div>
                <div class="col-xl-12">
                  <h3>{{transaction.performance.filmTitle}}</h3>
                  <p>
                    <span>{{transaction.performance.locationName}}</span> im <strong>{{transaction.performance.auditoriumName}}</strong> am <strong>{{transaction.performance.startDate.format('dddd, [den] DD.MM.YYYY')}}</strong> um <strong>{{transaction.performance.startDate.format('HH:mm [Uhr]')}}</strong>
                    <br>
                    <span *ngIf="transaction.customer">Gebucht von: <strong>{{transaction.customer.lastName}}, {{transaction.customer.firstName}}</strong> am <strong>{{transaction.date.format('dddd, [den] DD.MM.YYYY')}}</strong> um <strong>{{transaction.date.format('HH:mm [Uhr]')}}</strong></span>
                  </p>
                </div>
                <div class="col-12">
                  <form>
                    <table class="table table-striped table-bordered table-counter">
                      <thead>
                      <tr>
                        <th [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.TICKET'"></th>
                        <th *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'" [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.SEAT'"></th>
                        <th [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.SPIO'"></th>
                        <th [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.AMOUNT'" class="text-right"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let ticket of transaction.tickets;" [ngClass]="{'ticket-refunded': isRefunded(ticket)}">
                        <td>{{ticket.priceCategoryName}}<b *ngIf="ticket.priceMultiId" class="nobr"> [Part {{ticket.priceMultiId}}]</b></td>
                        <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'">Reihe {{ticket.spaceRow}}, Platz {{ticket.spaceRowPosition}}</td>
                        <ng-container [ngSwitch]="ticket.status">
                          <td *ngSwitchCase="ticketStatus.LOCAL_PRINTED.toString()">Gedruckt ({{ticket.salesCounterTicketId}})</td>
                          <td *ngSwitchCase="ticketStatus.LOCAL_PRINT_INVALID.toString()">Druck ungültig</td>
                          <td *ngSwitchCase="ticketStatus.LOCAL_VALIDATED.toString()">Einlass bestätigt</td>
                          <td *ngSwitchCase="ticketStatus.LOCAL_INVALIDATED.toString()">Einlass ungültig</td>
                          <td *ngSwitchCase="ticketStatus.REFUND_COMPLETED.toString()">Storniert</td>
                          <td *ngSwitchDefault="">Ausstehend</td>
                        </ng-container>
                        <td class="text-right">
                          <span *ngIf="transaction.skipSaleFee">{{ticket.fullAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</span>
                          <span *ngIf="!transaction.skipSaleFee">{{ticket.fullAmountWithFee | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</span>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'" colspan="3" [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.TOTAL'"></td>
                        <td *ngIf="transaction.performance.occupationType == 'UNRESERVED'" colspan="2" [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.TOTAL'"></td>
                        <td class="text-right" colspan="1">{{transaction.fullAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
                      </tr>
                      <tr *ngIf="totalRefundFee && totalRefundFee > 0">
                        <td colspan="3">Inklusive Stornogebühr in Höhe von:</td>
                        <td class="text-right" colspan="1">{{totalRefundFee | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
                      </tr>
                      <tr *ngIf="totalVoucherValue && totalVoucherValue < 0">
                        <td colspan="3">Gutschein(e) eingelöst:</td>
                        <td class="text-right" colspan="1">{{totalVoucherValue | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
                      </tr>
                      </tfoot>
                    </table>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    href="#"
                    class="btn btn-primary btn-lg mb-3 mt-2 float-right"
                    type="button"
                    aria-pressed="true"
                    (click)="showTicketResetModal()"
                    [disabled]="!hasPrinter || !hasPrintedTickets">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    <span [translate]="'MODUL_COUNTER.CONFIRMATION.RESET_FAILED_TICKET_PRINTS'"></span>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col" *ngIf="uniquePayments">
                  <h4>Zahlungsmittel</h4>
                  <div class="payment-methods">
                    <div class="payment-method" *ngFor="let payment of uniquePayments">
                      <div class="icon">
                        <i class="fa fa-{{payment.icon}}" aria-hidden="true"></i>
                      </div>
                      <div class="name text-secondary font-weight-medium" [translate]="'MODUL_TRANSACTIONS.ENUMS.PAYMENT_TYPES.'+payment.providerType"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4">
              <h2 class="pb-3 pt-1 bb-1" [translate]="'MODUL_COUNTER.CONFIRMATION.ACTIONS'"></h2>
              <div class="row">
                <div class="col-12">
                  <button href="#" class="btn btn-success btn-lg btn-giant btn-block mb-3" type="button" aria-pressed="true" (click)="printTickets()" [disabled]="!hasPrinter || _isPrinting || !hasPrintableTickets || numberOfPrintableTickets < 1">
                    <i class="fa fa-print" aria-hidden="true"></i> <span>{{ numberOfPrintableTickets+'' | pluralate: 'MODUL_COUNTER.CONFIRMATION.PRINT_PLURAL'}}</span>
                  </button>
                </div>
                <div *ngIf="!onlyShowPrintButtons" class="col-lg-6 col-xl-12">
                  <button href="#" class="btn btn-primary btn-lg btn-giant btn-block mb-3" type="button" aria-pressed="true" [disabled]="_isPrinting" (click)="gotoDetails(transaction.externalId)" [innerHTML]="'MODUL_COUNTER.CONFIRMATION.DETAILS' | translate"></button>
                </div>
                <div *ngIf="!onlyShowPrintButtons" class="col-lg-6 col-xl-12">
                  <button href="#" class="btn btn-primary btn-lg btn-giant btn-block mb-3" type="button" aria-pressed="true" [disabled]="_isPrinting" (click)="gotoSchedule()" [translate]="'MODUL_COUNTER.CONFIRMATION.SCHEDULE'"></button>
                </div>
                <div *ngIf="!onlyShowPrintButtons" class="col-lg-6 col-xl-12">
                  <button href="#" class="btn btn-primary btn-lg btn-giant btn-block mb-3" type="button" aria-pressed="true" [disabled]="_isPrinting" (click)="gotoDashboard()" [innerHTML]="'MODUL_COUNTER.CONFIRMATION.DASHBOARD' |  translate"></button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="!transaction && !isLoading">
          <p>Keine Transaktion vorhanden</p>
        </div>
      </div>
    </div>
  </div>
</div>
