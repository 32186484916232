import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'c360-transaction-chargeback-modal',
  templateUrl: './transaction-chargeback-modal.component.html'
})
export class TransactionChargebackModalComponent implements OnInit {

  transactionResponseStatusOK: any;
  transactionResponseStatusFail: any;

  /**
   *
   * @param {NgbActiveModal} _activeModal
   * @param {TranslateService} _translationService
   */
  constructor(private _activeModal: NgbActiveModal,
              private _translationService: TranslateService) {
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }
}
