import {Hal} from '../../shared/models/hal';
import {Media} from './media.model';
import {isArray, isUndefined} from 'util';
import {FilmPresentationTypes} from '../enums/film-presentation-types.enum';
import {environment} from '../../../environments/environment';

export class Film extends Hal {

  public id: string;

  public eventId: string;

  public typeChildId: string; // "12345678"

  public title: string; // "Test"

  public description: string; // "shdfgksdghlsjflskhdfkshdf"

  public directors: { key: string, value: string }[]; // []

  public actors: { key: string, value: string }[]; // []

  public cameraOperators: { key: string, value: string }[]; // []

  public cutters: { key: string, value: string }[]; // []

  public books: { key: string, value: string }[]; // []

  public runtime: number; // 0

  public ageRating: string; // "RELEASE_FROM_0_YEARS"

  public releaseDate: any; // null

  public countryOfOrigin: string; // ""

  public creationYear: number; // 0

  public spokenLanguage: string; // ""

  public subtitleLanguage: string; // ""

  public websiteUrl: string; // ""

  public genres: { key: string, value: string }[]; // []

  public publisher: string; // ""

  public _links: any;

  public trailer: string;

  public presentationType: string;

  public languageVersion: string;

  public media: Array<Media>;

  constructor(
    _links?: any,
    id?: string,
    eventId?: string,
    typeChildId?: string,
    title?: string,
    description?: string,
    directors?: { key: string, value: string }[],
    actors?: { key: string, value: string }[],
    cameraOperators?: { key: string, value: string }[],
    cutters?: { key: string, value: string }[],
    books?: { key: string, value: string }[],
    runtime?: number,
    ageRating?: string,
    releaseDate?: any,
    countryOfOrigin?: string,
    creationYear?: number,
    spokenLanguage?: string,
    subtitleLanguage?: string,
    websiteUrl?: string,
    genres?: { key: string, value: string }[],
    publisher?: string,
    trailer?: string,
    presentationType?: string,
    languageVersion?: string,
    media?: Array<{ url: string, type: string, description?: string }>
  ) {
    super(_links);

    this.id = id;
    this.eventId = eventId;
    this.typeChildId = typeChildId;
    this.title = title;
    this.description = description;
    this.directors = directors;
    this.actors = actors;
    this.cameraOperators = cameraOperators;
    this.cutters = cutters;
    this.books = books;
    this.runtime = runtime;
    this.ageRating = ageRating;
    this.releaseDate = releaseDate;
    this.countryOfOrigin = countryOfOrigin;
    this.creationYear = creationYear;
    this.spokenLanguage = spokenLanguage;
    this.subtitleLanguage = subtitleLanguage;
    this.websiteUrl = websiteUrl;
    this.genres = genres;
    this.publisher = publisher;
    this.trailer = trailer;
    this.presentationType = presentationType;
    this.languageVersion = languageVersion;

    if (media === null || isUndefined(media)) {
      this.media = [];
    } else if (media && isArray(media)) {
      this.media = media.map((data) => new Media(data.url, data.type, data.description));
    }

  }


  get firstPosterUrl(): string {
    if (this.media && this.media.length > 0) {
      const posters = this.media.filter((media) => {
        return media.type === 'POSTER';
      });
      if (posters.length > 0) {
        return posters[0].url;
      }
    }
    return null;
  }

  get firstYoutubeTrailerUrl(): string {
    if (this.media && this.media.length > 0) {
      const youtubeTrailers = this.media.filter((media) => {
        return media.type === 'TRAILER_YOUTUBE';
      });
      if (youtubeTrailers.length > 0) {
        return environment.youtube.previewImageUrl(youtubeTrailers[0].url);
      }
    }
    return null;
  }

  get tabTitlePresentationType(): string {
    const presentationType = FilmPresentationTypes[this.presentationType] ? FilmPresentationTypes[this.presentationType] : this.presentationType;
    return `${presentationType}`;
  }
}
