export enum PaymentTypes {
  CASH = 'CASH',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  EC_TERMINAL = 'EC_TERMINAL',
  VOUCHER = 'VOUCHER',
  INVOICE_FILM_DISTRIBUTOR = 'INVOICE_FILM_DISTRIBUTOR',
  PAYPAL = 'PAYPAL',
  PAYDIREKT = 'PAYDIREKT',
  SOFORT = 'SOFORT',
  KLARNA = 'KLARNA',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_CARD_VISA = 'CREDIT_CARD_VISA',
  CREDIT_CARD_MASTER_CARD = 'CREDIT_CARD_MASTER_CARD',
  CREDIT_CARD_AMERICAN_EXPRESS = 'CREDIT_CARD_AMERICAN_EXPRESS',
  CREDIT_CARD_DINERS_DISCOVER = 'CREDIT_CARD_DINERS_DISCOVER',
  CREDIT_CARD_JCB = 'CREDIT_CARD_JCB',
  CREDIT_CARD_MAESTRO_INTERNATIONAL = 'CREDIT_CARD_MAESTRO_INTERNATIONAL',
  CREDIT_CARD_CHINA_UNION_PAY = 'CREDIT_CARD_CHINA_UNION_PAY',
  CREDIT_CARD_UATP_AIRPLUS = 'CREDIT_CARD_UATP_AIRPLUS',
  I_DEAL = 'I_DEAL',
  FREE = 'FREE',
}

