export class Media {
  public type: string;
  public description: string;
  public url: string;

  constructor(url: string, type: string, description?: string) {
    this.url = url;
    this.type = type;
    this.description = description;
  }
}
