export enum ReportsTypes {
  SALES_COUNTER_REPORT = 'SALES_COUNTER_REPORT',
  DISTRIBUTOR_REPORT = 'DISTRIBUTOR_REPORT',
  PERFORMANCE_REPORT = 'PERFORMANCE_REPORT',
  PERFORMANCE_REPORT_INCLUDE_SELLING_POINT = 'PERFORMANCE_REPORT_INCLUDE_SELLING_POINT',
  AUDITORIUM_REPORT = 'AUDITORIUM_REPORT',
  POST_CODE_REPORT = 'POST_CODE_REPORT',
  CITY_NAME_REPORT = 'CITY_NAME_REPORT',
  TOTAL_REPORT = 'TOTAL_REPORT'
}
