export const locale_base_de = {
  'lang': 'de',
  'data': {
    'MODUL_COUNTER': {
      'NAME': 'Counter',
      'DASHBOARD': {
        'TITLE': 'Kasse - {{name}}',
        'TITLE_NO_PRINTER': 'Kasse',
        'SEARCH_BY_CODE': 'Suche nach Buchungscode',
        'SEARCH_BY_CODE_HINT':
          'Klicken Sie in das Suchfeld und lesen Sie den Buchungscode per Barcodescanner ein. ' +
          'Ersatzweise geben Sie den ganzen Buchungscode manuell in das Suchfeld ein.',
        'SEARCH_PICKUPCODE_NOT_FOUND': 'Buchungscode nicht gefunden!',
        'SEARCH_BY_NAME': 'Suche nach Buchungsdetails',
        'SEARCH_BY_NAME_HINT': 'Suchen Sie nach Nachnamen, Vornamen, E-Mail oder Filmtitel.',
        'SEARCH': 'Suchen',
        'BOOK_NOW': 'Ticket buchen',
        'SEARCH_VOUCHER': 'Suche nach Gutscheincode',
        'SEARCH_VOUCHER_DESCRIPTIONS': [
          'Klicken Sie in das Suchfeld und geben Sie einen Gutscheincode ein. Sie können folgende Informationen abrufen:',
          'Art des Gutscheins (Wertgutschein, Ticketgutschein, etc.)',
          'Status des Gutscheins (erstellt/verfügbar/aktiv/eingelöst/inaktiv)',
          'Wert des Gutscheins',
          'Nach Eingabe des Gutscheincodes haben Sie die Möglichkeit den Gutschein zu verkaufen (Setzt den Status auf \'aktiv\'), zu stornieren (Setzt den Status auf \'inaktiv\') oder den Gutschein zurückzusetzen (Setzt den Status auf \'verfügbar\').'
        ],
        'ADDITIONAL_ACTIONS': 'Weitere Aktionen'
      },
      'SCHEDULE': {
        'TITLE': 'Spielplan',
        'SCOPES': {
          'ALL': 'Alle',
          'TODAY': 'Heute',
          'WEEK': 'Aktuelle Woche',
          'UPCOMING': 'Demnächst'
        },
        'MODES': {
          'DAY': 'Tagesansicht',
          'FILM': 'Filmansicht'
        },
        'REFRESH': 'Spielplan aktualisieren'
      },
      'PRINTER_WARNING': {
        'TITLE': 'Ticketdrucker',
        'CONTENT': 'Wir konnten keine Verbindung zum Ticketdrucker herstellen'
      },
      'CONFIRMATION': {
        'NEXT_SPIO': 'Nachfolgender SPIO-Code: ',
        'SPIO_WRONG': 'nicht zutreffend?',
        'TITLE': 'Buchungsbestätigung',
        'TABLE': {
          'TICKET': 'Preisart',
          'SEAT': 'Sitzplatz',
          'SPIO': 'Spio Nr.',
          'AMOUNT': 'Betrag',
          'TOTAL': 'Gesamtbetrag'
        },
        'RESET_FAILED_TICKET_PRINTS': 'Ausdruck fehlerhaft? Druck zurücksetzen',
        'IDENTITY_OK': 'Identität geprüft',
        'ACTIONS': 'Aktionen',
        'PRINT_PLURAL_ZERO': 'Keine druckbaren Tickets',
        'PRINT_PLURAL_ONE': '{{count}} Ticket jetzt drucken',
        'PRINT_PLURAL_OTHER': '{{count}} Tickets jetzt drucken',
        'PRINT_PLURAL': {
          '=0': 'MODUL_COUNTER.CONFIRMATION.PRINT_PLURAL_ZERO',
          '=1': 'MODUL_COUNTER.CONFIRMATION.PRINT_PLURAL_ONE',
          'other': 'MODUL_COUNTER.CONFIRMATION.PRINT_PLURAL_OTHER'
        },
        'DETAILS': 'Buchung bearbeiten <br><small>(Storno, Tickets ändern, ...)</small>',
        'DASHBOARD': 'Zum Dashboard<br><small>(Buchungscode eingeben)</small>',
        'SCHEDULE': 'Veranstaltungen',
        'RESET': {
          'TITLE': 'Fehlerhafte Ticketdrucke zurücksetzen',
          'INTRO': 'Markieren Sie die fehlerhaft gedruckten Tickets um diese zurückzusetzen.',
          'CANCEL': 'Schließen',
          'RESET': 'Markierte Tickets jetzt zurücksetzen'
        },
        'SPIO_NUMBER_CHECK': {
          'TITLE': 'Pflege der SPIO Nummer',
          'NEXT_TICKET_ID': 'zuletzt gedruckte SPIO Nummer',
          'NEXT_TICKET_ID_REQUIRED': 'Die SPIO Nummer wird benötigt',
          'LAST_TICKET_ID': 'SPIO Nummer des letzten Tickets',
          'LAST_TICKET_ID_REQUIRED': 'Die letzte SPIO Nummer wird benötigt',
          'LAST_TICKET_ID_INVALID': 'Die SPIO Nummer muss größer als die zuletzt gedruckte sein',
          'CANCEL': 'Abbrechen',
          'SET': 'Speichern'
        }
      },
      'COUNTER_CONFIRMATION_CART': {
        'CONFIRM_REFUND_MODAL': {
          'HEADER': 'Stornieren',
          'CONETENT': 'Möchten Sie wirklich stornieren?'
        },
        'CONFIRM_PAY_MODAL': {
          'HEADER': 'Bezahlen',
          'CONETENT': 'Möchten Sie die Transaktion abschließen?'
        },
        'COUNTER_CONFIRMATION_CART': {
          'TABLE': {
            'HEADER': {
              'TITLE': 'Bezeichnung',
              'CODE': 'Code',
              'PAYMENT': 'Zahlung'
            },
            'TOTAL': 'Gesamtbetrag'
          }
        },
        'BUTTONS': {
          'CANCEL': 'Schließen',
          'REFUND': 'Stornieren',
          'REFUND_ALL': 'Alle Stornieren',
          'PAY': 'Bezahlen'
        }
      },
      'TRANSACTION': {
        'DETAIL': {
          'TICKETS': {
            'TITLE': 'Ticketdaten',
            'PRINT': 'Buchungsbestätigung'
          }
        }
      },
      'VOUCHER': {
        'STATUS': {
          'CREATED': 'erstellt',
          'AVAILABLE': 'verfügbar',
          'EMITTED': 'aktiv',
          'REDEEMED': 'eingelöst',
          'REVOKED': 'inaktiv',
        },
        'LIST': {
          'LIST_HEADER': {
            'NAME': 'Name',
            'STATUS': 'Status',
            'DESCRIPTION': 'Beschreibung',
            'VALUE_INITIAL': 'Wert',
            'VALUE_CURRENT': 'Aktueller Wert'
          },
          'VALUE': {
            'TICKET_PERCENT': '1 Ticket'
          }
        },
        'BUTTONS': {
          'SET-STATUS-EMITTED': 'Verkaufen',
          'SET-STATUS-REVOKED': 'Stornieren',
          'SET-STATUS-AVAILABLE': 'Zurücksetzen',
        },
        'TOGGLE-STATUS-MODAL': {
          'HEADER_EMITTED': 'Gutschein verkaufen?',
          'CONTENT_EMITTED': 'Der Gutschein wird verkauft und ist sofort an der Kasse oder in der Onlinebuchung einlösbar.',
          'HEADER_REVOKED': 'Gutschein stornieren?',
          'CONTENT_REVOKED': 'Der Gutschein wird storniert und ist ab sofort nicht mehr an der Kasse oder in der Onlinebuchung einlösbar.',
          'HEADER_AVAILABLE': 'Gutschein zurücksetzen?',
          'CONTENT_AVAILABLE': 'Der Gutschein wird zurückgesetzt und ist weiterhin nicht an der Kasse oder in der Onlinebuchung einlösbar.'
        }
      }
    }
  }
};
