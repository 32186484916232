import {Component, Input} from '@angular/core';

@Component({
  selector: 'c360-loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent {
  @Input() loadingMessage = 'Loading...';

  constructor() {
  }
}
