export const locale_base_de = {
  'lang': 'de',
  'data': {
    'MODUL_TRANSACTIONS': {
      'NAME': 'Transaktionen',
      'SEPA': {
        'NAME': 'SEPA',
        'SEPA_MODAL': {
          'NAME': 'SEPA Export',
          'TEXT': 'Ihre SEPA Datei über folgenden Link herunterladen',
        },
        'PLEASE_SELECT_LOCATION': 'Bitte wählen Sie oben in der Navigation einen Standort aus um die SEPA-Dateien generieren zu können.',
        'BUTTON_EXPORT': 'Sepa XML generieren',
      },
      'COMPONENT_LIST': {
        'NAME': 'Liste',
        'ADD': 'Transaction hinzufügen',
        'SEARCH': 'Suchen',
        'RESET': 'Filter zurücksetzen',
        'FILTER': 'Filtern',
        'FILTER_OPTIONAL': 'Optionale Filter',
        'TABS': {
          'OVERVIEW': 'Übersicht'
        }
      },
      'COMPONENT_DETAIL': {
        'NAME': 'Detail'
      },
      'REFUND_MODAL': {
        'NAME': 'Stornierung',
        'TEXT': 'Alle aufgeführten Tickets stornieren?',
        'PLEASE_SELECT_TICKTES': 'Bitte wählen Sie Ticktes zum Stornieren aus.',
        'REFUNDED_PARTIAL': 'Teilstorno war erfolgreich!',
        'REFUNDED': 'Buchung storniert!',
        'REFUND_FAILED': 'Storno fehlerhaft! Bitte versuchen Sie es erneut!'
      },
      'CHARGEBACK_MODAL': {
        'NAME': 'Stornierung',
        'TEXT': 'Alle aufgeführten Tickets stornieren?',
        'PLEASE_SELECT_TICKTES': 'Bitte wählen Sie Ticktes zum Stornieren aus.',
        'REFUNDED_PARTIAL': 'Teilstorno war erfolgreich!',
        'REFUNDED': 'Buchung storniert!',
        'REFUND_FAILED': 'Storno fehlerhaft! Bitte versuchen Sie es erneut!',
        'CHARGE_BACK_MAIL_COMPLETED': 'Rücklastschrift erfolgreich',
        'CHARGE_BACK_MAIL_FAILED': 'Rücklastschrift nicht gesendet'
      },
      'UPDATE_MODAL': {
        'NAME': 'Preis ändern',
        'TEXT': 'Alle aufgeführten Tickets stornieren?'
      },
      'TICKET_TYPE_UPDATE_MODAL': {
        'NAME': 'Preise ändern.',
        'TEXT': 'Aufgeführten Ticketpreise ändern?',
        'TICKET_PRICE_CHANGE_COMPLETED': 'TICKET_PRICE_CHANGE_COMPLETED',
        'TICKET_PRICE_CHANGE_FAILED': 'TICKET_PRICE_CHANGE_FAILED'
      },
      'ENUMS': {
        'USER_GENDER': {
          'FEMALE': 'Frau',
          'MALE': 'Herr',
          'undefined': '-',
          'null': '-'
        },
        'TRANSACTION_STATUS': {
          'ALL': 'Alle',
          'COMPLETE': 'Erfolgreich',
          'CANCELED': 'Abgebrochen',
          'FAILED': 'Fehlerhaft',
          'REFUNDED': 'Storniert',
          'REFUNDED_PARTIAL': 'Teil Storniert',
          'REFUND_FAILED': 'Storno Fehler',
          'INCOMPLETE': 'Nicht fertiggestellt',
          'WAITING_PAYMENT': 'Zahlung ausstehend',
          'WAITING_TICKETSYSTEM': 'Ticketsystem ausstehend',
          'WAITING_REFUND': 'Stornierung ausstehend',
          'WAITING_EXPORT': 'Export ausstehend'
        },
        'TICKET_STATUS': {
          'ALL': 'Alle',
          'BOOKING_INIT': 'Buchung wird durchgeführt',
          'BOOKING_FAILED': 'Buchung fehlgeschlagen',
          'BOOKING_COMPLETED': 'Buchung abgeschlossen',
          'LOCAL_PRINTED': 'Ticket gedruckt',
          'LOCAL_PRINT_INVALID': 'Als Fehldruck markiert',
          'LOCAL_VALIDATED': 'Einlass bestätigt',
          'LOCAL_INVALIDATED': 'Einlass ungültig',
          'REFUND_FAILED': 'Storno fehlgeschlagen’',
          'REFUND_COMPLETED': 'Storno erfolgreich',
          'REFUNDED_PARTIAL': 'Teilstorno erfolgreich',
          'PRICE_CHANGE_COMPLETED': 'Preis geändert',
          'PRICE_CHANGE_FAILED': 'Preisänderung fehlgeschlagen!'
        },
        'HISTORY_STATUS': {
          'ALL': 'Alle',
          'CREATED': 'CREATED',
          'PAYMENT_INIT': 'PAYMENT_INIT',
          'PAYMENT_FAILED': 'PAYMENT_FAILED',
          'PAYMENT_REDIRECTED': 'PAYMENT_REDIRECTED',
          'PAYMENT_COMPLETED': 'PAYMENT_COMPLETED',
          'PAYMENT_REFUND_INIT': 'PAYMENT_REFUND_INIT',
          'PAYMENT_REFUND_FAILED': 'PAYMENT_REFUND_FAILED',
          'PAYMENT_REFUND_WAITING': 'PAYMENT_REFUND_WAITING',
          'PAYMENT_REFUND_COMPLETED': 'PAYMENT_REFUND_COMPLETED',
          'PAYMENT_EXPORTED': 'PAYMENT_EXPORTED',
          'PAYMENT_CANCELED': 'PAYMENT_CANCELED',
          'TICKET_BOOKING_INIT': 'TICKET_BOOKING_INIT',
          'TICKET_BOOKING_FAILED': 'TICKET_BOOKING_FAILED',
          'TICKET_BOOKING_COMPLETED': 'TICKET_BOOKING_COMPLETED',
          'TICKET_LOCAL_PRINTED': 'TICKET_LOCAL_PRINTED',
          'TICKET_LOCAL_PRINT_FAILED': 'TICKET_LOCAL_PRINT_FAILED',
          'TICKET_LOCAL_VALIDATED': 'TICKET_LOCAL_VALIDATED',
          'TICKET_LOCAL_VALIDATE_FAILED': 'TICKET_LOCAL_VALIDATE_FAILED',
          'TICKET_LOCAL_INVALIDATED': 'TICKET_LOCAL_INVALIDATED',
          'TICKET_LOCAL_INVALIDATE_FAILED': 'TICKET_LOCAL_INVALIDATE_FAILED',
          'TICKET_PARTIAL_REFUND_INIT': 'TICKET_PARTIAL_REFUND_INIT',
          'TICKET_PARTIAL_REFUND_FAILED': 'TICKET_PARTIAL_REFUND_FAILED',
          'TICKET_PARTIAL_REFUND_COMPLETED': 'TICKET_PARTIAL_REFUND_COMPLETED',
          'TICKET_REFUND_INIT': 'TICKET_REFUND_INIT',
          'TICKET_REFUND_FAILED': 'TICKET_REFUND_FAILED',
          'TICKET_REFUND_COMPLETED': 'TICKET_REFUND_COMPLETED',
          'TICKET_PRICE_CHANGE_INIT': 'TICKET_PRICE_CHANGE_INIT',
          'TICKET_PRICE_CHANGE_COMPLETED': 'TICKET_PRICE_CHANGE_COMPLETED',
          'TICKET_PRICE_CHANGE_FAILED': 'TICKET_PRICE_CHANGE_FAILED',
          'PRODUCT_BOOKING_INIT': 'PRODUCT_BOOKING_INIT',
          'PRODUCT_BOOKING_FAILED': 'PRODUCT_BOOKING_FAILED',
          'PRODUCT_BOOKING_COMPLETED': 'PRODUCT_BOOKING_COMPLETED',
          'PRODUCT_PARTIAL_REFUND_INIT': 'PRODUCT_PARTIAL_REFUND_INIT',
          'PRODUCT_PARTIAL_REFUND_FAILED': 'PRODUCT_PARTIAL_REFUND_FAILED',
          'PRODUCT_PARTIAL_REFUND_COMPLETED': 'PRODUCT_PARTIAL_REFUND_COMPLETED',
          'PRODUCT_REFUND_INIT': 'PRODUCT_REFUND_INIT',
          'PRODUCT_REFUND_FAILED': 'PRODUCT_REFUND_FAILED',
          'PRODUCT_REFUND_COMPLETED': 'PRODUCT_REFUND_COMPLETED',
          'CONFIRMATION_MAIL_INIT': 'CONFIRMATION_MAIL_INIT',
          'CONFIRMATION_MAIL_FAILED': 'CONFIRMATION_MAIL_FAILED',
          'CONFIRMATION_MAIL_COMPLETED': 'CONFIRMATION_MAIL_COMPLETED',
          'CONFIRMATION_MAIL_RECEIVED': 'CONFIRMATION_MAIL_RECEIVED',
          'CONFIRMATION_PDF_INIT': 'CONFIRMATION_PDF_INIT',
          'CONFIRMATION_PDF_FAILED': 'CONFIRMATION_PDF_FAILED',
          'CONFIRMATION_PDF_COMPLETED': 'CONFIRMATION_PDF_COMPLETED',
          'CONFIRMATION_PAGE_DISPLAYED': 'CONFIRMATION_PAGE_DISPLAYED',
          'CHARGE_BACK_MAIL_INIT': 'CHARGE_BACK_MAIL_INIT',
          'CHARGE_BACK_MAIL_FAILED': 'CHARGE_BACK_MAIL_FAILED',
          'CHARGE_BACK_MAIL_COMPLETED': 'CHARGE_BACK_MAIL_COMPLETED',
          'PAYMENT_CHANGE_INIT': 'PAYMENT_CHANGE_INIT',
          'PAYMENT_CHANGE_COMPLETED': 'PAYMENT_CHANGE_COMPLETED',
          'PAYMENT_CHANGE_FAILED': 'PAYMENT_CHANGE_FAILED',
          'VOUCHERS_CHARGE_INIT': 'VOUCHERS_CHARGE_INIT',
          'VOUCHERS_CHARGE_COMPLETED': 'VOUCHERS_CHARGE_COMPLETED',
          'VOUCHERS_CHARGE_FAILED': 'VOUCHERS_CHARGE_FAILED',
          'RECREATE_DATA_PROTECTION_IDS': 'RECREATE_DATA_PROTECTION_IDS',
          'EXPORT_INIT': 'EXPORT_INIT',
          'EXPORT_FAILED': 'EXPORT_FAILED',
          'EXPORT_COMPLETED': 'EXPORT_COMPLETED',
        },
        'PAYMENT_TYPES': {
          'ALL': 'Alle',
          'DIRECT_DEBIT': 'Lastschrift',
          'VOUCHER': 'Gutschein',
          'EC_TERMINAL': 'EC Terminal',
          'CASH': 'Barzahlung',
          'INVOICE_FILM_DISTRIBUTOR': 'Verleihgutschein',
          'PAYPAL': 'PayPal',
          'PAYDIREKT': 'Paydirekt',
          'SOFORT': 'SOFORT Überweisung',
          'KLARNA': 'Klarna',
          'CREDIT_CARD': 'Kreditkarte',
          'CREDIT_CARD_VISA': 'Visa',
          'CREDIT_CARD_MASTER_CARD': 'mastercard',
          'CREDIT_CARD_AMERICAN_EXPRESS': 'American Express',
          'CREDIT_CARD_DINERS_DISCOVER': 'Diners Club',
          'CREDIT_CARD_JCB': 'JCB',
          'CREDIT_CARD_MAESTRO_INTERNATIONAL': 'maestro',
          'CREDIT_CARD_CHINA_UNION_PAY': 'Union Pay',
          'CREDIT_CARD_UATP_AIRPLUS': 'Airplus',
          'I_DEAL': 'iDeal',
          'FREE': '-',
        },
        'TRANSACTION_TYPE': {
          'ALL': 'Alle',
          'SALE': 'Kauf',
          'RESERVATION': 'Reservierung'
        },
        'LOCATIONS': {
          'ALL': 'Alle'
        },
        'SALES_COUNTER_FILTER': {
          'ALL': 'Alle',
          'ONLY_SALES_COUNTER': 'Nur Kasse',
          'ONLY_CUSTOMER_ONLINE': 'Nur Online'
        },
      },
      'COUNTER_REPORT_TYPES': {
        'SALES_COUNTER_REPORT': 'Kassenbericht',
        'DISTRIBUTOR_REPORT': 'Verleiherbericht',
        'PERFORMANCE_REPORT': 'Veranstaltungsbericht',
        'PERFORMANCE_REPORT_INCLUDE_SELLING_POINT': 'Veranstaltungsbericht inkl. Verkaufsstellen',
        'AUDITORIUM_REPORT': 'Saalbericht',
        'POST_CODE_REPORT': 'Postleitzahlenbericht',
        'CITY_NAME_REPORT': 'Stadtnamenbericht',
        'TOTAL_REPORT': 'Gesamteinnahmenbericht'
      },
      'COUNTER_REPORT_GENERATION_STATUS': {
        'GENERATION_IS_QUEUED': 'In Warteschlange',
        'GENERATION_IS_STARTED': 'Gestartet',
        'GENERATION_IS_COMPLETED': 'Fertig'
      },
      'COMPONENT_COUNTER_REPORT': {
        'SELECT_LOCATION': 'Location',
        'SELECT_REPORT_TYPE': 'Typ',
        'SELECT_REPORT_SALES_COUNTER': 'Kasse',
        'SELECT_REPORT_START': 'Ab',
        'SELECT_REPORT_END': 'Bis'
      },
      'COMPONENT_TRANSACTION_PAYMENT_EDIT_MODAL': {
        'MODAL_TITLE': 'Zahlungsdaten ändern',
        'SELECT_TYPE': '--- Typ auswählen ---',
        'FIELDS': {
          'PAYMENT_PROVIDER_TYPE': 'Zahlungstyp',
          'PROVIDER_TYPE_CUSTOMER_NAME': 'Kontoinhaber',
          'PROVIDER_TYPE_ID': 'IBAN'
        }
      }
    }
  }
};
