<div class="modal-header">
  <h4 class="modal-title" [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.CONFIRM_PAY_MODAL.HEADER'"></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body clearfix">
  <p [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.CONFIRM_PAY_MODAL.CONETENT'"></p>
  <table class="table table-striped table-bordered table-sm">
    <tbody>
    <ng-container *ngFor="let group of counterConfirmationCartCurrencyGrouped">
      <tr>
        <th [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.COUNTER_CONFIRMATION_CART.TABLE.TOTAL'"></th>
        <td class="text-right">
          <strong style="font-size: 20px;">{{group.total | currency: group.currency :"symbol":'1.2-2':currentLang}}</strong>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-dark"
          (click)="activeModal.dismiss()"
          [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.BUTTONS.CANCEL'">
  </button>
  <button type="button"
          class="btn btn-success"
          (click)="activeModal.close()"
          [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.BUTTONS.PAY'">
  </button>
</div>
