<div class="container-fluid">
    <span onclick="window.history.back();" class="back-to-overview mb-3">
        <i class="fa fa-arrow-circle-o-left mr-1" aria-hidden="true"></i>
        <span>Zurück</span>
    </span>
    <div class="card">
        <div class="card-header">
            <h1>{{ 'MODUL_EVENTS.COMPONENT_DETAIL.NAME' | translate}} Editor</h1>
        </div>
        <c360-loading *ngIf="isLoading"></c360-loading>
        <div class="card-body">
            <ngb-tabset #eventTabs="ngbTabset" (tabChange)="onTabChange($event)">
                <ngb-tab title="Stammdaten" (select)="onTabSelect($event)" [id]="'tab-event-main-data'">
                    <ng-template ngbTabContent>
                      <c360-event-form
                        [event]="event"
                        (onDelete)="deleteEvent()"
                        (onCancel)="onCancel()"
                        (onFormSubmit)="onEvenUpdatetFormSubmit($event)"></c360-event-form>
                    </ng-template>
                </ngb-tab>
                <ngb-tab *ngFor="let key of filmsKeys"
                title="{{ films[key]?.tabTitlePresentationType }} - {{'MODUL_EVENTS.LANGUAGE_VERSION.' + films[key]?.languageVersion | translate}}"
                [id]="createTabId('tab-event-film-', films[key].typeChildId)"
                (select)="onTabSelect($event)">
                <ng-template ngbTabContent>

                    <ngb-tabset (tabChange)="onSubTabChange($event, films[key])">
                        <ngb-tab title="Allgemeine Daten"
                        [id]="createTabId('tab-event-film-main-data-', films[key].typeChildId)">
                        <ng-template ngbTabContent>
                            <c360-event-film-form
                            [event]="event"
                            [eventFilms]="films"
                            [eventFilm]="films[key]"
                            [formMode]="'update'"
                            (onFormSubmit)="onFilmFormSubmit($event)"
                            (onDelete)="deleteFilm($event)"
                            (onCancel)="onCancel()"></c360-event-film-form>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Medien" [id]="createTabId('tab-event-film-media-', films[key].typeChildId)">
                        <ng-template ngbTabContent>
                            <c360-event-media-form [film]="films[key]"
                            (onFilmFormSubmit)="onFilmFormSubmit($event)"></c360-event-media-form>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="+ Version hinzufügen" (select)="onTabSelect($event)" class="add-version"
        [id]="'tab-event-film-add'">
        <ng-template ngbTabContent>
            <c360-event-film-form [event]="event"
            [eventFilms]="films"
            [formMode]="'create'"
            (onFormSubmit)="onFilmFormSubmit($event)"></c360-event-film-form>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
        </div>
    </div>
</div>
