import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UserService} from '../../login/services/user.service';
import {Voucher, VoucherStatusType} from '../models/voucher.model';
import {VoucherHalData} from '../models/voucher-hal-data';
import {ErrorMessage} from '../../shared/models/error.model';
import {isUndefined} from 'util';

@Injectable()
export class HalPaymentService extends HalService {

  protected gatewayName = 'payment';
  protected voucherDomainName = 'vouchers';

  /**
   *
   * @param {HttpClient} _http
   * @param {AlertService} _alertService
   * @param {UserService} _userService
   * @param {LoggerService} _logger
   */
  constructor(
    protected _http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
    super(_http,
      _alertService,
      _userService,
      _logger);
  }

  /**
   *
   * @param {number} page
   */
  public getAllVouchers(page: number = null): void {
    this.getAll(this.voucherDomainName, page);
  }

  /**
   *
   * @param {any} response
   */
  public parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Strip page params
      console.warn('parseRootLinks', link);
      if (id === 'vouchers') {
        this._data[id] = new VoucherHalData(id, link);
      }
    });
  }

  /**
   *
   * @param {number} id
   * @return {Promise<Voucher>}
   */
  public getVoucher(id: string): Promise<Voucher> {
    console.warn('getPerformance', id, this._data, this.voucherDomainName, this._data[this.voucherDomainName]);
    return this.get(this._data[this.voucherDomainName], id);
  }

  public destroyDataById(id: string): void {
    this._data[id].data = {};
    this.data.next(this._data);
  }

  /**
   *
   * @param {string} mandatorId
   * @param {string} voucherCode
   * @return {Observable<{ [key: string]: Voucher }>}
   */
  public searchVoucherByMandatorIdAndVoucherCode(mandatorId: string, voucherCode: string): Observable<{ [key: string]: Voucher }> {
    const url = `${this._data['vouchers'].href}/status?mandatorId=${mandatorId}&voucherCode=${voucherCode}`;
    return this._http
      .get(url).pipe(
        map((response) => {
          if (response != null) {
            this._data['vouchers'].parseToArrayObjects(response);
            this.data.next(this._data);
            return this._data['vouchers'].data;
          }
          return response;
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.error && response.error.errorUserMessage) {
            const _errorMessage: ErrorMessage = new ErrorMessage(response.error.errorCode, response.error.errorMessage, response.error.errorUserMessage);
            this._logger.error('HAL ServiceError', _errorMessage);
          } else if (!isUndefined(response.error)) {
            this._logger.error('HAL ServiceError', response);
          }
          this.handleError(response);
          this._data['vouchers'].data = {};
          this.data.next(this._data);
          return observableThrowError(response);
        })
      );
  }

  /**
   *
   * @param {string} mandatorId
   * @param {string} locationId
   * @param {string} code
   * @param {VoucherStatusType} status
   * @return {Observable<any>}
   */
  public updateVoucherStatusByCode(mandatorId: string, locationId: string, code: string, status: VoucherStatusType): Observable<any> {
    const url = `${this._data['vouchers'].href}/status`;
    return this._http
      .patch(
        url,
        {
          mandatorId: mandatorId,
          locationId: locationId,
          status: status,
          voucherCodes: [
            code
          ]
        }
      ).pipe(
        map((response: any) => {
          if (response != null) {
            if (response && response.voucherInfos) {
              response.voucherInfos.map((item) => {
                this._data['vouchers'].parseToArrayObjects(item);
              });
            }
            this.data.next(this._data);
            return this._data['vouchers'].data;
          }
          return response;
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.error && response.error.errorUserMessage) {
            const _errorMessage: ErrorMessage = new ErrorMessage(response.error.errorCode, response.error.errorMessage, response.error.errorUserMessage);
            this._logger.error('HAL ServiceError', _errorMessage);
          } else if (!isUndefined(response.error)) {
            this._logger.error('HAL ServiceError', response);
          }
          this.handleError(response);
          return observableThrowError(response);
        })
      );
  }

  public callBookingTransactionCompletionProductSimpleRequest(locationId: string, voucherCode: string, providerType: string): Observable<any> {
    return this._http
      .post(
        this.gatewayUrl + 'booking-transaction/transactions/custom/completion/productSimple',
        {
          locationId: locationId,
          voucherCode: voucherCode,
          providerType: providerType
        }
      ).pipe(
        map((response: any) => {
          if (response != null) {
            this._data['vouchers'].parseToArrayObjects(response);
            this.data.next(this._data);
            return this._data['vouchers'].data;
          }
          return response;
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.error && response.error.errorUserMessage) {
            const _errorMessage: ErrorMessage = new ErrorMessage(response.error.errorCode, response.error.errorMessage, response.error.errorUserMessage);
            this._logger.error('HAL ServiceError', _errorMessage);
          } else if (!isUndefined(response.error)) {
            this._logger.error('HAL ServiceError', response);
          }
          this.handleError(response);
          return observableThrowError(response);
        })
      );
  }

  public callBookingTransactionRefundProductSimpleRequest(locationId: string, voucherCode: string): Observable<any> {
    return this._http
      .post(
        this.gatewayUrl + 'booking-transaction/transactions/custom/refund/productSimple',
        {
          locationId: locationId,
          voucherCode: voucherCode
        }
      ).pipe(
        map((response: any) => {
          if (response != null) {
            this._data['vouchers'].parseToArrayObjects(response);
            this.data.next(this._data);
            return this._data['vouchers'].data;
          }
          return response;
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.error && response.error.errorUserMessage) {
            const _errorMessage: ErrorMessage = new ErrorMessage(response.error.errorCode, response.error.errorMessage, response.error.errorUserMessage);
            this._logger.error('HAL ServiceError', _errorMessage);
          } else if (!isUndefined(response.error)) {
            this._logger.error('HAL ServiceError', response);
          }
          this.handleError(response);
          return observableThrowError(response);
        })
      );
  }
}
