import {Injectable} from '@angular/core';
import * as AcrossTabs from 'across-tabs/dist/across-tabs';
import {Router} from '@angular/router';
import {TabCommunicationMessage, TabCommunicationMessageTypes} from '../models/tab-communication-message.interface';
import {Observable, ReplaySubject} from 'rxjs';
import {LoggerService} from '../../shared/services/logger/logger.service';

@Injectable()
export class AcrossTabsParentService {

  private _parent;
  private _postMessageEvents = [];
  private _allTabs = [];
  private _openedTabs = [];
  private _closedTabs = [];
  private newTab = null;
  private i = 0;

  private tabMessages: ReplaySubject<TabCommunicationMessage> = new ReplaySubject<TabCommunicationMessage>(null);

  constructor(private _router: Router,
              private _logger: LoggerService) {
    //
    // this._logger.warn('PARENT');

    this._parent = new AcrossTabs.default.Parent({
      onHandshakeCallback: this.onHandshakeCallback,
      onChildCommunication: this.onChildCommunication,
      onChildDisconnect: this.onChildDisconnect
    });

    // this._logger.warn('PARENT', this._parent);
  }

  private onHandshakeCallback = (data) => {
    // this._logger.warn('onHandshakeCallback', data, this);
    data.type = 'open';
  };

  private onChildDisconnect = (data) => {
    // this._logger.warn('onChildDisconnect', data, this);
    data.type = 'close';
  };

  private onChildCommunication = (data) => {
    // this._logger.warn('onChildCommunication', data, this);

    data.type = 'custom';
    this._postMessageEvents.push(data);
    const msg = data.msg;
    // Check for TabCommunicationMessage
    if (msg.type != null && msg.body != null) {
      switch (msg.type) {
        case TabCommunicationMessageTypes.CHILD_READY:
          // this._logger.warn('NEW CHILD IS READY');
          break;
        case TabCommunicationMessageTypes.BOOKING_COMPLETE:
          // this._logger.warn('BOOK SUCCESSFUL', msg.body);
          if (msg.body.id != null) {
            this._router.navigate(['/counter/confirmation/' + msg.body.id]);
          }
          break;
      }
    }

    this.publishMessage(msg);
  };

  private publishMessage(msg) {
    // this._logger.warn('publish', this);
    this.tabMessages.next(
      msg
    );
    // this._logger.warn('CURRENT OBS VAL', msg);
  }

  openNewTab(url) {
    const config = {
      url: url,
      windowName: 'Child - ' + ++this.i,
      windowFeatures: ''
    };
    // this._logger.warn('openNewTab', config, this);
    this._parent.openNewTab(config);
  }

  closeAllTabs() {
    this._parent.closeAllTabs();
  }

  closeTab(id) {
    // this._logger.warn('Close Tab by Id', id);
    this._parent.closeTab(id);
  }

  broadCastTo(tab, message: TabCommunicationMessage) {
    this._parent.broadCastTo(tab, message);
  }

  broadCastAll(message: TabCommunicationMessage) {
    this._parent.broadCastAll(message);
  }

  public onTabMessage$(): Observable<TabCommunicationMessage> {
    return this.tabMessages.asObservable();
  }
}
