import {Price} from './price.model';

export class AreaPrice {

  public areaId: string;
  public prices: Array<Price>;

  constructor(areaId?: string, prices?: Array<Price>) {
    this.areaId = areaId;
    this.prices = prices.map(
      (price) =>
        new Price(
          price.id,
          price.priceCategoryId,
          price.vatSet, price.netAmount,
          price.fullAmount,
          price.segments
        )
    );
  }

}
