import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'c360-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})

export class EventsComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
