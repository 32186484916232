import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ISalesCounter} from '../../../shared/models/sales-counter.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SpioValidation} from '../../validators/spio.validator';
import {PrinterService} from '../../../shared/services/printer.service';
import {isNumber} from '../../../shared/utils';
import {SalesCounterService} from '../../services/sales-counter.service';

@Component({
  selector: 'c360-counter-spio-number-check',
  templateUrl: './counter-spio-number-check.component.html'
})
export class CounterSpioNumberCheckComponent implements OnInit {

  @Input() salesCounter: ISalesCounter = null;

  public spioForm: FormGroup = null;

  /**
   *
   * @param _activeModal
   * @param _translationService
   * @param _printerService
   * @param _salesCounterService
   * @param _formBuilder
   */
  constructor(private _activeModal: NgbActiveModal,
              private _translationService: TranslateService,
              private _printerService: PrinterService,
              private _salesCounterService: SalesCounterService,
              private readonly _formBuilder: FormBuilder) {}


  ngOnInit(): void {
    this.initForm();
  }

  printSetupTicket(): void {
    if (null !== this.spioForm.value && null != this.spioForm.value.nextTicketId) {
      this._printerService.printTestTicket(this.spioForm.value.nextTicketId + 1);
    }
  }

  private initForm(): void {
    this.spioForm = this._formBuilder
      .group({
          nextTicketId: [
            this.salesCounter.nextTicketId - 1,
            [
              Validators.required
            ]
          ],
          lastTicketId: [
            this.salesCounter.lastTicketId + 1,
            [
              Validators.required
            ]
          ]
        }, {
          validator: SpioValidation.LastTicketIdIsAfterNextTicketId
        }
      );
  }

  public submitForm() {
    if (this.spioForm.valid) {

      if (this.spioForm.value.nextTicketId && this.spioForm.value.lastTicketId) {
        const nextTicketId = this.spioForm.value.nextTicketId;
        const lastTicketId = this.spioForm.value.lastTicketId;
        if (
          isNumber(nextTicketId) && Number(nextTicketId) &&
          isNumber(lastTicketId) && Number(lastTicketId) &&
          (
            nextTicketId !== this.salesCounter.nextTicketId ||
            lastTicketId !== this.salesCounter.lastTicketId
          )
        ) {
          // Set Next and Last ticket ID
          // Add 1 to nextTicketId since form is asking for last printed Ticket
          this._salesCounterService
            .updateSalesCounterTicketNumber(parseInt(nextTicketId, 10) + 1, parseInt(lastTicketId, 10) - 1)
            .subscribe(
              (response: ISalesCounter) => {
                this._activeModal.close(response);
              },
              () => {}
            );
        }
      }
    }
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang(): string {
    return this._translationService.currentLang;
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  update(): void {
    this._activeModal.close(this.salesCounter);
  }
}
