import {Component} from '@angular/core';
import {AlertService} from '../../services/alert.service';
import {Observable} from 'rxjs';
import {Alert} from '../../models/alert.model';

@Component({
  selector: 'c360-alerts',
  templateUrl: './alerts.component.html'
})
export class AlertComponent {

  private _alerts$: Observable<Alert[]>;

  constructor(private _alertService: AlertService) {
    this._alerts$ = this._alertService.alerts$();
  }

  onClosed(alert) {
    this._alertService.acknowledgeAlert(alert);
  }

  get alerts$(): Observable<Alert[]> {
    return this._alerts$;
  }
}
