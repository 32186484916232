export class Param {

  private _key: string;
  private _value: string;

  constructor(_key: string, _value: string) {
    this._key = _key;
    this._value = _value;
  }

  public get key(): string {
    return this._key;
  }

  public set key(value: string) {
    this._key = value;
  }

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this._value = value;
  }
}
