export enum PrinterConnectionState {
  DISCONNECTED,
  CONNECTING,
  CONNECTED,
  NOT_FOUND,
  ERROR
}
export enum PrinterState {
  MISSING,
  IDLE,
  PRINT_INIT,
  PRINTING,
  PRINT_COMPLETE
}

export enum PrinterErrorTypes {
  BUSY,
  NO_TEMPLATES,
  TIMEOUT,
  ACK_FAILED
}

export enum PrinterSocketMessages {
  INIT = 'init',
  UPDATE = 'update',
  PRINT_REQUEST = 'print_request',
  PRINT_MESSAGE_ACK = 'print_message_ack',
  PRINT_MESSAGE_NACK = 'print_message_nack',
}
