import {Component, Input} from '@angular/core';
import {ScheduleExportFilm} from '../../../models/schedule-export.model';
import {environment} from '../../../../../environments/environment';
import {AcrossTabsParentService} from '../../../../core/services/across-tabs.service';
import {ScheduleExportPerformance} from '../../../models/schedule-export.response.models';
import {PerformanceStatus} from '../../../../performances/enums/performance-status.enum';

@Component({
  selector: '[c360-counter-schedule-item]',
  templateUrl: './counter-schedule-item.component.html'
})
export class CounterScheduleItemComponent {

  @Input() film: ScheduleExportFilm = null;

  public performanceStatus = PerformanceStatus;

  constructor(private _acrossTabsParentService: AcrossTabsParentService) {
  }

  gotoBooking(performance: ScheduleExportPerformance): void {
    if (performance.status !== this.performanceStatus.SOLD_OUT) {
      this._acrossTabsParentService.openNewTab(
        '' + environment.urls.booking + 'checkout/' + performance.id
      );
    }
  }

  getClassForPercentageOfFreeSeats(performance: ScheduleExportPerformance) {
    let result = 'occupation-success';
    const total = performance.occupationStats.total;
    const available = performance.occupationStats.free + performance.occupationStats.blocked;
    if (total - available >= (total / 100) * 50) {
      result = 'occupation-warning';
    }
    if ((total - available) >= (total / 100) * 90) {
      result = 'occupation-danger';
    }
    return result;
  }

  get cdnImageEnvironment(): any {
    return environment.cdnImage;
  }
}
