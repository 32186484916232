export enum TransactionStatus {
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  REFUNDED_PARTIAL = 'REFUNDED_PARTIAL',
  REFUND_FAILED = 'REFUND_FAILED',
  INCOMPLETE = 'INCOMPLETE',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  WAITING_TICKETSYSTEM = 'WAITING_TICKETSYSTEM',
  WAITING_REFUND = 'WAITING_REFUND',
  WAITING_EXPORT = 'WAITING_EXPORT'
}
