import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TransactionStatus} from '../../../enums/transaction-status.enum';
import {TransactionType} from '../../../enums/transaction-type.enum';
import {Params} from '../../../../shared/models/search/params.model';
import {Param} from '../../../../shared/models/search/param.model';
import {HalLocationsService} from '../../../../locations/services/hal-locations.service';
import {UserService} from '../../../../login/services/user.service';
import * as moment from 'moment';
import {isUndefined} from 'util';
import {SalesCounterFilter} from '../../../enums/sales-counter-filter.enum';

@Component({
  selector: 'c360-counter-transactions-search',
  templateUrl: './counter-transaction-search.component.html'
})

export class CounterTransactionsFilterComponent implements OnInit {

  private _selectedMandatorId: string;
  private _salesCounterId: string = null;

  @Output() searchParams: EventEmitter<any> = new EventEmitter<any>();

  statusSearch = TransactionStatus;
  typeSearch = TransactionType;
  salesCounterFilter = SalesCounterFilter;

  locationSearchFormControl: string = null;
  statusSearchFormControl: string = null;
  typeSearchFormControl: string = null;
  freeSearchFormControl: string = null;
  periodStartDateSearchFormControl: any = null;
  periodEndDateSearchFormControl: any = null;
  salesCounterFilterFormControl: string = null;
  intendedForPrintingFormControl: string = null;
  lastKeyUp: any = null;

  private readonly sepa_route_path: string = 'sepa';
  private readonly minDiffTime = 500;

  private _isSepaModule = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _halLocationsService: HalLocationsService,
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {

    this._selectedMandatorId = this._userService.getUser().mandatorId;
    this._salesCounterId = this._userService.getUser().salesCounterId;
  }

  /**
   * OnInit
   */
  ngOnInit(): void {

    if (null !== this.route.snapshot.paramMap.get('search')) {

      this.freeSearchFormControl = this.route.snapshot.paramMap.get('search');
    }

    if (!isUndefined(this.route.snapshot.routeConfig.path)
      && this.route.snapshot.routeConfig.path === this.sepa_route_path) {

      this._isSepaModule = true;
    }

    if (null !== this.route.snapshot.paramMap.get('search')) {

      setTimeout(() => {
        this.searchTransactions();
      });
    }
  }

  /**
   * Add
   */
  add() {
    this.router.navigate(['../add'], {
      relativeTo: this.route,
      replaceUrl: true
    });
  }

  /**
   * fullTextSearch
   */
  public fullTextSearch(): void {

    this.lastKeyUp = moment.now();

    if (this.freeSearchFormControl.length > 2 || this.freeSearchFormControl.length === 0) {
      setTimeout(() => {
        this.preTimeSearchTransactions();
      }, this.minDiffTime);
    }
  }

  /**
   * preTimeSearchTransactions
   */
  public preTimeSearchTransactions(): void {

    const diffTime = moment.now() - this.lastKeyUp;

    if (diffTime > this.minDiffTime) {
      this.searchTransactions();
    }
  }

  /**
   * Search with the selected/inputed search criteria
   */
  public searchTransactions(): void {

    const params = new Params();

    if (null !== this.statusSearchFormControl) {

      params.add(new Param('historyLastStatus', this.statusSearchFormControl));
    }

    if (null !== this.typeSearchFormControl) {

      params.add(new Param('transactionType', this.typeSearchFormControl));
    }

    if (null !== this.freeSearchFormControl && this.freeSearchFormControl !== '') {

      params.add(new Param('searches', this.freeSearchFormControl));
    }

    if (null !== this.locationSearchFormControl) {

      params.add(new Param('locationId', this.locationSearchFormControl));
    }

    if (null !== this.periodStartDateSearchFormControl) {

      let dateStart = this.periodStartDateSearchFormControl.year;
      // tslint:disable-next-line:max-line-length
      dateStart += '-' + (this.periodStartDateSearchFormControl.month < 10 ? ('0' + this.periodStartDateSearchFormControl.month) : this.periodStartDateSearchFormControl.month);
      // tslint:disable-next-line:max-line-length
      dateStart += '-' + (this.periodStartDateSearchFormControl.day < 10 ? ('0' + this.periodStartDateSearchFormControl.day) : this.periodStartDateSearchFormControl.day);
      params.add(new Param('periodStartDate', dateStart + 'T00:00:00'));
    }

    if (null !== this.periodEndDateSearchFormControl) {

      let dateEnd = this.periodEndDateSearchFormControl.year;
      // tslint:disable-next-line:max-line-length
      dateEnd += '-' + (this.periodEndDateSearchFormControl.month < 10 ? ('0' + this.periodEndDateSearchFormControl.month) : this.periodEndDateSearchFormControl.month);
      // tslint:disable-next-line:max-line-length
      dateEnd += '-' + (this.periodEndDateSearchFormControl.day < 10 ? ('0' + this.periodEndDateSearchFormControl.day) : this.periodEndDateSearchFormControl.day);
      params.add(new Param('periodEndDate', dateEnd + 'T23:59:59'));
    }

    if (null !== this.salesCounterFilterFormControl) {

      params.add(new Param('salesCounterFilter', this.salesCounterFilterFormControl));
    }

    if (null !== this.intendedForPrintingFormControl && this.intendedForPrintingFormControl) {

      params.add(new Param('intendedForPrinting', 'true'));
    }

    this.searchParams.emit(params);
  }

  /**
   * Reset the search form controlls and make a clean search
   */
  public resetSearchFormControlls(): void {

    this.locationSearchFormControl = null;
    this.statusSearchFormControl = null;
    this.typeSearchFormControl = null;
    this.freeSearchFormControl = '';
    this.periodStartDateSearchFormControl = null;
    this.periodEndDateSearchFormControl = null;
    this.salesCounterFilterFormControl = null;
    this.intendedForPrintingFormControl = null;

    this.searchTransactions();
  }

  /**
   * Is Sepa Modul
   * @returns {boolean}
   */
  get isSepaModule(): boolean {
    return this._isSepaModule;
  }

  /**
   * Gets salesCounterId
   * @returns {string}
   */
  get salesCounterId(): string {
    return this._salesCounterId;
  }
}
