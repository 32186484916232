import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {isObject, isUndefined} from 'util';
import {LoggerService} from './logger/logger.service';
import {ErrorMessage} from '../models/error.model';
import {AlertService} from './alert.service';

@Injectable()
export class HttpService {

  protected _endpoints = {};

  protected headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(protected _http: HttpClient,
              protected _alertService: AlertService,
              protected _logger: LoggerService) {
  }

  static serializeData(data) {
    // If this is not an object, defer to native stringification.
    if (!isObject(data)) {
      return ((data == null) ? '' : data.toString());
    }
    const buffer = [];
    // Serialize each key in the object.
    for (const name in data) {
      if (!data.hasOwnProperty(name)) {
        continue;
      }
      const value = data[name];
      buffer.push(
        encodeURIComponent(name) +
        '=' +
        encodeURIComponent((value == null) ? '' : value)
      );
    }
    // Serialize the buffer and clean it up for transportation.
    const source = buffer
      .join('&')
      .replace(/%20/g, '+');
    return (source);
  }

  protected handleError(error: any): void {

    if (error.error && error.error.errorUserMessage) {

      const _errorMessage: ErrorMessage = new ErrorMessage(error.error.errorCode, error.error.errorMessage, error.error.errorUserMessage);

      this._logger.error('HAL ServiceError', _errorMessage);
      this._alertService.addAlert(_errorMessage);
    } else if (!isUndefined(error.error)) {

      this._logger.error('HAL ServiceError', error);
      this._alertService.addAlert(error);
    }

    if (error.error && error.error.errorValidationList) {
      for (const key in error.error.errorValidationList) {
        if (error.error.errorValidationList.hasOwnProperty(key)) {
          const messageObject = error.error.errorValidationList[key];
          this._alertService.addAlert(messageObject.message);
        }
      }
    } else {

//      this._alertService.addAlert(error);
    }
  }
}
