<td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'" class="nobr">Reihe {{ticket.spaceRow}}, Platz {{ticket.spaceRowPosition}}</td>
<td>
  <select name="price_{{ticket.id}}" (change)="changePrice($event)">
    <ng-container *ngFor="let price of priceList">

      <option value="{{price.id}}" [selected]="ticket.priceId === price.id">{{price.name}}</option>

    </ng-container>
  </select>
</td>
<td *ngIf="transaction.skipSaleFee" class="text-right">{{ticket.fullAmount | currency: currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
<td *ngIf="!transaction.skipSaleFee" class="text-right">{{ticket.fullAmountWithFee | currency: currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
<td class="text-right">
  <span class="text-danger">{{calcDifference | currency: currency :"symbol":'1.2-2':this.getCurrentLang()}}</span>
</td>
<td class="text-right">
  <span>{{calcAmount | currency: currency :"symbol":'1.2-2':this.getCurrentLang()}}</span>
</td>
