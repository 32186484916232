export const locale_base_de = {
  'lang': 'de',
  'data': {
    'MODUL_NAVIGATION' : {
      'NAME': 'Navigationsmenü',
      'TOP': {
        'LOCATION': 'Standort',
        'MANDATOR': 'Mandator',
      },
      'SIDE': {
        'ADMINBOARD': 'Adminboard',
        'DASHBOARD': 'Dashboard',
        'GENERAL_SETTINGS': 'Allgemeine Einstellungen',
        'GENERAL': 'Allgemeines',
        'USER': 'Benutzer',
        'CUSTOMERS': {
          'NAME': 'Profile',
          'PROFILES': 'Profile',
        },
        'EVENTS': {
          'NAME': 'Filme',
          'EVENTS': 'Events',
          'FILMS': 'Filme',
          'SCHEDULE': 'Spielplan'
        },
        'PERFORMANCES': {
          'LIST': 'Veranstaltungen',
          'NAME': 'Veranstaltungen',
          'SCHEDULE': 'Spielplan',
          'ATTRIBUTES': 'Attribute'
        },
        'TRANSACTIONS': {
          'NAME': 'Buchungen',
          'OVERVIEW': 'Transaktionen',
          'SEPA': 'SEPA'
        },
        'LOCATIONS': {
          'NAME': 'Standorte',
          'OVERVIEW': 'Übersicht',
          'LOCATION': 'Standortdaten',
          'DETAIL': 'Standortdetails',
          'LEGAL': 'Rechtstexte'
        },
        'COUNTER': {
          'NAME': 'Kasse',
          'DASHBOARD': 'Dashboard',
          'SCHEDULE': 'Veranstaltungen',
          'TRANSACTIONS': 'Buchungen',
          'CONFIRMATION': 'Bestätigung'
        },
        'LANGUAGE': {
          'NAME': 'Sprache wechseln',
          'DE' : 'Deutsch',
          'EN' : 'Englisch',
        },
        'REPORTS':{
          'NAME': 'Berichte'
        }
      }
    }
  }
};
