import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Film} from '../../models/film.model';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {HalEventsService} from '../hal-events.service';

@Injectable()
export class EventFilmsResolver implements Resolve<any> {

  constructor(
    private _halEventsService: HalEventsService,
    private _logger: LoggerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {

    return new Promise((resolve, reject) => {
      this._halEventsService.findAllFilmsByEventId(route.params.id)
        .then((films: { [key: string]: Film }) => {
          resolve(films);
        }, (data) => {
        }).catch((err) => {
        this._logger.warn('FindAllEvents error', err);
        reject(err);
      });
    });
  }
}
