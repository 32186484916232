import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FileManipulatorService} from '../../../../shared/services/file-manipulator.service';

@Component({
  selector: '[c360-sepa-export-modal]',
  templateUrl: './sepa-export-modal.component.html'
})
export class SepaExportModalComponent {

  private _response;
  private fileManipulatorService: FileManipulatorService;

  /**
   * @param {NgbActiveModal} _activeModal
   */
  constructor(private _activeModal: NgbActiveModal,
              private _fileManipulatorService: FileManipulatorService) {

    this.fileManipulatorService = _fileManipulatorService;
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  /**
   * @returns {any}
   */
  get response() {
    return this._response;
  }

  /**
   * @param value
   */
  set response(value) {
    this._response = value;
  }

  /**
   * On click download file
   * @param {string} cdnReference
   */
  public onClickDownload(cdnReference: string): void {

    this.fileManipulatorService.downloadXML(cdnReference, true);
  }
}
