import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'c360-saved-user-modal',
  templateUrl: './saved-user-modal.component.html',
})
export class SavedUserModalComponent {

  constructor(private _activeModal: NgbActiveModal) {
  }

  /**
   *
   */
  public onClose(): void {
    this._activeModal.close();
  }
}
