import {Injectable} from '@angular/core';
import {LoggerService} from './logger.service';
import {environment} from '../../../../environments/environment';

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService implements LoggerService {
  isDebugMode: boolean;

  constructor() {
    this.isDebugMode = !environment.production;
  }

  get info() {
    if (this.isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (this.isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    if (this.isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

  invokeConsoleMethod(type: string, args?: any): void {
    const logFn: Function = (console)[type] || console.log || noop;
    logFn.apply(console, [args]);
  }
}
