import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {isUndefined} from 'util';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {AWSService} from '../../../core/services/aws.service';
import {HalEventsService} from '../../services/hal-events.service';
import {Event} from '../../models/event.model';
import {Film} from '../../models/film.model';
import {Media} from '../../models/media.model';
import {EventDeleteModalComponent} from '../event-delete-modal/event-delete-modal.component';
import {FilmDeleteModalComponent} from '../film-delete-modal/film-delete-modal.component';

@Component({
  selector: 'c360-events-detail',
  templateUrl: './detail.component.html'
})

export class EventsDetailComponent implements OnInit, OnDestroy {
  @ViewChild('eventTabs', {static: true}) eventTabs: NgbTabset;
  @ViewChild('deleteConfirmationModal', {static: false}) deleteConfirmationModal;

  private _isLoading: boolean;
  private _event: Event;
  private _films: Film[];
  private _filmsKeys: Array<string>;

  private currentTab = null;
  private currentFilmId: string = null;

  private _subscriptions: Subscription = new Subscription();

  /**
   *
   * @param {ActivatedRoute} _activatedRoute
   * @param {HalEventsService} _halEventsService
   * @param {AWSService} _AWSService
   * @param {Location} _location
   * @param {NgbModal} _modalService
   * @param {LoggerService} _logger
   * @param {Router} _router
   */
  constructor(private _activatedRoute: ActivatedRoute,
              private _halEventsService: HalEventsService,
              private _AWSService: AWSService,
              private _location: Location,
              private _modalService: NgbModal,
              private _logger: LoggerService,
              private _router: Router) {
    this._isLoading = false;
    this._filmsKeys = [];
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this._activatedRoute.data.subscribe((data: { event: Event, films: Film[] }) => {
        this._event = data.event;
        this._films = data.films;
        this._filmsKeys = Object.keys(this._films);
      })
    );

    this._subscriptions.add(
      this._activatedRoute.params.subscribe(params => {
        if (this._films != null) {
          const film = this._films[params.filmId];
          if (film != null) {
            this.currentFilmId = film.typeChildId;
          }
        }
      })
    );

    this._subscriptions.add(
      this._halEventsService.data.subscribe((data) => {
        if (data != null && data['films'] != null) {
          this._films = data['films'].data;
          this._filmsKeys = Object.keys(this._films);
          if (this.currentFilmId === null) {
            this.activateTabById('tab-event-main-data');
          } else {
            setTimeout(() => {
              this.activateTabById('tab-event-film-' + this.currentFilmId);
            }, 100);
          }
        }
      })
    );

    this._subscriptions.add(
      this._activatedRoute.fragment.subscribe((fragment: string) => {
        if (fragment === 'create-new-film') {
          this.activateTabById('tab-event-film-add');
        }
      })
    );

    this._logger.info('TABS', this.eventTabs);
  }

  ngOnDestroy(): void {
    if (!isUndefined(this._subscriptions)) {
      this._subscriptions.unsubscribe();
    }
  }

  /**
   *
   * @param {{mode: string; data: Film; deleteFromS3?: Media; s3Path?: string}} formData
   */
  onFilmFormSubmit(formData: { mode: string, data: Film, deleteFromS3?: Media, s3Path?: string }): void {
    this._logger.info('onFilmFormSubmit', formData, formData.mode);

    if (formData.mode === 'create') {
      this.addFilm(formData.data);
    } else if (formData.mode === 'update') {
      if (formData.deleteFromS3) {
        for (const key in this._films) {
          if (this._films.hasOwnProperty(key)) {
            const tmpFilm: Film = this._films[key];
            if (tmpFilm.id === formData.data.id) {
              continue;
            }
            if (tmpFilm.media.find((item2) => {
              return item2.url === formData.deleteFromS3.url;
            })) {
              formData.deleteFromS3 = null;
            }
          }
        }
      }

      this._isLoading = true;
      this.currentFilmId = formData.data.typeChildId;
      this.updateFilm(formData.data)
        .then(() => {
          if (formData.deleteFromS3) {
            this._AWSService
              .deleteMedia(formData.deleteFromS3, formData.s3Path)
              .subscribe(
                (response) => {
                  this._isLoading = false;
                  this._logger.info('delete media from s3', response);
                },
                (error) => {
                  this._isLoading = false;
                  this._logger.error('delete media from s3', error);
                }
              );
          } else {
            this._isLoading = false;
          }
        });
    } else if (formData.mode === 'delete') {
      // this.currentFilmId = null;
      this.deleteFilm(formData.data);
    }
  }

  /**
   *
   * @param {{mode: string; data: Event; }} formData
   */
  onEvenUpdatetFormSubmit(formData): void {

    if (formData.mode === 'update' && !isUndefined(formData.data)) {

      this._isLoading = true;

      this.updateEvent(formData.data)
        .then((event) => {

          this._event = event;
          this._isLoading = false;
          this._router.navigate(['/', 'events', 'detail', this._event.id], {
            replaceUrl: true
          });
        }).catch(() => {

        this._isLoading = false;
      });
    }
  }

  private addFilm(data): void {
    this._isLoading = true;
    this._halEventsService
      .saveFilm(data)
      .then(
        (response) => {
          this._isLoading = false;
          this.currentFilmId = response.id;
          this._router.navigate(['/', 'events', 'detail', response.eventId, response.id], {
            replaceUrl: true
          });
          this.activateTabById('tab-event-film-' + this.currentFilmId);
        },
        (error) => {
          this._isLoading = false;
          this._logger.error(error);
        }
      );
  }

  private updateFilm(data): Promise<any> {
    return this._halEventsService.updateFilm(data);
  }

  private updateEvent(data): Promise<any> {
    return this._halEventsService.updateEvent(data);
  }

  deleteEvent(): void {
    const modalRef = this._modalService.open(EventDeleteModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.event = this._event;
    modalRef.result.then(
      (data) => {
        if (data === true) {
          this._isLoading = true;
          this._logger.info('onDelete', this._event);
          this._halEventsService
            .deleteEvent(this._event)
            .then(
              () => {
                this._isLoading = false;
                this.onCancel();
              }, (rejected) => {
                this._isLoading = false;
              }
            );
        }
      }
    );
  }

  /**
   *
   * @param {Film} film
   */
  deleteFilm(film: Film): void {
    const modalRef = this._modalService.open(FilmDeleteModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.film = film;
    modalRef.result.then(
      (data) => {
        if (data === true) {
          this._isLoading = true;
          this._logger.info('onDelete', film);
          for (const key in this._films) {
            if (this._films.hasOwnProperty(key)) {
              const tmpFilm: Film = this._films[key];
              if (tmpFilm.id === film.id) {
                continue;
              }
              film.media = film.media.filter((item) => {
                return item.type === 'TRAILER_YOUTUBE' || !tmpFilm.media.find((item2) => {
                  return item2.url === item.url;
                });
              });
            }
          }
          this._halEventsService
            .deleteFilm(film)
            .then(
              () => {
                this._isLoading = false;
                this._router.navigate(['/', 'events', 'detail', this.event.id], {
                  replaceUrl: true
                });
              }, (rejected) => {
                this._isLoading = false;
              }
            );
        }
      }
    );
  }


  onCancel(): void {
    this._location.back();
  }

  /**
   *
   * @param tab
   */
  onTabSelect(tab): void {
    this._logger.info('Tab selected', tab);
    this.currentTab = tab;
  }

  /**
   *
   * @param event
   */
  onTabChange(event): void {
  }

  /**
   *
   * @param event
   * @param film
   */
  onSubTabChange(event, film): void {
  }

  /**
   *
   * @param string
   * @param id
   * @returns {string}
   */
  createTabId(string, id): string {
    return `${string}${id}`;
  }

  /**
   *
   * @param {string} id
   */
  activateTabById(id: string): void {
    setTimeout(() => {
      // Activate Tab
      this.eventTabs.select(id);
    });
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {Event}
   */
  get event(): Event {
    return this._event;
  }

  /**
   *
   * @returns {Film[]}
   */
  get films(): Film[] {
    return this._films;
  }

  /**
   *
   * @returns {Array<string>}
   */
  get filmsKeys(): Array<string> {
    return this._filmsKeys;
  }
}
