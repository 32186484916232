import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../services/user.service';
import {EmailValidator} from '../validators/email.validator';
import {PrinterConnectionState} from '../../shared/enums/printer.enums';
import {PrinterService} from '../../shared/services/printer.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'c360-login-form',
  templateUrl: './login-form.component.html'
})
export class LoginFormComponent implements OnInit, OnDestroy {

  @Output() onEmailChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() error = null;
  @Input() preEmail: string;

  private _loginForm: FormGroup;
  private _email: FormControl;
  private _password: FormControl;

  private subscribe: Subscription;
  public hasPrinter = false;
  public superLogin = false;

  constructor(private _userService: UserService,
              private _printerService: PrinterService) {
  }

  ngOnInit(): void {
    this.createFormControls();
    this.createForm();

    this.subscribe = new Subscription();
    this.subscribe.add(
      this._printerService.connectionState$().subscribe(isConnected => {
        this.hasPrinter = (isConnected === PrinterConnectionState.CONNECTED);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   *
   */
  changeEmail(): void {

    this.onEmailChange.emit(this.email.value);
  }

  createFormControls(): void {
    this._email = new FormControl(this.preEmail, [
      Validators.required,
      EmailValidator.email
    ]);
    this._password = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);
  }

  createForm(): void {
    this._loginForm = new FormGroup({
      email: this._email,
      password: this._password
    });
  }

  submitForm() {

    if (this._loginForm.valid) {
      this.onFormSubmit.emit({
        data: this._loginForm.value,
        superLogin: this.superLogin
      });
    }
  }

  get loginForm(): FormGroup {
    return this._loginForm;
  }

  get email(): FormControl {
    return this._email;
  }

  get password(): FormControl {
    return this._password;
  }
}
