import {HalData} from '../../shared/models/halData';
import {Location} from './location.model';
import {isArray} from 'util';

export class LocationHalData extends HalData {

  data: { [id: string]: Location } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.locations) {
      response._embedded.locations.forEach((location: Location) => {
        this.parseToObject(location);
      });
    } else if (isArray(response)) {
      response.map((location: Location) => {
        this.parseToObject(location);
      });
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): Location {
    if (response != null) {
      this.data['' + response.id + ''] = new Location(
        response._links,
        response.id,
        response.mandatorId,
        response.name,
        response.country,
        response.zipCode,
        response.city,
        response.street,
        response.houseNumber,
        response.currency,
        response.timeZone,
        response.iban,
        response.bic,
        response.creditorId,
        response.creditorName,
        response.sepaRulebook,
        response.mailTemplateBookingConfirmationId,
        response.mailTemplateChargebackId,
        response.pdfTemplateBookingConfirmationReference,
        response.bookingTransactionRefundableForCustomer
      );
    }
    return this.data['' + response.id + ''];
  }
}
