import {HalData} from '../../shared/models/halData';
import {Mandator} from './mandator.model';
import {isArray} from 'util';

export class MandatorHalData extends HalData {

  data: { [id: string]: Mandator } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.mandators) {
      response._embedded.locations.forEach((location: Mandator) => {
        this.parseToObject(location);
      });
    } else if (isArray(response)) {
      response.map((mandator: Mandator) => {
        this.parseToObject(mandator);
      });
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): Mandator {
    if (response != null) {
      const mandator = new Mandator(
        response._links,
        response.id,
        response.name,
        response.cdnType,
        response.cdnInternalUrl,
        response.cdnInternalUrl,
        response.termsAndConditions,
        response.imprint,
        response.dataProtectionGuidelines,
        response.oauthClientIds,
        response.startDayOfWeek
      );

      this.data['' + response.id + ''] = mandator;
    }
    return this.data['' + response.id + ''];
  }
}
