<div class="container-fluid">
    <span onclick="window.history.back();" class="back-to-overview mb-3">
        <i class="fa fa-arrow-circle-o-left mr-1" aria-hidden="true"></i>
        <span>Zurück</span>
    </span>
    <div class="card">
        <div class="card-header">
            <h1>{{ 'MODUL_EVENTS.COMPONENT_ADD.NAME' | translate}}</h1>
        </div>
        <div class="card-body">
            <ngb-tabset>
                <ngb-tab title="Stammdaten">
                    <ng-template ngbTabContent>
                        <c360-event-form [event]="event" [formMode]="'create'" (onFormSubmit)="save($event)" (onCancel)="cancel()"></c360-event-form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>
