import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {AlertService} from '../../shared/services/alert.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _alertService: AlertService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401: // Unauthorized
              window.location.href = '/login';
              return observableThrowError({});
          }
          return observableThrowError(err);
        }
      }));
  }
}
