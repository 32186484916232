import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {english} from './i18/client/en';
import {german} from './i18/client/de';
import {AuditoriumsRoutingModule} from './auditoriums-routing.module';
import {HalLocationsService} from '../locations/services/hal-locations.service';
import {AuditoriumsComponent} from './components/auditoriums.component';

const COMPONENTS = [
  AuditoriumsComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
    NgbModule,

    AuditoriumsRoutingModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [
    HalLocationsService
  ],
  entryComponents: []
})
export class AuditoriumsModule {

  constructor(private translationLoader: TranslationLoaderService) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(english, german);
  }
}
