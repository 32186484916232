<div class="filter-row mt-2 mb-2">

    <div class="mr-sm-2 mb-2" *ngIf="!isSepaModule">
        <label class="mb-0" for="schedule-select-status">Status:</label>
        <select class="form-control" aria-label="Status-Filter"
                id="schedule-select-status"
                [(ngModel)]="statusSearchFormControl"
                (change)="searchTransactions()">
            <option [ngValue]="null">--- {{'MODUL_TRANSACTIONS.ENUMS.TRANSACTION_STATUS.ALL' | translate}} ---</option>
            <option *ngFor="let item of statusSearch | keys" [ngValue]="item.value">{{'MODUL_TRANSACTIONS.ENUMS.TRANSACTION_STATUS.' + item.value | translate}}</option>
        </select>
    </div>

    <div class="mr-sm-2 mb-2" *ngIf="!isSepaModule">
        <label class="mb-0" for="schedule-select-type">Typ:</label>
        <select class="form-control" aria-label="Typ-Filter"
                id="schedule-select-type"
                [(ngModel)]="typeSearchFormControl"
                (change)="searchTransactions()">
            <option [ngValue]="null">--- {{'MODUL_TRANSACTIONS.ENUMS.TRANSACTION_TYPE.ALL' | translate}} ---</option>
            <option *ngFor="let item of typeSearch | keys" [ngValue]="item.value">{{'MODUL_TRANSACTIONS.ENUMS.TRANSACTION_TYPE.' + item.value | translate}}</option>
        </select>
    </div>

   <div class="mr-sm-2 mb-2" *ngIf="!isSepaModule && salesCounterId !== null && salesCounterId !== ''">
      <label class="mb-0" for="schedule-select-status">Kassenfilter:</label>
      <select class="form-control" aria-label="Status-Filter"
              id="select-sales-counter-filter"
              [(ngModel)]="salesCounterFilterFormControl"
              (change)="searchTransactions()">
        <option [ngValue]="null">--- {{'MODUL_TRANSACTIONS.ENUMS.SALES_COUNTER_FILTER.ALL' | translate}} ---</option>
        <option *ngFor="let item of salesCounterFilter | keys" [ngValue]="item.value">{{'MODUL_TRANSACTIONS.ENUMS.SALES_COUNTER_FILTER.' + item.value | translate}}</option>
      </select>
    </div>

    <div class="mr-sm-2 mb-2">
        <label class="mb-0" for="schedule-select-date-from">Von:</label>
        <div class="input-group date">
            <input type="text" class="form-control"
            id="schedule-select-date-from"
            placeholder="yyyy-mm-dd"
            [(ngModel)]="periodStartDateSearchFormControl"
            (ngModelChange)="searchTransactions()"
            name="dpFrom"
            ngbDatepicker #d="ngbDatepicker"
            (change)="searchTransactions()">
            <div class="input-group-addon" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i>
            </div>
        </div>
    </div>

    <div class="mr-sm-2 mb-2">
        <label class="mb-0" for="schedule-select-date-to">Bis:</label>
        <div class="input-group date">
            <input type="text" class="form-control"
            id="schedule-select-date-to"
            placeholder="yyyy-mm-dd"
            [(ngModel)]="periodEndDateSearchFormControl"
            (ngModelChange)="searchTransactions()"
            name="dpTo"
            ngbDatepicker #dTo="ngbDatepicker"
            (change)="searchTransactions()">
            <div class="input-group-addon" (click)="dTo.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i>
            </div>
        </div>
    </div>

    <div class="mr-sm-2 mb-2">
        <label class="mb-0" for="schedule-search">Suchen:</label>
        <input class="form-control" id="schedule-search" type="search" placeholder="Suche (3 Zeichen min)" aria-label="Suche" [(ngModel)]="freeSearchFormControl" (keyup)="fullTextSearch()">
    </div>

    <div class="mr-sm-2 mb-2" *ngIf="!isSepaModule && salesCounterId !== null && salesCounterId !== ''">
      <div class="form-group form-check pl-0 mb-0 pb-2 px-3">
        <input type="checkbox"
               id="schedule-intended-for-printing-from"
               value="true"
               [(ngModel)]="intendedForPrintingFormControl"
               (change)="searchTransactions()">
        <label class="form-check-label" for="schedule-intended-for-printing-from">Druckbar</label>
      </div>
    </div>

    <div class="mr-sm-2 mb-2">
        <button type="submit" class="btn btn-danger" (click)="resetSearchFormControlls()">{{ 'MODUL_TRANSACTIONS.COMPONENT_LIST.RESET' | translate}}</button>
    </div>
</div>
