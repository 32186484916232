import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../models/user';

@Component({
  selector: '[c360-user-list-item]',
  templateUrl: './user-list-item.component.html'
})
export class UserListItemComponent {

  @Input() user: User;
  @Output() onUpdate: EventEmitter<User> = new EventEmitter<User>();

  constructor() {
  }

  public onAccountLocked(): void {
    this.user.accountLocked = !this.user.accountLocked;
    this.onUpdate.emit(this.user);
  }
}
