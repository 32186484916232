import {Hal} from '../../shared/models/hal';

export class User extends Hal {

  public id: number;
  public mandatorId: string;
  public locationId: string;
  public externalId: string;
  public accountEnabled: boolean;
  public accountExpiryDate: string;
  public accountLocked: boolean;
  public emailAddress: string;
  public emailAddressApproved: boolean;
  public passwordExpiryDate: string;
  public gender: string;
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;
  public mobileNumber: string;
  public streetName: string;
  public houseNumber: string;
  public postCode: string;
  public cityName: string;
  public role: string;
  public fullName: string;
  public userId: string;
  public country: string;
  public _links: any;

  constructor(
    _links?: any,
    id?: number,
    mandatorId?: string,
    locationId?: string,
    externalId?: string,
    accountEnabled?: boolean,
    accountExpiryDate?: string,
    accountLocked?: boolean,
    emailAddress?: string,
    emailAddressApproved?: boolean,
    passwordExpiryDate?: string,
    gender?: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string,
    mobileNumber?: string,
    streetName?: string,
    houseNumber?: string,
    postCode?: string,
    cityName?: string,
    role?: string,
    fullName?: string,
    userId?: string,
    country?: string,
  ) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.locationId = locationId;
    this.externalId = externalId;
    this.accountEnabled = accountEnabled;
    this.accountExpiryDate = accountExpiryDate;
    this.accountLocked = accountLocked;
    this.emailAddress = emailAddress;
    this.emailAddressApproved = emailAddressApproved;
    this.passwordExpiryDate = passwordExpiryDate;
    this.gender = gender;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.mobileNumber = mobileNumber;
    this.streetName = streetName;
    this.houseNumber = houseNumber;
    this.postCode = postCode;
    this.cityName = cityName;
    this.role = role;
    this.fullName = fullName;
    this.userId = userId;
    this.country = country;
  }

  get userToString(): string {
    return [
      this.firstName,
      this.lastName
    ].join(' - ');
  }

}
