
    <div class="modal-header">
      <h4 class="modal-title">{{ options.title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="no()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ options.message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="yes()">{{ options.yes }}</button>
      <button type="button" class="btn btn-secondary" (click)="no()">{{ options.no }}</button>
    </div>