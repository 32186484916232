import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ValidationService} from '../../services/validation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'c360-form-validation-messages',
  template: `
    <div *ngIf="errorMessage != null" [translate]="errorMessage.message" [translateParams]="errorMessage.params"></div>`
})
export class FormValidationMessagesComponent {
  private _errorMessage: { message: string, params: any };
  @Input() control: FormControl;

  constructor(private _translationService: TranslateService) {
  }

  get errorMessage(): { message: string, params: any } {

    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.control.dirty)) {
        return {
          message: ValidationService.getValidatorErrorMessage(propertyName),
          params: this.control.errors[propertyName]
        };
      }
    }
    return null;
  }
}
