<!-- Top Bar -->
<header class="app-header navbar">
  <button class="navbar-toggler mobile-sidebar-toggler d-lg-none mr-auto" type="button" (click)="toggleMenu(true)">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" routerLink="/dashboard">
    <img src="assets/img/ticket360_logo.svg" alt="T360 Logo">
  </a>
  <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" (click)="minimizeSidebar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <form novalidate autocomplete="off" *ngIf="userContextForm" [formGroup]="userContextForm">
    <ul class="nav navbar-nav d-md-down-none">
      <li class="nav-item pl-2">
        <a class="nav-link" routerLink="/dashboard">Dashboard</a>
      </li>
      <li *ngIf="userMandatorName !== null && userMandatorId !== null" class="nav-item px-3">
        <span class="nav-link">{{ 'MODUL_NAVIGATION.TOP.MANDATOR' | translate }}: <b>{{userMandatorName}}</b></span>
      </li>
      <li *ngIf="userLocationName !== null && userLocationId !== null" class="nav-item px-3">
        <span class="nav-link">{{ 'MODUL_NAVIGATION.TOP.LOCATION' | translate }}: <b>{{userLocationName}}</b></span>
      </li>

      <li *ngIf="!userMandatorId" class="nav-item pl-5" style="width: 220px;">
        <div style="text-align: left;">
          <div *ngIf="mandator && !mandatorSearchShow">
            <div style="display: flex;" class="">
              {{ 'MODUL_NAVIGATION.TOP.MANDATOR' | translate }}
              <div *ngIf="mandator" style="display: flex;">&nbsp;(
                <div class="link"><a (click)="toggleMandatorSearchShow(true)">entfernen</a></div>
                )
              </div>
              :
            </div>
            <span style="display: block; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><b>{{mandator.name}}</b></span>
          </div>
          <div class="">
            <input *ngIf="mandatorSearchShow"
                   type="text"
                   class="form-control w-100"
                   placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.SELECT_MANDATOR_PLACEHOLDER' | translate }}"
                   formControlName="mandatorSearchTerm"
                   (focusin)="toggleMandatorChooseShow(true)"
                   (focusout)="toggleMandatorChooseFocus()"
                   #mandatorSearch
            >
            <select *ngIf="mandatorChooseShow" class="form-control"
                    style="position: absolute; width: auto; min-width: 220px;"
                    id="schedule-select-mandator"
                    formControlName="mandatorId"
                    size="{{mandatorListFiltered.length < 20 ? mandatorListFiltered.length > 1 ? mandatorListFiltered.length : 2 : 20}}"
                    (focusin)="toggleMandatorChooseShow(true)"
                    (focusout)="toggleMandatorChooseShow(false)"
                    #mandatorChoose>
              <option *ngFor="let mandatorItem of mandatorListFiltered"
                      [ngValue]="mandatorItem.id"
                      [label]="mandatorItem.name">{{mandatorItem.name}}
              </option>
            </select>
          </div>
        </div>
      </li>

<!--      <li *ngIf="userMandatorId && mandator && userContextForm.get('mandatorId').value" class="nav-item px-3">-->
<!--        <div class="nav-link">{{ 'MODUL_NAVIGATION.TOP.MANDATOR' | translate }}:-->
<!--          <b>{{mandator.name}}</b>-->
<!--        </div>-->
<!--      </li>-->

      <li *ngIf="mandator && !userLocationId" class="nav-item pl-5" style="width: 360px;">
        <div style="text-align: left;">
          <div *ngIf="location && !locationSearchShow">
            <div style="display: flex;" class="">
              {{ 'MODUL_NAVIGATION.TOP.LOCATION' | translate }}
              <div *ngIf="location" style="display: flex;">&nbsp;(
                <div class="link"><a (click)="toggleLocationSearchShow(true)">entfernen</a></div>
                )
              </div>
              :
            </div>
            <span style="display: block; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><b>{{location.name}}</b></span>
          </div>
          <div class="">
            <input *ngIf="locationSearchShow"
                   type="text"
                   class="form-control w-100"
                   placeholder="{{ 'MODUL_USERS.COMPONENT_DETAIL.SELECT_LOCATION_PLACEHOLDER' | translate }}"
                   formControlName="locationSearchTerm"
                   (focusin)="toggleLocationChooseShow(true)"
                   (focusout)="toggleLocationChooseFocus()"
                   #locationSearch
            >
            <select *ngIf="locationChooseShow" class="form-control"
                    style="position: absolute; width: auto; min-width: 360px;"
                    id="schedule-select-location"
                    formControlName="locationId"
                    size="{{locationListFiltered.length < 20 ? locationListFiltered.length > 1 ? locationListFiltered.length : 2 : 20}}"
                    (focusin)="toggleLocationChooseShow(true)"
                    (focusout)="toggleLocationChooseShow(false)"
                    #locationChoose>
              <option *ngFor="let locationItem of locationListFiltered"
                      [ngValue]="locationItem.id"
                      [label]="locationItem.name">{{locationItem.name}}
              </option>
            </select>
          </div>
        </div>
      </li>

<!--      <li *ngIf="userLocationId && location && userContextForm.get('locationId').value" class="nav-item px-3">-->
<!--        <span class="nav-link">{{ 'MODUL_NAVIGATION.TOP.LOCATION' | translate }}:-->
<!--          <b>{{location.name}}</b>-->
<!--        </span>-->
<!--      </li>-->

    </ul>
  </form>
  <ul class="nav navbar-nav ml-auto" style="margin-right: 10px;">
    <!--<li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="fa fa-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
    </li>-->
    <li class="nav-item d-md-down-none">
      <!--<a class="nav-link" href="#"><i class="fa fa-list-ul"></i></a>-->
    </li>
    <li class="nav-item d-md-down-none">
      <!--<a class="nav-link" href="#"><i class="fa fa-map-marker"></i></a>-->
    </li>
    <li class="nav-item dropdown" *ngIf="isLoggedIn$ | async">
      <c360-counter-confirmation-cart></c360-counter-confirmation-cart>
    </li>
    <li class="nav-item nav-item-printer">
      <img src="/assets/img/printer_okay.png" class="img-printer-okay" *ngIf="hasPrinter" placement="bottom" ngbTooltip="Ticketdrucker verbunden">
      <img src="/assets/img/printer_missing.png" class="img-printer-missing" *ngIf="!hasPrinter" placement="bottom" ngbTooltip="Kein Ticketdrucker">
    </li>
    <li class="nav-item dropdown nav-item-avatar" *ngIf="isLoggedIn$ | async">
      <c360-user-menu-icon></c360-user-menu-icon>
    </li>
  </ul>
  <!--<button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" (click)="minimizeSidebar()">
    <span class="navbar-toggler-icon"></span>
  </button>-->
</header>
