import {Injectable, OnDestroy} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import {HalData} from '../../shared/models/halData';
import {CounterTransactionHalData} from '../models/counter-transaction-hal-data';
import {CounterTransactionSearchHalData} from '../models/counter-transaction-search-hal-data';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../login/services/user.service';
import {Transaction} from '../models/transaction/transaction.model';
import {Params} from '../../shared/models/search/params.model';
import {CounterTransactionSearch} from '../models/counter-transaction-search.model';

@Injectable()
export class HalCounterTransactionsService extends HalService implements OnDestroy {

  protected gatewayName = 'booking-transaction';
  protected subscribtions: Subscription = new Subscription();

  public transactions = new BehaviorSubject<CounterTransactionHalData>(null);
  public transactionsSearch = new BehaviorSubject<CounterTransactionSearchHalData>(null);

  /**
   * Constructor
   *
   * @param {HttpClient} http
   * @param {AlertService} _alertService
   * @param {UserService} _userService
   * @param {LoggerService} _logger
   */
  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
    super(http, _alertService, _userService, _logger);

    this.subscribtions = this.data.subscribe((data) => {
      if (data != null && data.transactions != null) {

        this.transactions.next(data.transaction);
      }
      if (data != null && data.transactionSearches != null) {

        this.transactionsSearch.next(data.transactionSearches);
      }
    });
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }

  /**
   * Parse Root Links
   * @param response
   */
  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {

      let link = response._links[id];
      link = this.getRawLink(link.href); // Script page params

      if (id === 'transaction' || id === 'transactionSearches') {

        this._data[id] = new CounterTransactionSearchHalData(id, link);
      } else if (id === 'transactions') {

        this._data[id] = new CounterTransactionHalData(id, link);
      } else {

        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   * Get All
   * @param {number} page
   * @returns {Observable<HalData>}
   */
  getAllTransactions(page: number = null): void {
    this.getAll('transactions', page);
  }

  /**
   * Get All
   * @param {number} page
   * @param {string} params
   * @returns {Observable<HalData>}
   */
  getAllTransactionsSearch(page: number = null, params: Params = null): void {

    this.getAll('transactionSearches', page, params);
  }

  /**
   * Get transactions by Transaction Id
   *
   * @param {String} id
   */
  getTransactionsById(id: String): Promise<Transaction> {

    return this.get(this._data.transactions, id);
  }

  /**
   * Get transactions by Transaction Id
   *
   * @param {String} id
   */
  getTransactionsByExternalId(id: String): Promise<Transaction> {

    return this.getByExternalId(this._data.transactions, id);
  }

  /**
   * Get transactions by Transaction pickup code
   *
   * @param {String} pickupCode
   */
  getTransactionsIdByPickupCode(pickupCode: String): Promise<CounterTransactionSearch> {

    const _halData: HalData = this._data.transactionSearches;

    return this.http.get(_halData.href + '/search/findByPickupCode?pickupCode=' + pickupCode, {headers: this.headers})
      .toPromise()
      .then((response: any) => {
        if (response.id == null) {
          return null;
        }
        return _halData.parseToObject(response);
      })
      .catch((reason) => {
        if (reason.status !== 404) {
          this.handleError(reason);
        }
        return null;
      });
  }

  /**
   * Resend booking confirmation mail
   * @param params
   * @returns {Promise<any>}
   */
  resendBookingConfirmationMail(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/mailResend', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Refund transactions and/or ticktes
   * @param params
   * @returns {Promise<any>}
   */
  refundTransaction(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/refund', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          transactionStatus: transactionStatus
        };

        return response;
      });
  }

  chargeBack(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/chargeback', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          transactionHistoryStatus: transactionStatus
        };

        return response;
      });
  }

  findSimpleListByPriceIds(_ids: string): Promise<any> {

    return this.http.get(this.gatewayUrl + 'price/prices/custom/findSimpleListByPriceIds?priceIds=' + _ids, {headers: this.headers})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Refund transactions and/or ticktes
   * @param params
   * @returns {Promise<any>}
   */
  updateTicketsPrice(params: any): Promise<any> {

    return this.http.post(
      this.gatewayUrl + 'booking-transaction/transactions/custom/updateTransactionTicketPriceType', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          transactionStatus: transactionStatus
        };

        return response;
      });
  }
}

