<form *ngIf="filterForm"
      [formGroup]="filterForm"
      (ngSubmit)="submitForm()">
  <div class="filter-row mt-2 mb-2">

    <div class="mr-sm-2 mb-2">
      <label for="reports-select-type">
        {{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_TYPE' | translate}}
      </label>
      <div class="input-group date">
        <select id="reports-select-type"
                class="form-control custom-select"
                name="reports-select-type"
                attr.aria-label="{{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_TYPE' | translate}}"
                formControlName="reportType">
          <option [ngValue]="null">Wähle eine Art</option>
          <option *ngFor="let key of reportTypesKeys" [ngValue]="reportTypes[key]">
            {{'MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.'+reportTypes[key] | translate}}
          </option>
        </select>
      </div>
    </div>

    <div class="mr-sm-2 mb-2">
      <label for="report-start-date">
        {{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_START' | translate}}
      </label>
      <div class="input-group date">
        <input id="report-start-date"
               class="form-control custom-input"
               placeholder="dd.mm.yyyy"
               name="report-start-date"
               ngbDatepicker
               readonly
               type="text"
               #startDate="ngbDatepicker"
               formControlName="reportStartDate">
        <div class="input-group-addon" (click)="onOpenStartDate()">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="mr-sm-2 mb-2">
      <label for="report-end-date">
        {{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_END' | translate}}
      </label>
      <div class="input-group date">
        <input id="report-end-date"
               class="form-control custom-input"
               placeholder="dd.mm.yyyy"
               name="report-end-date"
               ngbDatepicker
               readonly
               type="text"
               [minDate]="filterForm.get('reportStartDate').value"
               #endDate="ngbDatepicker"
               formControlName="reportEndDate">
        <div class="input-group-addon" (click)="onOpenEndDate()">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="mr-sm-2 mb-2">
      <label for="reports-select-sales-counter">
        {{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_SALES_COUNTER' | translate}}
      </label>
      <div class="input-group date">
        <select id="reports-select-sales-Counter"
                class="form-control custom-select"
                name="reports-select-sales-counter"
                attr.aria-label="{{ 'MODUL_TRANSACTIONS.COMPONENT_COUNTER_REPORT.SELECT_REPORT_TYPE' | translate}}"
                formControlName="salesCounter">
          <option [ngValue]="null">Wähle eine Kasse</option>
          <option *ngFor="let key of salesCounterListKeys" [ngValue]="salesCounterList[key]">
            {{ salesCounterList[key].name }}
          </option>
        </select>
      </div>
    </div>


    <div class="mr-sm-2 mb-2">
      <button type="submit"
              class="btn btn-success"
              [disabled]="filterForm.invalid"
              [translate]="'FORMS.BUTTONS.SEND'">
      </button>
    </div>

    <div class="mr-sm-2 mb-2">
      <div class="form-check p-0">
        <input type="checkbox"
               class="m-0"
               id="reports-generate-new"
               name="reports-generate-new"
               formControlName="generateNew">
        <label class="form-check-label" aria-hidden="true" for="reports-generate-new">Neu generieren</label>
      </div>
    </div>

  </div>
</form>
