import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TransactionsComponent} from './components/transactions.component';
import {TransactionsListComponent} from './components/list/transactions-list.component';
import {TransactionsRootLinkResolver} from './services/resolver/transactions-root-link.resolver';
import {SepaRootLinkResolver} from './services/resolver/sepa-root-link.resolver';
import {SepaListComponent} from './components/sepa/list/sepa-list.component';
import {LocationsRootLinkResolver} from '../locations/services/resolver/locations-root-link.resolver';
import {ReportsComponent} from './components/reports/reports.component';
import {ChangeOccupationComponent} from '../performances/components/change-occupation/change-occupation.component';

const appRoutes: Routes = [
  {
    path: '',
    component: TransactionsComponent,
    resolve: {
      rootLink: TransactionsRootLinkResolver,
      sepaRootLink: SepaRootLinkResolver,
      LocationsRootLinkResolver
    },
    data: {
      breadcrumb: false
    },
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: TransactionsListComponent,
        data: {
          breadcrumb: false
        }
      },
      {
        path: 'detail',
        component: TransactionsListComponent,
        data: {
          breadcrumb: 'Detail'
        }
      },
      {
        path: 'sepa',
        component: SepaListComponent,
        data: {
          breadcrumb: 'Sepa'
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          breadcrumb: 'Berichte'
        }
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    TransactionsRootLinkResolver,
    SepaRootLinkResolver,
    LocationsRootLinkResolver
  ]
})
export class TransactionsRoutingModule {
}

