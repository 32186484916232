import {Hal} from '../../shared/models/hal';

export class Mandator extends Hal {

  public id: string;
  public name: string;
  public cdnType: string;
  public cdnInternalUrl: string;
  public cdnExternalUrl: number;
  public termsAndConditions: string;
  public imprint: string;
  public dataProtectionGuidelines: string;
  public oauthClientIds: string;
  public startDayOfWeek: string;

  public _links:  any;

  constructor(
    _links?: any,
    id?: string,
    name?: string,
    cdnType?: string,
    cdnInternalUrl?: string,
    cdnExternalUrl?: number,
    termsAndConditions?: string,
    imprint?: string,
    dataProtectionGuidelines?: string,
    oauthClientIds?: string,
    startDayOfWeek?: string
  ) {
      super(_links);

      this.id = id;
      this.name = name;
      this.cdnType = cdnType;
      this.cdnInternalUrl = cdnInternalUrl;
      this.cdnExternalUrl = cdnExternalUrl;
      this.termsAndConditions = termsAndConditions;
      this.imprint = imprint;
      this.dataProtectionGuidelines = dataProtectionGuidelines;
      this.oauthClientIds = oauthClientIds;
      this.startDayOfWeek = startDayOfWeek;
  }

}
