import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseListComponent} from '../../../shared/components/base-list.component';
import {Subscription} from 'rxjs';
import {UserHalData} from '../../models/user-hal-data';
import {HalUsersService} from '../../services/hal-users.service';
import {User} from '../../models/user';

@Component({
  selector: 'c360-users-list',
  templateUrl: './list.component.html'
})

export class UsersListComponent extends BaseListComponent implements OnInit, OnDestroy {

  private _userHalData: UserHalData;
  private _getUserSubscription: Subscription;
  private _subscriptions: Subscription = new Subscription();
  private _isLoading: boolean;
  private _searchString: string;

  constructor(private _halUsersService: HalUsersService,
              private _changeDetectorRef: ChangeDetectorRef) {

    super();
  }

  ngOnInit() {
    this._getUserSubscription = this._halUsersService
      .searchUsers
      .subscribe(
        (users: UserHalData) => {
          this._isLoading = false;
          if (null != users) {
            this._userHalData = users;
            this.currentPage = users.page.currentPage;
            this._changeDetectorRef.detectChanges();
          }
        });

    this.getAll(1);
  }

  ngOnDestroy() {
    this._getUserSubscription.unsubscribe();
    this._subscriptions.unsubscribe();
  }

  /**
   *
   * @param {number} page
   */
  private getAll(page: number = null): void {
    this._isLoading = true;
    this._halUsersService.searchAllUsers(page, this._searchString);
  }

  /**
   *
   * @param {number} pageNumber
   */
  public getAllByPage(pageNumber: number): void {
    this.getAll(pageNumber);
  }

  /**
   *
   * @param {string} searchString
   */
  public onSearch(searchString: string): void {
    this._searchString = searchString;
    this.getAll(1);
  }

  /**
   *
   * @param {User} user
   */
  public onUpdate(user: User): void {
    this._isLoading = true;
    const data = <any> {
      id: user.id,
      accountLocked: user.accountLocked
    };
    this._halUsersService
      .updateUser(data)
      .then(
        () => {
          this._isLoading = false;
        },
        () => {
          this._isLoading = false;
        }
      );
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {HalData}
   */
  get userHalData(): UserHalData {
    return this._userHalData;
  }
}
