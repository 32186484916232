import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalUsersService} from '../hal-users.service';
import {User} from '../../models/user';
import {LoggerService} from '../../../shared/services/logger/logger.service';

@Injectable()
export class UserDetailResolver implements Resolve<any> {

  constructor(
    private _halUsersService: HalUsersService,
    private _logger: LoggerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {

    return new Promise((resolve, reject) => {
      this._halUsersService.getUser(route.params.id)
        .then((user: User) => {
          resolve(user);
        }).catch((err) => {
        this._logger.warn('Users error', err);
        reject(err);
      });
    });
  }
}
