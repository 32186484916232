import {locale_base_en} from '../en';
import {mergeDeep} from '../../../shared/utils';

const locale_client_en = {
  'data': {
    'MODUL_EVENTS' : {
      'NAME': 'Events',
      'COMPONENT_LIST' : {
        'NAME': 'Overview'
      },
      'COMPONENT_DETAIL' : {
        'NAME': 'Detail'
      }
    }
  }
};

export const english = mergeDeep({}, locale_client_en, locale_base_en);
