<div class="container-fluid">
  <div class="card">
    <div class="card-header">
        <h1>{{ 'MODUL_TRANSACTIONS.NAME' | translate}}</h1>
    </div>
    <div class="card-body">
    <c360-loading *ngIf="isLoading"></c360-loading>

      <ngb-tabset #transactionTabs="ngbTabset"
                  (tabChange)="onTabChange($event)"
                  [activeId]="TAB_OVERVIEW_ID">

        <!-- Start Overview Tab -->
        <ngb-tab title="Übersicht"
                 (select)="onTabSelect($event)"
                 [id]="TAB_OVERVIEW_ID">
          <ng-template ngbTabContent>

              <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                <c360-transactions-search (searchParams)="searchWithParams($event)"></c360-transactions-search>
                <form *ngIf="transactionHalData">
                      <table class="table table-striped table-bordered table--selectable">
                        <caption hidden><h2>Liste aller Buchunge</h2></caption>
                        <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th class="nobr" scope="col">Pickup Code</th>
                          <th scope="col">Status</th>
                          <th scope="col">Name/Email</th>
                          <th scope="col">Buchung</th>
                          <th scope="col">Vorstellung</th>
                          <th scope="col">Ort</th>
                          <th scope="col">Zahlung</th>
                        </tr>
                        </thead>

                        <tbody>

                        <tr *ngFor="let transaction of transactionHalData.data | keys"
                            c360-transactions-list-item [transaction]="transaction.value"
                            (click)="openTabTransaction(transaction.value.transactionId)">
                        </tr>

                          </tbody>

                      </table>

                      <!-- Start Pagination -->
                      <ngb-pagination *ngIf="transactionHalData.page"
                                      [maxSize]="5"
                                      [rotate]="true"
                                      [ellipses]="true"
                                      [boundaryLinks]="true"
                                      [collectionSize]="transactionHalData.page.totalElements"
                                      [pageSize]="transactionHalData.page.totalElementsForPage"
                                      [(page)]="currentPage"
                                      (pageChange)="getAllByPage($event)">
                      </ngb-pagination>
                      <!-- End Pagination -->

                </form>
              </div>

          </ng-template>
        </ngb-tab>
        <!-- End Overview Tab -->

        <!-- Start Transactions Tabs -->
        <ngb-tab *ngFor="let transaction of activeTransactions"
                 (select)="onTabSelect($event)"
                 [id]="createTabId(TAB_TRANSACTION_PREFIX, transaction.id)">

          <ng-template ngbTabTitle>{{transaction.getFullNameForTab()}} {{transaction.id}} <button aria-label="Close" (click)="closeTabById($event, transaction.id)" class="close closeTab ml-2" type="button" >×</button></ng-template>

          <ng-template ngbTabContent>

            <c360-transactions-detail [transaction]="transaction"
                                      (onChangeTransaction)="openTabTransaction($event)"
                                      [reOpenRefundModal]="getReOpenRefundModal()"
                                      (onRefundTickets)="onRefundTickets($event)">
            </c360-transactions-detail>

          </ng-template>
        </ngb-tab>
        <!-- End Transactions Tabs -->

      </ngb-tabset>

  </div>
</div>
</div>
