import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalEventsService} from '../hal-events.service';

@Injectable()
export class EventRootLinkResolver implements Resolve<any> {

  constructor(private _halEventService: HalEventsService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this._halEventService.getRootLink();
  }
}
