<div class="container-fluid">
  <div class="card">
    <div class="card-header">
        <h1>{{ 'MODUL_EVENTS.COMPONENT_FILMS_LIST.NAME' | translate}}</h1>
    </div>
    <c360-loading *ngIf="isLoading || isSearchLoading"></c360-loading>
    <div class="card-body">
      <div *ngIf="!isLoading">
        <c360-events-films-filter (onSearch)="onSearch($event)"></c360-events-films-filter>

          <ng-container *ngIf="filmHalData">
            <form>
              <table class="table table-striped">
                <caption hidden>Liste aller Filme</caption>
                <thead>
                <tr>
                  <!--<th scope="col"><span aria-label="ID-Nummer">#</span></th>-->
                  <th scope="col">Titel</th>
                  <th scope="col">Beschreibung</th>
                  <th scope="col">Regie</th>
                  <th scope="col">Darsteller</th>
                  <th scope="col">Genre</th>
                  <th scope="col">Laufzeit</th>
                  <th scope="col">Sprache</th>
                  <th scope="col">Jahr</th>
                  <th scope="col">FSK</th>
                  <th scope="col">Starttermin</th>
                  <th scope="col">Poster</th>
                  <th scope="col">Trailer</th>
                  <th scope="col"><span class="text-hide">Bearbeitungsbuttons</span></th>
                </tr>
                </thead>
                <tbody *ngIf="filmHalData.data">
                <tr *ngFor="let film of filmHalData.data | keys" c360-events-films-list-item [film]="film.value"
                    (onDelete)="onDelete($event)">
                </tr>
                </tbody>
              </table>
            </form>
            <!-- Start Pagination -->
            <ngb-pagination *ngIf="filmHalData.page"
                            [maxSize]="5"
                            [rotate]="true"
                            [ellipses]="true"
                            [boundaryLinks]="true"
                            [collectionSize]="filmHalData.page.totalElements"
                            [(page)]="currentPage"
                            (pageChange)="getAllByPage($event)"></ngb-pagination>
            <!-- End Pagination -->
          </ng-container>
      </div>
    </div>
  </div>
</div>