import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {isString, isUndefined} from 'util';
import {LoggerService} from '../../../../shared/services/logger/logger.service';
import {Params} from '../../../../shared/models/search/params.model';
import {Param} from '../../../../shared/models/search/param.model';
import {UserService} from '../../../../login/services/user.service';
import {BaseListComponent} from '../../../../shared/components/base-list.component';
import {HalCounterTransactionsService} from '../../../services/hal-counter-transactions.service';
import {CounterTransactionSearchHalData} from '../../../models/counter-transaction-search-hal-data';
import {Transaction} from '../../../models/transaction/transaction.model';
import {ActivatedRoute, Router} from '@angular/router';
import {TransactionStatus} from '../../../enums/transaction-status.enum';
import {CounterTransactionSearch} from '../../../models/counter-transaction-search.model';
import {filter, tap} from 'rxjs/operators';
import {CounterConfirmationCartStatus} from '../../../enums/counter-confirmation-cart-status.enum';
import {ConfirmationService} from '../../../services/confirmation.service';
import {isNumeric} from 'rxjs/internal-compatibility';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'c360-counter-transactions-list',
  templateUrl: './counter-transactions-list.component.html'
})
export class CounterTransactionsListComponent extends BaseListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('transactionTabs', {static: false, read: false}) transactionTabs: NgbTabset;
  public activeTransactions: Transaction[] = [];
  public _transactionSearchHalData: CounterTransactionSearchHalData;
  public readonly TAB_TRANSACTION_PREFIX = 'tab-transaction-';
  public readonly TAB_OVERVIEW_ID = 'tab-transaction-overview';

  private subscriptions: Subscription = null;

  private _isLoading: boolean;
  private _currentTab = null;
  private _userLocationId: string;
  private _params: Params = new Params();
  private _searchParams: Params = new Params();
  private _reOpenRefundModal: any = null;
  private _id: string = null;
  public performanceId: string = null;
  public performanceIdSearch: string = null;
  public transactionIdsToPrint: number[] = [];
  public transactionExternalIdsToPrint = null;
  public transactionExternalIdToPrintCount = null;
  public transactionExternalIdToPrint = null;
  public transactionExternalIdToPrintHasError = false;
  public transactionExternalIdToPrintIsFinished = false;
  public transactionExternalIdToPrintDownloadRunning = false;

  constructor(
    private _halTransactionsService: HalCounterTransactionsService,
    private _logger: LoggerService,
    private _ref: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _confirmationService: ConfirmationService,
    private _cd: ChangeDetectorRef,
    private _http: HttpClient
  ) {
    super();
  }

  /**
   * on init
   */
  ngOnInit() {
    this._params = this.addParamsMandatoryForTransactions(this._params);

    this.performanceId = this._route.snapshot.paramMap.get('performanceId');
    this.transactionExternalIdsToPrint = null;
    this.transactionExternalIdToPrintCount = null;
    this.transactionExternalIdToPrint = null;
    this.transactionExternalIdToPrintHasError = false;
    this.transactionExternalIdToPrintIsFinished = false;
    this.transactionExternalIdToPrintDownloadRunning = false;

    this.init();
  }

  ngAfterViewInit(): void {
    if (!this.performanceId) {
      this._id = this._route.snapshot.paramMap.get('id');

      if (null !== this._id) {
        this.openTabTransactionByExternal(this._id);
      }

      this.subscriptions.add(
        this._confirmationService
          .counterConfirmationCartEvent
          .pipe(
            filter((data) => data === CounterConfirmationCartStatus.SUCCESS),
            tap((data) => {
              if (null !== this._id) {
                this.openTabTransactionByExternal(this._id);
              }
              this.init();
            })
          )
          .subscribe(
            () => {
            }
          )
      );
    }
  }

  /**
   * init
   */
  private init(): void {
    if (null != this.subscriptions) {
      this.subscriptions.unsubscribe();
    } else {
      this.subscriptions = new Subscription();
    }

    this._isLoading = this.performanceId !== null;

    this.subscriptions.add(
      this._halTransactionsService.transactionsSearch.subscribe((halData: CounterTransactionSearchHalData) => {

        if (null != halData && null != halData.data) {

          this._transactionSearchHalData = halData;

          if (this.performanceIdSearch !== null) {
            this.transactionIdsToPrint = [];
            this.transactionExternalIdsToPrint = Object.keys(this._transactionSearchHalData.data);
            this.transactionExternalIdsToPrint.forEach(transactionExternalId => this.transactionIdsToPrint.push(this._transactionSearchHalData.data[transactionExternalId].transactionId));
            this.transactionExternalIdToPrintCount = 0;
            this.transactionExternalIdToPrintNext(true);
          }

          if (null != halData.page) {

            this.currentPage = halData.page.currentPage;
          }

          this._isLoading = false;
          this._ref.markForCheck();
        }
      })
    );

    if (this.performanceId !== null) {
      const params = new Params();
      params.add(new Param('performanceId', this.performanceId));
      this.performanceIdSearch = this.performanceId;
      this._halTransactionsService.getAll('transactionSearches', 1, params, 'pickupCode,asc', 250);
    } else {
      if (null === this._route.snapshot.paramMap.get('search')) {
        this.performanceIdSearch = null;
        this.getAllSearch(1);
      }
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   *
   * @param {Params} params
   */
  searchWithParams(params: Params): void {

    this._isLoading = true;
    this.currentPage = 1;
    this._searchParams = params;

    this._halTransactionsService.getAllTransactionsSearch(this.currentPage, this._params.concats(this._searchParams));
  }

  /**
   *
   * @param {number} page
   */
  private getAllSearch(page: number = null): void {

    this._isLoading = true;
    this._halTransactionsService.getAllTransactionsSearch(page, this._params.concats(this._searchParams));
  }

  /**
   *
   * @param {number} pageNumber
   */
  getAllByPage(pageNumber: number): void {

    this.getAllSearch(pageNumber);
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {CounterTransactionSearchHalData}
   */
  get transactionHalData(): CounterTransactionSearchHalData {
    return this._transactionSearchHalData;
  }

  /**
   *
   * @param tab
   */
  onTabSelect(tab): void {
    this._logger.info('Tab selected', tab);

    this._currentTab = tab;
  }

  /**
   *
   * @param event
   */
  onTabChange(event: NgbTabChangeEvent): void {

    this._reOpenRefundModal = null;

    let nextId = event.nextId;

    nextId = nextId.replace(this.TAB_TRANSACTION_PREFIX, '');

    if (event.nextId !== this.TAB_OVERVIEW_ID &&
      isNumeric(nextId)
    ) {
      this.openTabTransaction(nextId);
    }
  }

  /**
   *
   * @param transaction
   */
  onSubTabChange(transaction): void {
  }

  /**
   *
   * @param string
   * @param id
   * @returns {string}
   */
  createTabId(string, id): string {
    return `${string}${id}`;
  }

  /**
   *
   * @param {string} id
   */
  activateTabById(id: string): void {
    setTimeout(() => {
      this.transactionTabs.select(id);
    });
  }

  onRefundTickets(obj: any): void {

    if (!isUndefined(obj.tickets) &&
      !isUndefined(obj.responseStatusOk) &&
      !isUndefined(obj.responseStatusFail)
    ) {

      this._reOpenRefundModal = {
        tickets: obj.tickets,
        responseStatusOk: obj.responseStatusOk,
        responseStatusFail: obj.responseStatusFail,
        skipRefundFee: obj.skipRefundFee
      };
    }

    if (!isUndefined(obj.transactionId)) {

      this.openTabTransaction(obj.transactionId);
    }

  }

  /**
   *
   * @param {string} id
   */
  openTabTransactionByExternal(id: string): void {

    this._isLoading = true;

    this._halTransactionsService.getTransactionsByExternalId(id).then((transaction) => {

      const findObject = this.activeTransactions.find(x => parseInt(x.id, 10) === parseInt(transaction.id, 10));

      if (!isUndefined(findObject)) {

        const i = this.activeTransactions.indexOf(findObject);
        this.activeTransactions[i] = transaction;
      } else {

        this.activeTransactions.push(transaction);
      }

      this.activateTabById(this.TAB_TRANSACTION_PREFIX + transaction.id);

      this._isLoading = false;
      this._ref.markForCheck();
    });
  }

  /**
   *
   * @param {string} id
   */
  openTabTransaction(id: string): void {

    this._isLoading = true;

    this._halTransactionsService.getTransactionsById(id).then((transaction) => {

      const findObject = this.activeTransactions.find(x => parseInt(x.id, 10) === parseInt(id, 10));

      if (!isUndefined(findObject)) {

        const i = this.activeTransactions.indexOf(findObject);
        this.activeTransactions[i] = transaction;
      } else {

        this.activeTransactions.push(transaction);
      }

      this._isLoading = false;
      this._ref.markForCheck();
    });
  }

  onTransactionClick(transaction: CounterTransactionSearch) {
    if (this.transactionExternalIdToPrintCount == null) {
      if (
        transaction != null &&
        (
          transaction.historyLastStatus === TransactionStatus.COMPLETE ||
          transaction.historyLastStatus === TransactionStatus.REFUNDED_PARTIAL ||
          transaction.historyLastStatus === TransactionStatus.REFUND_FAILED
        )
      ) {
        if (null == transaction.transactionExternalIdNew) {
          this.gotoTransactionConfirmation(transaction.id);
        } else {
          this._router.navigate(['/counter/confirmation/' + transaction.transactionExternalIdNew + '/' + transaction.pickupCode]);
        }
      } else {
        this.gotoTransactionDetails(transaction.id);
      }
    }
  }

  /**
   * Goto Transaction Confirmation
   */
  gotoTransactionConfirmation(transactionId: string) {
    this._router.navigate(['/counter/confirmation/' + transactionId], {relativeTo: this._route});
  }

  gotoTransactionDetails(transactionId: string) {
    this._router.navigate(['/counter/transactions/list/' + transactionId], {relativeTo: this._route});
  }

  /**
   * Close tab by id
   * @param {string} tabId
   */
  closeTabById(e, tabId: string): void {
    e.preventDefault();

    const findObject = this.activeTransactions.find(x => parseInt(x.id, 10) === parseInt(tabId, 10));

    if (!isUndefined(findObject)) {

      const i = this.activeTransactions.indexOf(findObject);
      this.activeTransactions.splice(i, 1);
    }
  }

  /**
   * Sets to params mandatory search params
   * @param {Params} params
   * @returns {Params}
   */
  private addParamsMandatoryForTransactions(params: Params): Params {

    params.add(new Param('sort', 'historyFirstDate,desc'));

    if (this.isUserLocation()) {
      params.add(new Param('locationId', this._userService.getUser().locationId));
    }

    return params;
  }

  /**
   * Test if the user is location user
   * @returns {boolean}
   */
  public isUserLocation(): boolean {

    this._userLocationId = this._userService.getUser().locationId;

    if (!isUndefined(this._userLocationId) && this._userLocationId !== null) {
      return true;
    }

    return false;
  }

  public getReOpenRefundModal(): any {

    return this._reOpenRefundModal;
  }

  get existsLocationId(): boolean {
    return this._userService.getUser().locationId && isString(this._userService.getUser().locationId);
  }

  transactionExternalIdToPrintNext(result: boolean) {
    if (this.performanceId && this.transactionExternalIdToPrintCount != null) {
      this.transactionExternalIdToPrintHasError = false;
      if (result) {
        if (this.transactionExternalIdToPrintCount < this.transactionExternalIdsToPrint.length) {
          this.transactionExternalIdToPrint = null;
          this._cd.detectChanges();
          this.transactionExternalIdToPrint = this.transactionExternalIdsToPrint[this.transactionExternalIdToPrintCount];
          this._cd.detectChanges();
          this.transactionExternalIdToPrintCount++;
        } else {
          this.transactionExternalIdsToPrint = null;
          this.transactionExternalIdToPrintCount = null;
          this.transactionExternalIdToPrint = null;
          this.transactionExternalIdToPrintHasError = false;
          this.transactionExternalIdToPrintIsFinished = true;
        }
      } else {
        this.transactionExternalIdToPrintHasError = true;
      }
    } else {
      this.transactionExternalIdsToPrint = null;
      this.transactionExternalIdToPrintCount = null;
      this.transactionExternalIdToPrint = null;
      this.transactionExternalIdToPrintHasError = false;
      this.transactionExternalIdToPrintIsFinished = false;
    }
  }

  transactionExternalIdToPrintDownload() {
    this.transactionExternalIdToPrintDownloadRunning = true;
    this._http.post<Blob>(
      environment.urls.gateway + 'booking-transaction/transactions/custom/export',
      {
        transactionIds: this.transactionIdsToPrint,
      },
      {observe: 'response', responseType: 'blob' as 'json'}
    ).subscribe((response: HttpResponse<Blob>) => {
        const filename: string = this.getFileName(response);
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'blob'}));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.transactionExternalIdToPrintDownloadRunning = false;
      }
    );
  }

  getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('content-disposition');
      const matches = /filename="(.*?)"/g.exec(contentDisposition);
      filename = matches && matches.length > 1 ? matches[1] : this.performanceId + '.xlsx';
    } catch (e) {
      filename = this.performanceId + '.xlsx';
    }
    return filename;
  }

  transactionExternalIdToPrintRetry() {
    window.location.reload();
  }
}
