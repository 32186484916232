import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {HalUsersService} from '../../services/hal-users.service';
import {User} from '../../models/user';

@Component({
  selector: 'c360-users-add',
  templateUrl: './add.component.html'
})
export class UsersAddComponent {

  private _isLoading: boolean;
  private readonly _user: User = new User();

  /**
   *
   * @param {Router} _router
   * @param {HalUsersService} _halUsersService
   * @param {Location} _location
   */
  constructor(private readonly _router: Router,
              private readonly _halUsersService: HalUsersService,
              private readonly _location: Location) {
    this._isLoading = false;
  }

  /**
   *
   * @param {User} user
   */
  public onSumbit(user: User): void {
    this._isLoading = true;
    this._halUsersService
      .saveUser(user)
      .then((data) => {
          this._isLoading = false;
          this._router.navigate(['/', 'users', 'detail', data.id]);
        }, (error) => {
          this._isLoading = false;
        }
      );
  }

  /**
   *
   */
  public onCancel() {
    this._location.back();
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {User}
   */
  get user(): User {
    return this._user;
  }
}
