import {Hal} from '../../shared/models/hal';
import {Area} from './area.model';

export class AreaLayout extends Hal {

  public _links: any;
  public id: string;
  public name: string;
  public mandatorId: string;
  public auditoriumLayoutId: string;
  public areas: Array<Area>;

  constructor(_links?: any,
              id?: string,
              name?: string,
              mandatorId?: string,
              auditoriumLayoutId?: string,
              areas?: Array<Area>) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.auditoriumLayoutId = auditoriumLayoutId;
    this.name = name;
    this.areas = areas ? areas.map((area) => new Area(area.id, area.name, area.spaces)) : [];
  }

}
