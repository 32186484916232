import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CounterRoutingModule} from './counter-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CounterComponent} from './components/counter.component';
import {CounterDashboardComponent} from './components/dashboard/counter-dashboard.component';
import {CounterScheduleComponent} from './components/schedule/counter-schedule.component';
import {CounterTransactionsComponent} from './components/transactions/counter-transactions.component';
import {CounterConfirmationComponent} from './components/confirmation/counter-confirmation.component';
import {ScheduleService} from './services/schedule.service';
import {CounterResetTicketTypeModalComponent} from './components/confirmation/counter-reset-ticket-type-modal.component';
import {ConfirmationService} from './services/confirmation.service';
import {CounterSpioNumberCheckComponent} from './components/confirmation/counter-spio-number-check.component';
import {SalesCounterService} from './services/sales-counter.service';
import {FilmFilterPipe} from './pipes/film-filter.pipe';
import {CounterTransactionsListComponent} from './components/transactions/list/counter-transactions-list.component';
import {CounterTransactionsListItemComponent} from './components/transactions/list/counter-transactions-list-item.component';
import {CounterTransactionsFilterComponent} from './components/transactions/list/counter-transaction-search.component';
import {CounterTransactionRefundModalComponent} from './components/transactions/list/counter-transaction-refund-modal.component';
import {CounterTransactionUpdateTicketTypeModalComponent} from './components/transactions/list/counter-transaction-update-ticket-type-modal.component';
import {CounterTransactionsDetailComponent} from './components/transactions/detail/counter-transaction-detail.component';
import {CounterTransactionResendMailFormComponent} from './components/transactions/forms/counter-transaction-resend-mail-form.component';
import {HalCounterTransactionsService} from './services/hal-counter-transactions.service';
import {CounterTransactionsUpdateTicketTypeItemComponent} from './components/transactions/list/counter-transactions-update-ticket-type-item.component';
import {CounterScheduleItemComponent} from './components/schedule/films/counter-schedule-item.component';
import {CounterScheduleItemTodayComponent} from './components/schedule/days/counter-schedule-item-today.component';
import {CounterScheduleControlComponent} from './components/schedule/counter-schedule-control.component';
import {CounterScheduleListDayComponent} from './components/schedule/days/counter-schedule-list-day.component';
import {CounterScheduleListFilmComponent} from './components/schedule/films/counter-schedule-list-film.component';
import {CounterTransactionChargebackModalComponent} from './components/transactions/list/counter-transaction-chargeback-modal.component';
import {HalPaymentService} from './services/hal-payment.service';
import {VoucherStatusComponent} from './components/voucher-status/voucher-status.component';
import {VoucherListComponent} from './components/voucher-status/voucher-list.component';
import {VoucherListItemComponent} from './components/voucher-status/voucher-list-item.component';
import {VoucherToggleStatusModalComponent} from './components/voucher-status/voucher-toggle-status-modal.component';
import {CounterReportComponent} from './components/counter-report/counter-report.component';
import {CounterPrintTicketModalComponent} from './components/confirmation/counter-print-ticket-modal.component';
import {CounterConfirmationCartComponent} from './components/counter-confirmation-cart/counter-confirmation-cart.component';
import {CounterConfirmationCartItemComponent} from './components/counter-confirmation-cart/counter-confirmation-cart-item.component';
import {ConfirmRefundModalComponent} from './components/counter-confirmation-cart/confirm-refund-modal.component';
import {ConfirmPayModalComponent} from './components/counter-confirmation-cart/confirm-pay-modal.component';

const COMPONENTS = [
  CounterComponent,
  CounterDashboardComponent,
  CounterScheduleComponent,
  CounterScheduleControlComponent,
  CounterScheduleListDayComponent,
  CounterScheduleListFilmComponent,
  CounterScheduleItemComponent,
  CounterScheduleItemTodayComponent,
  CounterConfirmationComponent,
  CounterTransactionsComponent,
  CounterPrintTicketModalComponent,
  CounterResetTicketTypeModalComponent,
  CounterSpioNumberCheckComponent,
  CounterTransactionsListComponent,
  CounterTransactionsListItemComponent,
  CounterTransactionsDetailComponent,
  CounterTransactionsFilterComponent,
  CounterTransactionRefundModalComponent,
  CounterTransactionChargebackModalComponent,
  CounterTransactionResendMailFormComponent,
  CounterTransactionUpdateTicketTypeModalComponent,
  CounterTransactionsUpdateTicketTypeItemComponent,
  CounterReportComponent,
  VoucherStatusComponent,
  VoucherListComponent,
  VoucherListItemComponent,
  CounterConfirmationCartComponent,
  CounterConfirmationCartItemComponent,
  ConfirmRefundModalComponent,
  ConfirmPayModalComponent
];

const MODALS = [
  CounterPrintTicketModalComponent,
  CounterResetTicketTypeModalComponent,
  CounterSpioNumberCheckComponent,
  CounterTransactionRefundModalComponent,
  CounterTransactionUpdateTicketTypeModalComponent,
  CounterTransactionChargebackModalComponent,
  VoucherToggleStatusModalComponent,
  VoucherToggleStatusModalComponent,
  ConfirmRefundModalComponent,
  ConfirmPayModalComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...MODALS,
    FilmFilterPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CounterRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    NgbModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [],
  entryComponents: [
    ...MODALS
  ]
})
export class CounterModule {

  constructor(
    private translationLoader: TranslationLoaderService
  ) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(english, german);
  }

  static forRoot() {
    return {
      ngModule: CounterModule,
      providers: [
        ScheduleService,
        ConfirmationService,
        SalesCounterService,
        HalCounterTransactionsService,
        HalPaymentService
      ]
    };
  }
}
