import * as moment from 'moment';
import {Film} from '../../events/models/film.model';
import {Media} from '../../events/models/media.model';
import {MediaTypes} from '../../events/enums/media-types.enum';
import {
  ScheduleExportAttributes, ScheduleExportPerformance,
  ScheduleExportResponse
} from './schedule-export.response.models';


export class ScheduleExportEvent {
  id: string;
  name: string;
  type: string;
  typeMasterId: string;

  films: ScheduleExportFilm[] = [];

  constructor(event) {
    this.id = event.id;
    this.name = event.name;
    this.type = event.type;
    this.typeMasterId = event.typeMasterId;
    this.films = [];
  }
  private hasFilm(id) {
    const index = this.films.findIndex(film => film.id === id);
    return (index != null && index > -1);
  }
  public addFilm(film: ScheduleExportFilm) {
    if (!this.hasFilm(film.id)) {
      this.films.push(new ScheduleExportFilm(
        film._links,
        film.id,
        film.eventId,
        film.typeChildId,
        film.title,
        film.description,
        film.directors,
        film.actors,
        film.cameraOperators,
        film.cutters,
        film.books,
        film.runtime,
        film.ageRating,
        film.releaseDate,
        film.countryOfOrigin,
        film.creationYear,
        film.spokenLanguage,
        film.subtitleLanguage,
        film.websiteUrl,
        film.genres,
        film.publisher,
        film.trailer,
        film.presentationType,
        film.languageVersion,
        film.media
      ));
    }
  }

  public addPerformance(performance: ScheduleExportPerformance) {
    performance.startDate = moment(performance.startDate);

    const film = this.films.find(f => f.id === performance.filmId);
    if (film != null) {
      film.performances.push({
        id: performance.id,
        eventId: performance.eventId,
        filmId: performance.filmId,
        filmAgeRating: performance.filmAgeRating,
        auditoriumName: performance.auditoriumName,
        startDate: performance.startDate,
        attributeIds: performance.attributeIds,
        absoluteRuntime: performance.absoluteRuntime,
        bookingLink: performance.bookingLink,
        occupationStats: performance.occupationStats,
        status: performance.status
      });
    }
  }
}

export class ScheduleExportFilm extends Film {
  performances: ScheduleExportPerformance[];
  poster: Media;

  constructor(
    _links?: any,
    id?: string,
    eventId?: string,
    typeChildId?: string,
    title?: string,
    description?: string,
    directors?: { key: string, value: string }[],
    actors?: { key: string, value: string }[],
    cameraOperators?: { key: string, value: string }[],
    cutters?: { key: string, value: string }[],
    books?: { key: string, value: string }[],
    runtime?: number,
    ageRating?: string,
    releaseDate?: any,
    countryOfOrigin?: string,
    creationYear?: number,
    spokenLanguage?: string,
    subtitleLanguage?: string,
    websiteUrl?: string,
    genres?: { key: string, value: string }[],
    publisher?: string,
    trailer?: string,
    presentationType?: string,
    languageVersion?: string,
    media?: Array<{ url: string, type: string, description?: string }>
  ) {
    super(
      _links,
      id,
      eventId,
      typeChildId,
      title,
      description,
      directors,
      actors,
      cameraOperators,
      cutters,
      books,
      runtime,
      ageRating,
      releaseDate,
      countryOfOrigin,
      creationYear,
      spokenLanguage,
      subtitleLanguage,
      websiteUrl,
      genres,
      publisher,
      trailer,
      presentationType,
      languageVersion,
      media);
    this.performances = [];

    this.poster = this.media.find((m: Media) => m.type === MediaTypes.POSTER);
    if (this.poster == null) {
      this.poster = {
        type: MediaTypes.POSTER,
        url: '/assets/img/media_poster.png',
        description: ''
      };
    }
  }
}

export class ScheduleExportFilms {
  events: ScheduleExportEvent[] = [];
  attributes: {[id: string]: ScheduleExportAttributes} = {};

  constructor(scheduleExportResponse: ScheduleExportResponse) {
    this.parseEvents(scheduleExportResponse.eventMap);
    this.parseFilms(scheduleExportResponse.filmMap);
    this.parsePerformances(scheduleExportResponse.performanceMap);

    this.attributes = scheduleExportResponse.performanceAttributeMap;
  }

  private parseEvents(eventMap): void {
    Object.keys(eventMap).forEach(eventId => {
      this.events.push(new ScheduleExportEvent(eventMap[eventId]));
    });
  }

  private parseFilms(filmMap): void {
    Object.keys(filmMap).forEach(filmId => {
      const event = this.events.find(e => e.id === filmMap[filmId].eventId);
      if (event != null) {
        event.addFilm(filmMap[filmId]);
      }
    });
  }

  private parsePerformances(performanceMap: {[id: string]: ScheduleExportPerformance[]}): void {
    Object.keys(performanceMap).forEach(dayKey => {
      performanceMap[dayKey].forEach((performance: ScheduleExportPerformance) => {
        const event = this.events.find(e => e.id === performance.eventId);
        if (event != null) {
          event.addPerformance(performance);
        }
      });
    });
  }

}
