import {Injectable} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {HalData} from '../../shared/models/halData';
import {AreaLayoutHalData} from '../models/area-layout-hal-data';
import {AreaLayout} from '../models/area-layout.model';

@Injectable()
export class HalAreasService extends HalService {

  protected gatewayName = 'area';

  /**
   *
   * @param {number} page
   */
  getAllAreaLayouts(page: number = null) {
    this.getAll('areaLayouts', page);
  }

  /**
   *
   * @param {string} id
   * @returns {Promise<AreaLayout>}
   */
  getAreaLayout(id: string): Promise<AreaLayout> {
    return super.get(this._data['areaLayouts'], id);
  }

  /**
   *
   * @param response
   */
  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Srtip page params
      if (id === 'areaLayouts') {
        this._data[id] = new AreaLayoutHalData(id, link);
      } else {
        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   *
   * @param {AreaLayout} data
   * @returns {Promise<AreaLayout>}
   */
  saveAreaLayout(data: AreaLayout): Promise<AreaLayout> {
    return this.save(this._data['areaLayouts'], data);
  }

  /**
   *
   * @param {AreaLayout} data
   * @returns {Promise<any>}
   */
  updateAreaLayout(data: AreaLayout): Promise<any> {
    return this.update(this._data['areaLayouts'], data.id, data);
  }

  /**
   *
   * @param {AreaLayout} data
   * @returns {Promise<any>}
   */
  deleteAreaLayout(data: AreaLayout): Promise<any> {
    return this.delete(this._data['areaLayouts'], data.id);
  }

  streamAllAreaLayoutsByAuditoriumLayoutId(mandatorId: string,
                                           locationId: string,
                                           auditoriumLayoutId: string): Promise<{ [key: string]: AreaLayout }> {
    const url = `${this._data['areaLayouts'].href}/search/streamAllByMandatorIdAndLocationIdAndAuditoriumLayoutId?mandatorId=${mandatorId}&locationId=${locationId}&auditoriumLayoutId=${auditoriumLayoutId}`;

    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['areaLayouts'].parseToArrayObjects(response);
        return this._data['areaLayouts'].data;
      }
      return response;
    });
  }
}
