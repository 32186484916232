import {Page} from './page';
import {HalDataInterface} from './halData.interface';
import {isUndefined} from 'util';

export class HalData implements HalDataInterface {

  name: string;
  data: any;
  page: Page;
  href: string;

  constructor(name: string, href: string = null, page: Page = new Page(), data = {}) {
    this.name = name;
    this.href = href;
    this.page = page;
    this.data = data;
  }

  parseToArrayObjects(response: any) {
    return response;
  }

  parseToObject(response: any) {
    return response;
  }

  hasEntryWithId(id) {
    return (this.data[id] != null);
  }

  getEntryById(id) {
    if (isUndefined(this.data)) {
      return null;
    }
    return this.data[id] ? this.data[id] : null;
  }

  removeById(id) {
    if (!this.getEntryById(id)) {
      return;
    }
    delete this.data[id];
  }
}
