import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear
} from 'date-fns';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'c360-date-time-picker',
  templateUrl: 'date-picker.component.html',
  styles: [
      `
          .form-group {
              width: 100%;
          }
    `
  ]
})
export class DateTimePickerComponent implements OnInit {
  @Input() placeholder: string;
  @Input('date') dateTime: Date;
  @Output() onDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  date: Date;

  dateStruct: NgbDateStruct;

  timeStruct: NgbTimeStruct;

  datePicker: any;

  constructor(private cdr: ChangeDetectorRef) {
  }


  ngOnInit(): void {
    if (this.dateTime != null) {
      this.dateStruct = {
        day: getDate(this.dateTime),
        month: getMonth(this.dateTime) + 1,
        year: getYear(this.dateTime)
      };
      this.timeStruct = {
        second: getSeconds(this.dateTime),
        minute: getMinutes(this.dateTime),
        hour: getHours(this.dateTime)
      };
      this.cdr.detectChanges();
    }
  }

  updateDate(): void {
    const newDate: Date = setYear(
      setMonth(
        setDate(
          setHours(
            setMinutes(
              setSeconds(this.dateTime, this.timeStruct.second),
              this.timeStruct.minute
            ), this.timeStruct.hour
          ), this.dateStruct.day
        ),
        this.dateStruct.month - 1
      ),
      this.dateStruct.year
    );
    this.onDateChanged.emit(newDate);
  }
}
