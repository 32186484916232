import {debounceTime} from 'rxjs/operators';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'c360-events-films-filter',
  templateUrl: './filter.component.html'
})

export class EventsFilmsFilterComponent implements OnInit, OnDestroy {

  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  private _inputSearchEvents: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
    this._inputSearchEvents.pipe(
      debounceTime(500))
      .subscribe(
        (value) => {
          this.onSearch.emit(value);
        }
      );
  }

  ngOnDestroy(): void {
    this._inputSearchEvents.unsubscribe();
  }

  get inputSearchEvents(): EventEmitter<string> {
    return this._inputSearchEvents;
  }
}
