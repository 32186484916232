import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalUsersService} from '../hal-users.service';

@Injectable()
export class UsersRootLinkResolver implements Resolve<boolean> {

  constructor(private halUsersService: HalUsersService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this.halUsersService.getRootLink();
  }
}
