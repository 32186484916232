import {map} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PrinterService} from '../services/printer.service';
import {Injectable} from '@angular/core';
import {PrinterState} from '../enums/printer.enums';

@Injectable()
export class NotPrintingGuard implements CanActivate {

  constructor(private _printerService: PrinterService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._printerService.printerState$().pipe(map(printerState => {
      console.warn('STATE', printerState, printerState !== PrinterState.PRINTING);
      return printerState !== PrinterState.PRINTING;
    }));
  }
}
