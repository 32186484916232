import {Component} from '@angular/core';
import {Event} from '../../models/event.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: '[c360-event-delete-modal]',
  templateUrl: './event-delete-modal.component.html'
})
export class EventDeleteModalComponent {

  private _event: Event;

  constructor(private _activeModal: NgbActiveModal) {
  }

  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  get event(): Event {
    return this._event;
  }

  set event(value: Event) {
    this._event = value;
  }
}
