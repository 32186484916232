export const locale_base_de = {
  'lang': 'de',
  'data': {
    'MODUL_EVENTS': {
      'NAME': 'Events',
      'COMPONENT_EVENTS_LIST': {
        'NAME': 'Master-Film',
        'ADD': 'Master-Film hinzufügen',
        'SEARCH': 'Suchen',
        'FILTER': 'Filtern',
        'FILTER_OPTIONAL': 'Optionale Filter',
      },
      'COMPONENT_FILMS_LIST': {
        'NAME': 'Filme'
      },
      'COMPONENT_ADD': {
        'NAME': 'Master-Film'
      },
      'COMPONENT_DETAIL': {
        'NAME': 'Event/Film Editor',
        'INPUT_ADD': '+',
      },
      'MODEL': {
        'FILM': {
          'ID': 'Id',
          'TYPE-CHILD-ID': 'Type Child ID',
          'EVENTID': 'Eventid',
          'TYPECHILDID': 'Typechildid',
          'TITLE': 'Titel',
          'DESCRIPTION': 'Filmbeschreibung',
          'DIRECTORS': 'Regisseure',
          'DIRECTORS_ADD': 'Regisseure hinzufügen ',
          'ACTORS': 'Darsteller',
          'ACTORS_ADD': 'Darsteller hinzufügen',
          'CAMERAOPERATORS': 'Kamera',
          'CAMERAOPERATORS_ADD': 'Kamera hinzufügen',
          'CUTTERS': 'Schnitt',
          'CUTTERS_ADD': 'Schnitt hinzufügen',
          'BOOKS': 'Buch',
          'BOOKS_ADD': 'Buch hinzufügen',
          'RUNTIME': 'Laufzeit',
          'AGERATING': 'FSK',
          'AGERATINGS': {
            'RELEASE_FROM_0_YEARS': 'FSK ab 0 Jahre',
            'RELEASE_FROM_6_YEARS': 'FSK ab 6 Jahre',
            'RELEASE_FROM_12_YEARS': 'FSK ab 12 Jahre',
            'RELEASE_FROM_16_YEARS': 'FSK ab 16 Jahre',
            'RELEASE_FROM_18_YEARS': 'FSK ab 18 Jahre',
          },
          'RELEASEDATA': 'Starttermin',
          'COUNTRYOFORIGIN': 'Land',
          'CREATIONYEAR': 'Jahr',
          'SPOKENLANGUAGE': 'Sprache',
          'SUBTITLELANGUAGE': 'Untertitel',
          'WEBSITEURL': 'URL',
          'GENRES': 'Genre',
          'GENRES_ADD': 'Genre hinzufügen',
          'PUBLISHER': 'Publisher',
          'PRESENTATION-TYPE': 'Präsentationstyp',
          'LANGUAGE-VERSION': 'Sprachversion',
          'MEDIA': {
            'POSTER': 'Filmplakat',
            'DESIGN': 'Szenenbild',
            'TRAILER_YOUTUBE': 'Youtube'
          }
        }
      },
      'ERRORS': {
        'REQUIRED': 'Es ist ein ppflichtfeld.',
        'ALPHANUMERIC': 'Muss alphanumerisch sein.',
        'NUMERIC': 'Muss numerisch sein.',
        'MIME_TYPE': 'Daten Typ nicht erlaubt.'
      },
      'LANGUAGE_VERSION': {
        'OV': 'Originalsprache',
        'OMU': 'Originalsprache mit Untertitel',
        'GERMAN': 'Deutsche Sprachfassung'
      },
      'PRESENTATION_TYPE': {
        'TYPE_2D': '2D',
        'TYPE_3D': '3D'
      }
    }
  }
};
