import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventsComponent} from './components/events.component';
import {EventsDetailComponent} from './components/detail/detail.component';
import {EventRootLinkResolver} from './services/resolver/event-root-link.resolver';
import {EventDetailResolver} from './services/resolver/event-detail.resolver';
import {EventFilmsResolver} from './services/resolver/event-films.resolver';
import {EventsAddComponent} from './components/add/add.component';
import {EventsListComponent} from './components/events-list/events-list.component';
import {FilmsListComponent} from './components/films-list/films-list.component';

const appRoutes: Routes = [
  {
    path: '',
    component: EventsComponent,
    resolve: {
      rootLinks: EventRootLinkResolver
    },
    data: {
      breadcrumb: false
    },
    children: [
      {
        path: '',
        redirectTo: 'films/list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: EventsListComponent
      },
      {
        path: 'add',
        component: EventsAddComponent,
        data: {
          breadcrumb: 'Add'
        }
      },
      {
        path: 'detail/:id',
        component: EventsDetailComponent,
        resolve: {
          event: EventDetailResolver,
          films: EventFilmsResolver
        },
        data: {
          breadcrumb: 'Detail'
        }
      },
      {
        path: 'detail/:id/:filmId',
        component: EventsDetailComponent,
        resolve: {
          event: EventDetailResolver,
          films: EventFilmsResolver
        },
        data: {
          breadcrumb: 'Detail'
        }
      },
      {
        path: 'films/list',
        component: FilmsListComponent,
        data: {
          breadcrumb: 'Films'
        }
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  providers: [
    EventRootLinkResolver,
    EventDetailResolver,
    EventFilmsResolver
  ],
  exports: [
    RouterModule
  ]
})
export class EventsRoutingModule {
}

