import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TransactionsComponent} from './components/transactions.component';
import {TransactionsListComponent} from './components/list/transactions-list.component';
import {TransactionsListItemComponent} from './components/list/transactions-list-item.component';
import {TransactionsDetailComponent} from './components/detail/transaction-detail.component';
import {TransactionsFilterComponent} from './components/list/transaction-search.component';
import {TransactionRefundModalComponent} from './components/list/transaction-refund-modal.component';
import {TransactionResendMailFormComponent} from './components/forms/transaction-resend-mail-form.component';
import {SepaListComponent} from './components/sepa/list/sepa-list.component';
import {SepaListItemComponent} from './components/sepa/list/sepa-list-item.component';
import {SepaDetailComponent} from './components/sepa/detail/sepa-detail.component';
import {SepaExportModalComponent} from './components/sepa/list/sepa-export-modal.component';
import {SepaListItemExportedComponent} from './components/sepa/list/sepa-list-item-exported.component';
import {HalTransactionsService} from './services/hal-transactions.service';
import {TransactionsRoutingModule} from './transactions-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HalSepaService} from './services/hal-sepa.service';
import {TransactionUpdateTicketTypeModalComponent} from './components/list/transaction-update-ticket-type-modal.component';
import {TransactionsUpdateTicketTypeItemComponent} from './components/list/transactions-update-ticket-type-item.component';
import {TransactionChargebackModalComponent} from './components/list/transaction-chargeback-modal.component';
import {ReportsFilterComponent} from './components/reports/reports-filter.component';
import {ReportsComponent} from './components/reports/reports.component';
import {TransactionPaymentEditModalComponent} from './components/detail/transaction-payment-edit-modal.component';
import {NgxMaskModule} from 'ngx-mask';

const COMPONENTS = [
  TransactionsComponent,
  TransactionsListComponent,
  TransactionsListItemComponent,
  TransactionsDetailComponent,
  TransactionsFilterComponent,
  TransactionResendMailFormComponent,
  SepaListComponent,
  SepaListItemComponent,
  SepaDetailComponent,
  SepaListItemExportedComponent,
  TransactionsUpdateTicketTypeItemComponent,
  ReportsComponent,
  ReportsFilterComponent,
];

const MODALS = [
  TransactionPaymentEditModalComponent,
  SepaExportModalComponent,
  TransactionRefundModalComponent,
  TransactionUpdateTicketTypeModalComponent,
  TransactionChargebackModalComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...MODALS
  ],
  imports: [
    CommonModule,
    FormsModule,
    TransactionsRoutingModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
    NgbModule,
    NgxMaskModule.forChild()
  ],
  exports: [
    ...COMPONENTS,
    TransactionsRoutingModule
  ],
  providers: [
    HalTransactionsService,
    HalSepaService
  ],
  entryComponents: [
    ...MODALS
  ]
})
export class TransactionsModule {

  constructor(
    private translationLoader: TranslationLoaderService
  ) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(english, german);
  }
}
