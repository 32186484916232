<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h1>{{ 'MODUL_ADMIN.NAME' | translate}}</h1>
    </div>
    <div class="card-body">
      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab *ngFor="let service of serviceKeys" [title]="service" [id]="service">
          <ng-template ngbTabContent>
            <div class="embed-responsive embed-responsive-4by3">
              <iframe [title]="service" [src]="serviceUrls[service]" class="embed-responsive-item"></iframe>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>
