import {Hal} from '../../shared/models/hal';

export class Role extends Hal {

  public id: number;
  public name: string;

  constructor(
    _links?: any,
    id?: number,
    name?: string,
  ) {
    super(_links);

    this.id = id;
    this.name = name;
  }

}
