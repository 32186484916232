import {HalData} from '../../shared/models/halData';
import {SepaFile} from './sepa-file.model';

export class SepaFileHalData extends HalData {

  data: Array<SepaFile>;

  /**
   * Parse to Array Object
   *
   * @param response
   */
  parseToArrayObjects(response: any) {
    this.data = [];
    response._embedded.transactionSepaFiles.forEach((sepaFile: SepaFile) => {

      this.parseToObject(sepaFile);
    });

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  /**
   * Parse to Object
   *
   * @param response
   * @returns {SepaFile}
   */
  parseToObject(response: any): SepaFile | void {
    if (response != null) {

      const sepaFile = new SepaFile(
        response._links,
        response.id,
        response.mandatorId,
        response.locationId,
        response.cdnReference,
        response.fileName,
        response.date
      );

      this.data.push(sepaFile);
      return sepaFile;
    }
  }
}
