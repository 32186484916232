import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {v4 as uuid} from 'uuid';
import {isString} from 'util';
import {environment} from '../../../../environments/environment';
import {UserGenders} from '../../enums/user-genders.enum';
import {UserRoles} from '../../enums/user-roles.enum';
import {Countries} from '../../../shared/enums/countries.enum';
import {HalMandatorsService} from '../../../locations/services/hal-mandators.service';
import {HalLocationsService} from '../../../locations/services/hal-locations.service';
import {UserService} from '../../../login/services/user.service';
import {ValidationService} from '../../../shared/services/validation.service';
import {Location} from '../../../locations/models/location.model';
import {User} from '../../models/user';
import {Mandator} from '../../../locations/models/mandator.model';

@Component({
  selector: 'c360-user-details-form',
  templateUrl: './user-details.form.html'
})
export class UserDetailsFormComponent implements OnInit {

  private _isLoading: boolean;

  @Input() user: User;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  private _formMode: string = null;
  private _userForm: FormGroup;

  /* Genders Enum */
  private _genders = UserGenders;
  private _genderKeys;

  /* Roles Enum */
  private _roles = UserRoles;
  private _rolesKeys;

  /* Countries Enum */
  private _countries = Countries;
  private _countriesKeys;

  private _userRoleIndex: number;
  private _passwordGenerated: boolean;

  private _mandatorList: { [key: string]: Mandator };
  private _mandatorListKey;
  private _mandator: Mandator;
  private _selectMandatorByRoles = [
    'ROLE_MANDATOR',
    'ROLE_LOCATION',
    'ROLE_SALES_COUNTER',
    'ROLE_PRE_SALES_COUNTER',
    'ROLE_TICKET_VALIDATOR',
    'ROLE_CUSTOMER',
  ];

  private _locationList: { [key: string]: Location };
  private _locationListKey;
  private _location: Location;
  private _selectLocationByRoles = [
    'ROLE_LOCATION',
    'ROLE_SALES_COUNTER',
    'ROLE_PRE_SALES_COUNTER',
    'ROLE_TICKET_VALIDATOR',
  ];

  /**
   *
   * @param {FormBuilder} _formBuilder
   * @param {UserService} _userService
   * @param {HalMandatorsService} _halMandatorsService
   * @param {HalLocationsService} _halLocationsService
   */
  constructor(private readonly _formBuilder: FormBuilder,
              private readonly _userService: UserService,
              private readonly _halMandatorsService: HalMandatorsService,
              private readonly _halLocationsService: HalLocationsService) {
    this._isLoading = false;
    this._passwordGenerated = false;

    this._genderKeys = Object.keys(this._genders);
    this._rolesKeys = Object.keys(this._roles);
    this._countriesKeys = Object.keys(this._countries);

    this._userRoleIndex = environment.userPermissionOrder.indexOf(this._userService.getUser().authorityBase);
  }

  ngOnInit(): void {
    this._formMode = 'create';
    if (this.user && this.user.id != null) {
      this._formMode = 'update';
    }

    this._isLoading = true;

    if (!this.existsMandatorId) {
      this._halMandatorsService
        .streamAllMandators()
        .then((tmpMandatorData) => {
          this._mandatorList = tmpMandatorData;
          this._mandatorListKey = Object.keys(this._mandatorList);
          if (this.user.mandatorId) {
            this._halLocationsService
              .streamAllLocationsByMandatorId(this.user.mandatorId)
              .then((tmpLocationData) => {
                this._locationList = tmpLocationData;
                this._locationListKey = Object.keys(this._locationList);
                this.createForm();
              });
          } else {
            this.createForm();
          }
        });
    } else {
      this._halMandatorsService
        .getMandatorById(this._userService.getUser().mandatorId)
        .then(
          (tmpMandatorData) => {
            this._mandator = tmpMandatorData;

            if (!this.existsLocationId) {
              this._halLocationsService
                .streamAllLocationsByMandatorId(this._userService.getUser().mandatorId)
                .then((tmpLocationData) => {
                  this._locationList = tmpLocationData;
                  this._locationListKey = Object.keys(this._locationList);
                  this.createForm();
                });
            } else {
              this._halLocationsService
                .getLocation(this._userService.getUser().locationId)
                .then(
                  (tmpLocationData) => {
                    this._location = tmpLocationData;
                    this.createForm();
                  },
                  () => {

                  }
                );
            }
          },
          () => {

          }
        );
    }
  }

  /**
   *
   */
  private createForm(): void {
    this._isLoading = false;
    let idDefinition = [];
    let passwordDefinition = [];
    if (this._formMode === 'create') {
      passwordDefinition = [
        null,
        [
          Validators.required,
          ValidationService.securePassword
        ]
      ];
    } else {
      idDefinition = [
        this.user.id,
        Validators.required
      ];
    }

    const selectedRole = environment.userPermissionOrder.find((item) => this.user.role && item === this.user.role);
    this._userForm = this._formBuilder.group({
      id: idDefinition,
      passwordPlain: passwordDefinition,
      role: [
        selectedRole ? selectedRole : null,
        Validators.required
      ],
      accountEnabled: [
        this.user.accountEnabled ? this.user.accountEnabled : false
      ],
      mandatorId: [
        this.user.mandatorId
      ],
      locationId: [
        this.user.locationId
      ],
      externalId: [
        this.user.externalId ? this.user.externalId : uuid()
      ],
      accountExpiryDate: [
        this.user.accountExpiryDate
      ],
      accountLocked: [
        this.user.accountLocked ? this.user.accountLocked : false
      ], // hidden
      emailAddress: [
        this.user.emailAddress,
        [
          Validators.required,
          ValidationService.emailValidator,
        ]
      ],
      emailAddressApproved: [
        this.user.emailAddressApproved ? this.user.emailAddressApproved : false
      ],
      passwordExpiryDate: [
        this.user.passwordExpiryDate
      ],
      gender: [
        this.user.gender,
        Validators.required
      ],
      firstName: [
        this.user.firstName,
        Validators.required
      ],
      lastName: [
        this.user.lastName,
        Validators.required
      ],
      phoneNumber: [
        this.user.phoneNumber
      ],
      mobileNumber: [
        this.user.mobileNumber
      ],
      streetName: [
        this.user.streetName,
        Validators.required
      ],
      houseNumber: [
        this.user.houseNumber,
        Validators.required
      ],
      postCode: [
        this.user.postCode,
        Validators.required
      ],
      cityName: [
        this.user.cityName,
        Validators.required
      ],
      country: [
        this.user.country,
        Validators.required
      ]
    });

    if (this._formMode === 'update') {
      this._userForm.get('emailAddress').disable();
    }

    this._userForm
      .get('role')
      .valueChanges
      .subscribe(
        (value) => {
          if (this.selectLocationByRolesAllowed(value)) {
            if (this.existsLocationId) {
              this._userForm.get('locationId').setValue(this._userService.getUser().locationId);
            }
            this._userForm.get('locationId').setValidators(Validators.required);
            this._userForm.get('locationId').updateValueAndValidity();
          } else {
            this._userForm.get('locationId').setValue(null);
            this._userForm.get('locationId').clearValidators();
            this._userForm.get('locationId').updateValueAndValidity();
          }

          if (this.selectMandatorByRolesAllowed(value)) {
            if (this.existsMandatorId) {
              this._userForm.get('mandatorId').setValue(this._userService.getUser().mandatorId);
            }
            this._userForm.get('mandatorId').setValidators(Validators.required);
            this._userForm.get('mandatorId').updateValueAndValidity();
          } else {
            this._userForm.get('mandatorId').setValue(null);
            this._userForm.get('mandatorId').clearValidators();
            this._userForm.get('mandatorId').updateValueAndValidity();
          }
        }
      );

    this._userForm
      .get('mandatorId')
      .valueChanges
      .subscribe(
        (value) => {
          if (!this.existsLocationId) {
            this._halLocationsService
              .streamAllLocationsByMandatorId(value)
              .then((data) => {
                this._isLoading = false;
                this._locationList = data;
                this._locationListKey = Object.keys(this._locationList);
              });
          }
        }
      );
  }

  /**
   *
   * @param {FormGroup} formGroup
   */
  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        // control.updateValueAndValidity();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   *
   * @param {number} length
   * @returns {string}
   */
  private randomPassword(length: number = 8): string {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890';
    const result = (new Array(8).fill('').map((val, index) => chars.charAt(Math.floor(Math.random() * chars.length))).join(''));
    if (ValidationService.securePassword(new FormControl(result)) !== null) {
      return this.randomPassword(length);
    }
    return result;
  }

  /**
   *
   */
  public generatePassword(): void {
    this._userForm.get('passwordPlain').setValue(this.randomPassword(8));
    this._passwordGenerated = true;
  }

  /**
   *
   * @param {string} action
   */
  public submitForm(action?: string) {
    if (this._userForm.valid) {
      this.onFormSubmit.emit(this._userForm.value);
    } else {
      this.validateAllFormFields(this._userForm);
    }
  }

  /**
   *
   */
  public cancel(): void {
    this.onCancel.emit();
  }

  public selectLocationByRolesAllowed(value: string): boolean {
    if (value === null) {
      return false;
    }
    return this._selectLocationByRoles.indexOf(value) !== -1;
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {FormGroup}
   */
  get userForm(): FormGroup {
    return this._userForm;
  }

  /**
   *
   * @returns {UserGenders}
   */
  get genders() {
    return this._genders;
  }

  /**
   *
   * @returns {any}
   */
  get genderKeys() {
    return this._genderKeys;
  }

  /**
   *
   * @returns {Countries}
   */
  get countries() {
    return this._countries;
  }

  /**
   *
   * @returns {any}
   */
  get countriesKeys() {
    return this._countriesKeys;
  }

  /**
   *
   * @returns {boolean}
   */
  get passwordGenerated(): boolean {
    return this._passwordGenerated;
  }

  /**
   *
   * @param {boolean} value
   */
  set passwordGenerated(value: boolean) {
    this._passwordGenerated = value;
  }

  /**
   *
   * @returns {Array<string>}
   */
  get roleList(): Array<string> {
    return environment.userPermissionOrder;
  }

  /**
   *
   * @returns {number}
   */
  get userRoleIndex(): number {
    return this._userRoleIndex;
  }

  /**
   *
   * @returns {{[p: string]: Mandator}}
   */
  get mandatorList(): { [p: string]: Mandator } {
    return this._mandatorList;
  }

  /**
   *
   * @returns {any}
   */
  get mandatorListKey() {
    return this._mandatorListKey;
  }

  /**
   *
   * @returns {boolean}
   */
  get existsMandatorId(): boolean {
    return this._userService.getUser().mandatorId && isString(this._userService.getUser().mandatorId);
  }

  /**
   *
   * @returns {{[p: string]: Location}}
   */
  get locationList(): { [p: string]: Location } {
    return this._locationList;
  }

  /**
   *
   * @returns {any}
   */
  get locationListKey() {
    return this._locationListKey;
  }

  /**
   *
   * @returns {boolean}
   */
  get existsLocationId(): boolean {
    return this._userService.getUser().locationId && isString(this._userService.getUser().locationId);
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoggedInUser(): boolean {
    return this._userService.getUser().externalId === this.user.externalId;
  }

  /**
   *
   * @returns {Location}
   */
  get location(): Location {
    return this._location;
  }

  /**
   *
   * @returns {boolean}
   */
  public selectMandatorByRolesAllowed(value: string): boolean {
    if (value === null) {
      return false;
    }
    return this._selectMandatorByRoles.indexOf(value) !== -1;
  }

  /**
   *
   * @returns {Mandator}
   */
  get mandator(): Mandator {
    return this._mandator;
  }
}
