import {locale_base_en} from '../en';
import {mergeDeep} from '../../../shared/utils';

const locale_client_en = {
  'data': {
    'MODUL_DASHBOARD' : {
      'NAME': 'Dashboard'
    }
  }
};

export const english = mergeDeep({}, locale_client_en, locale_base_en);
