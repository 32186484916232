import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Film} from '../../models/film.model';

@Component({
  selector: '[c360-film-delete-modal]',
  templateUrl: './film-delete-modal.component.html'
})
export class FilmDeleteModalComponent {

  private _film: Film;

  constructor(private _activeModal: NgbActiveModal) {
  }

  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  get film(): Film {
    return this._film;
  }

  set film(value: Film) {
    this._film = value;
  }
}
