import {concatMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {S3authSignatureModel} from '../../login/models/s3auth-signature.model';
import {Media} from '../../events/models/media.model';

@Injectable()
export class AWSService {

  /**
   *
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {
  }

  /**
   *
   * @returns {Observable<S3authSignatureModel>}
   */
  public s3authSignature(path?: string): Observable<S3authSignatureModel> {
    let url = `${environment.urls.gateway}${environment.s3.authUrl}`;
    if (path) {
      url = `${url}?path=${path}`;
    }
    return this.http
      .get(url).pipe(
      map((response: any) => {
        return new S3authSignatureModel(
          <string>response.base64Policy,
          <string>response.signature,
          <string>response.accessKeyID,
          <string>response.awsRegion,
          <string>response.awsService,
          <string>response.successActionRedirect,
          <string>response.bucket,
          <string>response.uuid,
          <string>response.key,
          <string>response.amzDate,
          <string>response.absoluteUploadUrl,
        );
      }));
  }

  /**
   *
   * @param {Media} media
   * @returns {Observable<any>}
   */
  public deleteMedia(media: Media, path?: string): Observable<any> {
    return this.s3authSignature(path).pipe(
      concatMap((s3authSignature: S3authSignatureModel) => {
        const fileName = media.url.split('/').slice(-1).pop();
        let url = `${environment.urls.gateway}${environment.s3.deleteUrl}${fileName}`;
        if (s3authSignature.key) {
          url = `${environment.urls.gateway}${environment.s3.deleteUrl}${s3authSignature.key}${fileName}`;
        }
        return this.http.delete(url, {
          params: {
            bucketName: 'public'
          }
        });
      }));
  }
}
