<td class="pl-0 pt-3" width="200">
  <img [src]="cdnImageEnvironment.imageUrl(film.poster.url)+'?tr=w-200'">
  <div class="trivia-and-ratings">
    <div class="d-block">
      <span class="genres">{{film.genres}}</span>
    </div>
    <div class="d-block">
      <span class="country-and-year" *ngIf="(film.countryOfOrigin && film.creationYear)">{{film.countryOfOrigin}} {{film.creationYear}}</span>
      <span class="publisher" *ngIf="film.publisher">{{film.publisher}}</span>
    </div>
    <div class="d-block">
      <span class="runtime" *ngIf="film.runtime">{{film.runtime}} Minuten </span>
    </div>
    <div class="d-block">
      <span class="rating" *ngIf="film.ageRating">{{'MODUL_EVENTS.MODEL.FILM.AGERATINGS.' + film.ageRating | translate}}</span>
    </div>
    <div class="d-block">
      [<span class="type" *ngIf="film.presentationType" [translate]="'MODUL_EVENTS.PRESENTATION_TYPE.' + film.presentationType"></span> /
      <span class="version" *ngIf="film.languageVersion" [translate]="'MODUL_EVENTS.LANGUAGE_VERSION.' + film.languageVersion"></span>]
    </div>
  </div>
</td>
<td class="pr-0">
  <table class="table table-striped table--selectable mb-0">
    <thead>
      <th>Datum</th>
      <th>Uhrzeit</th>
      <th>Kino</th>
      <th>Freie Plätze</th>
    </thead>
    <tbody>
      <tr *ngFor="let performance of film.performances | orderBy: 'startDate'" (click)="gotoBooking(performance)">
        <td>{{performance.startDate.format('dd., DD.MM.YYYY')}}</td>
        <td>{{performance.startDate.format('HH:mm')}}</td>
        <td>{{performance.auditoriumName}}</td>

        <ng-container *ngIf="performance.status !== performanceStatus.SOLD_OUT">
          <td *ngIf="performance.occupationStats" ngClass="{{getClassForPercentageOfFreeSeats(performance)}}">{{performance.occupationStats.free + performance.occupationStats.selectedFree + performance.occupationStats.blocked + performance.occupationStats.selectedBlocked}}</td>
          <td *ngIf="!performance.occupationStats">N/A</td>
        </ng-container>
        <ng-container *ngIf="performance.status === performanceStatus.SOLD_OUT">
          <td><img src="/assets/img/sold-out.png"></td>
        </ng-container>

      </tr>
    </tbody>
  </table>
</td>
