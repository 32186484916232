import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {SideNavigationComponent} from './components/navigation-side.component';
import {TopNavigationComponent} from './components/navigation-top.component';
import {FooterNavigationComponent} from './components/navigation-footer.component';
import {BreadcrumbComponent} from './components/breadcrumb.component';
import {UserMenuIconComponent} from './components/user-menu-icon.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CounterModule} from '../counter/counter.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    TopNavigationComponent,
    SideNavigationComponent,
    BreadcrumbComponent,
    FooterNavigationComponent,
    UserMenuIconComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule.forChild(),
    NgbModule,
    CounterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    TopNavigationComponent,
    SideNavigationComponent,
    BreadcrumbComponent,
    FooterNavigationComponent,
    UserMenuIconComponent
  ],
  providers: []
})
export class NavigationModule {

  constructor(
    private translationLoader: TranslationLoaderService
  ) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }

}
