import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './core/components/not-found/not-found.component';
import {DashboardComponent} from './dashboard/components/dashboard.component';
import {LoginGuard} from './login/guards/login.guard';
import {LoginComponent} from './login/components/login.component';
import {InitComponent} from './init/components/init.component';
import {InitGuard} from './init/guards/init.guard';
import {AuthorizationGuard} from './login/guards/authorization.guard';
import {AdminboardComponent} from './admin/components/adminboard/adminboard.component';
import {TokenRefreshGuard} from './login/guards/token-refresh.guard';
import {MandatorsRootLinkResolver} from './locations/services/resolver/mandators-root-link.resolver';
import {LocationsRootLinkResolver} from './locations/services/resolver/locations-root-link.resolver';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'init',
    pathMatch: 'full',
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard]
  },
  {
    path: 'init',
    component: InitComponent,
    data: {
      breadcrumb: ''
    },
    resolve: {
      MandatorsRootLinkResolver,
      LocationsRootLinkResolver
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: ''
    },
  },
  {
    path: 'adminboard',
    component: AdminboardComponent,
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: 'Events'
    }
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: 'Users'
    },
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: 'Locations'
    },
  },
  {
    path: 'performances',
    loadChildren: () => import('./performances/performances.module').then(m => m.PerformancesModule),
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: 'Performances'
    },
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: 'Transactions'
    },
  },
  {
    path: 'counter',
    loadChildren: () => import('./counter/counter.module').then(m => m.CounterModule),
    canActivate: [InitGuard, LoginGuard, AuthorizationGuard, TokenRefreshGuard],
    data: {
      breadcrumb: 'Kasse'
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [InitGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      breadcrumb: ''
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
