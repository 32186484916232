export class Alert {

  constructor(
    private title: string = 'Error',
    private code: string = 'FE-CMS-001',
    private messages: Array<string> = ['Unexpected Error'],
    private dismissable: boolean = true,
    private type: string = 'danger',
  ) {}
}
