export enum SpaceType {
  STANDING_PLACE = 'STANDING_PLACE',
  /** Seat normal space type. */
  SEAT_NORMAL = 'SEAT_NORMAL',
  /** Seat couch space type. */
  SEAT_COUCH = 'SEAT_COUCH',
  /** Seat wheelchair space type. */
  SEAT_WHEELCHAIR = 'WHEELCHAIR',

  /**
  REGULAR = 1,
  WHEELCHAIR= 3,
  HOUSESEAT = 4,
  REMOVABLE = 5,
  AISLE = 0,

  // CUSTOMS - INTERNAL USE ONLY
  LOVECHAIRRIGHT = 'LOVECHAIRRIGHT',
  LOVECHAIRLEFT  = 'LOVECHAIRLEFT',
  WHEELCHAIRCOMPANION = 'WHEELCHAIRCOMPANION', // not yet available
  UNKNOWN = 'UNKNOWN'
  */
}
