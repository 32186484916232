import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'c360-auditoriums',
  templateUrl: './auditoriums.component.html',
  styleUrls: ['./auditoriums.component.scss']
})
export class AuditoriumsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
