import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Transaction} from '../../../models/transaction/transaction.model';
import {TicketStatus} from '../../../enums/ticket-status.enum';
import {TransactionTicket} from '../../../models/transaction/transaction-ticket.model';

@Component({
  selector: 'c360-counter-transaction-refund-modal',
  templateUrl: './counter-transaction-refund-modal.component.html'
})
export class CounterTransactionRefundModalComponent implements OnInit {

  private _tickets: [string];
  private _transaction: Transaction;
  transactionResponseStatusOK: any;
  transactionResponseStatusFail: any;
  isMandatoryRefundConfirmation: any = false;
  isMandatoryRefundSwitch: any = true;
  isTicketSelectedForRefund: any = true;

  private _selectAllTickets: any = true;

  public skipRefundFee = true;
  TicketStatus = TicketStatus;

  /**
   *
   * @param {NgbActiveModal} _activeModal
   * @param {TranslateService} _translationService
   */
  constructor(private _activeModal: NgbActiveModal,
              private _translationService: TranslateService) {
  }

  /**
   * OnInit
   */
  ngOnInit(): void {


    if (this._transaction.skipRefundFee != null) {
      this.skipRefundFee = this._transaction.skipRefundFee;
    }

    if (this.selectAllTickets) {

      this._transaction.tickets.forEach((ticket) => {

        ticket.selectedForRefund = true;
      });

      this.isTicketSelectedForRefund = true;
    }
  }

  /**
   * Refund Tickets
   */
  refund(): void {

    const _ticketIds = [];

    this._transaction.tickets.forEach((ticket) => {

      if (ticket.selectedForRefund &&
        (ticket.status === TicketStatus.BOOKING_COMPLETED ||
          ticket.status === TicketStatus.REFUND_FAILED ||
          ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
          ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
          ticket.status === TicketStatus.LOCAL_INVALIDATED ||
          ticket.status === TicketStatus.PRICE_CHANGE_FAILED)) {

        this.isTicketSelectedForRefund = false;
        _ticketIds.push(ticket.id);
      }
    });

    const data = {
      'isOK': true,
      'tickets': _ticketIds,
      'skipRefundFee': this.skipRefundFee,
      'isMandatoryRefundConfirmation': this._transaction.isSepaFileGeneratedForTransaction
    };

    this._activeModal.close(data);
  }

  /**
   * OnClickAllTickets
   */
  onClickAllTickets(): void {

    this._transaction.tickets.forEach((ticket) => {

      ticket.selectedForRefund = this._selectAllTickets;
    });

    this.isTicketSelectedForRefund = this._selectAllTickets;
  }

  /**
   * onClickTicket
   */
  onClickTicket(): void {

    let _selected: any = true;

    this.isTicketSelectedForRefund = false;

    this._transaction.tickets.forEach((ticket) => {

      if (ticket.status === TicketStatus.BOOKING_COMPLETED ||
        ticket.status === TicketStatus.REFUND_FAILED ||
        ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
        ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
        ticket.status === TicketStatus.LOCAL_INVALIDATED ||
        ticket.status === TicketStatus.PRICE_CHANGE_FAILED) {

        if (!ticket.selectedForRefund) {

          _selected = false;
        } else {

          this.isTicketSelectedForRefund = true;
        }
      }
    });

    this._selectAllTickets = _selected;
  }

  /**
   * isTicketRefundable
   * @param {TransactionTicket} ticket
   * @returns {boolean}
   */
  isTicketRefundable(ticket: TransactionTicket): boolean {

    if (ticket.status === TicketStatus.BOOKING_COMPLETED ||
      ticket.status === TicketStatus.REFUND_FAILED ||
      ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
      ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
      ticket.status === TicketStatus.LOCAL_INVALIDATED ||
      ticket.status === TicketStatus.PRICE_CHANGE_FAILED) {

      return true;
    }

    return false;
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  /**
   * Sets mandatoyRefundConfirmation
   */
  public setMandatoryRefundConfirmation(): void {

    this.isMandatoryRefundSwitch = !this.isMandatoryRefundSwitch;
    this.isMandatoryRefundConfirmation = this.isMandatoryRefundSwitch;
  }

  /**
   * Gets tickets
   * @returns {[string]}
   */
  get tickets(): [string] {
    return this._tickets;
  }

  /**
   * Sets tickets
   * @param {[string]} value
   */
  set tickets(value: [string]) {
    this._tickets = value;
  }

  /**
   * Gets transaction
   * @returns {Transaction}
   */
  get transaction(): Transaction {
    return this._transaction;
  }

  /**
   * Sets transaction
   * @param {Transaction} value
   */
  set transaction(value: Transaction) {
    this._transaction = value;
    this.isMandatoryRefundConfirmation = this._transaction.isSepaFileGeneratedForTransaction;
  }

  /**
   * Gets selectAllTickets
   * @returns {any}
   */
  get selectAllTickets(): any {
    return this._selectAllTickets;
  }

  /**
   * Sets selectAllTickets
   * @param value
   */
  set selectAllTickets(value: any) {
    this._selectAllTickets = value;
  }
}
