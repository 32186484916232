import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../environments/environment';
import {Transaction} from '../../models/transaction/transaction.model';
import {TranslateService} from '@ngx-translate/core';
import {HalTransactionsService} from '../../services/hal-transactions.service';
import {TransactionRefundModalComponent} from '../list/transaction-refund-modal.component';
import {isUndefined} from 'util';
import {TransactionTicket} from '../../models/transaction/transaction-ticket.model';
import {TicketStatus} from '../../enums/ticket-status.enum';
import {FileManipulatorService} from '../../../shared/services/file-manipulator.service';
import {UserService} from '../../../login/services/user.service';
import {TransactionStatus} from '../../enums/transaction-status.enum';
import {TransactionUpdateTicketTypeModalComponent} from '../list/transaction-update-ticket-type-modal.component';
import {TransactionChargebackModalComponent} from '../list/transaction-chargeback-modal.component';
import {TransactionPayment} from '../../models/transaction/transaction-payment.interface';
import {TransactionPaymentEditModalComponent} from './transaction-payment-edit-modal.component';

@Component({
  selector: 'c360-transactions-detail',
  templateUrl: './transaction-detail.component.html'
})

export class TransactionsDetailComponent implements OnInit, OnDestroy, OnChanges {

  @Output() onChangeTransaction: EventEmitter<string> = new EventEmitter<string>();
  @Output() onRefundTickets: EventEmitter<any> = new EventEmitter<any>();
  @Input() transaction: Transaction;
  @Input() reOpenRefundModal: any;

  private fileManipulatorService: FileManipulatorService;

  private _bookingUrl: string = null;
  private _isLoading: boolean;
  private _isUserRolFreeForRefund: any = true;

  /**
   *
   * @param {TranslateService} _translationService
   * @param {HalTransactionsService} _halTransactionsService
   * @param {NgbModal} _modalService
   * @param {FileManipulatorService} _fileManipulatorService
   * @param {UserService} _userService
   */
  constructor(
    private _translationService: TranslateService,
    private _halTransactionsService: HalTransactionsService,
    private _modalService: NgbModal,
    private _fileManipulatorService: FileManipulatorService,
    private _userService: UserService) {

    this.fileManipulatorService = _fileManipulatorService;
    this.bookingUrl = environment.urls.booking;
  }

  /**
   * @param {{[p: string]: SimpleChange}} changes
   */
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (!isUndefined(changes['transaction']) &&
      changes['transaction'].currentValue !== changes['transaction'].previousValue) {

      if (!isUndefined(changes['reOpenRefundModal']) &&
        null !== changes['reOpenRefundModal'].currentValue &&
        !isUndefined(changes['reOpenRefundModal'].currentValue) &&
        changes['reOpenRefundModal'].currentValue !== changes['reOpenRefundModal'].previousValue &&
        changes['reOpenRefundModal'].firstChange) {

        setTimeout(() => {
          this.openRefundTickets(
            this.reOpenRefundModal.tickets, this.reOpenRefundModal.responseStatusOk, this.reOpenRefundModal.responseStatusFail
          );
        });
      }

    }
  }

  /**
   * Init
   */
  ngOnInit() {

    if (!this._userService.isSalesCounter() &&
      this.transaction.isSepaFileGeneratedForTransaction
    ) {

      this._isUserRolFreeForRefund = false;
    }
  }

  /**
   * Destroy
   */
  ngOnDestroy() {
  }

  /**
   * On click download file
   */
  public onClickDownload(_cdnReference): void {

    this.fileManipulatorService.downloadXML(_cdnReference, true);
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }

  /**
   * Refunds a ticket
   * @param [string] _tickets
   * @param {string} _responseStatusOk
   * @param {string} _responseStatusFail
   */
  public openRefundTickets(_tickets: [string],
                           _responseStatusOk: string = null,
                           _responseStatusFail: string = null): void {

    const modalRef = this._modalService.open(TransactionRefundModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.transaction = this.transaction;
    modalRef.componentInstance.tickets = _tickets;
    modalRef.componentInstance.transactionResponseStatusOK = _responseStatusOk;
    modalRef.componentInstance.transactionResponseStatusFail = _responseStatusFail;

    modalRef.result.then(
      (data) => {

        if (data.isOK === true) {

          this.refundTickets(data.tickets, data.isMandatoryRefundConfirmation, data.skipRefundFee);
        }
      }
    );
  }

  /**
   * Update the ticket type of a ticket
   * @param {Transaction} _transaction
   * @param {TransactionTicket} _ticket
   * @param {string} _responseStatusOk
   * @param {string} _responseStatusFail
   */
  public openUpdateTicketsType(_transaction: Transaction,
                               _ticket: TransactionTicket,
                               _responseStatusOk: string,
                               _responseStatusFail: string): void {

    if (!isUndefined(_ticket)) {

      const modalRef = this._modalService.open(TransactionUpdateTicketTypeModalComponent, {backdrop: 'static', size: 'lg'});
      modalRef.componentInstance.transaction = _transaction;
      modalRef.componentInstance.transactionResponseStatusOK = _responseStatusOk;
      modalRef.componentInstance.transactionResponseStatusFail = _responseStatusFail;

      modalRef.result.then(
        (data) => {

          if (data) {
            const transactionTicketIdsAndPriceIds = [];

            for (const key of data.keys()) {
              data.get(key).forEach(_ticketPrice => {

                if (!isUndefined(_ticketPrice.changePriceId) && null !== _ticketPrice.changePriceId) {
                  transactionTicketIdsAndPriceIds.push({transactionTicketId: _ticketPrice.id, priceId: _ticketPrice.changePriceId});
                }

              });
            }

            let _params = null;

            if (this.transaction.isSepaFileGeneratedForTransaction) {

              _params = {
                'externalId': this.transaction.externalId,
                'transactionTicketIdsAndPriceIds': transactionTicketIdsAndPriceIds,
                'mandatoryExecution': true,
                'paymentProvider': 'DEFAULT',
                'paymentProviderType': 'CASH'
              };
            } else {

              _params = {
                'externalId': this.transaction.externalId,
                'transactionTicketIdsAndPriceIds': transactionTicketIdsAndPriceIds
              };
            }

            if (transactionTicketIdsAndPriceIds.length > 0) {

              this._isLoading = true;

              this._halTransactionsService.updateTicketsPrice(_params).then((response) => {

                this._isLoading = false;

                this.onChangeTransaction.emit(this.transaction.id);

                if (!isUndefined(response.transactionHistoryStatus)) {

                  _responseStatusOk = response.transactionHistoryStatus;
                } else {

                  _responseStatusOk = null;
                }

                if (!isUndefined(response.transactionHistoryStatus)) {

                  _responseStatusOk = response.transactionHistoryStatus;
                  _responseStatusFail = null;
                } else {

                  _responseStatusFail = 'TICKET_PRICE_CHANGE_FAILED';
                  _responseStatusOk = null;
                }

                this.openUpdateTicketsType(_transaction, _ticket, _responseStatusOk, _responseStatusFail);
              });
            }
          }
        }
      );
    }
  }

  /**
   * Open Charge Back window
   * @param {string} _responseStatus
   */
  public openChargeBack(_responseStatus): void {

    let _responseStatusOk: string = null;
    let _responseStatusFail: string = null;

    if (_responseStatus === TransactionStatus.REFUNDED ||
      _responseStatus === TransactionStatus.REFUNDED_PARTIAL) {

      _responseStatusOk = _responseStatus;
    } else {

      _responseStatusFail = _responseStatus;
    }

    const modalRef = this._modalService.open(TransactionChargebackModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.transactionResponseStatusOK = _responseStatusOk;
    modalRef.componentInstance.transactionResponseStatusFail = _responseStatusFail;

    modalRef.result.then(
      (data) => {
        this.onChangeTransaction.emit(this.transaction.id);
      });
  }

  /**
   * Tests if a transacion is refundable
   * @param {Transaction} transaction
   * @returns {boolean}
   */
  public isTransactionRefundable(transaction: Transaction): boolean {

    if (!this.isUserRolFreeForRefund) {

      return false;
    }

    let isRefundable = false;

    transaction.tickets.forEach((ticket) => {

      if (ticket.status === TicketStatus.BOOKING_COMPLETED ||
        ticket.status === TicketStatus.REFUND_FAILED ||
        ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
        ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
        ticket.status === TicketStatus.LOCAL_INVALIDATED ||
        ticket.status === TicketStatus.PRICE_CHANGE_FAILED ||
        ticket.status === TicketStatus.LOCAL_PRINTED ||
        ticket.status === TicketStatus.LOCAL_VALIDATED
      ) {

        isRefundable = true;
      }
    });

    return isRefundable;
  }

  /**
   * Tests if a transacion is updateable
   * @param {Transaction} transaction
   * @returns {boolean}
   */
  public isTransactionUpdateable(transaction: Transaction): boolean {

    if (!this.isUserRolFreeForRefund) {

      return false;
    }

    let isUpdateable = false;

    transaction.tickets.forEach((ticket) => {

      if (transaction.vouchers.length > 0) {
        return false;
      }

      if (ticket.voucher === null &&
        (ticket.status === TicketStatus.BOOKING_COMPLETED ||
          ticket.status === TicketStatus.REFUND_FAILED ||
          ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
          ticket.status === TicketStatus.PRICE_CHANGE_FAILED ||
          ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
          ticket.status === TicketStatus.LOCAL_INVALIDATED)
      ) {

        isUpdateable = true;
      }
    });

    return isUpdateable;
  }

  /**
   * Tests if a ticket is refundable
   * @param {TransactionTicket} ticket
   * @returns {boolean}
   */
  public isTicketRefundable(ticket: TransactionTicket): boolean {

    if (ticket.status === TicketStatus.BOOKING_COMPLETED) {
      return true;
    }

    return false;
  }

  public isTransactionRecreateDataProtectionIdsAllowed(transaction: Transaction): boolean {

    let isTransactionRecreateDataProtectionIdsAllowed = false;

    if (transaction.status == TransactionStatus.COMPLETE ||
      transaction.status == TransactionStatus.REFUNDED_PARTIAL ||
      transaction.status == TransactionStatus.REFUND_FAILED
    ) {

      transaction.tickets.forEach((ticket) => {

        if (ticket.status === TicketStatus.BOOKING_COMPLETED ||
          ticket.status === TicketStatus.REFUND_FAILED ||
          ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
          ticket.status === TicketStatus.PRICE_CHANGE_FAILED ||
          ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
          ticket.status === TicketStatus.LOCAL_INVALIDATED
        ) {

          isTransactionRecreateDataProtectionIdsAllowed = true;
        }
      });
    }

    return isTransactionRecreateDataProtectionIdsAllowed;
  }

  /**
   * Resend booking confirmation mail
   */
  resendBookingConfirmationMail(recipientToEmailAddress: string): void {

    this._isLoading = true;

    let _recipientToEmailAddress: string;

    if (isUndefined(recipientToEmailAddress) || recipientToEmailAddress === '') {

      _recipientToEmailAddress = null;
    } else {

      _recipientToEmailAddress = recipientToEmailAddress;
    }

    const _params = {
      'externalId': this.transaction.externalId,
      'recipientToEmailAddress': _recipientToEmailAddress
    };

    this._halTransactionsService.resendBookingConfirmationMail(_params).then((response) => {
      this._isLoading = false;
      this.onChangeTransaction.emit(this.transaction.id);
    });
  }

  /**
   * Refund Tickets
   * @param [string] _tickets
   */
  public refundTickets(_tickets: [string], isMandatoryRefund: boolean, skipRefundFee: boolean): void {

    this._isLoading = true;

    if (isUndefined(_tickets) || _tickets.length < 1) {

      this._isLoading = false;
      this.openRefundTickets(_tickets, null, this._translationService.instant('PLEASE_SELECT_TICKTES'));

      return;
    }

    let _params = null;

    if (this.transaction.isSepaFileGeneratedForTransaction) {

      _params = {
        'externalId': this.transaction.externalId,
        'ticketIds': _tickets,
        'mandatoryExecution': isMandatoryRefund,
        'skipRefundFee': skipRefundFee,
        'paymentProvider': 'DEFAULT',
        'paymentProviderType': 'CASH'
      };
    } else {

      _params = {
        'externalId': this.transaction.externalId,
        'ticketIds': _tickets,
        'mandatoryExecution': isMandatoryRefund,
        'skipRefundFee': skipRefundFee
      };
    }


    this._halTransactionsService.refundTransaction(_params).then((response) => {

      if (!isUndefined(response.transactionStatus)) {

        this.reopenRefundTicktes(response, _tickets, skipRefundFee);
      }
    }, (response) => {

      if (!isUndefined(response.transactionStatus)) {

        this.reopenRefundTicktes(response, _tickets, skipRefundFee);
      }

    }).catch((response) => {

      if (!isUndefined(response.transactionStatus)) {

        this.reopenRefundTicktes(response, _tickets, skipRefundFee);
      }
    });
  }

  /**
   *
   * @param response
   * @param _tickets
   */
  private reopenRefundTicktes(response, _tickets, skipRefundFee): void {

    let _reOpenRefundModal: any = null;

    if (response.transactionStatus === TransactionStatus.REFUNDED_PARTIAL || response.transactionStatus === TransactionStatus.REFUNDED) {

      _reOpenRefundModal = {
        transactionId: this.transaction.id,
        tickets: _tickets,
        responseStatusOk: response.transactionStatus,
        responseStatusFail: null,
        skipRefundFee: skipRefundFee
      };
    } else {

      _reOpenRefundModal = {
        transactionId: this.transaction.id,
        tickets: _tickets,
        responseStatusOk: null,
        responseStatusFail: response.transactionStatus,
        skipRefundFee: skipRefundFee
      };
    }

    this.onRefundTickets.emit(_reOpenRefundModal);
    this._isLoading = false;

  }


  /**
   * On click download pdf file
   */
  public onClickDownloadPdf(): void {
    this.fileManipulatorService.downloadXML(this.transaction.cdnReferencePdf, false);
  }

  public onEditPayments(payment: TransactionPayment): void {
    const modalRef = this._modalService.open(TransactionPaymentEditModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.payment = {
      transactionId: this.transaction.id,
      externalId: this.transaction.externalId,
      paymentProvider: payment.provider,
      paymentProviderType: payment.providerType,
      providerTypeId: payment.providerTypeIdMasked, // neu
      providerTypeIdEncrypted: payment.providerTypeIdEncrypted, // neu
      providerTypeCustomerName: payment.providerTypeCustomerName
    };
    modalRef.result.then(
      (data) => {
        if (data === true) {
          this.onChangeTransaction.emit(this.transaction.id);
        }
      }
    );
  }

  /**
   * Gets bookingUrl
   * @returns {string}
   */
  get bookingUrl(): string {
    return this._bookingUrl;
  }

  /**
   * Sets bookingUrl
   * @param {string} value
   */
  set bookingUrl(value: string) {
    this._bookingUrl = value;
  }

  /**
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Gets isUserRolFreeForRefund
   * @returns {boolean}
   */
  get isUserRolFreeForRefund(): boolean {
    return this._isUserRolFreeForRefund;
  }

  /**
   * Sets isUserRolFreeForRefund
   * @param {boolean} value
   */
  set isUserRolFreeForRefund(value: boolean) {
    this._isUserRolFreeForRefund = value;
  }

  /**
   * chargeBack
   * @param _externalId
   */
  public chargeBack(_externalId): void {

    this._isLoading = true;

    const _params = {
      'externalId': _externalId
    };

    this._halTransactionsService.chargeBack(_params).then((response) => {

      this._isLoading = false;

      if (!isUndefined(response.transactionHistoryStatus)) {

        this.openChargeBack(response.transactionHistoryStatus);
      }
    }, (response) => {

      this._isLoading = false;

      if (!isUndefined(response.transactionHistoryStatus)) {

        this.openChargeBack(response.transactionHistoryStatus);
      }

    }).catch((response) => {

      this._isLoading = false;

      if (!isUndefined(response.transactionHistoryStatus)) {

        this.openChargeBack(response.transactionHistoryStatus);
      }
    });
  }

  /**
   * isChargeBackable
   * @returns {boolean}
   */
  public isChargeBackable(): boolean {

    if (!this.transaction.isSepaFileGeneratedForTransaction) {
      return false;
    }


    if (this.transaction.status !== TransactionStatus.COMPLETE &&
      this.transaction.status !== TransactionStatus.REFUNDED_PARTIAL &&
      this.transaction.status !== TransactionStatus.REFUND_FAILED
    ) {

      return false;
    }

    return this._userService.isLocationAdmin();
  }

  /**
   * Recreate booking confirmation pdf
   */
  recreateBookingConfirmationPdf(createTicketPdfs: boolean = false): void {

    this._isLoading = true;

    const _params = {
      'externalId': this.transaction.externalId,
      'createTicketPdfs': createTicketPdfs
    };

    this._halTransactionsService.recreateBookingConfirmationPdf(_params).then((response) => {
      this._isLoading = false;
      this.onChangeTransaction.emit(this.transaction.id);
    });
  }

  /**
   * Recreate data protection ids
   */
  recreateDataProtectionIds(): void {

    this._isLoading = true;

    const _params = {
      'externalId': this.transaction.externalId
    };

    this._halTransactionsService.recreateDataProtectionIds(_params).then((response) => {
      this._isLoading = false;
      this.onChangeTransaction.emit(this.transaction.id);
    });
  }
}
