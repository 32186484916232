import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {HalService} from '../../shared/services/halService';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {UserHalData} from '../models/user-hal-data';
import {UserService} from '../../login/services/user.service';
import {RoleHalData} from '../models/role-hal-data';
import {Page} from '../../shared/models/page';

@Injectable()
export class HalUsersService extends HalService {

  protected gatewayName = 'auth';
  protected subscribtions: Subscription = new Subscription();

  public users = new BehaviorSubject<UserHalData>(null);
  public searchUsers = new BehaviorSubject<UserHalData>(null);
  public roles = new BehaviorSubject<RoleHalData>(null);

  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
    super(http, _alertService, _userService, _logger);

    this.subscribtions = this.data.subscribe((data) => {
      if (data != null && data['users'] != null) {
        this.users.next(data['users']);
      }
      if (data != null && data['searchUsers'] != null) {
        this.searchUsers.next(data['searchUsers']);
      }
      if (data != null && data['roles'] != null) {
        this.roles.next(data['roles']);
      }
    });
  }

  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Srtip page params
      if (id === 'users') {
        this._data[id] = new UserHalData(id, link);
      } else if (id === 'searchUsers') {
        this._data[id] = new UserHalData(id, link);
      } else if (id === 'roles') {
        this._data[id] = new RoleHalData(id, link);
      }
    });
  }

  getAllUsers(page: number = null) {
    this.getAll('users', page);
  }

  getUser(id: string): Promise<User> {
    return super.get(this._data['users'], id);
  }

  saveUser(data: User): Promise<User> {
    this._logger.info('Saving', data);
    return this.save(this._data['users'], data);
  }

  updateUser(data: User): Promise<User> {
    this._logger.info('Updating', data);
    return this.update(this._data['users'], data.id, data);
  }

  deleteUser(data: User): Promise<any> {
    this._logger.info('Deleting', data);
    return this.delete(this._data['users'], data.id);
  }

  getAllRoles() {
    return this.search(
      `${this._data['roles'].href}`
    ).then((response: any) => {
      if (response != null) {
        this._data['roles'].parseToArrayObjects(response);
        return this._data['roles'].data;
      }
    });
  }

  public changeEmailAddress(data): Observable<any> {
    const url = `${this._data['users'].href}/custom/changeEmailAddress`;
    return this.http.post(url, data);
  }

  public searchAllUsers(page: number = 1, searchString: string = null): Promise<{ [key: string]: User }> {
    if (this._data['searchUsers'].page == null) {
      this._data['searchUsers'].page = new Page(page);
    } else if (page != null) {
      this._data['searchUsers'].page.currentPage = page;
    }
    const url = `${this._data['searchUsers'].href}?${this._data['searchUsers'].page.getParamsForPage()}&${searchString ? searchString : searchString}`;

    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['searchUsers'].parseToArrayObjects(response);
        this.data.next(this._data);
        return this._data['searchUsers'].data;
      }
      return response;
    });

  }

  public findUserByExternalId(externalId): Promise<any> {
    const url = `${this._data['users'].href}/search/findByExternalId?externalId=${externalId}`;
    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        const halParsedData = this._data['users'].parseToObject(response);
        this.data.next(this._data);
        return halParsedData;
      }
      return response;
    });
  }

}
