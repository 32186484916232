<th class="text-center">
  <div class="form-check p-0">
    <input type="checkbox" class="m-0"
           name="transactionIdFormControl"
           id="selectRow1"
           [value]="transaction.transactionId"
           [checked]="transaction.selected"
           (change)="onClickRowCheckbox(transaction.transactionId)"
           [disabled]="!isSelectedLocationId">
    <label class="form-check-label" aria-hidden="true" hidden for="selectRow1">Zeile auswählen</label>
  </div>
</th>
<td>{{transaction?.transactionId}}</td>
<td>{{transaction?.pickupCode}}</td>
<td>{{'MODUL_TRANSACTIONS.ENUMS.TRANSACTION_STATUS.' + transaction?.historyLastStatus | translate}}</td>
<td>{{transaction?.customerFullName}}<br/>{{transaction?.customerEmailAddress}}</td>
<td>{{transaction?.historyFirstDate | date: 'dd.MM.yyyy HH:mm'}}</td>
<td>{{transaction?.eventTitle}}<br/>{{transaction?.performanceStartDate | date: 'dd.MM.yyyy HH:mm'}}</td>
<td>{{transaction?.auditoriumName}}<br/>{{transaction?.locationName}}</td>
<td>{{'MODUL_TRANSACTIONS.ENUMS.PAYMENT_TYPES.' + transaction?.paymentProviderType | translate}}<br/>{{transaction?.fullAmount | currency: transaction?.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
