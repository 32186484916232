<td>
  {{item.title}}
</td>
<td>
  {{item.code}}
</td>
<td class="text-right">
  <ng-container
    *ngIf="item.skipSaleFee || !item.fullAmountWithFee">{{item.fullAmount | currency: item.currency :"symbol":'1.2-2':currentLang}}
  </ng-container>
  <ng-container
    *ngIf="!item.skipSaleFee && item.fullAmountWithFee">{{item.fullAmountWithFee | currency: item.currency :"symbol":'1.2-2':currentLang}}
  </ng-container>
  <div class="payment-methods">
    <div class="payment-method" *ngFor="let payment of item.payments">
      <div class="icon">
        <i class="fa fa-{{payment.icon}}" aria-hidden="true"></i>
      </div>
      <div class="name text-secondary font-weight-medium"
           [translate]="'MODUL_TRANSACTIONS.ENUMS.PAYMENT_TYPES.'+payment.providerType"></div>
    </div>
  </div>
</td>
<td>
  <button class="btn btn-danger"
          [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.BUTTONS.REFUND'"
          (click)="refundTransaction()">
  </button>
</td>
