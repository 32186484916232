import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LoggerService} from './logger/logger.service';
import {AlertService} from './alert.service';
import {isUndefined} from 'util';

@Injectable()
export class FileManipulatorService {

  private _gatewayUrl = environment.urls.gateway;
  private _endpointS3Auth = 'auth/s3auth/getPresignedObjectUrl';

  protected requestHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  protected headers = new HttpHeaders({
    'Content-Type': 'application/json;charset=UTF-8'
  });

  private readonly PRIVATE_BUCKET: string = 'private';
  private readonly PUBLIC_BUCKET: string = 'public';

  constructor(protected http: HttpClient,
              protected _logger: LoggerService,
              protected _alertService: AlertService) {
  }

  /**
   * Download XML
   *
   * @param {string} cdnReference
   * @param {boolean} privateBucket
   * @returns {Promise<any>}
   */
  public downloadXML(cdnReference: string,
                     privateBucket: boolean = true): boolean {

    const _url: string = this.generateUrlWithParams(cdnReference, privateBucket);


    this.http.get(_url, {headers: this.headers})
      .toPromise()
      .then((response: any) => {

        if (!isUndefined(response.url)) {

          window.open(response.url, '_blank');

          return true;
        }

        return false;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });

    return false;
  }

  /**
   * Generate url for get files from S3 Auth service
   * @param {string} cdnReference
   * @param {boolean} privateBucket
   * @returns {string}
   */
  private generateUrlWithParams(cdnReference: string,
                                privateBucket: boolean = true): string {

    let _url: string = this._gatewayUrl;
    _url += this._endpointS3Auth;
    _url += '?filePath=' + cdnReference;

    if (privateBucket) {
      _url += '&bucketName=' + this.PRIVATE_BUCKET;
    } else {
      _url += '&bucketName=' + this.PUBLIC_BUCKET;
    }

    return _url;
  }

  /**
   * Error handler
   *
   * @param error
   */
  protected handleError(error: any): void {

    const errorMessage = (error.message) ? error.message :
      error.status ? '${error.status} - ${error.statusText}' : 'Server error';

    this._logger.error('File Manipulator ServiceError', errorMessage);

    if (error.error && error.error.errorUserMessage) {
      this._alertService.addAlert(error.error.errorUserMessage);
    }
    if (error.error && error.error.errorValidationList) {
      for (const key in error.error.errorValidationList) {
        if (error.error.errorValidationList.hasOwnProperty(key)) {
          const messageObject = error.error.errorValidationList[key];
          this._alertService.addAlert(messageObject.message);
        }
      }
    } else {
      this._alertService.addAlert(error);
    }

  }
}
