import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthorizationRules} from '../models/authorization.rules.interface';
import {UserService} from '../services/user.service';

@Injectable()
export class AuthorizationGuard implements CanActivate {

  private authorizationRules: AuthorizationRules;
  private _subscribe: Subscription;

  constructor(private _userService: UserService) {
    this._subscribe = this._userService.isloggedIn$().subscribe(isLoggedin => {
      this.authorizationRules = this._userService.getAuthorizationRules();
    });
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const domain = state.url.replace(/^\/([^\/]*).*$/, '$1');
    if (this.authorizationRules.hasOwnProperty(domain)) {
      return this.authorizationRules[domain].allowed;
    }
    return false;
  }
}
