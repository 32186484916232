<div style="display: flex; justify-content : space-between;">
  <h1 [translate]="'LOGIN.FORM.TITLE'"></h1>
  <span *ngIf="!superLogin" class="super superFalse" (click)="superLogin=!superLogin"></span>
  <span *ngIf="superLogin" class="super superTrue" (click)="superLogin=!superLogin"></span>

  <div class="login-item-printer">
    <img src="/assets/img/printer_okay.png" class="img-printer-okay" *ngIf="hasPrinter" placement="bottom" ngbTooltip="Ticketdrucker verbunden">
    <img src="/assets/img/printer_missing.png" class="img-printer-missing" *ngIf="!hasPrinter" placement="bottom" ngbTooltip="Kein Ticketdrucker">
  </div>
</div>

<form novalidate autocomplete="off"
      [formGroup]="loginForm"
      (ngSubmit)="submitForm()">

  <div class="form-group" [ngClass]="{
        'has-danger': email.invalid && email.dirty,
        'has-success': email.valid && email.dirty
        }">
    <label for="email" [translate]="'LOGIN.FORM.LABEL_EMAIL'"></label>
    <div class="input-group">
      <span class="input-group-addon"><i class="fa fa-user mx-auto"></i></span>
      <input type="email"
             class="form-control"
             formControlName="email"
             id="email"
             (change)="changeEmail()">
    </div>
    <div *ngIf="loginForm.controls.email.errors && !loginForm.controls.email.pristine" class="error-msg">
      <c360-form-validation-messages [control]="loginForm.controls.email"></c360-form-validation-messages>
    </div>
  </div>

  <div class="form-group" [ngClass]="{
        'has-danger': password.invalid && password.dirty,
        'has-success': password.valid && password.dirty
        }">
    <label for="password" [translate]="'LOGIN.FORM.LABEL_PASSWORD'"></label>
    <div class="input-group">
      <span class="input-group-addon"><i class="fa fa-lock mx-auto"></i></span>
      <input type="password"
             class="form-control"
             formControlName="password"
             id="password">
    </div>
    <div *ngIf="loginForm.controls.password.errors && !loginForm.controls.password.pristine" class="error-msg">
      <c360-form-validation-messages [control]="loginForm.controls.password"></c360-form-validation-messages>
    </div>
  </div>

  <p *ngIf="error != null" class="alert alert-danger" [translate]="'LOGIN.FORM.ERROR'"></p>

  <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid" [translate]="'LOGIN.FORM.SUBMIT'"></button>
</form>
