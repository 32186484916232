import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {TransactionTicket} from '../../../models/transaction/transaction-ticket.model';
import {Transaction} from '../../../models/transaction/transaction.model';
import {TicketStatus} from '../../../enums/ticket-status.enum';
import {HalCounterTransactionsService} from '../../../services/hal-counter-transactions.service';

@Component({
  selector: 'c360-counter-transaction-update-ticket-type-modal',
  templateUrl: './counter-transaction-update-ticket-type-modal.component.html'
})
export class CounterTransactionUpdateTicketTypeModalComponent implements OnInit {

  private _transaction: Transaction;
  transactionResponseStatusOK: any;
  transactionResponseStatusFail: any;

  isMandatoryRefundConfirmation: any = false;
  isMandatoryRefundSwitch: any = true;

  private _ticketsForUpdate: TransactionTicket[] = [];
  private _isLoading: boolean;
  private _priceList: any;
  private _spaceIdToTickets: Map<string, Array<TransactionTicket>> = new Map();

  calcAmount: any = 0;
  calcDifference: any = 0;
  amount: any = 0;

  /**
   *
   * @param {NgbActiveModal} _activeModal
   * @param {TranslateService} _translationService
   */
  constructor(private _activeModal: NgbActiveModal,
              private _translationService: TranslateService,
              private _ref: ChangeDetectorRef,
              private _halTransactionsService: HalCounterTransactionsService) {
  }

  /**
   * Init
   */
  ngOnInit(): void {

    this.findSimpleListByPriceIds();
    this._ref.markForCheck();
  }

  private findSimpleListByPriceIds(): void {

    this._isLoading = true;

    const _ids: string = this.getDifferentsPriceIds().toString();

    this._halTransactionsService.findSimpleListByPriceIds(_ids).then((response) => {

      this._priceList = response;
      this._isLoading = false;
    });
  }

  /**
   * Gets all differents price ids for the tickets of the transaction
   * @returns {Array<string>}
   */
  private getDifferentsPriceIds(): Array<string> {

    const _priceIds: any = [];

    if (null !== this._transaction) {

      this._transaction.tickets.forEach(_ticket => {

        if (this.isTicketUpdateable(_ticket)) {

          if (this.transaction.skipSaleFee) {
            this.amount = this.amount + _ticket.fullAmount;
            _ticket.calcAmount = _ticket.fullAmount;
          } else {
            _ticket.calcAmount = _ticket.fullAmountWithFee;
            this.amount = this.amount + _ticket.fullAmountWithFee;
          }

          _ticket.calcDifference = 0;
          if (!this._spaceIdToTickets.has(_ticket.spaceId)) {
            this._spaceIdToTickets.set(_ticket.spaceId, new Array<TransactionTicket>());
            this._ticketsForUpdate.push(_ticket);
          } else {
            this._spaceIdToTickets.get(_ticket.spaceId)[0].fullAmount += _ticket.fullAmount;
            this._spaceIdToTickets.get(_ticket.spaceId)[0].fullAmountWithFee += _ticket.fullAmountWithFee;
          }
          this._spaceIdToTickets.get(_ticket.spaceId).push(_ticket);

          if (_priceIds.indexOf(_ticket.priceId) < 0) {
            _priceIds.push(_ticket.priceId);
          }
        }
      });
    }

    this.calcAmount = this.amount;

    return _priceIds;
  }


  /**
   * changePrice
   * @param event
   */
  public changePrice(_obj: any): void {

    let _calcAmount = 0;
    const _ticketId = _obj.ticketId;
    const _spaceId = _obj.spaceId;
    const _priceId = _obj.priceId;

    this._ticketsForUpdate.forEach(_ticket => {

      if (_ticketId === _ticket.id) {

        _ticket.changePriceId = _priceId;
        _ticket.calcAmount = _obj.calcAmount;
        _ticket.calcDifference = _obj.calcDifference;
      }

      _calcAmount = _calcAmount + _ticket.calcAmount;
    });

    this._spaceIdToTickets.get(_spaceId).forEach(_otherTicket => {
        _otherTicket.changePriceId = _priceId;
      }
    );

    this.calcDifference = _calcAmount - this.amount;
    this.calcAmount = _calcAmount;
  }

  /**
   *
   * @param priceId
   * @returns {number}
   */
  private getAmonutFromPrice(priceId): number {

    this.priceList.priceId.forEach(price => {

      if (priceId === price.id) {
        if (this.transaction.skipSaleFee) {

          return price.fullAmount;
        } else {

          return price.fullAmountWithFee;
        }
      }
    });

    return 0;
  }

  /**
   * isTicketUpdateable
   * @param {TransactionTicket} ticket
   * @returns {boolean}
   */
  isTicketUpdateable(ticket: TransactionTicket): boolean {

    if (ticket.voucher === null && (ticket.status === TicketStatus.BOOKING_COMPLETED ||
      ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
      ticket.status === TicketStatus.LOCAL_INVALIDATED ||
      ticket.status === TicketStatus.REFUND_FAILED ||
      ticket.status === TicketStatus.PRICE_CHANGE_FAILED ||
      ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED)

    ) {

      return true;
    }

    return false;
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  /**
   * Sets mandatoyRefundConfirmation
   */
  public setMandatoryRefundConfirmation(): void {

    this.isMandatoryRefundSwitch = !this.isMandatoryRefundSwitch;
    this.isMandatoryRefundConfirmation = this.isMandatoryRefundSwitch;
  }

  /**
   * Gets transaction
   * @returns {Transaction}
   */
  get transaction(): Transaction {
    return this._transaction;
  }

  /**
   * Sets transaction
   * @param {Transaction} value
   */
  set transaction(value: Transaction) {
    this._transaction = value;
    this.isMandatoryRefundConfirmation = this._transaction.isSepaFileGeneratedForTransaction;
  }


  get ticketsForUpdate(): TransactionTicket[] {
    return this._ticketsForUpdate;
  }

  get spaceIdToTickets(): Map<string, Array<TransactionTicket>> {
    return this._spaceIdToTickets;
  }

  /**
   * Gets priceList
   * @returns {any}
   */
  get priceList(): any {
    return this._priceList;
  }
}
