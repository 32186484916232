import {Hal} from '../../shared/models/hal';

export class Auditorium extends Hal {

  public _links: any;
  public id: string;
  public mandatorId: string;
  public locationId: string;
  public name: string;

  constructor(_links?: any,
              id?: string,
              mandatorId?: string,
              locationId?: string,
              name?: string) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.locationId = locationId;
    this.name = name;
  }

}
