import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {LoginComponent} from './components/login.component';
import {AuthenticationService} from './services/authentication.service';
import {LoginFormComponent} from './components/login-form.component';
import {LoginForgotPasswordComponent} from './components/login-forgot-password.component';
import {LoginGuard} from './guards/login.guard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './services/token-interceptor';
import {ResponseInterceptor} from './services/response-interceptor';
import {SharedModule} from '../shared/shared.module';
import {AuthorizationGuard} from './guards/authorization.guard';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {TokenRefreshGuard} from './guards/token-refresh.guard';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    LoginForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
    NgbTooltipModule,
  ],
  exports: [
    LoginComponent,
    LoginFormComponent,
    LoginForgotPasswordComponent
  ],
  providers: [
    AuthenticationService,
    LoginGuard,
    AuthorizationGuard,
    TokenRefreshGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    CookieService
  ]
})
export class LoginModule {

  constructor(
    private translationLoader: TranslationLoaderService
  ) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }

}
