<div class="row">
  <div class="col-md-4 form-inline">
    <a class="btn btn-primary"
       [routerLink]="['/','events','add']">
      <i class="fa fa-plus"></i>
      {{'MODUL_EVENTS.COMPONENT_EVENTS_LIST.ADD' | translate}}</a>
  </div>
  <div class="col-md-8 form-inline justify-content-end">
    <input class="form-control mr-sm-2 my-2" type="search" placeholder="Search" aria-label="Search">
    <button class="btn btn-outline-success my-2 mr-sm-2" type="submit"><i class="fa fa-search"></i> {{
      'MODUL_EVENTS.COMPONENT_EVENTS_LIST.SEARCH' | translate}}
    </button>
    <div class="mr-sm-2 my-2">
      {{ 'MODUL_EVENTS.COMPONENT_EVENTS_LIST.FILTER' | translate}}:
      <select class="form-control" aria-label="Filter">
        <option>{{ 'MODUL_EVENTS.COMPONENT_EVENTS_LIST.FILTER_OPTIONAL' | translate}}</option>
      </select>
    </div>
  </div>
</div>
