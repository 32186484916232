import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HalPaymentService} from '../../services/hal-payment.service';
import {Subscription} from 'rxjs';
import {Voucher} from '../../models/voucher.model';
import {VoucherToggleStatusModalComponent} from './voucher-toggle-status-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../login/services/user.service';
import {PaymentTypesSalesCounter} from '../../enums/payment-types-sales-counter.enum';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService} from '../../services/confirmation.service';
import {TabCommunicationMessageTypes} from '../../../core/models/tab-communication-message.interface';
import {ITransactionProductPaymentCompletion} from '../../../transactions/models/transaction/transaction-payment.interface';

@Component({
  selector: '[c360-voucher-list-item]',
  templateUrl: './voucher-list-item.component.html',
  styleUrls: [
    './voucher-list-item.component.scss'
  ]
})
export class VoucherListItemComponent implements OnInit, OnDestroy {
  @Input() voucher: Voucher;
  private _payment: ITransactionProductPaymentCompletion;
  private _paymentForm: FormGroup;
  private _subscriptions: Subscription;
  private _paymentTypesList = PaymentTypesSalesCounter;
  private _paymentTypesListKey: Array<string>;
  private _isLoading: boolean = false;

  /**
   *
   * @param {HalPaymentService} _halPaymentService
   * @param {UserService} _userService
   * @param {NgbModal} _modalService
   * @param {FormBuilder} _formBuilder
   * @param {ConfirmationService} _confirmationService
   */
  constructor(private readonly _halPaymentService: HalPaymentService,
              private readonly _userService: UserService,
              private readonly _modalService: NgbModal,
              private readonly _formBuilder: FormBuilder,
              private _confirmationService: ConfirmationService) {
    this._paymentTypesListKey = Object.keys(this._paymentTypesList);
  }

  ngOnInit(): void {
    this._payment = {
      paymentProviderType: 'CASH'
    };
    this._subscriptions = new Subscription();
    this._paymentForm = this._formBuilder
      .group({
        paymentProviderType: [
          this._payment.paymentProviderType,
          [
            Validators.required
          ]
        ]
      });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public callBookingTransactionCompletionProductSimpleRequest(): void {
    if (this._paymentForm.valid) {
      this._isLoading = true;
      const modalRef = this._modalService.open(VoucherToggleStatusModalComponent, {backdrop: 'static'});
      modalRef.componentInstance.voucher = this.voucher;
      modalRef.componentInstance.setStatus = 'EMITTED';
      modalRef.result.then(
        (data) => {
          if (data === true) {
            this._subscriptions
              .add(
                this._halPaymentService
                  .callBookingTransactionCompletionProductSimpleRequest(this._userService.getUser().locationId, this.voucher.code, this._paymentForm.get('paymentProviderType').value)
                  .subscribe(
                    (newData) => {
                      this._isLoading = false;
                      if (newData[this.voucher.id].status == 'EMITTED') {
                        this._confirmationService.setCounterConfirmationCart({
                          id: null,
                          type: TabCommunicationMessageTypes.CONFIRMATION_READY,
                          body: {
                            id: newData[this.voucher.id].transactionExternalId
                          }
                        }).subscribe();
                      }
                    },
                    () => {
                      this._isLoading = false;
                    }
                  )
              );
          } else {
            this._isLoading = false;
          }
        }
      ).catch(() => {
        this._isLoading = false;
      });
    }
  }

  public callBookingTransactionRefundProductSimpleRequest(): void {
    this._isLoading = true;
    const modalRef = this._modalService.open(VoucherToggleStatusModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.voucher = this.voucher;
    modalRef.componentInstance.setStatus = 'REVOKED';
    modalRef.result.then(
      (data) => {
        if (data === true) {
          this._subscriptions
            .add(
              this._halPaymentService
                .callBookingTransactionRefundProductSimpleRequest(this._userService.getUser().locationId, this.voucher.code)
                .subscribe(
                  () => {
                    this._isLoading = false;
                  },
                  () => {
                    this._isLoading = false;
                  }
                )
            );
        } else {
          this._isLoading = false;
        }
      }
    ).catch(() => {
      this._isLoading = false;
    });
  }

  public revertStatus(): void {
    this._isLoading = true;
    const modalRef = this._modalService.open(VoucherToggleStatusModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.voucher = this.voucher;
    modalRef.componentInstance.setStatus = 'AVAILABLE';
    modalRef.result.then(
      (data) => {
        if (data === true) {
          this._subscriptions
            .add(
              this._halPaymentService
                .updateVoucherStatusByCode(this._userService.getUser().mandatorId, this._userService.getUser().locationId, this.voucher.code, 'AVAILABLE')
                .subscribe(
                  () => {
                    this._isLoading = false;
                  },
                  () => {
                    this._isLoading = false;
                  }
                )
            );
        } else {
          this._isLoading = false;
        }
      }
    ).catch(() => {
      this._isLoading = false;
    });
  }

  /**
   *
   * @return {any}
   */
  get paymentTypesList(): any {
    return this._paymentTypesList;
  }

  /**
   *
   * @return {Array<string>}
   */
  get paymentTypesListKey(): Array<string> {
    return this._paymentTypesListKey;
  }

  /**
   *
   * @return {ITransactionPaymentUpdate}
   */
  get payment(): ITransactionProductPaymentCompletion {
    return this._payment;
  }

  /**
   *
   * @param {ITransactionPaymentUpdate} value
   */
  set payment(value: ITransactionProductPaymentCompletion) {
    this._payment = value;
  }

  /**
   *
   * @return {FormGroup}
   */
  get paymentForm(): FormGroup {
    return this._paymentForm;
  }

  /**
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }
}
