import {HalData} from '../../shared/models/halData';
import {AreaLayout} from './area-layout.model';
import {isArray} from 'util';

export class AreaLayoutHalData extends HalData {

  data: { [id: string]: AreaLayout } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.areaLayouts) {
      response._embedded.areaLayouts.forEach((areaLayout: AreaLayout) => {
        this.parseToObject(areaLayout);
      });
    } else if (isArray(response)) {
      response.map((areaLayout: AreaLayout) => {
        this.parseToObject(areaLayout);
      });
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): AreaLayout {
    if (response != null) {
      const area = new AreaLayout(
        response._links,
        response.id,
        response.name,
        response.mandatorId,
        response.auditoriumLayoutId,
        response.areas
      );

      this.data['' + response.id + ''] = area;
    }
    return this.data['' + response.id + ''];
  }
}
