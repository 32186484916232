import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'c360-events-filter',
  templateUrl: './filter.component.html'
})

export class EventsFilterComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  add() {
    this.router.navigate(['../add'], {
      relativeTo: this.route,
      replaceUrl: true
    });
  }
}
