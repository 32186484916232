import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from './user.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private _router: Router,
              private _userService: UserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this._userService.getUser() != null) {
      if (request.url.indexOf(environment.urls.auth) === -1) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this._userService.getUser().accessToken}`
          }
        });
      }
    }

    return next.handle(request);
  }
}
