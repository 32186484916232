import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Voucher} from '../../models/voucher.model';

@Component({
  selector: 'c360-film-voucher-toggle-status-modal',
  templateUrl: './voucher-toggle-status-modal.component.html'
})
export class VoucherToggleStatusModalComponent {

  private _voucher: Voucher;
  private _setStatus: string;

  /**
   *
   * @param {NgbActiveModal} _activeModal
   */
  constructor(private _activeModal: NgbActiveModal) {
  }

  /**
   *
   * @return {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  /**
   *
   * @return {Voucher}
   */
  get voucher(): Voucher {
    return this._voucher;
  }

  /**
   *
   * @param {Voucher} value
   */
  set voucher(value: Voucher) {
    this._voucher = value;
  }

  /**
   *
   * @return {string}
   */
  get setStatus(): string {
    return this._setStatus;
  }

  /**
   *
   * @param {string} value
   */
  set setStatus(value: string) {
    this._setStatus = value;
  }
}
