export class ErrorMessage {

  errorCode: string;
  errorMessage: string;
  errorUserMessage: string;

  constructor(_errorCode, _errorMessage, _errorUserMessage) {
    this.errorCode = _errorCode;
    this.errorMessage = _errorMessage;
    this.errorUserMessage = _errorUserMessage;
  }
}
