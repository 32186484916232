import {map, tap} from 'rxjs/operators';
import {HttpService} from '../../shared/services/http.service';
import {environment} from '../../../environments/environment';
import {Observable, of, throwError as _throw, from, forkJoin, BehaviorSubject} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {EventEmitter, Inject, Injectable} from '@angular/core';
import {Transaction} from '../models/transaction/transaction.model';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {TabCommunicationMessage} from '../../core/models/tab-communication-message.interface';
import {isArray} from 'util';
import {TransactionTicket} from '../models/transaction/transaction-ticket.model';
import {TicketStatus} from '../enums/ticket-status.enum';
import {HalCounterTransactionsService} from './hal-counter-transactions.service';
import {TransactionPayment} from '../models/transaction/transaction-payment.interface';
import {PaymentTypes} from '../enums/payment-types.enum';
import {CounterConfirmationCartStatus} from '../enums/counter-confirmation-cart-status.enum';

export interface ICounterConfirmationCart {
  title: string;
  transactionId: string;
  mandatorId: string;
  tickets: Array<{
    id: string;
    skipSaleFee: boolean;
    fullAmount: number;
    fullAmountWithFee: number;
    status: string;
    selectedForRefund: boolean;
  }>;
  code: string;
  skipSaleFee: boolean;
  fullAmount: number;
  fullAmountWithFee: number;
  currency: string;
  payments: Array<{
    icon: string;
    providerType: PaymentTypes;
  }>;
}

@Injectable()
export class ConfirmationService extends HttpService {

  protected readonly _counterConfirmationCartStorageName: string = 'COUNTER_CONFIRMATION_CART';
  private _counterConfirmationCartEvent: EventEmitter<string> = new EventEmitter<string>();
  private _isActive: BehaviorSubject<boolean>;

  protected endpoints = {
    gateway: environment.urls.gateway
  };

  constructor(
    protected readonly _http: HttpClient,
    protected readonly _alertService: AlertService,
    protected readonly _logger: LoggerService,
    protected readonly _halTransactionsService: HalCounterTransactionsService,
    @Inject('LOCALSTORAGE') private readonly _localStorage: any
  ) {
    super(_http, _alertService, _logger);

    this._isActive = new BehaviorSubject<boolean>(this.getCounterConfirmationCart() != null && this.getCounterConfirmationCart().length > 0);
  }

  getConfirmation(externalId: string): Observable<any> {
    return this._http
      .get(this.endpoints.gateway + 'booking-transaction/transactions/search/findByExternalId?externalId=' + externalId).pipe(
      map((response: Transaction) => new Transaction(
        response._links,
        response.id,
        response.mandatorId,
        response.externalId,
        response.type,
        response.pickupCode,
        response.processId,
        response.customer,
        response.histories,
        response.performance,
        response.tickets,
        response.products,
        response.payments,
        moment(response.date),
        response.status,
        response.fullAmount,
        response.fullAmountWithFee,
        response.clientIpAddress,
        response.clientVersion,
        response.confirmationMailReceived,
        response.currency,
        response.cdnReferencePdf,
        response.skipSaleFee,
        response.skipRefundFee,
        response.refundFeeNet,
        response.refundFeeGross,
        response.saleFeeNet,
        response.saleFeeGross,
        response.vouchers
      )),
        catchError((err: any, caught) => {
          return null;
        })
      )
  }

  public getCounterConfirmationCart(): Array<ICounterConfirmationCart> {
    try {
      return <Array<ICounterConfirmationCart>>JSON.parse(this._localStorage.getItem(this._counterConfirmationCartStorageName));
    } catch (e) {
      return null;
    }
  }

  /**
   *
   * @param {string} name
   * @param value
   * @returns {Observable<any>}
   */
  public setCounterConfirmationCart(value: TabCommunicationMessage): Observable<any> {
    if(this._isActive.getValue()) {
      this._counterConfirmationCartEvent.emit(CounterConfirmationCartStatus.LOADING);
      return this.getConfirmation(value.body.id)
        .pipe(
          tap((data: Transaction) => {
            const storageCartItem: ICounterConfirmationCart = {
              title: data.performance.filmTitle ? data.performance.filmTitle : data.products[0].name,
              transactionId: data.id,
              mandatorId: data.mandatorId,
              tickets: data.tickets.map((item: TransactionTicket) => {
                return {
                  id: item.id,
                  skipSaleFee: item.skipSaleFee,
                  fullAmount: item.fullAmount,
                  fullAmountWithFee: item.fullAmountWithFee,
                  status: item.status,
                  selectedForRefund: item.selectedForRefund,
                };
              }),
              code: data.pickupCode ? data.pickupCode : data.products[0].code,
              skipSaleFee: data.skipSaleFee,
              fullAmount: data.fullAmount,
              fullAmountWithFee: data.fullAmountWithFee,
              currency: data.currency,
              payments: data.payments.map((item: TransactionPayment) => {
                return {
                  icon: Transaction.getIconForPayment(item.providerType),
                  providerType: item.providerType
                };
              })
            };
            try {
              let storageCartItems: Array<ICounterConfirmationCart> = this.getCounterConfirmationCart();
              if (storageCartItems && isArray(storageCartItems)) {
                if (!storageCartItems.find((item) => item.code === storageCartItem.code)) {
                  storageCartItems.push(storageCartItem);
                }
              } else {
                storageCartItems = [
                  storageCartItem
                ];
              }
              this._localStorage.setItem(this._counterConfirmationCartStorageName, JSON.stringify(storageCartItems));
            } catch (e) {
              console.error(e);
            }
            this._counterConfirmationCartEvent.emit(CounterConfirmationCartStatus.SUCCESS);
          })
        );
    } else {
      return this._isActive;
    }
  }

  public removeCounterConfirmationCartItem(code: string): Observable<any> {
    this._counterConfirmationCartEvent.emit(CounterConfirmationCartStatus.LOADING);
    let storageCartItems: Array<ICounterConfirmationCart> = this.getCounterConfirmationCart();
    if (storageCartItems && isArray(storageCartItems)) {
      const storageCartItem = storageCartItems.find((item) => item.code === code);
      if (!storageCartItem) {
        return _throw(`Pickup code (${code}) does not exist!`);
      }
      const ticketIds = storageCartItem.tickets && isArray(storageCartItem.tickets) ? storageCartItem.tickets.filter((item) => {
        if (item.selectedForRefund &&
          (item.status === TicketStatus.BOOKING_COMPLETED ||
            item.status === TicketStatus.REFUND_FAILED ||
            item.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
            item.status === TicketStatus.LOCAL_PRINT_INVALID ||
            item.status === TicketStatus.LOCAL_INVALIDATED ||
            item.status === TicketStatus.PRICE_CHANGE_FAILED ||
            item.status === TicketStatus.LOCAL_PRINTED ||
            item.status === TicketStatus.LOCAL_VALIDATED)
        ) {
          return true;
        }
        return false;
      }).map((item) => item.id) : [];
      const _params = {
        mandatorId: storageCartItem.mandatorId,
        transactionId: storageCartItem.transactionId,
        ticketIds: ticketIds,
        mandatoryRefund: false,
        skipRefundFee: true
      };
      return from(this._halTransactionsService
        .refundTransaction(_params))
        .pipe(
          tap(() => {
            storageCartItems = storageCartItems.filter((item) => item.code !== storageCartItem.code);
            this._localStorage.setItem(this._counterConfirmationCartStorageName, JSON.stringify(storageCartItems));
          }),
          tap(() => this._counterConfirmationCartEvent.emit(CounterConfirmationCartStatus.SUCCESS))
        );
    }
    return _throw(`Counter confirmation cart is empty`);
  }

  public removeCounterConfirmationCart(refund: boolean): Observable<any> {
    this._counterConfirmationCartEvent.emit(CounterConfirmationCartStatus.LOADING);
    if (refund === false) {
      return of(true)
        .pipe(
          tap(() => this._localStorage.removeItem(this._counterConfirmationCartStorageName)),
          tap(() => this._counterConfirmationCartEvent.emit(CounterConfirmationCartStatus.SUCCESS))
        );
    }
    const storageCartItems: Array<ICounterConfirmationCart> = this.getCounterConfirmationCart();
    return forkJoin(
      storageCartItems.map((item) => this.removeCounterConfirmationCartItem(item.code)),
    ).pipe(
      tap(() => this._localStorage.removeItem(this._counterConfirmationCartStorageName)),
      tap(() => this._counterConfirmationCartEvent.emit(CounterConfirmationCartStatus.SUCCESS))
    );
  }

  get counterConfirmationCartEvent(): EventEmitter<string> {
    return this._counterConfirmationCartEvent;
  }

  isActive$(): Observable<boolean> {
    return this._isActive;
  }

  public toggleActive(): Observable<any> {
    this._isActive.next(!this._isActive.getValue());
    return this._isActive;
  }
}
