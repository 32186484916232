import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class LayoutService {

  private _sidebarCollapsed: boolean;
  private _sidebarMinimized: boolean;

  private sidebarCollapsed = new BehaviorSubject<boolean>(false);
  private sidebarMinimized = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  get sidebarCollapsed$(): Observable<boolean> {
    return this.sidebarCollapsed.asObservable();
  }

  get sidebarMinimized$(): Observable<boolean> {
    return this.sidebarMinimized.asObservable();
  }

  toggleSidebarCollapsed() {
    this._sidebarCollapsed = !this._sidebarCollapsed;
    this.sidebarCollapsed.next(this._sidebarCollapsed);
  }

  toggleSidebarMinimized() {
    this._sidebarMinimized = !this._sidebarMinimized;
    this.sidebarMinimized.next(this._sidebarMinimized);
  }

  isSidebarMinimized(): boolean {
    return this._sidebarMinimized;
  }
}
