import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {UserService} from '../../login/services/user.service';
import {AuthenticationService} from '../../login/services/authentication.service';
import {Router} from '@angular/router';
import {LayoutService} from '../../shared/services/layout.service';
import {isUndefined} from 'util';
import {PrinterConnectionState} from '../../shared/enums/printer.enums';
import {PrinterService} from '../../shared/services/printer.service';
import {HalMandatorsService} from '../../locations/services/hal-mandators.service';
import {HalLocationsService} from '../../locations/services/hal-locations.service';
import {Mandator} from '../../locations/models/mandator.model';
import {Location} from '../../locations/models/location.model';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'c360-navigation-top',
  templateUrl: './navigation-top.component.html',
})

export class TopNavigationComponent implements OnInit, OnDestroy {

  private subscribe: Subscription;
  private collapsed = true;
  private _isLoggedIn$: Observable<boolean>;

  public hasPrinter = false;
  userMandatorId: string = null;
  userLocationId: string = null;
  userMandatorName: string = null;
  userLocationName: string = null;

  private _userContextForm: FormGroup;

  private _mandatorList: Mandator[];
  private _mandatorListFiltered: Mandator[];
  private _mandator: Mandator;
  private _mandatorSearchTerm: string;
  private _mandatorSearchShow = true;
  private _mandatorChooseShow = false;

  @ViewChild('mandatorSearch', {static: false})
  private _mandatorSearch: ElementRef;

  @ViewChild('mandatorChoose', {static: false})
  private _mandatorChoose: ElementRef;

  private _locationList: Location[];
  private _locationListFiltered: Location[];
  private _location: Location;
  private _locationSearchTerm: string;
  private _locationSearchShow = true;
  private _locationChooseShow = false;

  @ViewChild('locationSearch', {static: false})
  private _locationSearch: ElementRef;

  @ViewChild('locationChoose', {static: false})
  private _locationChoose: ElementRef;

  constructor(
    private _layoutService: LayoutService,
    private router: Router,
    private _userService: UserService,
    private _printerService: PrinterService,
    private _authenticationService: AuthenticationService,
    private readonly _formBuilder: FormBuilder,
    private _halMandatorsService: HalMandatorsService,
    private _halLocationsService: HalLocationsService,
    private el: ElementRef) {
  }

  ngOnInit() {

    this.subscribe = new Subscription();
    this.subscribe.add(this._userService.isloggedIn$().subscribe(isLoggedin => {
      if (!isUndefined(this._userService.getUser()) &&
        this._userService.getUser() !== null &&
        this._userService.getUser().mandatorId !== null
      ) {

        this.userMandatorId = this._userService.getUser().mandatorId;
        this.userMandatorName = this._userService.getUser().mandatorName;
      } else {

        this.userMandatorId = null;
        this.userMandatorName = null;
      }

      if (!isUndefined(this._userService.getUser()) &&
        this._userService.getUser() !== null &&
        this._userService.getUser().locationId !== null
      ) {

        this.userLocationId = this._userService.getUser().locationId;
        this.userLocationName = this._userService.getUser().locationName;
      } else {

        this.userLocationId = null;
        this.userLocationName = null;
      }

      if (!isUndefined(this._userService.getUser())) {
        if (!this.userMandatorId) {
          this._halMandatorsService
            .streamAllMandators()
            .then((data) => {
              this._mandatorList = Object.values(data);
              this._mandatorListFiltered = this._mandatorList;
              this._mandatorSearchTerm = '';
              this.createForm();
            });
        } else {
          if (!this.userLocationId) {
            this._halLocationsService.streamAllLocationsByMandatorId(this.userMandatorId).then(
              (data) => {
                this._locationList = Object.values(data);
                this._locationListFiltered = this._locationList;
                this._locationSearchTerm = '';
                this.createForm();
                if (!isUndefined(this._userService.getUser()) &&
                  this._userService.getUser() !== null &&
                  this._userService.getUser().mandatorId !== null
                ) {
                  this._mandator = new Mandator(
                    null,
                    this.userMandatorId,
                    this.userMandatorName,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  );
                  this.toggleLocationSearchShow(true);
                }
              }
            );
          } else {
            this.createForm();
          }
        }
      }
    }));
    // this.subscribe.add(this._halMandatorsService.mandators.subscribe(next => {

    //   })
    // );
    this.subscribe.add(
      this._printerService.connectionState$().subscribe(isConnected => {
        this.hasPrinter = (isConnected === PrinterConnectionState.CONNECTED);
      })
    );

    this._isLoggedIn$ = this._userService.isLoggedIn;
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  private createForm(): void {

    this._userContextForm = this._formBuilder.group({
      mandatorId: [
        this._userService.getUser().mandatorId
      ],
      mandatorSearchTerm: [
        this._mandatorSearchTerm
      ],
      locationId: [
        this._userService.getUser().locationId
      ],
      locationSearchTerm: [
        this._locationSearchTerm
      ]
    }, {updateOn: 'change'});

    this._userContextForm
      .get('mandatorId')
      .valueChanges
      .subscribe(
        (value) => {
          this._mandator = this._mandatorList.find(mandator => mandator.id === value);
          this._userService.getUser().mandatorId = value;
          this._userService.userMandatorChangedEmitter.emit(value);
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigate([this.router.url]);
          if (this._mandator) {
            this.toggleMandatorSearchShow(false);
            this.toggleMandatorChooseShow(false);
            this._halLocationsService.streamAllLocationsByMandatorId(value).then(
              (data) => {
                this._location = null;
                this._userService.getUser().locationId = null;
                this._userService.userLocationChangedEmitter.emit(null);
                this._locationList = Object.values(data);
                this._locationListFiltered = this._locationList;
                this._userContextForm.get('locationSearchTerm').setValue('');
                this._locationSearchShow = true;
                this._locationChooseShow = false;
              }
            );
          }
        }
      );

    this._userContextForm
      .get('mandatorSearchTerm')
      .valueChanges
      .subscribe(
        (value) => {
          if (value) {
            this._mandatorListFiltered = this._mandatorList.filter(
              mandatorItem => mandatorItem.name.toLowerCase().includes(value.toLowerCase())
            );
          } else {
            this._mandatorListFiltered = this._mandatorList;
          }
        }
      );

    this._userContextForm
      .get('locationId')
      .valueChanges
      .subscribe(
        (value) => {
          this._location = this._locationList.find(location => location.id === value);
          this._userService.getUser().locationId = value;
          this._userService.userLocationChangedEmitter.emit(value);
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigate([this.router.url]);
          if (this._location) {
            this.toggleLocationSearchShow(false);
            this.toggleLocationChooseShow(false);
          }
        }
      );

    this._userContextForm
      .get('locationSearchTerm')
      .valueChanges
      .subscribe(
        (value) => {
          if (value) {
            this._locationListFiltered = this._locationList.filter(
              locationItem => locationItem.name.toLowerCase().includes(value.toLowerCase())
            );
          } else {
            this._locationListFiltered = this._locationList;
          }
        }
      );
  }

  get userContextForm(): FormGroup {
    return this._userContextForm;
  }

  getUser() {
    return this._userService.getUser();
  }

  toggleMenu(isMobile = false) {
    this._layoutService.toggleSidebarCollapsed();
  }

  logout() {
    this._authenticationService.logout();
    this.router.navigate(['login']);
  }

  minimizeSidebar() {
    this._layoutService.toggleSidebarMinimized();
  }

  get isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$;
  }

  get mandatorListFiltered(): Mandator[] {
    return this._mandatorListFiltered;
  }

  get mandator(): Mandator {
    return this._mandator;
  }

  get mandatorSearchShow(): boolean {
    return this._mandatorSearchShow;
  }

  toggleMandatorSearchShow(value: boolean) {
    this._mandatorSearchShow = value;
    if (value) {
      this._userContextForm.get('locationId').setValue(  null);
      this._userContextForm.get('mandatorId').setValue(  null);
    }
  }

  get mandatorChooseShow(): boolean {
    return this._mandatorChooseShow;
  }

  toggleMandatorChooseShow(value: boolean) {
    this._mandatorChooseShow = value;
  }

  toggleMandatorChooseFocus() {
    this._mandatorChoose.nativeElement.focus();
  }

  get locationListFiltered(): Location[] {
    return this._locationListFiltered;
  }

  get location(): Location {
    return this._location;
  }

  get locationSearchShow(): boolean {
    return this._locationSearchShow;
  }

  toggleLocationSearchShow(value: boolean) {
    this._locationSearchShow = value;
    if (value) {
      this._userContextForm.get('locationId').setValue(  null);
    }
  }

  get locationChooseShow(): boolean {
    return this._locationChooseShow;
  }

  toggleLocationChooseShow(value: boolean) {
    this._locationChooseShow = value;
  }

  toggleLocationChooseFocus() {
    this._locationChoose.nativeElement.focus();
  }
}
