<div class="modal-header">
  <h4 class="modal-title">{{ 'MODUL_TRANSACTIONS.REFUND_MODAL.NAME' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-container *ngIf="transactionResponseStatusOK === null && transactionResponseStatusFail === null">

  <div class="modal-body">

    <p *ngIf="transactionResponseStatusOK === null">{{ 'MODUL_TRANSACTIONS.REFUND_MODAL.TEXT' | translate}}</p>

    <div *ngIf="transaction.isSepaFileGeneratedForTransaction" class="alert alert-warning">
      <div class="form-check">
        <input class="form-check-input" id="exampleCheck1" type="checkbox" (click)="setMandatoryRefundConfirmation()">
        <label class="form-check-label" for="exampleCheck1"><i>SEPA Datei ist bereits generiert, wollen Sie mit der Stornierung fortfahren?</i></label>
      </div>
    </div>

    <div *ngIf="transactionResponseStatusOK !== null" class="alert alert-success">
      <div class="form-check">
        {{'MODUL_TRANSACTIONS.REFUND_MODAL.' + transactionResponseStatusOK | translate}}
      </div>
    </div>

    <div *ngIf="transactionResponseStatusFail !== null" class="alert alert-danger">
      <div class="form-check">
        {{'MODUL_TRANSACTIONS.REFUND_MODAL.' + transactionResponseStatusFail | translate}}
      </div>
    </div>

    <form>
      <table class="table table-striped table-sm table-bordered mb-0">
        <thead>
        <tr>
          <th>
              <input type="checkbox"
                     id="selectAllTickets"
                     name="selectAllTickets"
                     [(ngModel)]="selectAllTickets"
                     (change)="onClickAllTickets()">
          </th>
          <th *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'">Platz</th>
          <th>Preis-Art</th>
          <th>Betrag</th>
        </tr>
        </thead>
        <tbody>

        <ng-container *ngFor="let ticket of transaction.tickets;">
          <ng-container *ngIf="isTicketRefundable(ticket)">
            <tr>
              <td>
                <input type="checkbox"
                       id="selectedTickets"
                       name="selectedTickets"
                       [(ngModel)]="ticket.selectedForRefund"
                       (change)="onClickTicket()">
              </td>
              <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'">Reihe {{ticket.spaceRow}}, Platz {{ticket.spaceRowPosition}}</td>
              <td>{{ticket.priceCategoryName}}<b *ngIf="ticket.priceMultiId" class="nobr"> [Part {{ticket.priceMultiId}}]</b></td>
              <td *ngIf="transaction.skipSaleFee">{{ticket.fullAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
              <td *ngIf="!transaction.skipSaleFee">{{ticket.fullAmountWithFee | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
            </tr>
          </ng-container>
        </ng-container>

        </tbody>
        <tfoot *ngIf="transaction.skipRefundFee === null">
          <tr>
            <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'" colspan="4">
              <input type="checkbox" name="skipRefundFee" [(ngModel)]="skipRefundFee">Stornogebühr entfällt
            </td>
            <td *ngIf="transaction.performance.occupationType == 'UNRESERVED'" colspan="3">
              <input type="checkbox" name="skipRefundFee" [(ngModel)]="skipRefundFee">Stornogebühr entfällt
            </td>
          </tr>
        </tfoot>
      </table>
    </form>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Schließen</button>
    <button *ngIf="transactionResponseStatusOK == null" type="button" class="btn btn-danger" [disabled]="(isMandatoryRefundConfirmation || !isTicketSelectedForRefund)" (click)="refund()">Stornieren</button>
  </div>

</ng-container>

<ng-container *ngIf="transactionResponseStatusOK !== null ||transactionResponseStatusFail !== null">

  <div class="modal-body">

    <div *ngIf="transactionResponseStatusOK !== null" class="alert alert-success">
      <div class="form-check">
        {{'MODUL_TRANSACTIONS.REFUND_MODAL.' + transactionResponseStatusOK | translate}}
      </div>
    </div>

    <div *ngIf="transactionResponseStatusFail !== null" class="alert alert-danger">
      <div class="form-check">
        {{'MODUL_TRANSACTIONS.REFUND_MODAL.' + transactionResponseStatusFail | translate}}
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Ok</button>
  </div>

</ng-container>
