import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ConfirmationService, ICounterConfirmationCart} from '../../services/confirmation.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmRefundModalComponent} from './confirm-refund-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: '[c360-counter-confirmation-cart-item]',
  templateUrl: './counter-confirmation-cart-item.component.html',
  styleUrls: [
    'counter-confirmation-cart-item.component.scss'
  ]
})
export class CounterConfirmationCartItemComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  @Input() item: ICounterConfirmationCart;

  constructor(
    private readonly _confirmationService: ConfirmationService,
    private readonly _translateService: TranslateService,
    private readonly _modalService: NgbModal
  ) {

  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public refundTransaction(): void {
    const confirmModal = this._modalService.open(ConfirmRefundModalComponent, {backdrop: 'static'});
    confirmModal.result.then(
      (data) => {
        this._confirmationService
          .removeCounterConfirmationCartItem(this.item.code)
          .subscribe();
      },
      () => {
      }
    );
  }

  get currentLang(): string {
    return this._translateService.currentLang;
  }
}
