import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PrintableTicket} from '../models/printer/printable-ticket.model';
import {HttpService} from './http.service';
import {AlertService} from './alert.service';
import {LoggerService} from './logger/logger.service';
import {Transaction} from '../../counter/models/transaction/transaction.model';
import {PrintError} from '../models/printer/print-error.model';
import {TicketTemplates} from '../models/printer/ticket-templates';
import {PrintAcknowledgeResponse} from '../models/printer/print-ack-response.interface';
import {ISalesCounter} from '../models/sales-counter.model';
import * as moment from 'moment';
import {PrinterConnectionState, PrinterErrorTypes, PrinterSocketMessages, PrinterState} from '../enums/printer.enums';

const wsFactory = {
  tryCount: 1,
  connect: function(url): Promise<WebSocket> {
    return new Promise((v, x) => {

      try {
        const ws = new WebSocket(url);

        ws.onerror = e => {
          x(new Error('Printer Connection Failed'));
        };
        ws.onopen = e => {
          if (e.target instanceof WebSocket) {
            v(ws);
          }
        };
        ws.onmessage = m => {
        };
      } catch (e) {
        x(new Error('Can not create Websocket'));
      }
    });
  }
};

@Injectable()
export class PrinterService extends HttpService {

  protected endpoints = {
    gateway: environment.urls.gateway
  };

  private _connectionState: BehaviorSubject<PrinterConnectionState>;
  private _printErrors: BehaviorSubject<PrintError>;
  private _printAcks: BehaviorSubject<PrintableTicket>;
  private _printerState: BehaviorSubject<PrinterState>;

  private _salesCounterId: string;
  private _nextTicketId: number;
  private _lastTicketId: number;
  private _printerAddress: string = environment.urls.printer;
  private _socket: WebSocket = null;
  private _printer = null;

  private _printerQueue: PrintableTicket[] = [];

  private _printResultTimeout = null;
  private _printerTimeout = 15000;

  constructor(protected _http: HttpClient,
              protected _alertService: AlertService,
              protected _logger: LoggerService) {
    super(_http, _alertService, _logger);

    this._connectionState = new BehaviorSubject<PrinterConnectionState>(PrinterConnectionState.DISCONNECTED);
    this._printErrors = new BehaviorSubject<PrintError>(null);
    this._printAcks = new BehaviorSubject<PrintableTicket>(null);
    this._printerState = new BehaviorSubject<PrinterState>(PrinterState.IDLE);

    this.connect();
  }

  public connect() {
    if (this._connectionState.getValue() === PrinterConnectionState.DISCONNECTED) {
      this._connectionState.next(PrinterConnectionState.CONNECTING);
      this._printerState.next(PrinterState.IDLE);

      if (this.detectPrinter()) {
        this.establishConnection();
      } else {
        this._connectionState.next(PrinterConnectionState.NOT_FOUND);
        this._printerState.next(PrinterState.MISSING);
      }
    }
  }

  /**
   * Check if Endpoints exists
   * @returns {boolean}
   */
  private detectPrinter() {
    return true;
  }

  /**
   * Establish WS Connection
   */
  private establishConnection(): Promise<boolean> {
    return wsFactory.connect(this._printerAddress)
      .then((ws: WebSocket) => {
        this._socket = ws;

        this._socket.onmessage = (ev: MessageEvent) => {
          try {
            const message = JSON.parse(ev.data);
            if (message.type != null) {
              switch (message.type) {
                case PrinterSocketMessages.INIT:
                  if (message.payload != null) {
                    if (message.payload.device != null && message.payload.id != null) {
                      this._printer = message.payload.device;
                      this._salesCounterId = message.payload.id;

                      this._connectionState.next(PrinterConnectionState.CONNECTED);
                      this._printerState.next(PrinterState.IDLE);
                      break;
                    }
                  }
                  this._printer = null;
                  this._salesCounterId = null;

                  this._connectionState.next(PrinterConnectionState.NOT_FOUND);
                  this._printerState.next(PrinterState.MISSING);
                  break;

                case PrinterSocketMessages.UPDATE:
                  if (message.payload != null) {
                    if (message.payload.device != null) {
                      this._printer = message.payload.device;
                    }
                    if (message.payload.id != null) {
                      this._salesCounterId = message.payload.id;
                    }
                  }
                  break;

                case PrinterSocketMessages.PRINT_MESSAGE_ACK:
                  if (message.payload != null) {
                    this.onPrintMessageReceived(message.payload);
                  }
                  break;

                case PrinterSocketMessages.PRINT_MESSAGE_NACK:
                  if (message.payload != null) {
                    this.onPrintFailure(message.payload);
                  }
                  break;
                default:
                  break;
              }
            }
          } catch (e) {
            // Unknown format. Skip
          }
        };

        this._socket.onerror = (ev: Event) => {
          this._connectionState.next(PrinterConnectionState.ERROR);
          this._printerState.next(PrinterState.MISSING);
        };

        this._socket.onclose = (ev: CloseEvent) => {
          this.onDisconnect();
        };

        return true;
      }, (err) => {
        this.onDisconnect();
        return false;
      })
      .catch(() => {
        this.onDisconnect();
        return false;
      });
  }

  private onDisconnect() {
    this._connectionState.next(PrinterConnectionState.DISCONNECTED);
    this._printerState.next(PrinterState.MISSING);
  }

  /**
   * Add Ticket Templates for given Transaction to queue and start printing
   * @param {Transaction} transaction
   * @param printableTickets
   * @param salesCounter
   */
  printTickets(transaction: Transaction, printableTickets: string[], salesCounter: ISalesCounter): void {
    this.debug('printTickets', transaction.id, salesCounter);

    if (salesCounter != null) {
      this._nextTicketId = salesCounter.nextTicketId;
      this._lastTicketId = salesCounter.lastTicketId;

      this._printerState.next(PrinterState.PRINT_INIT);
      this.getPrintTemplates(transaction.externalId, printableTickets, salesCounter)
        .subscribe(
          (templates: TicketTemplates) => {
            if (templates == null) {
              this._printErrors.next({
                type: PrinterErrorTypes.NO_TEMPLATES,
                reason: 'Ticket Templates konnten nicht abgerufen werden',
                transactionId: transaction.id,
                ticketIds: printableTickets
              });
              return templates;
            }
            Object.keys(templates.ticketIds).forEach(ticketId => {
              let printableTicket: PrintableTicket = {
                ticketId: ticketId,
                transactionId: templates.transactionId.toString(10),
                transactionExternalId: templates.transactionExternalId,
                template: templates.ticketIds[ticketId]
              };
              if (!this._printerQueue.includes(printableTicket)) {
                this._printerQueue.push(printableTicket);
              }
            });

            if (this._printerState.getValue() !== PrinterState.PRINTING) {
              this.print();
            } else {
              this._printErrors.next({
                type: PrinterErrorTypes.BUSY,
                reason: 'Der Drucker ist in Betrieb',
                transactionId: transaction.id,
                ticketIds: printableTickets
              });
            }

          },
          (e) => {
            this._printErrors.next({
              type: PrinterErrorTypes.NO_TEMPLATES,
              reason: 'Ticket Templates konnten nicht abgerufen werden.',
              transactionId: transaction.id,
              ticketIds: printableTickets
            });
          }
        );
    }
  }

  /**
   * Return first Ticket in Queue
   * @returns {PrintableTicket}
   */
  private getFirstFromQueue(): PrintableTicket {
    if (this._printerQueue != null && this._printerQueue.length > 0) {
      this.debug('getFirstFromQueue', this._printerQueue[0]);
      return this._printerQueue[0];
    }
    return null;
  }

  /**
   * Remove Ticket from Queue
   * @param {PrintableTicket} ticket
   */
  private deleteTicketFromQueue(ticket: PrintableTicket) {
    this.debug('Delete Ticket from Queue', ticket);
    const index = this._printerQueue.findIndex(item => (item.ticketId === ticket.ticketId && item.transactionId === ticket.transactionId));
    this._printerQueue.splice(index, 1);
  }

  public getQueueCount(): number {
    return this._printerQueue.length + 1;
  }

  private print(): void {
    const template = this.getFirstFromQueue();
    this.debug('Print template', template);
    if (template != null) {
      this._printerState.next(PrinterState.PRINTING);

      // Print Timeout Handler
      this.clearPrintResultTimeout();
      this._printResultTimeout = setTimeout(() => {
        this.onPrintTimeout(template);
      }, this._printerTimeout);

      // Send request to printer
      this._socket.send(
        JSON.stringify({
          type: PrinterSocketMessages.PRINT_REQUEST,
          payload: template
        })
      );

    } else {
      if (this._printerState.getValue() === PrinterState.PRINTING) {
        this._printerState.next(PrinterState.PRINT_COMPLETE);
      } else {
        this._printerState.next(PrinterState.IDLE);
      }
    }
  }

  private clearPrintResultTimeout(): void {
    if (this._printResultTimeout != null) {
      clearTimeout(this._printResultTimeout);
    }
  }

  /**
   * Print Successful,
   * Ackowledge Print and print next
   * @param {PrintableTicket} printedTicket
   */
  private onPrintMessageReceived(printedTicket: PrintableTicket) {
    this.debug('Print Message received', printedTicket);
    this.clearPrintResultTimeout();

    if (printedTicket.transactionId != null && printedTicket.ticketId != null) {
      this.debug('add to acknowledged', printedTicket);
      // Acknowledge
      this._printAcks.next(printedTicket);

      this.deleteTicketFromQueue(printedTicket);

      // print next
      setTimeout(() => {
        this.debug('Send next ticket to Printer');
        this.print();
      }, 1500);
    } else {
      this._logger.warn('Test Ticket printed', printedTicket);
      this._printerState.next(PrinterState.IDLE);
    }
  }

  private onPrintFailure(template: PrintableTicket) {
    this.clearPrintResultTimeout();

    this._printerState.next(PrinterState.MISSING);

    // reset Queue
    this._printerQueue = [];

    this._printErrors.next({
      type: PrinterErrorTypes.TIMEOUT,
      reason: 'Zeitüberschreitung beim Drucken. Abbruch nach ' + this._printerTimeout / 1000 + ' Sekunden',
      transactionId: template.transactionId,
      ticketIds: [template.ticketId]
    });
  }

  /**
   * Print Request Timed Out
   * @param {PrintableTicket} template
   */
  private onPrintTimeout(template: PrintableTicket): void {
    this.debug('PRINT TIMEOUT', template);
    this.onPrintFailure(template);
  }

  getPrintTemplates(transactionExternalId: string, ticketIds: string[] = [], salesCounter: ISalesCounter) {
    this.debug('getPrintTemplates', transactionExternalId, ticketIds, salesCounter);
    return this._http
      .post(
        this.endpoints.gateway + 'booking-transaction/transactions/custom/noteSalesCounterTransaction',
        {
          externalId: transactionExternalId,
          ticketIds: ticketIds,
          nextTicketId: salesCounter.nextTicketId,
          lastTicketId: salesCounter.lastTicketId
        }
      ).pipe(
        map((response: TicketTemplates) => {
          this.debug('got templates', response);
          return response;
        }),
        catchError((err: any, caught) => {
          this.debug('error receiving templates', err);
          this.handleError(err);
          return of(null);
        }),);
  }

  private debug(...args) {
    // this._logger.info(args);
  }

  acknowledgePrintOfTickets(printedTickets: PrintableTicket[], salesCounter: ISalesCounter): Observable<any> {
    if (printedTickets != null && printedTickets.length > 0) {
      this.debug('AcknowledgePrintOfTikets', printedTickets, salesCounter);
      const transactionExternalId = printedTickets[0].transactionExternalId;
      return this._http
        .patch(
          this.endpoints.gateway + 'booking-transaction/transactions/custom/noteSalesCounterTransaction',
          {
            externalId: transactionExternalId,
            ticketIds: printedTickets.map(t => t.ticketId),
            nextTicketId: salesCounter.nextTicketId,
            lastTicketId: salesCounter.lastTicketId
          }
        );
    }
    this.debug('AcknowledgePrintOfTikets No Tickets', printedTickets, salesCounter);
    return of(null);
  }

  acknowledgePrint(printedTicket: PrintableTicket) {
    return this._http
      .patch(
        this.endpoints.gateway + 'booking-transaction/transactions/custom/noteSalesCounterTransaction',
        {
          externalId: printedTicket.transactionExternalId,
          ticketIds: [printedTicket.ticketId],
          nextTicketId: this._nextTicketId,
          lastTicketId: this._lastTicketId
        }
      ).pipe(
        map(
          (response: PrintAcknowledgeResponse) => {
            if (response != null) {
              this._nextTicketId = response.nextTicketId;
              this._lastTicketId = response.lastTicketId;

              this.deleteTicketFromQueue(printedTicket);
              if (response != null) {
                this._printAcks.next(response.ticketIds[printedTicket.ticketId]);
                return true;

              } else {
                this._printErrors.next({
                  type: PrinterErrorTypes.ACK_FAILED,
                  reason: 'Der Druck konnte nicht bestätigt werden.',
                  transactionId: printedTicket.transactionId,
                  ticketIds: [printedTicket.ticketId]
                });
              }
              return false;
            }
          }
        ), catchError((err: any, caught) => {
            this._printErrors.next({
              type: PrinterErrorTypes.ACK_FAILED,
              reason: err,
              transactionId: printedTicket.transactionId,
              ticketIds: [printedTicket.ticketId]
            });
            return of(null);
          }
        ),);
  }

  deletePrint(transactionExternalId: string, ticketIds: string[] = [], salesCounter: ISalesCounter) {
    this._nextTicketId = salesCounter.nextTicketId;
    this._lastTicketId = salesCounter.lastTicketId;
    this.debug('deletePrint', transactionExternalId, ticketIds, salesCounter);
    return this._http
      .request(
        'DELETE',
        this.endpoints.gateway + 'booking-transaction/transactions/custom/noteSalesCounterTransaction',
        {
          body: {
            externalId: transactionExternalId,
            ticketIds: ticketIds,
            nextTicketId: this._nextTicketId,
            lastTicketId: this._lastTicketId
          }
        }
      ).pipe(
        catchError((err: any, caught) => {
          return of(null);
        }));
  }

  cleanUpAfterPrint(): void {
    this.debug('cleanUpAfterPrint', this._printerQueue);
    this._printerQueue = [];
    // Reset Printer State if not "MISSING"
    if (this._printerState.getValue() !== PrinterState.MISSING) {
      this._printerState.next(PrinterState.IDLE);
    }
  }

  printTestTicket(nextTicketId: number): void {
    this._printerState.next(PrinterState.PRINTING);
    this.debug('printNextTicket', nextTicketId);
    // Send request to printer
    this._socket.send(
      JSON.stringify({
        type: PrinterSocketMessages.PRINT_REQUEST,
        payload: {
          transactionId: null,
          ticketId: null,
          template: this.getTestTicketTemplate(nextTicketId)
        }
      })
    );
  }

  connectionState$(): Observable<PrinterConnectionState> {
    return this._connectionState.asObservable();
  }

  printErrors$(): Observable<PrintError> {
    return this._printErrors.asObservable();
  }

  printAcks$(): Observable<PrintableTicket> {
    return this._printAcks.asObservable();
  }

  printerState$(): Observable<PrinterState> {
    return this._printerState.asObservable();
  }

  get salesCounterId(): string {
    return this._salesCounterId;
  }

  private getTestTicketTemplate(nextTicketId: number): string {
    const now = moment();
    return '<F2><HW0.2,1><NR><RC180,50>' +
      nextTicketId.toString(10) +
      '<F2><HW0.2,1><NR><RC180,150>' +
      'Setup' +
      '<F2><HW0.2,1><NR><RC180,300>' +
      'Einrichtungs-Ticket ' + nextTicketId.toString(10) +
      '<F13><HW1,1><NR><RC250,50>' +
      'Einrichtungs-Ticket (stets aufbewahren)' +
      '<F13><HW1,1><NR><RC300,50>' +
      now.format('dd DD.MM.YY') + ',' +
      now.format('HH:mm') + ' Uhr' +
      '<F13><HW1,1><NR><RC300,580>' +
      'SPIO Nummer' +
      '\'<F13><HW1,1><NR><RC250,860>' +
      'ungueltig' +
      '<p>';
  }
}
