import {ChangeDetectorRef, Injectable, Pipe, PipeTransform} from '@angular/core';
import {I18nPluralPipe, NgLocalization} from '@angular/common';

import {TranslateService} from '@ngx-translate/core';

/**
 * Maps a numeric value to a pluralized translated string.
 *
 * ## Usage
 *
 * expression | pluralate:'PATH.TO.TRANSLATION.MAPPING'
 *
 * ## Example
 *
 * ```ts
 * @Component({
 *   selector: 'my-inbox',
 *   template: `
 *     <div>
 *       {{ messages.length | pluralate:'INBOX.MESSAGES_PLURAL_CATEGORIES' }}
 *     </div>
 *   `,
 * })
 * class MyInbox {
 *   messages: any[];
 *   // ...
 * }
 * ```
 *
 * While the translation file is:
 *
 * ```json
 * // en.json
 * {
 *  "INBOX": {
 *    "INBOX.MESSAGES_PLURAL_ZERO": "Your inbox is empty :(",
 *    "INBOX.MESSAGES_PLURAL_ONE": "You have 1 new message",
 *    "INBOX.MESSAGES_PLURAL_OTHER": "You have {{ count }} new messages",
 *
 *    "MESSAGES_PLURAL_CATEGORIES": {
 *      "=0": "INBOX.MESSAGES_PLURAL_ZERO",
 *      "=1": "INBOX.MESSAGES_PLURAL_ONE",
 *      "other": "INBOX.MESSAGES_PLURAL_OTHER"
 *    }
 *  }
 * }
 * ```
 *
 * @implements {PipeTransform}
 */
@Injectable()
@Pipe({
  name: 'pluralate',
  pure: false, // required to update the value when the promise is resolved
})
export class PluralatePipe implements PipeTransform {
  private value = '';

  constructor(
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private localization: NgLocalization
  ) {
  }

  public transform(query: string, pluralizationHolderKey: string): string {
    const i18npipe: I18nPluralPipe = new I18nPluralPipe(this.localization);
    if (Array.isArray(query)) {
      return query;
    }
    const queryNum = parseInt(query, 10);
    if (isNaN(queryNum)) {
      return query;
    }

    this.translate.get(pluralizationHolderKey).subscribe((res: { [key: string]: string }) => {
      const mapping = res;
      const pluralCat = i18npipe.transform(queryNum, mapping);

      this.value = this.translate.instant(pluralCat, { count: queryNum });
      this.changeDetector.markForCheck();
    });

    return this.value;
  }
}
