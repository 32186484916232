import {Param} from './param.model';

export class Params {

  public params: Param[];

  constructor() {
    this.params = [];
  }

  /**
   * Add one Param object
   * @param {Param} param
   */
  public add(param: Param): void {
    this.params.push(param);
  }

  /**
   * Gets string of params for url request
   * @returns {string}
   */
  public getParamsForSearch(): string {

    let _params = '';

    let param: Param;

    for (param of this.params) {

      const _tmp = '&' + param.key + '=' + param.value;

      _params = _params + _tmp;
    }

    return _params;
  }

  /**
   * conact params
   * @param {Params} params
   */
  public concats(params: Params): any {
    const _params = new Params();
    _params.params = this.params.concat(params.params);
    return _params;
  }
}
