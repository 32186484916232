import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {isUndefined} from 'util';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {Params} from '../../../shared/models/search/params.model';
import {Param} from '../../../shared/models/search/param.model';
import {UserService} from '../../../login/services/user.service';
import {BaseListComponent} from '../../../shared/components/base-list.component';
import {HalTransactionsService} from '../../services/hal-transactions.service';
import {TransactionSearchHalData} from '../../models/transaction-search-hal-data';
import {Transaction} from '../../models/transaction/transaction.model';
import {isNumeric} from 'rxjs/internal-compatibility';

@Component({
  selector: 'c360-transactions-list',
  templateUrl: './transactions-list.component.html'
})
export class TransactionsListComponent extends BaseListComponent implements OnInit, OnDestroy {

  @ViewChild('transactionTabs', {static: true}) transactionTabs: NgbTabset;
  public activeTransactions: Transaction[] = [];
  public _transactionSearchHalData: TransactionSearchHalData;
  public readonly TAB_TRANSACTION_PREFIX = 'tab-transaction-';
  public readonly TAB_OVERVIEW_ID = 'tab-transaction-overview';

  private _subscription: Subscription = null;

  private _isLoading: boolean;
  private _currentTab = null;
  private _userLocationId: string;
  private _params: Params = new Params();
  private _searchParams: Params = new Params();
  private _reOpenRefundModal: any = null;

  /**
   * Constructor
   *
   * @param {HalTransactionsService} _halTransactionsService
   * @param {LoggerService} _logger
   * @param {ChangeDetectorRef} _ref
   */
  constructor(
    private _halTransactionsService: HalTransactionsService,
    private _logger: LoggerService,
    private _ref: ChangeDetectorRef,
    private _userService: UserService) {
    super();
  }

  /**
   * on init
   */
  ngOnInit() {
    this._params = this.addParamsMandatoryForTransactions(this._params);

    this.init();
  }

  /**
   * init
   */
  private init(): void {
    this._isLoading = false;

    this._subscription =
      this._halTransactionsService.transactionsSearch.subscribe((halData: TransactionSearchHalData) => {

        if (null != halData && null != halData.data) {

          this._transactionSearchHalData = halData;

          if (null != halData.page) {

            this.currentPage = halData.page.currentPage;
          }

          this._isLoading = false;
          this._ref.markForCheck();
        }
      });

    this.getAllSearch(1);
  }

  /**
   * on destroy
   */
  ngOnDestroy() {

    if (null != this._subscription && !isUndefined(this._subscription)) {
      this._subscription.unsubscribe();
    }
  }

  /**
   *
   * @param {Params} params
   */
  searchWithParams(params: Params): void {

    this._isLoading = true;
    this.currentPage = 1;
    this._searchParams = params;

    this._halTransactionsService.getAllTransactionsSearch(this.currentPage, this._params.concats(this._searchParams));
  }

  /**
   *
   * @param {number} page
   */
  private getAllSearch(page: number = null): void {

    this._isLoading = true;
    this._halTransactionsService.getAllTransactionsSearch(page, this._params.concats(this._searchParams));
  }

  /**
   *
   * @param {number} pageNumber
   */
  getAllByPage(pageNumber: number): void {

    this.getAllSearch(pageNumber);
  }

  /**
   *
   * @returns {boolean}
   */
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *
   * @returns {TransactionSearchHalData}
   */
  get transactionHalData(): TransactionSearchHalData {
    return this._transactionSearchHalData;
  }

  /**
   *
   * @param tab
   */
  onTabSelect(tab): void {
    this._logger.info('Tab selected', tab);

    this._currentTab = tab;
  }

  /**
   *
   * @param event
   */
  onTabChange(event: NgbTabChangeEvent): void {

    this._reOpenRefundModal = null;

    let nextId = event.nextId;

    nextId = nextId.replace(this.TAB_TRANSACTION_PREFIX, '');

    if (event.nextId !== this.TAB_OVERVIEW_ID &&
      isNumeric(nextId)
    ) {
      this.openTabTransaction(nextId);
    }
  }

  /**
   *
   * @param transaction
   */
  onSubTabChange(transaction): void {
  }

  /**
   *
   * @param string
   * @param id
   * @returns {string}
   */
  createTabId(string, id): string {
    return `${string}${id}`;
  }

  /**
   *
   * @param {string} id
   */
  activateTabById(id: string): void {
    setTimeout(() => {
      this.transactionTabs.select(id);
    });
  }

  onRefundTickets(obj: any): void {

    if (!isUndefined(obj.tickets) &&
      !isUndefined(obj.responseStatusOk) &&
      !isUndefined(obj.responseStatusFail)
    ) {

      this._reOpenRefundModal = {
        tickets: obj.tickets,
        responseStatusOk: obj.responseStatusOk,
        responseStatusFail: obj.responseStatusFail,
        skipRefundFee: obj.skipRefundFee
      };
    }

    if (!isUndefined(obj.transactionId)) {

      this.openTabTransaction(obj.transactionId);
    }

  }


  /**
   *
   * @param {string} id
   */
  openTabTransaction(id: string): void {

    this._isLoading = true;

    this._halTransactionsService.getTransactionsById(id).then((transaction) => {

      const findObject = this.activeTransactions.find(x => parseInt(x.id, 10) === parseInt(id, 10));

      if (!isUndefined(findObject)) {

        const i = this.activeTransactions.indexOf(findObject);
        this.activeTransactions[i] = transaction;
      } else {

        this.activeTransactions.push(transaction);
      }

      this._isLoading = false;
      this._ref.markForCheck();
    });
  }

  /**
   * Close tab by id
   * @param {string} tabId
   */
  closeTabById(e, tabId: string): void {
    e.preventDefault();

    const findObject = this.activeTransactions.find(x => parseInt(x.id, 10) === parseInt(tabId, 10));

    if (!isUndefined(findObject)) {

      const i = this.activeTransactions.indexOf(findObject);
      this.activeTransactions.splice(i, 1);
    }
  }

  /**
   * Sets to params mandatory search params
   * @param {Params} params
   * @returns {Params}
   */
  private addParamsMandatoryForTransactions(params: Params): Params {

    params.add(new Param('sort', 'historyFirstDate,desc'));

    if (this.isUserLocation()) {
      params.add(new Param('locationId', this._userService.getUser().locationId));
    }

    return params;
  }

  /**
   * Test if the user is location user
   * @returns {boolean}
   */
  public isUserLocation(): boolean {

    this._userLocationId = this._userService.getUser().locationId;

    if (!isUndefined(this._userLocationId) && this._userLocationId !== null) {
      return true;
    }

    return false;
  }

  public getReOpenRefundModal(): any {

    return this._reOpenRefundModal;
  }
}
