import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'c360-dashboard-control',
  templateUrl: './dashboard-control.component.html'
})
export class DashboardControlComponent {

  @Input() isRefreshing: boolean;

  @Output() onRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _cd: ChangeDetectorRef) {
  }

  refresh() {
    this.onRefresh.emit(true);
  }
}
