import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Film} from '../../models/film.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: '[c360-events-films-list-item]',
  templateUrl: './films-list-item.component.html',
  styleUrls: [
    './films-list-item.component.scss'
  ]
})
export class FilmsListItemComponent {

  @Input() film: Film;
  @Output() onDelete: EventEmitter<Film> = new EventEmitter<Film>();

  constructor() {
  }

  delete(): void {
    this.onDelete.emit(this.film);
  }

  /**
   *
   * @returns {any}
   */
  get cdnImageEnvironment(): any {
    return environment.cdnImage;
  }
}
