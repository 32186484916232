import {Component, OnDestroy, OnInit} from '@angular/core';
import {HalPaymentService} from '../../services/hal-payment.service';
import {Subscription} from 'rxjs';
import {Voucher} from '../../models/voucher.model';

@Component({
  selector: 'c360-voucher-list',
  templateUrl: './voucher-list.component.html'
})
export class VoucherListComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;

  private _voucherList: { [key: string]: Voucher };
  private _voucherListKey: Array<string>;

  /**
   *
   * @param {HalPaymentService} _halPaymentService
   */
  constructor(private readonly _halPaymentService: HalPaymentService) {
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
    this._subscriptions
      .add(
        this._halPaymentService
          .data
          .subscribe(
            (data) => {
              if (data && data.vouchers) {
                this._voucherList = data.vouchers.data;
                this._voucherListKey = Object.keys(this._voucherList);
              } else {
                this._voucherList = {};
                this._voucherListKey = [];
              }
            },
            () => {
            }
          )
      );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * @return {{ [p: string]: Voucher }}
   */
  get voucherList(): { [p: string]: Voucher } {
    return this._voucherList;
  }

  /**
   *
   * @return {Array<string>}
   */
  get voucherListKey(): Array<string> {
    return this._voucherListKey;
  }
}
