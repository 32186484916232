import {AbstractControl} from '@angular/forms';
export class SpioValidation {

  static LastTicketIdIsAfterNextTicketId(AC: AbstractControl) {
    const nextTicketId = AC.get('nextTicketId').value; // to get value in input tag
    const lastTicketId = AC.get('lastTicketId').value; // to get value in input tag
    if (lastTicketId > nextTicketId) {
      return null;
    } else {
      AC.get('lastTicketId').setErrors(
        {LastTicketIdIsAfterNextTicketId: true}
      );
    }
  }
}
