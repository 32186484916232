import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CounterTransactionSearch} from '../../../models/counter-transaction-search.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: '[c360-counter-transactions-list-item]',
  templateUrl: './counter-transactions-list-item.component.html'
})
export class CounterTransactionsListItemComponent {

  @Input() transaction: CounterTransactionSearch;
  @Output() onDelete: EventEmitter<CounterTransactionSearch> = new EventEmitter<CounterTransactionSearch>();

  constructor(private _translationService: TranslateService) {
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }
}
