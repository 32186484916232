<!--
TODO
<c360-loading [loadingMessage]="'Storing your data...'" [hidden]="!loading"></c360-loading>
-->
<!--<form novalidate autocomplete="off" [formGroup]="eventForm" (ngSubmit)="submitForm()" [hidden]="loading">-->
<form novalidate autocomplete="off" [formGroup]="eventForm" (ngSubmit)="submitForm()">
  <div class="row" *ngIf="isLoadingData">
    <div class="col-md-12">
      <c360-loading></c360-loading>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group row" *ngIf="formMode === 'create'">
        <label class="col-sm-2 col-form-label" for="select-event">Master Filme suchen und auswählen</label>
        <div class="col-sm-9">
          <ng-template #eventResultsTemplate let-event="result" let-t="term">
            <span *ngIf="event">
            {{event.name}}
            </span>
          </ng-template>
          <div class="event-selection">
            <input id="select-event"
                   type="text"
                   class="form-control"
                   placeholder="Existierende Master Film auswählen"
                   #searchEventElement
                   [(ngModel)]="selectedEvent"
                   (ngModelChange)="onChangeEvent()"
                   [ngModelOptions]="{standalone: true}"
                   [ngbTypeahead]="search()"
                   [editable]="false"
                   [resultTemplate]="eventResultsTemplate"
                   [inputFormatter]="formatter()"/>
            <div #eventSelected class="event-selected" [hidden]="!eventForm?.get('id').value">
              <div>
                {{eventForm.get('name').value}}
                <button type="button"
                        class="remove-btn"
                        (click)="removeEvent()">
                  <i class="fa fa-times-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" *ngIf="formMode === 'create'">
      <hr>
    </div>
    <div class="col-md-12">
      <input *ngIf="formMode != 'create'"
             type="text"
             class="form-control"
             formControlName="id"
             id="id"
             hidden>
      <div class="form-group row" [ngClass]="{
              'has-danger': eventForm.get('name').invalid && eventForm.get('name').dirty,
              'has-success': eventForm.get('name').valid && eventForm.get('name').dirty
              }">
        <label class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-9">
          <input type="text"
                 class="form-control"
                 formControlName="name"
                 id="name">
          <c360-form-validation-messages
            [control]="eventForm.get('name')"></c360-form-validation-messages>
        </div>
      </div>
      <div class="form-group row" [ngClass]="{
        'has-danger': eventForm.get('type').invalid && eventForm.get('type').dirty,
        'has-success': eventForm.get('type').valid && eventForm.get('type').dirty
        }">
        <label class="col-sm-2 col-form-label">Type</label>
        <div class="col-sm-9">
          <select class="form-control custom-select"
                  formControlName="type"
                  *ngIf="!isReadOnly">
            <option [ngValue]="null">--- Type auswählen ---</option>
            <option *ngFor="let key of eventTypesKeys" [value]="key" [label]="eventTypes[key]">{{eventTypes[key]}}</option>
          </select>
          <input
            type="text"
            class="form-control"
            formControlName="type"
            id="type"
            [readonly]="isReadOnly"
            *ngIf="isReadOnly">
          <c360-form-validation-messages
            [control]="eventForm.get('type')"></c360-form-validation-messages>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{
        'has-danger': eventForm.get('typeMasterId').invalid && eventForm.get('typeMasterId').dirty,
        'has-success': eventForm.get('typeMasterId').valid && eventForm.get('typeMasterId').dirty
        }">
        <label class="col-sm-2 col-form-label">TypeMasterId</label>
        <div class="col-sm-9">
          <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="typeMasterId"
                   id="typeMasterId"
                   [readonly]="isReadOnly">
            <span class="input-group-btn" *ngIf="eventForm.controls.type.value === 'FILM_CUSTOM' && !isReadOnly">
               <button type="button" class="btn btn-success" (click)="generateNewTypeMasterId()">Generate</button>
            </span>
          </div>
          <!--<input type="text"-->
                 <!--class="form-control"-->
                 <!--formControlName="typeMasterId"-->
                 <!--id="typeMasterId"-->
                 <!--[readonly]="isReadOnly">-->
          <div *ngIf="eventForm.get('typeMasterId').errors && (eventForm.get('typeMasterId').touched || eventForm.get('typeMasterId').dirty)"
               class="error-msg">
            <div [hidden]="!eventForm.get('typeMasterId').errors.pattern">
              <span [hidden]="eventForm.get('type').value !== 'FILM_CUSTOM'"
                    translate="MODUL_EVENTS.ERRORS.ALPHANUMERIC"></span>
              <span [hidden]="eventForm.get('type').value !== 'FILM_RENTRAK'"
                    translate="MODUL_EVENTS.ERRORS.NUMERIC"></span>
            </div>
          </div>
          <c360-form-validation-messages [control]="eventForm.get('typeMasterId')"></c360-form-validation-messages>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row mt-3">
    <div class="col-sm-12">
      <button type="submit" class="btn btn-success">Speichern</button>
      <button type="button" class="btn btn-primary" (click)="cancel()">Abbrechen</button>
      <button type="button" class="btn btn-danger" *ngIf="formMode != 'create'" (click)="delete()">Löschen</button>
    </div>
  </div>
</form>
