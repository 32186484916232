import {Injectable} from '@angular/core';

@Injectable()
export class ValidationService {

  constructor() {
  }

  static getValidatorErrorMessage(validatorName: string) {
    const config = {
      'required': 'VALIDATION_ERROR.REQUIRED',
      'invalidEmailAddress': 'VALIDATION_ERROR.INVALID_EMAIL',
      'invalidPassword': 'VALIDATION_ERROR.INVALID_PASSWORD',
      'minlength': 'VALIDATION_ERROR.INVALID_LENGTH',
      'MatchPassword': 'VALIDATION_ERROR.MATCH_PASSWORD',
      'securePassword': 'VALIDATION_ERROR.SECURE_PASSWORD',
      'iban': 'VALIDATION_ERROR.IBAN',
      'ngbDate': 'VALIDATION_ERROR.INVALID_DATE',
      'isNumber': 'VALIDATION_ERROR.INVALID_PICKUPCODE'
    };

    return config[validatorName];
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return {'invalidEmailAddress': true};
    }
  }

  /**
   * Validator that requires controls to have a non-empty value.
   * @param {?} control
   * @return {?}
   */
  static securePassword(control) {
    return /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.*[0-9]).{8,70})$/
      .test(control.value) ? null : {'securePassword': true};
  }

  static pickupcode(control) {

    if (!control.value && control.value === '') {

      return null;
    } else if (control.value && control.value.match(/^([0-9]\d*)?$/)) {

      return null;
    } else {

      return {'isNumber': true};
    }
  }

  static iban(control) {
    return !control.value || /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,17}/.test(control.value) ? null : {'iban': true};
  }
}
