import * as jwt_decode from 'jwt-decode';
import {environment} from '../../../environments/environment';
import {isUndefined} from 'util';

export interface UserResponse {
  access_token: string;
  refresh_token: string;
}

export class User {

  externalId: string;
  clientId: string;
  firstName: string;
  lastName: string;
  gender: string;
  username: string;
  authorityBase: string;
  mandatorId: string;
  mandatorName: string;
  locationId: string;
  locationName: string;
  salesCounterId: string;
  salesCounterName: string;
  userRoleIndex: number;

  constructor(private _accessToken?: string, private _refreshToken?: string, args?: any) {
    if (_accessToken != null) {
      this.populateByToken(_accessToken);
    }
    // else if (_refreshToken != null) {
    //  this.populateByToken(_refreshToken);
    // }
  }

  private populateByToken(token) {
    const decoded = jwt_decode(token);

    this.firstName = isUndefined(decoded.firstName) ? null : decoded.firstName;
    this.lastName = isUndefined(decoded.lastName) ? null : decoded.lastName;
    this.gender = isUndefined(decoded.gender) ? null : decoded.gender;
    this.username = isUndefined(decoded.user_name) ? null : decoded.user_name;
    this.externalId = isUndefined(decoded.externalId) ? null : decoded.externalId;
    this.authorityBase = isUndefined(decoded.authorityBase) ? null : decoded.authorityBase;
    this.mandatorId = isUndefined(decoded.mandatorId) ? null : decoded.mandatorId;
    this.mandatorName = isUndefined(decoded.mandatorName) ? null : decoded.mandatorName;
    this.locationId = isUndefined(decoded.locationId) ? null : decoded.locationId;
    this.locationName = isUndefined(decoded.locationName) ? null : decoded.locationName;
    this.salesCounterId = isUndefined(decoded.salesCounterId) ? null : decoded.salesCounterId;
    this.salesCounterName = isUndefined(decoded.salesCounterName) ? null : decoded.salesCounterName;
    this.clientId = isUndefined(decoded.client_id) ? null : decoded.client_id;
    this.userRoleIndex = environment.userPermissionOrder.indexOf(this.authorityBase);
  }

  getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(value: string) {
    this._accessToken = value;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  set refreshToken(value) {
    this._refreshToken = value;
  }
}
