<c360-navigation-top *ngIf="isLoggedIn && isInitComplete"></c360-navigation-top>

<div class="app-body">
  <c360-navigation-side *ngIf="isLoggedIn && isInitComplete"></c360-navigation-side>
  <main class="main">
    <c360-loading [loadingMessage]="'LOADING.TEXT' | translate" [hidden]="!loading"></c360-loading>
    <div [hidden]="loading" class="router-output">
      <!--<c360-breadcrumb *ngIf="isLoggedIn && isInitComplete"></c360-breadcrumb>-->
      <p>&nbsp;</p>
      <c360-alerts></c360-alerts>
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<c360-navigation-footer *ngIf="isLoggedIn && isInitComplete" class="app-footer"></c360-navigation-footer>

<iframe #ssoHolder id="ssoHolder" [src]="ssoUrl" width="1" height="1" (load)="ssoContainerLoaded()"></iframe>

<ng-container *c360Confirm>
  <c360-confirm-modal-component></c360-confirm-modal-component>
</ng-container>
