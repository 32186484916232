import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class InitService {

  private _initialUrl: string = null;
  private _initComplete: BehaviorSubject<boolean>;

  constructor() {
    this._initComplete = new BehaviorSubject<boolean>(false);
  }

  isInitComplete$(): Observable<boolean> {
    return this._initComplete;
  }

  get initialUrl(): string {
    return this._initialUrl;
  }

  set initialUrl(value: string) {
    this._initialUrl = value;
  }

  set initComplete(value: boolean) {
    this._initComplete.next(value);
  }
}
