import {HalData} from '../../shared/models/halData';
import {Transaction} from './transaction/transaction.model';

export class TransactionHalData extends HalData {

  data: { [id: string]: Transaction } = {};

  /**
   * Parse to Array Object
   *
   * @param response
   */
  parseToArrayObjects(response: any) {
    this.data = {};
    response._embedded.transactions.forEach((transaction: Transaction) => {
      this.parseToObject(transaction);
    });

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  /**
   * Parse to Object
   *
   * @param response
   * @returns {Transaction}
   */
  parseToObject(response: any): Transaction {

    if (response != null) {

      const transaction = new Transaction(
        response._links,
        response.id,
        response.mandatorId,
        response.externalId,
        response.type,
        response.pickupCode,
        response.processId,
        response.customer,
        response.histories,
        response.performance,
        response.tickets,
        response.payments,
        response.date,
        response.status,
        response.fullAmount,
        response.fullAmountWithFee,
        response.clientIpAddress,
        response.clientVersion,
        response.confirmationMailReceived,
        response.currency,
        response.cdnReferencePdf,
        response.skipSaleFee,
        response.skipRefundFee,
        response.refundFeeNet,
        response.refundFeeGross,
        response.saleFeeNet,
        response.saleFeeGross,
        response.vouchers
      );
      this.data['' + response.id + ''] = transaction;
    }

    return this.data['' + response.id + ''];
  }
}
