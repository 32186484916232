<div class="row">
  <div class="col-md-4 form-inline">
    <a class="btn btn-primary"
       [routerLink]="['/','events','add']">
      <i class="fa fa-plus"></i>
      {{'MODUL_EVENTS.COMPONENT_EVENTS_LIST.ADD' | translate}}</a>
  </div>
  <div class="col-md-8 form-inline justify-content-end">
    <input class="form-control mr-sm-2 my-2"
           type="search"
           placeholder="Search"
           aria-label="Search"
           #searchInputElement
           (input)="inputSearchEvents.emit(searchInputElement.value);false;">
  </div>
</div>
