import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CounterResetTicketTypeModalComponent} from './counter-reset-ticket-type-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ConfirmationService} from '../../services/confirmation.service';
import {PrinterService} from '../../../shared/services/printer.service';
import {PrinterConnectionState, PrinterErrorTypes, PrinterState} from '../../../shared/enums/printer.enums';
import {CounterSpioNumberCheckComponent} from './counter-spio-number-check.component';
import {SalesCounterService} from '../../services/sales-counter.service';
import {ISalesCounter} from '../../../shared/models/sales-counter.model';
import {Transaction} from '../../models/transaction/transaction.model';
import {TranslateService} from '@ngx-translate/core';
import {TicketStatus} from '../../enums/ticket-status.enum';
import {TransactionTicket} from '../../models/transaction/transaction-ticket.model';
import {PrintableTicket} from '../../../shared/models/printer/printable-ticket.model';
import {PrintAcknowledgeResponse} from '../../../shared/models/printer/print-ack-response.interface';
import {AlertService} from '../../../shared/services/alert.service';
import {ErrorMessage} from '../../../shared/models/error.model';
import {PrintError} from '../../../shared/models/printer/print-error.model';
import {TransactionStatus} from '../../enums/transaction-status.enum';
import {TransactionPayment} from '../../models/transaction/transaction-payment.interface';
import {AcrossTabsParentService} from '../../../core/services/across-tabs.service';
import {TabCommunicationMessage, TabCommunicationMessageTypes} from '../../../core/models/tab-communication-message.interface';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {CounterPrintTicketModalComponent} from './counter-print-ticket-modal.component';
import {concatMap, filter, tap} from 'rxjs/operators';
import {CounterConfirmationCartStatus} from '../../enums/counter-confirmation-cart-status.enum';

@Component({
  selector: 'c360-counter-confirmation',
  templateUrl: './counter-confirmation.component.html'
})
export class CounterConfirmationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() externalTransactionIdToPrint = null;
  @Input() onlyShowPrintButtons = false;
  @Output() ticketsPrinted: EventEmitter<boolean> = new EventEmitter<boolean>();

  private externalTransactionId = null;

  private _subscription: Subscription = null;

  private _acknowledgedTickets: PrintableTicket[] = [];

  public hasPrinter: boolean;
  public salesCounter: ISalesCounter = null;
  public transaction: Transaction = null;
  public oldPickupCode: string;
  public ticketStatus = TicketStatus;
  public hasPrintedTickets: boolean = null;
  public hasPrintableTickets: boolean = null;

  public isLoading = false;

  public totalRefundFee: number = null;
  public totalVoucherValue: number = null;
  public uniquePayments: TransactionPayment[] = [];

  _isPrinting = false;
  _hasPrinted = false;
  private _printerState: PrinterState = null;

  public ticketsAvailableInPrinter: number = null;
  public numberOfPrintableTickets: number;
  public numberOfTicketsToBePrinted: number;
  public enoughPaperForNextPrint: boolean;

  private _spioBadgeStatus: string;

  private _printTicketModalRef: NgbModalRef = null;
  private _resetTicketModalRef: NgbModalRef = null;
  private _spioNumberModalRef: NgbModalRef = null;

  public printError: PrintError = null;

  private _spioCheckRequired = false;

  static isTicketPrintable(ticket): boolean {
    return (
      ticket.status === TicketStatus.BOOKING_COMPLETED ||
      ticket.status === TicketStatus.LOCAL_PRINT_INVALID ||
      ticket.status === TicketStatus.LOCAL_INVALIDATED ||
      ticket.status === TicketStatus.REFUND_FAILED ||
      ticket.status === TicketStatus.PRICE_CHANGE_COMPLETED ||
      ticket.status === TicketStatus.PRICE_CHANGE_FAILED
    );
  }

  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _confirmationService: ConfirmationService,
              private _printerService: PrinterService,
              private _salesCounterService: SalesCounterService,
              private _translationService: TranslateService,
              private _acrossTabService: AcrossTabsParentService,
              private _alertService: AlertService,
              private _logger: LoggerService,
              private _modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (this._subscription == null) {
      this._subscription = new Subscription();
      if (this.externalTransactionIdToPrint != null) {
        this._subscription.add(
          this._confirmationService.getConfirmation(this.externalTransactionIdToPrint).subscribe(response => {
            if (this.externalTransactionIdToPrint === response.externalId) {
              this.transaction = response;
              this.isLoading = false;
              this.updateTickets();
            }
          })
        );
      }
      this._subscription.add(
        this._route.params.subscribe((params) => {
          if (params != null && params.externalTransactionId != null && this.externalTransactionId !== params.externalTransactionId) {
            this.externalTransactionId = params.externalTransactionId;
            this.oldPickupCode = params.oldPickupCode;

            this._subscription.add(
              this._confirmationService.getConfirmation(this.externalTransactionId).subscribe(response => {
                if (this.externalTransactionId === response.externalId) {
                  this.transaction = response;
                  this.isLoading = false;
                  this.updateTickets();

                  if (this.transaction != null) {
                    this._acrossTabService.broadCastAll({
                      id: null,
                      type: TabCommunicationMessageTypes.CONFIRMATION_READY,
                      body: {
                        id: this.externalTransactionId
                      }
                    });
                    this._subscription.add(
                      this._confirmationService
                        .counterConfirmationCartEvent
                        .pipe(
                          filter((data) => data === CounterConfirmationCartStatus.SUCCESS),
                          concatMap(() => this._confirmationService.getConfirmation(this.externalTransactionId)),
                          tap((data) => {
                            this.transaction = data;
                            this.updateTickets();
                          })
                        )
                        .subscribe(
                          () => {
                          }
                        )
                    );

                    this._subscription.add(
                      this._acrossTabService.onTabMessage$().subscribe((msg: TabCommunicationMessage) => {
                          this._logger.warn('ON Tab MESSAGE', msg);
                          if (msg != null) {
                            if (msg.type === TabCommunicationMessageTypes.PRINT_TICKETS) {
                              this._acrossTabService.broadCastTo(msg.id, {
                                id: msg.id,
                                type: TabCommunicationMessageTypes.CLOSE_CHILD,
                                body: {
                                  id: msg.body.id
                                }
                              });
                              if (msg.body.id === this.transaction.externalId) {
                                this._logger.warn('Lets print some tickets');
                                if (this.transaction.status === TransactionStatus.COMPLETE) {
                                  this._confirmationService
                                    .setCounterConfirmationCart(msg)
                                    .subscribe();
                                }
                                if (this.hasPrinter && this.hasPrintableTickets) {
                                  this.printTickets();
                                }
                              }
                            }
                          }
                        },
                        (err) => {
                          this._logger.warn('ERR', err);
                        },
                        () => {
                          this._logger.warn('COMPLETE');
                        })
                    );
                  }
                }
              })
            );
          }
        })
      ).add(
        this._printerService.connectionState$().subscribe(state => {
          this.hasPrinter = state === PrinterConnectionState.CONNECTED;
        })
      ).add(
        this._printerService.printErrors$().subscribe(
          error => {
            if (error != null && error.type != null) {
              this.printError = error;
              switch (error.type) {
                case PrinterErrorTypes.TIMEOUT:
                case PrinterErrorTypes.NO_TEMPLATES:
                  this.printComplete();
                  break;
              }
            } else {
              this.printError = null;
            }
          },
          (e) => {
          }
        )
      ).add(
        this._printerService.printAcks$().subscribe(
          (ticket: PrintableTicket) => {
            if (ticket != null) {
              this._acknowledgedTickets.push(ticket);
            }
          }
        )
      ).add(
        this._printerService.printerState$().subscribe(
          (state) => {
            this._printerState = state;

            if (this._isPrinting && state === PrinterState.PRINT_COMPLETE) {
              this.printComplete();
            }
            this._isPrinting = (state !== PrinterState.IDLE && state !== PrinterState.MISSING);
            this.updatePrintStateModal();
          }
        )
      ).add(
        this._salesCounterService.getSalesCounter().subscribe((salesCounter: ISalesCounter) => {
          this.salesCounter = salesCounter;

          if (this.salesCounter != null) {
            if (
              this.salesCounter.nextTicketId == null ||
              this.salesCounter.lastTicketId == null ||
              this.salesCounter.lastTicketId < this.salesCounter.nextTicketId
            ) {
              this.showSpioNumberCheck();
            }
          }
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.externalTransactionIdToPrint && (changes.externalTransactionIdToPrint.previousValue === undefined || changes.externalTransactionIdToPrint.previousValue !== changes.externalTransactionIdToPrint.currentValue)) {
      this._acknowledgedTickets = [];
      this.salesCounter = null;
      this.transaction = null;
      this.hasPrintedTickets = null;
      this.hasPrintableTickets = null;
      this.totalRefundFee = null;
      this.totalVoucherValue = null;
      this.uniquePayments = [];
      this._isPrinting = false;
      this._printerState = null;
      this.ticketsAvailableInPrinter = null;
      if (this._subscription != null) {
        this._subscription.unsubscribe();
      }
      this._subscription = null;
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    this._logger.warn('UNSUB');
    this._subscription.unsubscribe();
  }

  /**
   * Filter tickets by type and get templates for these tickets
   */
  printTickets() {
    this._logger.warn('PRINT TICKETS', this.salesCounter);
    if (this.salesCounter == null || this.salesCounter.nextTicketId == null) {
      const alert = new ErrorMessage(
        'PRINTER',
        'Starten Sie die Drucker Software neu, und loggen Sie sich dann erneut ein.',
        'Die Kasse ist nicht vollständig registriert.'
      );
      this._alertService.addAlert(alert);
    } else {
      if (this.numberOfPrintableTickets > 0) {

        this._acknowledgedTickets = [];

        this._printerService.printTickets(
          this.transaction,
          this.getPrintableTickets(),
          this.salesCounter
        );
      }
    }

  }

  private getTicketsToPrint(): TransactionTicket[] {
    if (this.transaction == null) {
      return [];
    }
    return this.transaction
      .tickets
      .filter(ticket => CounterConfirmationComponent.isTicketPrintable(ticket));
  }

  private getPrintableTickets(): string[] {
    let printableTickets = this.getTicketsToPrint()
      .map(ticket => ticket.id);
    // If there is not enough paper left, we will only print the exact number of ticket
    if (this.calcTicketsAvailableInPrinter() < printableTickets.length) {
      printableTickets = printableTickets.slice(0, this.ticketsAvailableInPrinter);
    }
    return printableTickets;
  }

  /**
   * After all tickets were printed (sent to printer)
   * or a Timeout occured, we'll have to acknowledge Print of tickets
   */
  printComplete(): void {
    if (this._acknowledgedTickets != null && this._acknowledgedTickets.length > 0) {

      // Set SPIO Numbers for acknowledgedTickets
      this._printerService.acknowledgePrintOfTickets(this._acknowledgedTickets, this.salesCounter).subscribe(
        (response: PrintAcknowledgeResponse) => {
          if (response !== null) {
            this._acknowledgedTickets = [];
            this._printerService.cleanUpAfterPrint();
            if (response.ticketIds != null) {
              // Map to Array of TransactionTickets
              const tickets = Object.keys(response.ticketIds).map(id => {
                return response.ticketIds[id];
              });
              this.salesCounter.nextTicketId = response.nextTicketId;
              this.updateTickets(tickets);
              this._hasPrinted = true;
              this.ticketsPrinted.emit(true);
            }
          } else {
          }
        },
        () => {
          this._acknowledgedTickets = [];
          this._printerService.cleanUpAfterPrint();
          this.ticketsPrinted.emit(false);
        }
      );
    } else {
      this._isPrinting = false;
      this.updatePrintStateModal();
      this.ticketsPrinted.emit(false);
    }
  }

  private updateTickets(tickets: TransactionTicket[] = null) {

    if (this.transaction != null && (this.externalTransactionIdToPrint == null || this.transaction.externalId === this.externalTransactionIdToPrint)) {
      console.warn('updating tickets', tickets);
      if (tickets != null && !Array.isArray(tickets)) {
        tickets = [tickets];
      }
      if (tickets != null && Array.isArray(tickets)) {
        this.transaction.tickets.forEach((t, index) => {
          const match = tickets.map(ticket => ticket.id).indexOf(t.id);
          if (match > -1) {
            this.transaction.tickets[index] = tickets[match];
          }
        });
      }
      this.totalRefundFee = 0;
      if (!this.transaction.skipRefundFee) {
        this.transaction.tickets.forEach((t) => {
          if (t.status === TicketStatus.REFUND_COMPLETED) {
            this.totalRefundFee += t.refundFee;
          }
        });
      }

      this.totalVoucherValue = 0;
      this.transaction.tickets.forEach(t => {
        if (t.voucher != null) {
          this.totalVoucherValue += (this.transaction.skipSaleFee) ? t.voucher.amount : t.voucher.amountWithFee;
        }
      });
      if (this.transaction.vouchers != null && this.transaction.vouchers.length > 0) {
        this.transaction.vouchers.forEach(v => {
          this.totalVoucherValue += v.amount;
        });
      }

      this.uniquePayments = [];
      const unique = {};
      if (this.transaction.payments != null) {
        this.transaction.payments.forEach(p => {
          unique[p.providerType] = p;
        });
        Object.keys(unique).forEach(type => {
          this.uniquePayments.push(
            unique[type]
          );
        });
      }

      this.transaction.payments.forEach(p => {
        p.icon = Transaction.getIconForPayment(p.providerType);
      });

      this.hasPrintedTickets = this.transaction.tickets.some(ticket => ticket.status === TicketStatus.LOCAL_PRINTED);
      this.hasPrintableTickets = this.transaction.tickets.some(
        (ticket) => CounterConfirmationComponent.isTicketPrintable(ticket)
      );
      if (!this._spioCheckRequired && this.salesCounter != null) {
        const mostRecentSPIO = Math.max(...this.transaction.tickets.map(ticket => ticket.salesCounterTicketId));
        this._spioCheckRequired = (mostRecentSPIO === this.salesCounter.nextTicketId - 1);
      }

      this.checkPrinterState();

      if (this.externalTransactionIdToPrint && this.hasPrintableTickets != null) {
        if (this.hasPrinter) {
          if (this.hasPrintableTickets === true) {
            this.printTickets();
          } else if (!this._hasPrinted) {
            this.ticketsPrinted.emit(true);
          }
        } else {
          this.ticketsPrinted.emit(false);
        }
      }
    }
  }

  calcTicketsAvailableInPrinter(): number {
    this.ticketsAvailableInPrinter = (this.salesCounter != null) ?
      (this.salesCounter.lastTicketId - this.salesCounter.nextTicketId) + 1 :
      0;
    return this.ticketsAvailableInPrinter;
  }

  /**
   * Check and Set Printer State
   * Check for Printable Tickets and Paper left in Printer
   */
  private checkPrinterState(): void {
    console.warn('checking Printer State', this.salesCounter);
    // Calc Number of Tickets left in Printer

    // Get number of Tickets to be printed
    this.numberOfPrintableTickets = this.getPrintableTickets().length;
    this.numberOfTicketsToBePrinted = this.getTicketsToPrint().length;

    // Default Badge
    let spioBadgeStatus = 'badge-primary';

    // Only Check if there are printable Tickets
    if (this.hasPrintableTickets) {
      // Check if enough tickets are left in printer
      this.enoughPaperForNextPrint = this.calcTicketsAvailableInPrinter() >= this.numberOfTicketsToBePrinted;

      if (this.calcTicketsAvailableInPrinter() <= 0) { // Danger if No Paper left
        spioBadgeStatus = 'badge-danger';
        this.showSpioNumberCheck();
      } else if (!this.enoughPaperForNextPrint) { // Warning if not enough Paper
        spioBadgeStatus = 'badge-warning';
      }

    } else {
      this.enoughPaperForNextPrint = true;
    }

    this._spioBadgeStatus = spioBadgeStatus;
  }

  /**
   * Show SPIO Check Modal
   */
  public showSpioNumberCheck(): void {
    if (this.salesCounter != null) {
      if (this._spioNumberModalRef != null) {
        this._spioNumberModalRef.close();
        this._spioNumberModalRef = null;
      }

      this._spioNumberModalRef = this._modalService.open(CounterSpioNumberCheckComponent, {backdrop: 'static'});
      this._spioNumberModalRef.componentInstance.salesCounter = this.salesCounter;

      this._spioNumberModalRef.result.then(
        (result) => {
          if (result != null && result !== false) {
            this.salesCounter = result;
            this.checkPrinterState();
          }
        });
    }
  }

  /**
   * Show Ticket Reset Modal
   */
  public showTicketResetModal(): void {
    if (this.salesCounter != null) {
      if (this._resetTicketModalRef != null) {
        this._resetTicketModalRef.close();
        this._resetTicketModalRef = null;
      }
      this._resetTicketModalRef = this._modalService.open(CounterResetTicketTypeModalComponent, {backdrop: 'static'});
      this._resetTicketModalRef.componentInstance.setTransaction(this.transaction);
      this._resetTicketModalRef.componentInstance.salesCounter = this.salesCounter;
      this._resetTicketModalRef.result.then(
        (data) => {
          if (data != null && data.transactionId != null) {
            this.salesCounter.nextTicketId = data.nextTicketId;
            if (data.ticketIds != null && Object.keys(data.ticketIds).length > 0) {
              this.updateTickets(Object.keys(data.ticketIds).map(id => data.ticketIds[id]));
            }
            if (this.salesCounter != null && this._spioCheckRequired) {
              this.showSpioNumberCheck();
            }
          }

        });
    }
  }

  public closePrintError() {
    this.printError = null;
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }

  gotoDashboard() {
    this._router.navigate(['/counter'], {relativeTo: this._route});
  }

  gotoSchedule() {
    this._router.navigate(['/counter/schedule'], {relativeTo: this._route});
  }

  gotoDetails(_id: string) {
    this._router.navigate(['/counter/transactions/list/' + _id], {relativeTo: this._route});
  }

  updatePrintStateModal() {
    if (this._isPrinting) {
      if (this._printTicketModalRef == null) {
        this._printTicketModalRef = this._modalService.open(CounterPrintTicketModalComponent, {
          backdrop: 'static',
          centered: true
        });
      }
      if (this._printerState === PrinterState.PRINT_INIT) {
        this._printTicketModalRef.componentInstance.printState = 'Tickets werden vorbereitet';
      } else if (this._printerState === PrinterState.PRINT_COMPLETE) {
        this._printTicketModalRef.componentInstance.printState = 'Tickets werden fertiggestellt';
      } else {
        this._printTicketModalRef.componentInstance.printState = 'Drucke ' + (this._printerService.getQueueCount() - 1) + ' Tickets';
      }
    } else {
      if (this._printTicketModalRef != null) {
        this._printTicketModalRef.close();
        this._printTicketModalRef = null;
      }
    }
  }

  isRefunded(ticket) {
    return ticket.status === TicketStatus.REFUND_COMPLETED.toString();
  }

  get spioBadgeStatus(): string {
    return this._spioBadgeStatus;
  }

  gotoNew() {
    this._router.navigate(['/counter/confirmation/' + this.transaction.externalId], {relativeTo: this._route});
  }
}
