export const locale_base_en = {
  'lang': 'en',
  'data': {
    'MODUL_COUNTER' : {
      'DASHBOARD': {
        'TITLE': 'Sales Counter - {{name}}',
        'TITLE_NO_PRINTER': 'Salse Counter'
      }
    }
  }
};
