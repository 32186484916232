<div class="modal-header">
  <h4 class="modal-title">Event löschen</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Möchten Sie wirklich das Event <strong>({{event.id}})</strong> löschen?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Schließen</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close(true)">Löschen</button>
</div>
