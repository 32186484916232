import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalAuditoriumsService} from '../hal-auditoriums.service';

@Injectable()
export class AuditoriumsRootLinkResolver implements Resolve<boolean> {

  constructor(private halService: HalAuditoriumsService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this.halService.getRootLink();
  }
}
