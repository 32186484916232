<div class="row" *ngIf="transactionListKey.length > 0">
  <c360-loading *ngIf="isLoading"></c360-loading>
  <div class="col-12 content">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12">
            <h2>{{ 'MODUL_USERS.TRANSACTION_LIST.HEADER' | translate}}</h2>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">{{ 'MODUL_USERS.TRANSACTION_LIST.PICKUP_CODE' | translate}}</th>
                <th scope="col" class="text-center">{{ 'MODUL_USERS.TRANSACTION_LIST.BOOKING_STATUS' | translate}}</th>
                <th scope="col">{{ 'MODUL_USERS.TRANSACTION_LIST.EVENT_NAME' | translate}}</th>
                <th scope="col">{{ 'MODUL_USERS.TRANSACTION_LIST.PERFORMANCE_START_DATE' | translate}}</th>
                <th scope="col">{{ 'MODUL_USERS.TRANSACTION_LIST.BOOKING_DATE' | translate}}</th>
                <th scope="col">{{ 'MODUL_USERS.TRANSACTION_LIST.LOCATION' | translate}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let key of transactionListKey">
                <td>{{transactionList[key].pickupCode}}</td>
                <td class="text-center">
                  {{'MODUL_USERS.TRANSACTION_LIST.TRANSACTION_STATUS.' + transactionList[key].historyLastStatus |
                  translate}}
                </td>
                <td>{{transactionList[key].eventTitle}}</td>
                <td>
                  {{transactionList[key].performanceStartDateAsFormatedDate}}
                </td>
                <td>
                  {{transactionList[key].historyFirstDateAsFormatedDate}}
                </td>
                <td>{{transactionList[key].locationName}}</td>
              </tr>
              </tbody>
            </table>
            <!-- Start Pagination -->
            <ngb-pagination *ngIf="page"
                            [maxSize]="5"
                            [rotate]="true"
                            [ellipses]="true"
                            [boundaryLinks]="true"
                            [collectionSize]="page.totalElements"
                            [pageSize]="page.totalElementsForPage"
                            [(page)]="page.currentPage"
                            (pageChange)="getAllByPage($event)"></ngb-pagination>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
