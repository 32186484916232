import {Component, OnDestroy, OnInit} from '@angular/core';
import {HalEventsService} from '../../services/hal-events.service';
import {Event} from '../../models/event.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggerService} from '../../../shared/services/logger/logger.service';

@Component({
  selector: 'c360-events-add',
  templateUrl: './add.component.html'
})

export class EventsAddComponent implements OnInit, OnDestroy {

  event: Event = new Event();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _halEventService: HalEventsService,
    private activatedRoute: ActivatedRoute,
    private _logger: LoggerService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  save(data) {
    this._logger.info('save Event', data);

    if (!data.id) {
      this._halEventService.saveEvent(data)
        .then((event: Event) => {
          if (event && event.id) {
            this.router.navigate(['../detail/' + event.id], {
              relativeTo: this.route,
              replaceUrl: true,
              fragment: 'create-new-film'
            });
          }
        })
        .catch((err) => {
          this._logger.error('Save failed', err);
        });
    } else if (data.id) {

      this._halEventService.updateEvent(data).then((event: Event) => {

        if (event && event.id) {

          this.router.navigate(['../detail/' + event.id], {
            relativeTo: this.route,
            replaceUrl: true,
            fragment: 'create-new-film'
          });
        }
      })
        .catch((err) => {
          this._logger.error('Update failed', err);
        });
    } else {
      this.router.navigate(['../detail/' + data.id], {
        relativeTo: this.route,
        replaceUrl: true,
        fragment: 'create-new-film'
      });
    }
  }

  cancel() {
    this._logger.info('Cancel', event);
    this.router.navigate(['../'], {
      relativeTo: this.route,
      replaceUrl: true
    });
  }
}
