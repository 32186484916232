export const locale_base_de = {
  'lang': 'de',
  'data': {
    'COMMON' :{
      'CHOOSE_LOCATION': 'Bitte wählen Sie oben in der Navigation einen Standort aus.'
    },
    'MODUL_USERS': {
      'NAME': 'User',

      'COMPONENT_FILTER': {
        'SELECT_ROLES': '-- Rolle auswählen --',
        'ROLES': 'Rolle auswählen',
        'ADD': 'Benutzer hinzufügen',
        'SEARCH': 'Suchen',
        'SEARCH_PLACEHOLDER': 'Email / Vorname / Name',
        'FILTER': 'Filter',
        'OPTIONAL_FILTER': 'Optionale Filterung',
        'ACTIVE_USER': 'Aktive Kunden',
        'BLOCKED_USER': 'Gesperrte Kunden',
        'UNCONFIRMED_USER': 'Unbestätigte Kunden',
      },

      'COMPONENT_LIST': {
        'NAME': 'Profile',
        'ALL_USERS_LIST': 'Liste aller User',
        'NAME_LASTNAME': 'Name / Vorname',
        'EMAIL': 'Email',
        'BIRTHDAY': 'Geburtstag',
        'CONFIRMED': 'Bestätigt',
        'STATUS': 'Status',
        'ROLE': 'Rolle'
      },

      'COMPONENT_DETAIL': {
        'NAME': 'Detail',
        'EDIT_PROFILE': 'Kundenprofil bearbeiten',
        'PROFILE_DATA': 'Profildaten',
        'USER_ID': 'Kunden-ID',
        'CONFIRMED': 'Bestätigt',
        'SALUTATION': 'Anrede',
        'MR': 'Herr',
        'MS': 'Frau',
        'FIRSTNAME': 'Vorname',
        'LASTNAME': 'Nachname',
        'EMAIL': 'Email',
        'EMAIL_CONFIRMED': 'Email Bestätigt',
        'EMAIL_UNCONFIRMED': 'Email Unbestätigte',
        'BIRTHDAY': 'Geburtstag',
        'STREET': 'Straße',
        'HOUSE_NO': 'Hausnummer',
        'ZIP_CODE': 'PLZ',
        'CITY': 'Ort',
        'COUNTRY': 'Land',
        'PHONE': 'Telefon',
        'MOBILE': 'Mobil',
        'SET_NEW_PASSWORD': 'Neues Passwort setzen',
        'SET_NEW_EMAIL': 'Neue Email setzen',
        'PASSWORD': 'Passwort',
        'SET_PASSWORD': 'Passwort setzen',
        'SET_EMAIL': 'Email setzen',
        'GENERATE_NEW_PASSWORD': 'Generieren',
        'BOOKING_OVERVIEW': 'Buchungsübersicht',
        'LIST_ALL_EVENTS': 'Liste aller Events',
        'FILM_EVENT': 'Film / Event',
        'PERFORMANCE': 'Vorstellung',
        'CINEMA': 'Kino',
        'BOOKING_STATUS': 'Buchungsstatus',
        'TICKET_STATUS': 'Ticketstatus',
        'DELETE_TEXT': 'Möchten Sie dieses Event wirklich löschen?',
        'DELETE_YES': 'Ja',
        'DELETE_NO': 'Nein',
        'SET_ROLE': 'Rolle setzen',
        'MANDATOR': 'Mandator',
        'LOCATION': 'Standort',
        'ROLE_SUPERADMIN': 'Super-Administrator',
        'ROLE_ADMIN': 'Administrator',
        'ROLE_USER': 'Kunde',
        'ACCOUNT_ENABLED': 'Account Status',
        'ACCOUNT_LOCKED': 'Aktive/Gesperrte',
        'UNLOCKED': 'Aktiver Benutzer',
        'LOCKED': 'Gesperrter Benutzer',
        'USER_ACTIVE': 'aktiv',
        'USER_INACTIVE': 'inaktiv',
        'SELECT_GENDER_PLACEHOLDER': 'Anrede auswählen...',
        'SELECT_MANDATOR_PLACEHOLDER': 'Mandator auswählen...',
        'SELECT_LOCATION_PLACEHOLDER': 'Standort auswählen...',
        'SELECT_COUNTRY_PLACEHOLDER': 'Land auswählen...',
        'MODAL_TITLE_DELETE': 'User löschen',
        'DELETE_USER_INFO': 'Soll der Bentzer "{{user}}" wirklich gelöscht werden.',
        'SAVED_USER_INFO': 'Die Änderungen wurden erfolgreich übernommen. Sollten Sie die E-Mail-Adresse geändert haben so wird eine Bestätigungslink an die neue E-Mail-Adresse gesendet. Bitte führen Sie diesen innerhalb von 72 Stunden aus. Ansonsten ist die Änderung unwirksam.'
      },
      'TRANSACTION_LIST': {
        'HEADER': 'Transaktionen',
        'PICKUP_CODE': 'Pickup Code',
        'EVENT_NAME': 'Filmtitel',
        'PERFORMANCE_START_DATE': 'Veranstaltungsdatum',
        'BOOKING_STATUS': 'Status',
        'BOOKING_DATE': 'Buchung',
        'LOCATION': 'Standort',
        'TRANSACTION_STATUS': {
          'ALL': 'Alle',
          'COMPLETE': 'Erfolgreich',
          'CANCELED': 'Abgebrochen',
          'FAILED': 'Fehlerhaft',
          'REFUNDED': 'Storniert',
          'REFUNDED_PARTIAL': 'Teil Storniert',
          'REFUND_FAILED': 'Storno Fehler',
          'INCOMPLETE': 'Nicht fertiggestellt',
          'WAITING_PAYMENT': 'Zahlung ausstehend',
          'WAITING_TICKETSYSTEM': 'Ticketsystem ausstehend',
          'WAITING_REFUND': 'Stornierung ausstehend',
          'WAITING_EXPORT': 'Export ausstehend'
        },
      },
      'ROLES': {
        'ROLE_ADMIN': 'Administrator',
        'ROLE_MANDATOR': 'Mandator',
        'ROLE_LOCATION': 'Location',
        'ROLE_SALES_COUNTER': 'Sales-Counter',
        'ROLE_PRE_SALES_COUNTER': 'Ext. Sales-Counter',
        'ROLE_TICKET_VALIDATOR': 'Ticket-Validator',
        'ROLE_CUSTOMER': 'Kunde'
      },
      'BTN': {
        'DELETE': 'Löschen',
        'CLOSE': 'Schließen'
      }

    }
  }
};
