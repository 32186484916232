import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HalEventsService} from '../hal-events.service';
import {Event} from '../../models/event.model';
import {LoggerService} from '../../../shared/services/logger/logger.service';

@Injectable()
export class EventDetailResolver implements Resolve<any> {

  constructor(
    private _halEventService: HalEventsService,
    private _logger: LoggerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {

    return new Promise((resolve, reject) => {
      this._halEventService
        .getEvent(route.params.id)
        .then((event: Event) => {
          resolve(event);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}
