<h1 [translate]="'LOGIN.PASSWORD_RESET.TITLE'"></h1>
<span class="close" (click)="goBack()"></span>
<p [translate]="'LOGIN.PASSWORD_RESET.INTRO'"></p>
<form novalidate autocomplete="off"
      *ngIf="!showConfirmation && !showErrorConfirmation"
      [formGroup]="forgotPasswordForm"
      (ngSubmit)="submitForm()">

  <div class="form-group" [ngClass]="{
        'has-danger': email.invalid && email.dirty,
        'has-success': email.valid && email.dirty
        }">
    <label [translate]="'LOGIN.PASSWORD_RESET.LABEL_EMAIL'" for="email"></label>
    <input type="email"
           class="form-control"
           formControlName="email"
           id="email">
    <c360-form-validation-messages [control] ="forgotPasswordForm.controls.email"></c360-form-validation-messages>
  </div>

  <button type="submit" class="btn btn-primary" [translate]="'LOGIN.PASSWORD_RESET.SUBMIT'"></button>
  <button class="btn btn-secondary pull-right" (click)="goBack()" [translate]="'LOGIN.PASSWORD_RESET.BACK_TO_LOGIN'"></button>
</form>
<ng-container *ngIf="showConfirmation">
  <p [translate]="'LOGIN.PASSWORD_RESET.CONFIRMATION'" class="alert alert-success"></p>
  <button class="btn btn-secondary" (click)="goBack()" [translate]="'LOGIN.PASSWORD_RESET.BACK_TO_LOGIN'"></button>
</ng-container>

<ng-container *ngIf="showErrorConfirmation">

  <p *ngIf="errorConfirmationMessage === null" [translate]="'LOGIN.PASSWORD_RESET.ERROR'" class="alert alert-danger"></p>
  <p *ngIf="errorConfirmationMessage !== null" class="alert alert-danger">{{errorConfirmationMessage}}</p>
</ng-container>
