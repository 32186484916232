import * as moment from 'moment';

export class S3authSignatureModel {
  public base64Policy: string;
  public signature: string;
  public accessKeyID: string;
  public awsRegion: string;
  public awsService: string;
  public successActionRedirect: string;
  public bucket: string;
  public uuid: string;
  public key: string;
  public absoluteUploadUrl: string;

  public date: Date;
  public amzDate: string;

  constructor(base64Policy: string,
              signature: string,
              accessKeyID: string,
              awsRegion: string,
              awsService: string,
              successActionRedirect: string,
              bucket: string,
              uuid: string,
              key: string,
              amzDate: string,
              absoluteUploadUrl: string) {
    this.base64Policy = base64Policy;
    this.signature = signature;
    this.accessKeyID = accessKeyID;
    this.awsRegion = awsRegion;
    this.awsService = awsService;
    this.successActionRedirect = successActionRedirect;
    this.bucket = bucket;
    this.uuid = uuid;
    this.key = key ? `${key}/` : key;

    this.amzDate = amzDate;
    this.absoluteUploadUrl = absoluteUploadUrl ? absoluteUploadUrl.replace(/null/g, '') : null;
    this.date = moment(this.amzDate).toDate();
  }

  private amzLongDate(date: Date): string {
    return date.toISOString().replace(/[:\-]|\.\d{3}/g, '').substr(0, 17);
  }

  get credential(): string {
    return `${this.accessKeyID}/${moment(this.date).format('YYYYMMDD')}/${this.awsRegion}/${this.awsService}/aws4_request`;
  }

  get url(): string {
    return `https://${this.bucket}.${this.awsService}.${this.awsRegion}.amazonaws.com`;
  }
}
