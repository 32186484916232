<!-- START CARD ROW -->
<div class="row" *ngIf="isLoading">
  <div class="col-md-12">
    <c360-loading></c360-loading>
  </div>
</div>
<div class="row">
  <div class="col-md-6 col-lg-4">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-ticket" aria-hidden="true"></i>
        Buchnungsdaten
      </div>
      <div class="card-body">
        Datum der Buchung: {{transaction?.date | date: 'dd.MM.yyyy - HH:mm'}}<br>
        Status: {{'MODUL_TRANSACTIONS.ENUMS.TRANSACTION_STATUS.' + transaction?.status | translate}}<br>
        Interne ID: {{transaction?.id}}<br>
        External ID: {{transaction?.externalId}}<br>
        Pickup Code: {{transaction?.pickupCode}}<br>
        Buchungsbestätigung: {{transaction?.confirmationMailReceived}}<br>
        Client: {{transaction?.clientVersion}}<br>
        IP-Adresse: {{transaction?.clientIpAddress}}
      </div>
    </div>
  </div>

  <div class="col-md-6 col-lg-4">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-user" aria-hidden="true"></i>
        Benutzerdaten zum Buchungszeitpunkt
      </div>
      <div class="card-body">
        Vorname: {{transaction?.customer?.firstName}}<br>
        Nachname: {{transaction?.customer?.lastName}}<br>
        Anrede: {{'MODUL_TRANSACTIONS.ENUMS.USER_GENDER.' +transaction?.customer?.gender | translate}}<br>
        Adresse: {{transaction?.customer?.streetName}} {{transaction?.customer?.houseNumber}}, {{transaction?.customer?.postCode}} {{transaction?.customer?.cityName}}<br>
        Festnetztelefon: {{transaction?.customer?.phoneNumber}}<br>
        Mobiltelefon: {{transaction?.customer?.mobileNumber}}<br>
        Email: {{transaction?.customer?.emailAddress}}
      </div>
    </div>
  </div>

  <div class="col-md-6 col-lg-4">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-money" aria-hidden="true"></i>
        Zahlungsdaten
      </div>
      <div class="card-body">

        <ng-container *ngFor="let voucher of transaction.vouchers; index as i;">
          <span *ngIf="voucher.amount < 0" class="text-danger">Einsatz: {{voucher.amount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}} {{voucher.name}} {{voucher.code}}</span>
          <span *ngIf="voucher.amount >= 0" class="text-success">Einsatz: {{voucher.amount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}} {{voucher.name}} {{voucher.code}}</span><br><br>
        </ng-container>

        <ng-container *ngFor="let payment of transaction.payments; index as i;">
          Zahlungstyp: {{'MODUL_TRANSACTIONS.ENUMS.PAYMENT_TYPES.' + payment.providerType | translate}}
          <button class="btn mt-0 float-right btn-link pt-0 pb-0 pr-0" *ngIf="payment.providerType === 'DIRECT_DEBIT' && payment.type === 'DEBIT'" (click)="onEditPayments(payment)">
            <i class="fa fa-pencil"></i>
            Bearbeiten
          </button>
          <br>
          <ng-container *ngIf="payment.providerType === 'DIRECT_DEBIT'">
            <ng-container *ngIf="payment.providerTypeIdMasked != null && payment.providerTypeIdMasked != '' ">
            IBAN: {{payment.providerTypeIdMasked}}<br>
            </ng-container>
            <ng-container *ngIf="payment.providerTypeReference != null && payment.providerTypeReference != '' ">
            Mandats-Referenz: {{payment.providerTypeReference}}<br>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="payment.type === 'DEBIT'">
            Betrag:  <span class="text-success">{{payment.amount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</span><br>
          </ng-container>

          <ng-container *ngIf="payment.type === 'CREDIT'">
            Betrag:  <span class="text-danger">{{payment.amount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</span><br>
          </ng-container>

          <ng-container *ngIf="payment.providerType === 'VOUCHERCARD'">
            Code: {{payment.providerTypeIdMasked}}<br>
          </ng-container>
          <br>
        </ng-container>

        <br>Gesamtbetrag: {{transaction.fullAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}
      </div>
    </div>
  </div>

</div>
<!-- END CARD ROW -->

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-film" aria-hidden="true"></i>
        Ticketdaten
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="card mb-0">
              <div class="card-body">
                <p>
                  Filmtitel: {{transaction?.performance?.filmTitle}}<br>
                  Standort: {{transaction?.performance?.locationName}}<br>
                  Saal: {{transaction?.performance?.auditoriumName}}<br>
                  Vorstellungsdatum: {{transaction?.performance?.startDate | date: 'dd.MM.yyyy - HH:mm'}}<br>
                  Ticketanzahl: {{transaction?.getTotalTickts()}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-8">
            <div class="row">
              <div class="col">
                <table class="table table-striped table-sm table-bordered">
                  <thead>
                  <tr>
                    <th *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'">Platz</th>
                    <th>Preis-Art</th>
                    <th>Status</th>
                    <th>Gutscheincode</th>
                    <th>Ticket-ID</th>
                    <th>Betrag</th>
                    <th>PDF</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let ticket of transaction.tickets; index as i;">
                    <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'">Reihe {{ticket.spaceRow}}, Platz {{ticket.spaceRowPosition}}</td>
                    <td>{{ticket.priceCategoryName}}<b *ngIf="ticket.priceMultiId" class="nobr"> [Part {{ticket.priceMultiId}}]</b></td>
                    <td>{{'MODUL_TRANSACTIONS.ENUMS.TICKET_STATUS.' + ticket.status | translate}}</td>
                    <td>{{ticket.voucher?.code}}</td>
                    <td>{{ticket.salesCounterTicketId}}</td>
                    <td>{{ticket.absoluteFullAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
                    <td><button [disabled]="ticket.cdnReferencePdf == null || !isTransactionRefundable(transaction)" (click)="onClickDownload(ticket.cdnReferencePdf)" class="btn btn-primary" style="color: #ffffff;">Download</button></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-auto pr-4">
                <div class="d-flex flex-column align-items-start">
                  <button type="button" class="btn btn-primary btn-big mb-2" [disabled]="!isTransactionRefundable(transaction)" (click)="recreateBookingConfirmationPdf(true)">PDFs erstellen</button>
                  <button type="button" class="btn btn-warning btn-big mb-2" [disabled]="!isTransactionUpdateable(transaction)" (click)="openUpdateTicketsType(transaction, null, null, null)"><i class="fa fa-pencil" aria-hidden="true"></i> Tickets bearbeiten</button>
                  <button type="button" class="btn btn-danger btn-big mb-2" [disabled]="!isTransactionRefundable(transaction)" (click)="openRefundTickets(null, null, null)"><i class="fa fa-ban" aria-hidden="true"></i> Tickets stornieren</button>
                  <button type="button" class="btn btn-danger btn-big mb-2" [disabled]="!isChargeBackable()" (click)="chargeBack(transaction.externalId)"><i class="fa fa-ban" aria-hidden="true"></i> Storno für Rücklastschrift</button>
                  <div *ngIf="transaction.isChargeBackMailCompleted === true" class="alert alert-success">
                    Rücklaststorno ausgeführt!<br>
                    Rücklastschrift versendet.
                  </div>
                  <div *ngIf="transaction.isChargeBackMailFailed === true" class="alert alert-warning">
                    Rücklaststorno ausgeführt!<br>
                    Rücklastschrift nicht versendet
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<h2>Aktionen</h2>
<!-- START CARD ROW -->
<div class="row">

  <div class="col-md-6 col-lg-4">
    <div class="card mb-0">
      <div class="card-header">
        <i class="fa fa-envelope" aria-hidden="true"></i>
        Buchungsbestätigungs E-Mail
      </div>
      <div class="card-body">
        <p>
          Zuletzt angefordert am:<br>
          {{transaction?.lastBookingMailSentDate | date: 'dd.MM.yyyy - HH:mm'}}
        </p>
        <c360-transaction-resend-mail-form (onFormSubmit)="resendBookingConfirmationMail($event)" [transaction]="transaction"></c360-transaction-resend-mail-form>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-lg-4">
    <div class="card mb-0">
      <div class="card-header">
        <i class="fa fa-question-circle" aria-hidden="true"></i>
        Status Abfragen
      </div>
      <div class="card-body">
        <p>Status der Buchung:<br>{{'MODUL_TRANSACTIONS.ENUMS.TRANSACTION_STATUS.' + transaction.status | translate}}</p>
        <a href="{{bookingUrl}}confirmation/{{transaction?.externalId}}" target="_blank" class="btn btn-primary" style="color: #ffffff;">Bestätigung öffnen</a>
        <button [disabled]="transaction.cdnReferencePdf == null" (click)="onClickDownload(transaction.cdnReferencePdf)" class="btn btn-primary" style="color: #ffffff;">PDF Download</button>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-lg-4">
    <div class="card mb-0">
      <div class="card-header">
        <i class="fa fa-cog" aria-hidden="true"></i>
        Administrativ
      </div>
      <div class="card-body">
        <a (click)="recreateBookingConfirmationPdf()" class="btn btn-primary" style="color: #ffffff;">PDF neu erstellen</a>
        <button [disabled]="!isTransactionRecreateDataProtectionIdsAllowed(transaction)" (click)="recreateDataProtectionIds()" class="btn btn-primary" style="color: #ffffff;">IDs neu erstellen (Externe ID, Pickup Code & Mandats-Referenz)</button>
      </div>
    </div>
  </div>

</div>
<!-- END CARD ROW -->
<br>
<!-- START CARD ROW -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-history" aria-hidden="true"></i>
        Historie
      </div>
      <div class="card-body p-0">
        <table class="table table-striped table-bordered table-sm mb-0">
          <thead>
          <tr>
            <th>Zeitpunkt</th>
            <th>Status</th>
            <th>Error Code</th>
            <th>Error Message</th>
            <th>Client Type</th>
            <th>Client Verison</th>
            <th>IP-Adresse</th>
            <th>Nutzer</th>
            <th>Service Instanz</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let history of transaction.histories">
            <td>{{history?.date | date: 'dd.MM.yyyy'}}<br>{{history?.date | date: 'HH:mm'}}</td>
            <td>{{'MODUL_TRANSACTIONS.ENUMS.HISTORY_STATUS.' + history.status | translate}}</td>
            <td class="nobr">{{history?.errorCode}}</td>
            <td width="500">{{history?.message}}</td>
            <td>{{history?.clientType}}</td>
            <td>{{history?.clientVersion}}</td>
            <td>{{history?.clientIpAddress}}</td>
            <td>{{history?.userId ? 'Benutzer: ' : 'Gast: '}}{{history?.userEmailAddress}}</td>
            <td>{{history?.serviceInstanceName}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- END CARD ROW -->
