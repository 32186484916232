import {BehaviorSubject, Observable, Subscription, throwError as observableThrowError, timer as observableTimer} from 'rxjs';
import {catchError, concatMap, delay, finalize, retry} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {HalData} from '../../shared/models/halData';
import {TransactionHalData} from '../models/transaction-hal-data';
import {TransactionSearchHalData} from '../models/transaction-search-hal-data';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserService} from '../../login/services/user.service';
import {Transaction} from '../models/transaction/transaction.model';
import {Params} from '../../shared/models/search/params.model';
import {SepaFileHalData} from '../models/sepa-file-hal-data';
import {ReportsGenerationStatus} from '../enums/report-generation-status.enum';
import {SalesCounterHalData} from '../../shared/models/sales-counter-hal-data';
import {SalesCounter} from '../../shared/models/sales-counter.model';
import {ITransactionPaymentUpdate} from '../models/transaction/transaction-payment.interface';

@Injectable()
export class HalTransactionsService extends HalService implements OnDestroy {

  protected gatewayName = 'booking-transaction';
  protected subscribtions: Subscription = new Subscription();

  public transactions = new BehaviorSubject<TransactionHalData>(null);
  public transactionsSearch = new BehaviorSubject<TransactionSearchHalData>(null);
  public transactionSepaFiles = new BehaviorSubject<SepaFileHalData>(null);

  /**
   * Constructor
   *
   * @param {HttpClient} http
   * @param {AlertService} _alertService
   * @param {UserService} _userService
   * @param {LoggerService} _logger
   */
  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
    super(http, _alertService, _userService, _logger);

    this.subscribtions = this.data.subscribe((data) => {
      if (data != null && data['transactions'] != null) {

        this.transactions.next(data['transaction']);
      }
      if (data != null && data['transactionSearches'] != null) {

        this.transactionsSearch.next(data['transactionSearches']);
      }
      if (data != null && data['findSepaFiles'] != null) {

        this.transactionSepaFiles.next(data['findSepaFiles']);
      }
    });
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }

  /**
   * Parse Root Links
   * @param response
   */
  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {

      let link = response._links[id];
      link = this.getRawLink(link.href); // Script page params

      if (id === 'salesCounters') {
        this._data[id] = new SalesCounterHalData(id, link);
      } else if (id === 'transaction' || id === 'transactionSearches') {

        this._data[id] = new TransactionSearchHalData(id, link);
      } else if (id === 'transactions') {

        this._data[id] = new TransactionHalData(id, link);
      } else if (id === 'findSepaFiles') {

        this._data[id] = new SepaFileHalData(id, link);
      } else {

        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   * Get All
   * @param {number} page
   * @returns {Observable<HalData>}
   */
  getAllTransactions(page: number = null): void {
    this.getAll('transactions', page);
  }

  /**
   * Get getSepaFiles
   * @param {number} page
   * @param {string} params
   * @returns {Observable<HalData>}
   */
  getAllSepaFiles(page: number = null, params: Params = null): boolean {

    this.getAll('findSepaFiles', page, params);

    return true;
  }

  /**
   * Get All
   * @param {number} page
   * @param {string} params
   * @returns {Observable<HalData>}
   */
  getAllTransactionsSearch(page: number = null, params: Params = null): void {

    this.getAll('transactionSearches', page, params);
  }

  /**
   * Get transactions by Transaction Id
   *
   * @param {String} id
   */
  getTransactionsById(id: String): Promise<Transaction> {

    return this.get(this._data['transactions'], id);
  }

  /**
   * Export transactions to SEPA File
   * @param params
   * @returns {Promise<any>}
   */
  exportTransactionToSepa(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'sepa/generateDirectDebitFile', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Resend booking confirmation mail
   * @param params
   * @returns {Promise<any>}
   */
  resendBookingConfirmationMail(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/mailResend', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Refund transactions and/or ticktes
   * @param params
   * @returns {Promise<any>}
   */
  refundTransaction(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/refund', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          'transactionStatus': transactionStatus
        };

        return response;
      });
  }

  chargeBack(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/chargeback', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          'transactionHistoryStatus': transactionStatus
        };

        return response;
      });
  }

  findSimpleListByPriceIds(_ids: string): Promise<any> {

    return this.http.get(this.gatewayUrl + 'price/prices/custom/findSimpleListByPriceIds?priceIds=' + _ids, {headers: this.headers})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Refund transactions and/or ticktes
   * @param params
   * @returns {Promise<any>}
   */
  updateTicketsPrice(params: any): Promise<any> {

    return this.http.post(
      this.gatewayUrl + 'booking-transaction/transactions/custom/updateTransactionTicketPriceType', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          'transactionStatus': transactionStatus
        };

        return response;
      });
  }

  /**
   *
   * @param {string} locationId
   * @returns {Promise<{[p: string]: SalesCounter}>}
   */
  searchAllSalesCountersByLocationId(locationId: string): Promise<{ [key: string]: SalesCounter }> {
    const url = `${this._data['salesCounters'].href}/search/searchAll?locationId=${locationId}`;
    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['salesCounters'].parseToArrayObjects(response);
        this.data.next(this._data);
        return this._data['salesCounters'].data;
      }
      return response;
    });
  }

  /**
   *
   * @param name
   * @param mandatorId
   * @param locationId
   * @param startDate
   * @param endDate
   * @param generateNew
   * @param salesCounterId
   */
  public generateReports(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true, salesCounterId: string = null): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': salesCounterId,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': false,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': false,
      'includePaymentReport': true,
      'includeSellingPoint': false,
      'groupBy': 'NONE',
      'generateNew': generateNew
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public generateDistributorReport(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': null,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': true,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': true,
      'includePaymentReport': true,
      'includeSellingPoint': false,
      'groupBy': 'PERFORMANCE',
      'generateNew': generateNew,
      'templateType': 'DISTRIBUTOR'
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public generatePerformanceReport(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': null,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': true,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': true,
      'includePaymentReport': true,
      'includeSellingPoint': false,
      'groupBy': 'PERFORMANCE',
      'generateNew': generateNew
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public generatePerformanceReportIncludeSellingPoint(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': null,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': true,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': true,
      'includePaymentReport': true,
      'includeSellingPoint': true,
      'groupBy': 'PERFORMANCE',
      'generateNew': generateNew
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public generateAuditoriumReport(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': null,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': true,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': true,
      'includePaymentReport': false,
      'includeSellingPoint': false,
      'groupBy': 'AUDITORIUM',
      'generateNew': generateNew
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public generatePostCodeReport(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': null,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': true,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': true,
      'includePaymentReport': false,
      'includeSellingPoint': false,
      'groupBy': 'POST_CODE',
      'generateNew': generateNew,
      'templateType': 'DISTRIBUTOR'
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public generateCityNameReport(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': null,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': true,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': true,
      'includePaymentReport': false,
      'includeSellingPoint': false,
      'groupBy': 'CITY_NAME',
      'generateNew': generateNew,
      'templateType': 'DISTRIBUTOR'
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public generateTotalReport(name: string, mandatorId: string, locationId: string, startDate: string, endDate: string, generateNew: boolean = true): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom`;
    const body = {
      'name': name,
      'mandatorId': mandatorId,
      'locationId': locationId,
      'salesCounterId': null,
      'auditoriumId': null,
      'performanceId': null,
      'periodDatesUsedForPerformanceDate': true,
      'periodStartDate': startDate,
      'periodEndDate': endDate,
      'includeCustomerBookings': true,
      'includePaymentReport': true,
      'includeSellingPoint': false,
      'groupBy': 'NONE',
      'generateNew': generateNew
    };
    return this.http.post(
      url,
      body
    ).pipe(
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public getReportObjectById(objectId: string): Observable<any> {
    const url = `${this._data['transactionReports'].href}/custom/${objectId}`;
    let retryOb;
    return (new Observable((observer) => {
      retryOb = observableTimer(0, 1000).pipe(
        concatMap(
          () => {
            return this.http.get(url).pipe(retry(5));
          }
        ),
        delay(500),)
        .subscribe(
          (response2) => {
            observer.next(response2);
            if (response2['generationStatus'] === ReportsGenerationStatus.GENERATION_IS_COMPLETED) {
              retryOb.unsubscribe();
              observer.complete();
            }
          }
        );
    })).pipe(
      finalize(
        () => {
          retryOb.unsubscribe();
        }
      ),
      catchError((err) => {
        this.handleError(err);
        return observableThrowError(err);
      })
    );
  }

  public getReportsHtml(counterReportObjectId: string): Observable<string> {
    const url = `${this._data['transactionReports'].href}/custom/${counterReportObjectId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'text/html;charset=UTF-8',
      'accept': 'text/html,application/xhtml+xml'
    });
    return this.http
      .get(
        url,
        {
          headers,
          responseType: 'text'
        }
      ).pipe(
        catchError((err) => {
          this.handleError(err);
          return '';
        })
      );
  }

  public updateTransactionPayment(value: ITransactionPaymentUpdate): Observable<any> {
    const url = `${this.gatewayUrl}booking-transaction/transactions/custom/updateTransactionPayment`;
    return this.http
      .post(
        url,
        value
      ).pipe(
        concatMap(() => {
          return (new Observable((observer) => {
            this.getTransactionsById(<any> value.transactionId)
              .then(
                () => {
                  observer.next();
                  observer.complete();
                },
                () => {
                  observer.error();
                  observer.complete();
                }
              );
          }));
        }),
        catchError((err) => {
          // this.handleError(err);
          return observableThrowError(err);
        })
      );
  }

  recreateBookingConfirmationPdf(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/pdfRecreate', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  recreateDataProtectionIds(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/dataProtectionIdsRecreate', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }
}

