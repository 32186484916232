import {TransactionPrice} from './transaction-price.interface';
import {TicketVoucher} from './ticket-voucher.model';

export class TransactionTicket {
  private _id: string;
  private _areaName: string;
  private _spaceId: string;
  private _priceId: string;
  private _priceMultiId: number;
  private _status: string;
  private _priceCategoryName: string;
  private _prices: TransactionPrice[];
  private _salesCounterTicketId: number;
  private _spaceName: string;
  private _spaceRow: string;
  private _spaceRowPosition: string;
  private _fullAmount: number;
  private _fullAmountWithFee: number;
  private _netAmount: number;
  private _selectedForRefund: boolean;
  private _vatSet: number;
  private _refundFee: number;
  private _saleFee: number;
  private _calcAmount: number;
  private _calcDifference: number;
  private _changePriceId: string;
  private _voucher: TicketVoucher = null;
  private _skipSaleFee: boolean;
  private _absoluteFullAmount: number;
  private _cdnReferencePdf: string;

  constructor() {
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get areaName(): string {
    return this._areaName;
  }

  set areaName(value: string) {
    this._areaName = value;
  }

  get spaceId(): string {
    return this._spaceId;
  }

  set spaceId(value: string) {
    this._spaceId = value;
  }

  get priceId(): string {
    return this._priceId;
  }

  set priceId(value: string) {
    this._priceId = value;
  }

  get priceMultiId(): number {
    return this._priceMultiId;
  }

  set priceMultiId(value: number) {
    this._priceMultiId = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get priceCategoryName(): string {
    return this._priceCategoryName;
  }

  set priceCategoryName(value: string) {
    this._priceCategoryName = value;
  }

  get prices(): TransactionPrice[] {
    return this._prices;
  }

  set prices(value: TransactionPrice[]) {
    this._prices = value;
  }

  get salesCounterTicketId(): number {
    return this._salesCounterTicketId;
  }

  set salesCounterTicketId(value: number) {
    this._salesCounterTicketId = value;
  }

  get spaceName(): string {
    return this._spaceName;
  }

  set spaceName(value: string) {
    this._spaceName = value;
  }

  get spaceRow(): string {
    return this._spaceRow;
  }

  set spaceRow(value: string) {
    this._spaceRow = value;
  }

  get spaceRowPosition(): string {
    return this._spaceRowPosition;
  }

  set spaceRowPosition(value: string) {
    this._spaceRowPosition = value;
  }

  get fullAmount(): number {
    return this._fullAmount;
  }

  set fullAmount(value: number) {
    this._fullAmount = value;
  }

  get fullAmountWithFee(): number {
    return this._fullAmountWithFee;
  }

  set fullAmountWithFee(value: number) {
    this._fullAmountWithFee = value;
  }

  get netAmount(): number {
    return this._netAmount;
  }

  set netAmount(value: number) {
    this._netAmount = value;
  }

  get selectedForRefund(): boolean {
    return this._selectedForRefund;
  }

  set selectedForRefund(value: boolean) {
    this._selectedForRefund = value;
  }

  get vatSet(): number {
    return this._vatSet;
  }

  set vatSet(value: number) {
    this._vatSet = value;
  }

  get refundFee(): number {
    return this._refundFee;
  }

  set refundFee(value: number) {
    this._refundFee = value;
  }

  get saleFee(): number {
    return this._saleFee;
  }

  set saleFee(value: number) {
    this._saleFee = value;
  }

  get calcAmount(): number {
    return this._calcAmount;
  }

  set calcAmount(value: number) {
    this._calcAmount = value;
  }

  get calcDifference(): number {
    return this._calcDifference;
  }

  set calcDifference(value: number) {
    this._calcDifference = value;
  }

  get changePriceId(): string {
    return this._changePriceId;
  }

  set changePriceId(value: string) {
    this._changePriceId = value;
  }

  get voucher(): TicketVoucher {
    return this._voucher;
  }

  set voucher(value: TicketVoucher) {
    this._voucher = value;
  }

  get skipSaleFee(): boolean {
    return this._skipSaleFee;
  }

  set skipSaleFee(value: boolean) {
    this._skipSaleFee = value;
  }

  get absoluteFullAmount(): number {

    let _tmpAmount = 0;

    if (this.skipSaleFee) {

      _tmpAmount = this.fullAmount;
    } else {

      _tmpAmount = this.fullAmountWithFee;
    }

    if (this.voucher !== null) {

      _tmpAmount = _tmpAmount + this.voucher.absoluteAmount;
    }

    return _tmpAmount;
  }

  get cdnReferencePdf(): string {
    return this._cdnReferencePdf;
  }

  set cdnReferencePdf(value: string) {
    this._cdnReferencePdf = value;
  }
}
