import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'c360-confirm-refund-modal',
  templateUrl: './confirm-refund-modal.component.html'
})
export class ConfirmRefundModalComponent {

  constructor(private readonly _activeModal: NgbActiveModal) {
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }
}
