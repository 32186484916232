import {Media} from '../../events/models/media.model';
import {Film} from '../../events/models/film.model';
import {MediaTypes} from '../../events/enums/media-types.enum';
import {FilmAgeRatings} from '../../events/enums/film-age-ratings.enum';
import {Moment} from 'moment';
import * as moment from 'moment';
import {PerformanceStatus} from '../../performances/enums/performance-status.enum';

export class ScheduleExportFilm extends Film {
  performances: ScheduleExportPerformance[];
  poster: Media;

  constructor(
    _links?: any,
    id?: string,
    eventId?: string,
    typeChildId?: string,
    title?: string,
    description?: string,
    directors?: { key: string, value: string }[],
    actors?: { key: string, value: string }[],
    cameraOperators?: { key: string, value: string }[],
    cutters?: { key: string, value: string }[],
    books?: { key: string, value: string }[],
    runtime?: number,
    ageRating?: string,
    releaseDate?: any,
    countryOfOrigin?: string,
    creationYear?: number,
    spokenLanguage?: string,
    subtitleLanguage?: string,
    websiteUrl?: string,
    genres?: { key: string, value: string }[],
    publisher?: string,
    trailer?: string,
    presentationType?: string,
    languageVersion?: string,
    media?: Array<{ url: string, type: string, description?: string }>
  ) {
    super(
      _links,
      id,
      eventId,
      typeChildId,
      title,
      description,
      directors,
      actors,
      cameraOperators,
      cutters,
      books,
      runtime,
      ageRating,
      releaseDate,
      countryOfOrigin,
      creationYear,
      spokenLanguage,
      subtitleLanguage,
      websiteUrl,
      genres,
      publisher,
      trailer,
      presentationType,
      languageVersion,
      media);
    this.performances = [];

    this.poster = this.media.find((m: Media) => m.type === MediaTypes.POSTER);
    if (this.poster == null) {
      this.poster = {
        type: MediaTypes.POSTER,
        url: '/assets/img/media_poster.png',
        description: ''
      };
    }
  }
}


export class ScheduleExportEvent {
  id: string;
  name: string;
  type: string;
  typeMasterId: string;
}

export interface ScheduleExportAttributes {
  id: string;
  name: string;
  description: string;
  type: string;
  imageUrl: string;
}

export interface ScheduleExportOccupationStats {
  free: number;
  selected: number;
  takenByFriend: number;
  broken: number;
  blocked: number;
  reserved: number;
  sold: number;
  lockedForSale: number;
  lockedForReservation: number;
  total: number;
}

export interface ScheduleExportPerformance {
  id: string;
  eventId: string;
  filmId: string;
  filmAgeRating: FilmAgeRatings;
  auditoriumName: string;
  startDate: Moment;
  attributeIds: string[];
  absoluteRuntime: number;
  bookingLink: string;
  occupationStats: ScheduleExportOccupationStats;
  status: PerformanceStatus;
}

export interface ScheduleDaysList {
  startDate: string;
  startDateKey: string;
}

export interface ScheduleExportResponse {
  performanceMap: {[id: string]: ScheduleExportPerformance[]};
  performanceAttributeMap: {[id: string]: ScheduleExportAttributes};
  eventMap: {[id: string]: ScheduleExportEvent};
  filmMap: {[id: string]: ScheduleExportFilm};
}

export class ScheduleExport {
  response: ScheduleExportResponse;
  daysList: ScheduleDaysList[] = [];
  startDate: Moment = null;
  endDate: Moment = null;
  timestamp: Moment = null;

  constructor(scheduleExportResponse: ScheduleExportResponse, startDate: Moment, endDate: Moment, timestamp?: Moment) {
    this.startDate = startDate;
    this.endDate = endDate;

    if (null != timestamp) {
      this.timestamp = timestamp;
    } else {
      this.timestamp = moment();
    }

    this.response = scheduleExportResponse;

    Object.keys(scheduleExportResponse.performanceMap).forEach(dateKey => {
      this.daysList.push({
        startDate: moment(dateKey).format('dddd, DD.MM.YYYY'),
        startDateKey: dateKey
      });

      scheduleExportResponse.performanceMap[dateKey].forEach((p, i) => {
        scheduleExportResponse.performanceMap[dateKey][i].startDate = moment(p.startDate);
      });
    });
  }
}

