<div class="modal-header bg-danger text-white">
  <h4 class="modal-title">{{ 'MODUL_USERS.COMPONENT_DETAIL.MODAL_TITLE_DELETE' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <p translate="MODUL_USERS.COMPONENT_DETAIL.DELETE_USER_INFO" [translateParams]="{user:user.userToString}"></p>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-dark"
          (click)="onClose()"
          translate="MODUL_USERS.BTN.CLOSE">
  </button>
  <button type="button"
          class="btn btn-danger"
          (click)="onDelete()"
          translate="MODUL_USERS.BTN.DELETE">
  </button>
</div>
