<div class="btn-group-lg pull-right" role="group">
  <button
    type="button"
    class="btn btn-primary"
    (click)="refresh()"
    placement="bottom"
    [disabled]="isRefreshing"
    ngbTooltip="{{'MODUL_DASHBOARD.REFRESH' | translate}}"><i class="fa fa-refresh" [ngClass]="{'fa-spin': isRefreshing}"></i></button>
  <br>
</div>
