export enum Countries {
  'ALBANIA'= 'COUNTRIES.ALBANIA',
  'ANDORRA'= 'COUNTRIES.ANDORRA',
  'ARMENIA'= 'COUNTRIES.ARMENIA',
  'AUSTRIA'= 'COUNTRIES.AUSTRIA',
  'AZERBAIJAN'= 'COUNTRIES.AZERBAIJAN',
  'BELARUS'= 'COUNTRIES.BELARUS',
  'BELGIUM'= 'COUNTRIES.BELGIUM',
  'BOSNIA_AND_HERZEGOVINA'= 'COUNTRIES.BOSNIA_AND_HERZEGOVINA',
  'BULGARIA'= 'COUNTRIES.BULGARIA',
  'CROATIA'= 'COUNTRIES.CROATIA',
  'CYPRUS'= 'COUNTRIES.CYPRUS',
  'CZECH_REPUBLIC'= 'COUNTRIES.CZECH_REPUBLIC',
  'DENMARK'= 'COUNTRIES.DENMARK',
  'ESTONIA'= 'COUNTRIES.ESTONIA',
  'FINLAND'= 'COUNTRIES.FINLAND',
  'FRANCE'= 'COUNTRIES.FRANCE',
  'GEORGIA'= 'COUNTRIES.GEORGIA',
  'GERMANY'= 'COUNTRIES.GERMANY',
  'GREECE'= 'COUNTRIES.GREECE',
  'HUNGARY'= 'COUNTRIES.HUNGARY',
  'ICELAND'= 'COUNTRIES.ICELAND',
  'IRELAND'= 'COUNTRIES.IRELAND',
  'ITALY'= 'COUNTRIES.ITALY',
  'KAZAKHSTAN'= 'COUNTRIES.KAZAKHSTAN',
  'KOSOVO'= 'COUNTRIES.KOSOVO',
  'LATVIA'= 'COUNTRIES.LATVIA',
  'LIECHTENSTEIN'= 'COUNTRIES.LIECHTENSTEIN',
  'LITHUANIA'= 'COUNTRIES.LITHUANIA',
  'LUXEMBOURG'= 'COUNTRIES.LUXEMBOURG',
  'MACEDONIA'= 'COUNTRIES.MACEDONIA',
  'MALTA'= 'COUNTRIES.MALTA',
  'MOLDOVA'= 'COUNTRIES.MOLDOVA',
  'MONACO'= 'COUNTRIES.MONACO',
  'MONTENEGRO'= 'COUNTRIES.MONTENEGRO',
  'NETHERLANDS'= 'COUNTRIES.NETHERLANDS',
  'NORWAY'= 'COUNTRIES.NORWAY',
  'POLAND'= 'COUNTRIES.POLAND',
  'PORTUGAL'= 'COUNTRIES.PORTUGAL',
  'ROMANIA'= 'COUNTRIES.ROMANIA',
  'RUSSIA'= 'COUNTRIES.RUSSIA',
  'SAN_MARINO'= 'COUNTRIES.SAN_MARINO',
  'SERBIA'= 'COUNTRIES.SERBIA',
  'SLOVAKIA'= 'COUNTRIES.SLOVAKIA',
  'SLOVENIA'= 'COUNTRIES.SLOVENIA',
  'SPAIN'= 'COUNTRIES.SPAIN',
  'SWEDEN'= 'COUNTRIES.SWEDEN',
  'SWITZERLAND'= 'COUNTRIES.SWITZERLAND',
  'TURKEY'= 'COUNTRIES.TURKEY',
  'UKRAINE'= 'COUNTRIES.UKRAINE',
  'UNITED_KINGDOM'= 'COUNTRIES.UNITED_KINGDOM',
  'VATICAN_CITY'= 'COUNTRIES.VATICAN_CITY',
}
