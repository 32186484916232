<div class="row mt-2">
  <div class="col-4">
    <div class="btn-group-lg pull-left" role="group" *ngIf="viewMode != null">
      <button
        type="button"
        class="btn"
        (click)="onSetViewMode(viewModes.DAY)"
        [ngClass]="{'btn-primary': viewMode === viewModes.DAY, 'btn-secondary': viewMode !== viewModes.DAY}"
        [translate]="'MODUL_COUNTER.SCHEDULE.MODES.DAY'"></button>
      <button
        type="button"
        class="btn"
        (click)="onSetViewMode(viewModes.FILM)"
        [ngClass]="{'btn-primary': (viewMode === viewModes.FILM), 'btn-secondary': (viewMode !== viewModes.FILM)}"
        [translate]="'MODUL_COUNTER.SCHEDULE.MODES.FILM'"></button>
      <br>
    </div>

  </div>
  <div class="col-3">
    <!--<input class="form-control" #filmFilterBox (change)="onFilmtitleFilterChange(filmFilterBox.value)" type="text" placeholder="Nach Filmtitel filtern...">-->
  </div>
  <div class="col-5">
    <div class="btn-group-lg pull-right" role="group" *ngIf="scope != null">
      <button
        type="button"
        class="btn"
        (click)="onSetScope(scopes.ALL)"
        [ngClass]="{'btn-primary': scope === scopes.ALL, 'btn-secondary': scope !== scopes.ALL}"
        [translate]="'MODUL_COUNTER.SCHEDULE.SCOPES.ALL'"></button>
      <button
        type="button"
        class="btn"
        (click)="onSetScope(scopes.WEEK)"
        [ngClass]="{'btn-primary': (scope === scopes.WEEK), 'btn-secondary': (scope !== scopes.WEEK)}"
        [translate]="'MODUL_COUNTER.SCHEDULE.SCOPES.WEEK'"></button>
      <button
        type="button"
        class="btn"
        (click)="onSetScope(scopes.UPCOMING)"
        [ngClass]="{'btn-primary': (scope === scopes.UPCOMING), 'btn-secondary': (scope !== scopes.UPCOMING)}"
        [translate]="'MODUL_COUNTER.SCHEDULE.SCOPES.UPCOMING'"></button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="refresh()"
        placement="bottom"
        [disabled]="isRefreshing"
        ngbTooltip="{{'MODUL_COUNTER.SCHEDULE.REFRESH' | translate}}"><i class="fa fa-refresh" [ngClass]="{'fa-spin': isRefreshing}"></i></button>
      <br>
    </div>
  </div>
</div>
