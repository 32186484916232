import {HalData} from '../../shared/models/halData';
import {TransactionSearch} from './transaction-search.model';

export class TransactionSearchHalData extends HalData {

  data: { [id: string]: TransactionSearch } = {};

  /**
   * Parse to Array Object
   *
   * @param response
   */
  parseToArrayObjects(response: any) {
    this.data = {};
    response._embedded.transactionSearches.forEach((transactionSearch: TransactionSearch) => {
      this.parseToObject(transactionSearch);
    });

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  /**
   * Parse to Object
   *
   * @param response
   * @returns {TransactionSearch}
   */
  parseToObject(response: any): TransactionSearch {
    if (response != null) {
      const transactionSearch = new TransactionSearch(
        response._links,
        response.id,
        response.mandatorId,
        response.locationId,
        response.transactionExternalIdNew,
        response.transactionId,
        response.transactionType,
        response.ticketSystemType,
        response.historyLastStatus,
        response.historyFirstDate,
        response.historyFirstClientType,
        response.historyFirstClientVersion,
        response.pickupCode,
        response.eventTitle,
        response.locationName,
        response.auditoriumName,
        response.performanceStartDate,
        response.customerFullName,
        response.customerEmailAddress,
        response.paymentProviderTypes,
        response.fullAmount,
        response.currency
      );
      this.data['' + response.id + ''] = transactionSearch;
    }

    return this.data['' + response.id + ''];
  }
}
