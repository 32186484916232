<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-6">
          <h1 [translate]="'MODUL_DASHBOARD.NAME'"></h1>
        </div>
        <c360-dashboard-control
          class="col-6 pt-2"
          (onRefresh)="refresh()"
          [isRefreshing]="isRefreshing"></c360-dashboard-control>
      </div>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-9" *ngIf="stats">
          <h3>Gesamtanzahl der Buchungsprozesse: {{stats.response.countOfProcesses}}</h3>
        </div>
        <div class="col-3 text-right" *ngIf="stats">
          <i class="fa fa-refresh" *ngIf="isRefreshing" [ngClass]="{'fa-spin': isRefreshing}"></i>&nbsp;<small class="text-center">Zuletzt aktualisiert: <b>{{lastUpdate}}</b></small>
        </div>
        <div class="col-12" *ngIf="!stats">
            <p class="text-center">Es konnten keine Statistiken abgerufen werden.</p>
        </div>
      </div>

      <div class="mt-1">
        <table id="myTable" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered">
          <thead>
          <tr>
            <th style="width: 90px;">Datum</th>
            <th style="width: 90px;">Datum</th>
            <th style="width: 50px;">Uhrzeit</th>
            <th style="width: 50px;">Uhrzeit</th>
            <th>Film</th>
            <th style="width: 30px;"><span ngbTooltip="Auslastung mittels verkaufter Plätze in %">AV%</span></th>
            <th style="width: 30px;"><span ngbTooltip="Auslastung mittels verkaufter und blockierter Plätze %">AB%</span></th>
            <th style="width: 30px;"><span ngbTooltip="Anzahl der Buchungsprozesse">P</span></th>
            <th style="width: 30px;"><span ngbTooltip="Verkaufte Plätze">V</span></th>
            <th style="width: 30px;"><span ngbTooltip="Freie Plätze">F</span></th>
            <th style="width: 30px;"><span ngbTooltip="Blockierte Plätze">B</span></th>
            <th style="width: 30px;"><span ngbTooltip="Kontingent">K</span></th>
          </tr>
          </thead>
          <tbody>
<!--          <ng-container *ngFor="let performanceStat of stats.response.performanceStats">-->
<!--            <tr>-->
<!--              <td>{{performanceStat.performance.startDate.format('dd., DD.MM.YYYY')}}</td>-->
<!--              <td>{{performanceStat.performance.startDate.format('HH:mm')}}</td>-->
<!--              <td>{{performanceStat.performance.filmTitle}}</td>-->
<!--              <td>{{performanceStat.countOfProcesses}}</td>-->
<!--              <td>{{performanceStat.performance.occupationStats.sold}}</td>-->
<!--              <td>{{performanceStat.performance.occupationStats.blocked + performanceStat.performance.occupationStats.selectedBlocked}}</td>-->
<!--              <td>{{performanceStat.performance.occupationStats.free + performanceStat.performance.occupationStats.selectedFree}}</td>-->
<!--              <td>{{performanceStat.performance.occupationStats.total}}</td>-->
<!--            </tr>-->
<!--          </ng-container>-->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
