import {Hal} from '../../shared/models/hal';
import {AreaPrice} from './area-price.model';

export class PriceLayout extends Hal {

  public _links: any;
  public id: string;
  public name: string;
  public mandatorId: string;
  public transactionFee: number;
  public areaPricesList: Array<AreaPrice>;

  constructor(_links?: any,
              id?: string,
              name?: string,
              mandatorId?: string,
              transactionFee?: number,
              areaPricesList?: Array<AreaPrice>) {
    super(_links);

    this.id = id;
    this.name = name;
    this.mandatorId = mandatorId;
    this.transactionFee = transactionFee;
    this.areaPricesList = areaPricesList ? areaPricesList.map((areaPrice) => new AreaPrice(areaPrice.areaId, areaPrice.prices)) : [];
  }

}
