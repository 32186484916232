export const locale_base_en = {
  'lang': 'en',
  'data': {
    'MODUL_EVENTS': {
      'NAME': 'Events',
      'COMPONENT_LIST': {
        'NAME': 'Events/Films',
        'ADD': 'Add Master-Film',
        'SEARCH': 'Search',
        'FILTER': 'Filter',
        'FILTER_OPTIONAL': 'Optional Filter'
      },
      'COMPONENT_ADD': {
        'NAME': 'Master-Film'
      },
      'COMPONENT_DETAIL': {
        'NAME': 'Event/Film Editor',
        'INPUT_ADD': '+'
      },
      'MODEL': {
        'FILM': {
          'ID': 'ID',
          'EVENTID': 'Eventid',
          'TYPECHILDID': 'Typechildid',
          'TITLE': 'Title',
          'DESCRIPTION': 'Description',
          'DIRECTORS': 'Directors',
          'DIRECTORS_ADD': 'Add Directors',
          'ACTORS': 'Actors',
          'ACTORS_ADD': 'Add Actors',
          'CAMERAOPERATORS': 'Cameraoperators',
          'CAMERAOPERATORS_ADD': 'Add Cameraoperators',
          'CUTTERS': 'Cutters',
          'CUTTERS_ADD': 'Add Cutters',
          'BOOKS': 'Books',
          'BOOKS_ADD': 'Add Books',
          'RUNTIME': 'Runtime',
          'AGERATING': 'Agerating',
          'RELEASEDATA': 'Releasedata',
          'COUNTRYOFORIGIN': 'Countryoforigin',
          'CREATIONYEAR': 'Creationyear',
          'SPOKENLANGUAGE': 'Spokenlanguage',
          'SUBTITLELANGUAGE': 'Subtitlelanguage',
          'WEBSITEURL': 'Websiteurl',
          'GENRES': 'Genres',
          'GENRES_ADD': 'Add Genres',
          'PUBLISHER': 'Publisher',
          'PRESENTATION-TYPE': 'Presentation type',
          'LANGUAGE-VERSION': 'Language version'
        }
      },
      'ERRORS': {
        'REQUIRED': 'Is required.',
        'ALPHANUMERIC': 'Must be alphanumeric.',
        'NUMERIC': 'must be umeric.',
        'MIME_TYPE': 'File type not allowed.'
      },
      'LANGUAGE_VERSION': {
        'OV': 'OV',
        'OMU': 'OMU',
        'GERMAN': 'german'
      }
    }
  }
};
