import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {InitService} from './services/init.service';
import {InitGuard} from './guards/init.guard';
import {InitComponent} from './components/init.component';

const COMPONENTS = [
  InitComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
    NgbModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [
    InitService,
    InitGuard
  ]
})
export class InitModule {

  constructor(private translationLoader: TranslationLoaderService) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(english, german);
  }
}
