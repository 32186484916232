import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {CounterScheduleScopes} from '../../enums/schedule-scope.enum';
import {CounterScheduleViewModes} from '../../enums/schedule-view-modes.enum';

@Component({
  selector: 'c360-counter-schedule-control',
  templateUrl: './counter-schedule-control.component.html'
})
export class CounterScheduleControlComponent {

  @Input() scope: CounterScheduleScopes = null;
  @Input() viewMode: CounterScheduleViewModes = null;
  @Input() isRefreshing: boolean;

  @Output() setScope: EventEmitter<CounterScheduleScopes> = new EventEmitter<CounterScheduleScopes>();
  @Output() setViewMode: EventEmitter<CounterScheduleViewModes> = new EventEmitter<CounterScheduleViewModes>();
  @Output() filmFilterChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() onRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _scopes = CounterScheduleScopes;
  private _viewModes = CounterScheduleViewModes;

  get scopes() {
    return this._scopes;
  }

  get viewModes() {
    return this._viewModes;
  }

  constructor(private _cd: ChangeDetectorRef) {
  }

  onSetScope(scope) {
    this.setScope.emit(scope);
  }

  onSetViewMode(viewMode: CounterScheduleViewModes) {
    this.setViewMode.emit(viewMode);
  }

  onFilmtitleFilterChange(title) {
    console.warn('title', title);
    this.filmFilterChanged.emit(
      title
    );
  }

  refresh() {
    this.onRefresh.emit(true);
  }
}
