import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CounterComponent} from './components/counter.component';
import {CounterDashboardComponent} from './components/dashboard/counter-dashboard.component';
import {CounterScheduleComponent} from './components/schedule/counter-schedule.component';
import {CounterTransactionsComponent} from './components/transactions/counter-transactions.component';
import {CounterConfirmationComponent} from './components/confirmation/counter-confirmation.component';
import {HasPrinterGuard} from '../shared/guards/has-printer.guard';
import {SharedModule} from '../shared/shared.module';
import {CounterTransactionsListComponent} from './components/transactions/list/counter-transactions-list.component';
import {CounterTransactionsRootLinkResolver} from './services/resolver/counter-transactions-root-link.resolver';
import {LocationsRootLinkResolver} from '../locations/services/resolver/locations-root-link.resolver';
import {NotPrintingGuard} from '../shared/guards/not-printing.guard';
import {PaymentRootLinkResolver} from './services/resolver/payment-root-link.resolver';
import {TransactionsRootLinkResolver} from '../transactions/services/resolver/transactions-root-link.resolver';

const appRoutes: Routes = [
  {
    path: '',
    component: CounterComponent,
    data: {
      breadcrumb: false
    },
    resolve: {
      rootLink: CounterTransactionsRootLinkResolver,
      LocationsRootLinkResolver,
      PaymentRootLinkResolver,
      TransactionsRootLinkResolver,
    },
    children: [
      {
        path: '',
        component: CounterDashboardComponent
      },
      {
        path: 'schedule',
        component: CounterScheduleComponent,
        canActivate: [
          HasPrinterGuard,
          NotPrintingGuard
        ],
        data: {
          breadcrumb: 'Veranstaltungen'
        }
      },
      {
        path: 'transactions',
        component: CounterTransactionsComponent,
        canActivate: [
          HasPrinterGuard,
          NotPrintingGuard
        ],
        data: {
          breadcrumb: 'Online Buchungen'
        },
        children: [
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full',
          },
          {
            path: 'list',
            component: CounterTransactionsListComponent,
            data: {
              breadcrumb: false
            }
          },
          {
            path: 'list/:id',
            component: CounterTransactionsListComponent,
            data: {
              breadcrumb: false
            }
          },
          {
            path: 'list/search/:search',
            component: CounterTransactionsListComponent,
            data: {
              breadcrumb: false
            }
          },
          {
            path: 'detail',
            component: CounterTransactionsListComponent,
            data: {
              breadcrumb: 'Detail'
            }
          },
          {
            path: 'manual-entry/:performanceId',
            component: CounterTransactionsListComponent,
            data: {
              breadcrumb: 'Manueller Einlass'
            }
          }
        ]
      },
      {
        path: 'confirmation/:externalTransactionId',
        component: CounterConfirmationComponent,
        canActivate: [
          HasPrinterGuard,
          NotPrintingGuard
        ],
        data: {
          breadcrumb: 'Bestätigung'
        }
      },
      {
        path: 'confirmation/:externalTransactionId/:oldPickupCode',
        component: CounterConfirmationComponent,
        canActivate: [
          HasPrinterGuard,
          NotPrintingGuard
        ],
        data: {
          breadcrumb: 'Bestätigung'
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes),
    SharedModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CounterTransactionsRootLinkResolver,
    PaymentRootLinkResolver
  ]
})
export class CounterRoutingModule {
}

