<div class="container-fluid">
    <div class="card">
      <div class="card-header">
          <h1>{{ 'MODUL_USERS.COMPONENT_LIST.NAME' | translate}}</h1>
      </div>
      <div class="card-body">
        <c360-user-filter (onSearch)="onSearch($event)"></c360-user-filter>
        <form *ngIf="userHalData && userHalData.data">
              <table class="table table-striped">
                <caption hidden>{{ 'MODUL_USERS.COMPONENT_LIST.ALL_USERS_LIST' | translate}}</caption>
                <thead>
                <tr>
                  <th scope="col">{{ 'MODUL_USERS.COMPONENT_LIST.NAME_LASTNAME' | translate}}</th>
                  <th scope="col">{{ 'MODUL_USERS.COMPONENT_LIST.EMAIL' | translate}}</th>
                  <th scope="col">{{ 'MODUL_USERS.COMPONENT_LIST.ROLE' | translate}}</th>
                  <th scope="col">{{ 'MODUL_USERS.COMPONENT_LIST.CONFIRMED' | translate}}</th>
                  <th scope="col" class="text-center">{{ 'MODUL_USERS.COMPONENT_LIST.STATUS' | translate}}</th>
                  <th scope="col">
                    <span class="text-hide">Bearbeitungsbuttons</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of userHalData.data | keys" c360-user-list-item [user]="user.value"
                    (onUpdate)="onUpdate($event)"></tr>
                </tbody>
              </table>
        </form>
        <!-- Start Pagination -->
        <ngb-pagination *ngIf="userHalData?.page"
                        [maxSize]="5"
                        [rotate]="true"
                        [ellipses]="true"
                        [boundaryLinks]="true"
                        [collectionSize]="userHalData.page.totalElements"
                        [pageSize]="userHalData.page.totalElementsForPage"
                        [(page)]="currentPage"
                        (pageChange)="getAllByPage($event)"></ngb-pagination>
        <!-- End Pagination -->

      </div>
    </div>
  </div>
