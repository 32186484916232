import {isUndefined} from 'util';

export class Page {

  protected size: number;
  protected number: number;

  private _totalElements: number;
  private _totalPages: number;
  private _currentPage: number;
  private _totalElementsForPage: number;
  private _sort: string;

  /**
   *
   * @param page
   */
  constructor(page: any = null) {

    if (page != null && !isUndefined(page)) {

      this.size = page.size;
      this._totalElements = page.totalElements;
      this.number = page.number;
    }

    this._currentPage = 1;
    this.totalElementsForPage = 25;
  }

  /**
   *
   * @param page
   */
  parseData(page: any = null) {

    if (page != null && !isUndefined(page)) {

      this.size = page.size;
      this._totalElements = page.totalElements;
      this.number = page.number;
    }
  }

  /**
   *
   * @param {string} sort
   * @returns {string}
   */
  getParamsForPage(sort: string = 'asc'): string {
    let params: string = null;

    params = 'page=' + (this._currentPage - 1);
    params += '&size=' + this._totalElementsForPage;
    params += '&sort=' + (this.sort ? this.sort : sort);

    return params;
  }

  /**
   *
   * @returns {number}
   */
  getTotalPages(): number {

    return this._totalPages = Math.ceil(this._totalElements / this.totalElementsForPage);
  }

  /**
   *
   * @returns {number}
   */
  get totalElements(): number {
    return this._totalElements;
  }

  /**
   *
   * @param {number} value
   */
  set totalElements(value: number) {
    this._totalElements = value;
  }

  /**
   *
   * @returns {number}
   */
  get totalPages(): number {
    return this._totalPages;
  }

  /**
   *
   * @param {number} value
   */
  set totalPages(value: number) {
    this._totalPages = value;
  }

  /**
   *
   * @returns {number}
   */
  get currentPage(): number {
    return this._currentPage;
  }

  /**
   *
   * @param {number} value
   */
  set currentPage(value: number) {
    this._currentPage = value;
  }

  /**
   *
   * @returns {number}
   */
  get totalElementsForPage(): number {
    return this._totalElementsForPage;
  }

  /**
   *
   * @param {number} value
   */
  set totalElementsForPage(value: number) {
    this._totalElementsForPage = value;
  }

  /**
   *
   * @returns {string}
   */
  get sort(): string {
    return this._sort;
  }

  /**
   *
   * @param {string} value
   */
  set sort(value: string) {
    this._sort = value;
  }
}
