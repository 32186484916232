import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {guid} from './utils';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add Custom Headers to all Gateway requests
    // Since urls.gateway is a relative path in most cases, a further check might be a good idea
    if (request.url.indexOf(environment.urls.gateway) > -1 && request.url.indexOf(environment.urls.auth) === -1) {
      // TODO: Remove debug message as soon as Backend is able to handle these CustomHeaders

      request = request.clone({
        setHeaders: {
          'client-Version': environment.version,
          'client-Type': 'CMS',
          requestId: guid()
        }
      });
    }
    return next.handle(request);
  }
}
