<div class="container-fluid">
  <c360-loading *ngIf="isLoading"></c360-loading>
  <span onclick="window.history.back();" class="back-to-overview mb-3">
      <i class="fa fa-arrow-circle-o-left mr-1" aria-hidden="true"></i>
      <span>Zurück</span>
  </span>
  <div class="row" *ngIf="user?.id">
    <div class="col-12 col-lg-8 content">
      <div class="card">
        <div class="card-header" [ngClass]="{
        'bg-danger text-white':user.id && !user.accountEnabled
        }">
          <div class="row">
            <div class="col-md-12">
              <h2>{{ 'MODUL_USERS.COMPONENT_DETAIL.PROFILE_DATA' | translate}}</h2>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <c360-user-details-form
                [user]="user"
                (onFormSubmit)="onSumbit($event)"
                (onCancel)="onCancel()"
                (onDelete)="onDelete($event)"></c360-user-details-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 content">
      <div class="card">
        <div class="card-header" [ngClass]="{
        'bg-danger text-white':user.id && !user.emailAddressApproved
        }">
          <div class="row">
            <div class="col-md-12">
              <h2>
                {{ 'MODUL_USERS.COMPONENT_DETAIL.SET_NEW_EMAIL' | translate}}
                <em class="small" *ngIf="user?.emailAddressApproved">
                  ({{'MODUL_USERS.COMPONENT_DETAIL.EMAIL_CONFIRMED' | translate}})
                </em>
                <em class="small" *ngIf="!user?.emailAddressApproved">
                  ({{'MODUL_USERS.COMPONENT_DETAIL.EMAIL_UNCONFIRMED' | translate}})
                </em>
              </h2>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <c360-user-change-email-form
                [user]="user"
                (onFormSubmit)="onEmailSubmit($event)"></c360-user-change-email-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <c360-user-detail-transaction-list [user]="user"></c360-user-detail-transaction-list>
    </div>
  </div>
  <!--
  <div class="row" *ngIf="listUserType === 'CUSTOMER'">
    <div class="col-12 content">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-12">
              <h2>{{ 'MODUL_USERS.COMPONENT_DETAIL.BOOKING_OVERVIEW' | translate}}</h2>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <table class="table table-striped">
                <caption hidden>{{ 'MODUL_USERS.COMPONENT_DETAIL.LIST_ALL_EVENTS' | translate}}</caption>
                <thead>
                <tr>
                  <th scope="col"><span aria-label="ID-Nummer">#</span></th>
                  <th scope="col">{{ 'MODUL_USERS.COMPONENT_DETAIL.FILM_EVENT' | translate}}</th>
                  <th scope="col">{{ 'MODUL_USERS.COMPONENT_DETAIL.PERFORMANCE' | translate}}</th>
                  <th scope="col">{{ 'MODUL_USERS.COMPONENT_DETAIL.CINEMA' | translate}}</th>
                  <th scope="col" class="text-center">{{ 'MODUL_USERS.COMPONENT_DETAIL.BOOKING_STATUS' | translate}}
                  </th>
                  <th scope="col" class="text-center">{{ 'MODUL_USERS.COMPONENT_DETAIL.TICKET_STATUS' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>1</th>
                  <td>Bad Moms 2</td>
                  <td>13.12.2017 20:15</td>
                  <td>Filmforum</td>
                  <td class="text-center"><i class="fa fa-check-circle ion--success" aria-label="Erfolgreich"></i></td>
                  <td class="text-center"><i class="fa fa-check-circle ion--success" aria-label="Abgeholt"></i></td>
                </tr>
                <tr>
                  <th>2</th>
                  <td>Bad Moms 2</td>
                  <td>13.12.2017 19:45</td>
                  <td>Filmforum</td>
                  <td class="text-center"><i class="fa fa-times-circle ion--danger" aria-label="Gescheitert"></i></td>
                  <td class="text-center"><i class="fa fa-times-circle ion--danger" aria-label="Nicht abgeholt"></i>
                  </td>
                </tr>
                <tr>
                  <th>3</th>
                  <td>Nightmare on Schifferstraße</td>
                  <td>10.11.2017 19:45</td>
                  <td>Filmforum</td>
                  <td class="text-center"><i class="fa fa-check-circle ion--success" aria-label="Erfolgreich"></i></td>
                  <td class="text-center"><i class="fa fa-check-circle ion--success" aria-label="Abgeholt"></i></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
</div>
