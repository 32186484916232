import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UsersComponent} from './components/users.component';
import {UsersListComponent} from './components/list/list.component';
import {UsersDetailComponent} from './components/detail/detail.component';
import {UserFilterComponent} from './components/list/filter.component';
import {UserListItemComponent} from './components/list/user-list-item.component';
import {UserDetailResolver} from './services/resolver/user-detail.resolver';
import {HalUsersService} from './services/hal-users.service';
import {UsersRootLinkResolver} from './services/resolver/users-root-link.resolver';
import {UsersRoutingModule} from './users-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UserDetailsFormComponent} from './components/forms/user-details.form';
import {UsersAddComponent} from './components/add/add.component';
import {UserChangeEmailFormComponent} from './components/forms/user-change-email.form';
import {UserProfileResolver} from './services/resolver/user-profile.resolver';
import {SavedUserModalComponent} from './components/saved-user-modal/saved-user-modal.component';
import {DeleteUserModalComponent} from './components/delete-user-modal/delete-user-modal.component';
import {UserWelcomeComponent} from './components/welcome/user-welcome.component';
import {UserDetailTransactionListComponent} from './components/transaction-list/user-detail-transaction-list.component';
import {HalMandatorsService} from '../locations/services/hal-mandators.service';
import {MandatorsRootLinkResolver} from '../locations/services/resolver/mandators-root-link.resolver';

@NgModule({
  declarations: [
    UsersComponent,
    UsersAddComponent,
    UsersListComponent,
    UsersDetailComponent,
    UserFilterComponent,
    UserListItemComponent,
    UserDetailsFormComponent,
    UserChangeEmailFormComponent,
    SavedUserModalComponent,
    DeleteUserModalComponent,
    UserWelcomeComponent,
    UserDetailTransactionListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    NgbModule
  ],
  exports: [
    UsersComponent,
    UsersListComponent,
    UsersRoutingModule,
    UsersDetailComponent,
    UserFilterComponent,
    UserListItemComponent,
    UserWelcomeComponent
  ],
  entryComponents: [
    SavedUserModalComponent,
    DeleteUserModalComponent
  ]
})
export class UsersModule {

  constructor(
    private translationLoader: TranslationLoaderService
  ) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(english, german);
  }

  static forRoot() {
    return {
      ngModule: UsersModule,
      providers: [
        HalUsersService,
        UsersRootLinkResolver,
        UserDetailResolver,
        UserProfileResolver,
        HalMandatorsService,
        MandatorsRootLinkResolver
      ]
    };
  }
}
