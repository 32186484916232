<div *ngIf="emptyList">
  <button class="btn" id="counterConfirmationCartToggle" (click)="toggleActive()">
    <ng-container *ngIf="!isActive">
      <i class="fa fa-calculator bg-danger" aria-hidden="true"></i>
      <span class="text-danger">Ausgeschaltet</span>
    </ng-container>
    <ng-container *ngIf="isActive">
      <i class="fa fa-calculator bg-success" aria-hidden="true"></i>
      <span class="text-success">Eingeschaltet</span>
    </ng-container>
  </button>
</div>
<div ngbDropdown [hidden]="emptyList">
  <button class="btn" id="counterConfirmationCartMenu" ngbDropdownToggle>
    <i class="fa fa-calculator" aria-hidden="true"></i>
    <ng-container *ngIf="!emptyList && counterConfirmationCartCurrencyGrouped.length === 1">
      {{counterConfirmationCartCurrencyGrouped[0].total | currency: counterConfirmationCartCurrencyGrouped[0].currency :"symbol":'1.2-2':currentLang}}
    </ng-container>
  </button>
  <div class="dropdown-menu dropdown-menu-right p-2" ngbDropdownMenu aria-labelledby="userMenuIcon">
    <div class="pr-3" style="max-height: 660px; overflow-y: scroll;">
      <table class="table table-striped table-bordered table-sm mr-2">
        <thead>
        <tr>
          <th [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.COUNTER_CONFIRMATION_CART.TABLE.HEADER.TITLE'">
          </th>
          <th [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.COUNTER_CONFIRMATION_CART.TABLE.HEADER.CODE'">
          </th>
          <th [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.COUNTER_CONFIRMATION_CART.TABLE.HEADER.PAYMENT'">
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let group of counterConfirmationCartCurrencyGrouped">
          <tr *ngFor="let item of group.items" c360-counter-confirmation-cart-item [item]="item">
          </tr>
          <tr>
            <td colspan="2"
                [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.COUNTER_CONFIRMATION_CART.TABLE.TOTAL'">
            </td>
            <td class="text-right">
              {{group.total | currency: group.currency :"symbol":'1.2-2':currentLang}}
            </td>
            <td></td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
    <div class="pt-2">
      <button class="btn btn-success"
              [disabled]="emptyList"
              [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.BUTTONS.PAY'"
              (click)="pay()">
      </button>
      <button class="btn btn-danger pull-right"
              [disabled]="emptyList"
              [translate]="'MODUL_COUNTER.COUNTER_CONFIRMATION_CART.BUTTONS.REFUND_ALL'"
              (click)="refundTransaction()">
      </button>
    </div>
  </div>
</div>
<c360-loading *ngIf="isLoading"></c360-loading>
