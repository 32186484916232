import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminboardComponent} from './components/adminboard/adminboard.component';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AdminboardComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NgbModule,
  ]
})
export class AdminModule {

  constructor(
    private translationLoader: TranslationLoaderService
  ) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }

}
