<table class="table table--schedule table-striped mb-0" [hidden]="voucherListKey.length === 0">
  <thead>
  <th translate="MODUL_COUNTER.VOUCHER.LIST.LIST_HEADER.NAME"></th>
  <th translate="MODUL_COUNTER.VOUCHER.LIST.LIST_HEADER.STATUS"></th>
  <th translate="MODUL_COUNTER.VOUCHER.LIST.LIST_HEADER.DESCRIPTION"></th>
  <th translate="MODUL_COUNTER.VOUCHER.LIST.LIST_HEADER.VALUE_INITIAL"></th>
  <th translate="MODUL_COUNTER.VOUCHER.LIST.LIST_HEADER.VALUE_CURRENT"></th>
  <th></th>
  </thead>
  <tbody>
  <tr *ngFor="let key of voucherListKey" c360-voucher-list-item [voucher]="voucherList[key]"></tr>
  </tbody>
</table>
