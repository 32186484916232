import {Component, OnDestroy, OnInit} from '@angular/core';
import {HalSepaService} from '../../../services/hal-sepa.service';
import {Sepa} from '../../../models/sepa.model';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'c360-sepa-detail',
  templateUrl: './sepa-detail.component.html'
})

export class SepaDetailComponent implements OnInit, OnDestroy {

  sepa: Sepa;

  constructor(
    private _halSepaService: HalSepaService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {

    let id: string;
    id = this.activatedRoute.snapshot.paramMap.get('id');

    this._halSepaService
      .getSepa(id)
      .then((sepa: Sepa) => {
        this.sepa = sepa;
      });
  }

  ngOnDestroy() {
  }
}
