<div class="container-fluid counter-dashboard-component">
  <div class="content">
    <div class="card">
      <div class="card-header">
        <h1 [translate]="'MODUL_COUNTER.DASHBOARD.TITLE'" [translateParams]="{name: salesCounterName}" *ngIf="hasPrinter"></h1>
        <h1 [translate]="'MODUL_COUNTER.DASHBOARD.TITLE_NO_PRINTER'" *ngIf="!hasPrinter"></h1>
        <span class="float-right" *ngIf="salesCounter">
          <span [translate]="'MODUL_COUNTER.CONFIRMATION.NEXT_SPIO'"></span>
          <span class="badge badge-pill pb-1 badge-primary"><h6 class="mb-0 px-1">{{salesCounter.nextTicketId}}</h6></span>
          <span class="ml-3 link" (click)="showSpioNumberCheck()">
            <i class="fa fa-edit" aria-hidden="true"></i>
            <span [translate]="'MODUL_COUNTER.CONFIRMATION.SPIO_WRONG'"></span>
          </span>
        </span>
      </div>
      <div class="card-body" *ngIf="!existsLocationId">
        {{ 'COMMON.CHOOSE_LOCATION' | translate }}
      </div>
      <div class="card-body pt-0" *ngIf="existsLocationId">
        <div class="row justify-content-center">
          <div class="col-11">
            <div class="row justify-content-center card-flex">
              <div class="col-6 mt-4">
                <div class="card mb-0">
                  <div class="card-header">
                    <i aria-hidden="true" class="fa fa-qrcode"></i> <span [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_BY_CODE'"></span>
                  </div>
                  <div class="card-body">
                    <p [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_BY_CODE_HINT'"></p>
                  </div>
                  <div *ngIf="!findTransaction" class="card-body alert-danger">
                    <span class="" [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_PICKUPCODE_NOT_FOUND'"></span>
                  </div>
                  <div class="card-footer">

                    <form autocomplete="off"
                          [formGroup]="pickupcodeForm"
                          (ngSubmit)="submitPickupcodeForm()">

                      <div class="input-group">
                        <input
                          id="pickupcode"
                          formControlName="pickupcodeControll"
                          maxlength="8"
                          minlength="8"
                          type="search"
                          aria-label="Suche"
                          class="form-control my-2"
                          autofocus="autofocus"/>

                        <button type="submit" class="btn btn-success my-2"><i aria-hidden="true" class="fa fa-search"></i> <span [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH'"></span></button>
                      </div>
                      <c360-form-validation-messages [control]="pickupcodeForm.controls.pickupcodeControll"></c360-form-validation-messages>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <div class="card mb-0">
                  <div class="card-header">
                    <i aria-hidden="true" class="fa fa-users"></i> <span [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_BY_NAME'"></span>
                  </div>
                  <div class="card-body">
                    <p [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_BY_NAME_HINT'"></p>
                  </div>
                  <div class="card-footer">
                    <form autocomplete="off"
                          [formGroup]="nameForm"
                          (ngSubmit)="submitNameForm()">

                      <div class="input-group">
                        <input
                          id="nameControll"
                          formControlName="nameControll"
                          type="search"
                          aria-label="Suche"
                          class="form-control my-2"/>

                        <button type="submit" class="btn btn-success my-2"><i aria-hidden="true" class="fa fa-search"></i> <span [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH'"></span></button>
                      </div>
                      <c360-form-validation-messages [control]="nameForm.controls.nameControll"></c360-form-validation-messages>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <c360-voucher-status></c360-voucher-status>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row justify-content-center pb-5 pt-4">
          <div class="col-8">
            <div class="row justify-content-center card-flex">
              <div class="cols">
                <button type="button" class="btn btn-primary btn-lg" [disabled]="!hasPrinter" (click)="bookNow()"><h3 class="text-center mb-0 py-2 px-5" [translate]="'MODUL_COUNTER.DASHBOARD.BOOK_NOW'"></h3></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <c360-counter-report *ngIf="existsLocationId"></c360-counter-report>
  </div>
</div>
