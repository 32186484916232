import {NgModule} from '@angular/core';
import {DashboardComponent} from './components/dashboard.component';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {UsersModule} from '../users/users.module';
import {DataTablesModule} from 'angular-datatables';
import {StatsService} from './services/stats.service';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DashboardControlComponent} from './components/dashboard-control.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardControlComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    TranslateModule.forChild(),
    UsersModule,
    DataTablesModule
  ],
  exports: [
    DashboardComponent
  ],
  providers: [
    StatsService
  ],
})

export class DashboardModule {

  constructor(
    private translationLoader: TranslationLoaderService
  ) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }

}
