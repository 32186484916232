import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'c360-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
