export const locale_base_en = {
  'lang': 'en',
  'data': {
    'MODUL_USERS': {
      'NAME': 'User',

      'COMPONENT_FILTER': {
        'SELECT_ROLES': '-- Select Role --',
        'ROLES': 'Select role',
        'ADD': 'Add user',
        'SEARCH': 'Search',
        'SEARCH_PLACEHOLDER': 'Email / Firstname / Lastname',
        'FILTER': 'Filter',
        'OPTIONAL_FILTER': 'Optional filter',
        'ACTIVE_USER': 'Active Users',
        'BLOCKED_USER': 'Blocked Users',
        'UNCONFIRMED_USER': 'Unconfirmed Users',
      },

      'COMPONENT_LIST': {
        'NAME': 'List',
        'ALL_USERS_LIST': 'List of all users',
        'NAME_LASTNAME': 'Lastname / Firstname',
        'EMAIL': 'Email',
        'BIRTHDAY': 'Birthday',
        'CONFIRMED': 'Confirmed',
        'STATUS': 'Status',
        'ROLE': 'Role',
      },

      'COMPONENT_DETAIL': {
        'NAME': 'Detail',
        'EDIT_PROFILE': 'Edit Profile',
        'PROFILE_DATA': 'Profile data',
        'USER_ID': 'User-ID',
        'CONFIRMED': 'Confirmed',
        'SALUTATION': 'Salutation',
        'MR': 'Mr',
        'MS': 'Ms',
        'FIRSTNAME': 'Firstname',
        'LASTNAME': 'Lastname',
        'EMAIL': 'Email',
        'EMAIL_CONFIRMED': 'Email confirmed',
        'EMAIL_UNCONFIRMED': 'Email unconfirmed',
        'BIRTHDAY': 'Birthday',
        'STREET': 'Street',
        'HOUSE_NO': 'House No.',
        'ZIP_CODE': 'ZIP Code',
        'CITY': 'City',
        'COUNTRY': 'Country',
        'PHONE': 'Phone',
        'MOBILE': 'Mobile',
        'SET_NEW_PASSWORD': 'Set new password',
        'SET_NEW_EMAIL': 'Set new Email',
        'PASSWORD': 'Password',
        'SET_PASSWORD': 'Set password',
        'SET_EMAIL': 'Set Email',
        'GENERATE_NEW_PASSWORD': 'Generate',
        'BOOKING_OVERVIEW': 'Booking overview',
        'LIST_ALL_EVENTS': 'List of all events',
        'FILM_EVENT': 'Film / Event',
        'PERFORMANCE': 'Performance',
        'CINEMA': 'Cinema',
        'BOOKING_STATUS': 'Booking status',
        'TICKET_STATUS': 'Ticket status',
        'DELETE_TEXT': 'Do you really want to delete this Event?',
        'DELETE_YES': 'Yes',
        'DELETE_NO': 'No',
        'SET_ROLE': 'Set role',
        'ROLE_SUPERADMIN': 'Super Administrator',
        'ROLE_ADMIN': 'Administrator',
        'ROLE_USER': 'Customer',
        'USER_ACTIVE': 'active',
        'ACCOUNT_ENABLED': 'Account Status',
        'ACCOUNT_LOCKED': 'Aktive/Locked',
        'UNLOCKED': 'User unlocked',
        'LOCKED': 'User locked',
        'USER_INACTIVE': 'inaktive',
        'SELECT_GENDER_PLACEHOLDER': '--- Select gender ---',
        'SELECT_LOCATION_PLACEHOLDER': '--- Select location ---',
        'SELECT_COUNTRY_PLACEHOLDER': '--- Select country ---',
        'MODAL_TITLE_DELETE': 'Delete user',
        'DELETE_USER_INFO': 'Should the user "{{user}}" really be deleted?'
      },
      'ROLES': {
        'ROLE_ADMIN': 'Administrator',
        'ROLE_MANDATOR': 'Mandator',
        'ROLE_LOCATION': 'Location',
        'ROLE_SALES_COUNTER': 'Sales-Counter',
        'ROLE_PRE_SALES_COUNTER': 'Ext. Sales-Counter',
        'ROLE_TICKET_VALIDATOR': 'Ticket-Validator',
        'ROLE_CUSTOMER': 'Customer'
      },
      'BTN': {
        'DELETE': 'Delete',
        'CLOSE': 'Close'
      }

    }
  }
};
