import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TransactionSearch} from '../../../models/transaction-search.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: '[c360-sepa-list-item]',
  templateUrl: './sepa-list-item.component.html'
})
export class SepaListItemComponent {

  @Input() transaction: TransactionSearch;
  @Input() isSelectedLocationId;
  @Output() onClickRow: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _translationService: TranslateService) {
  }

  public onClickRowCheckbox(id: string): void {
    this.onClickRow.emit(id);
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }
}