import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuditoriumsRootLinkResolver} from './services/resolver/auditoriums-root-link.resolver';
import {AuditoriumsDetailResolver} from './services/resolver/auditoriums-detail.resolver';
import {AuditoriumsComponent} from './components/auditoriums.component';
import {HalAuditoriumsService} from './services/hal-auditoriums.service';

const appRoutes: Routes = [
  {
    path: '',
    component: AuditoriumsComponent,
    resolve: {
      rootLink: AuditoriumsRootLinkResolver
    },
    data: {
      breadcrumb: false
    },
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    HalAuditoriumsService,
    AuditoriumsRootLinkResolver,
    AuditoriumsDetailResolver
  ]
})
export class AuditoriumsRoutingModule {
}

