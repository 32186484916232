import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrinterConnectionState} from '../../shared/enums/printer.enums';
import {Subscription} from 'rxjs';
import {LayoutService} from '../../shared/services/layout.service';
import {PrinterService} from '../../shared/services/printer.service';
import {UserService} from '../../login/services/user.service';
import {HalLocationsService} from '../../locations/services/hal-locations.service';

@Component({
  selector: 'c360-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;
  public isConnected: boolean;

  constructor(private _printerService: PrinterService,
              private _halLocationsService: HalLocationsService,
              private _userService: UserService,
              private _layoutService: LayoutService) {
    this._subscription = this._printerService.connectionState$().subscribe((state) => {
      this.isConnected = (state === PrinterConnectionState.CONNECTED);
    });
  }

  ngOnInit(): void {
    if (!this._layoutService.isSidebarMinimized()) {
      this._layoutService.toggleSidebarMinimized();
    }
  }

  ngOnDestroy(): void {
  }
}
