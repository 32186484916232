export enum HistoryStatus {
  CREATED = 'CREATED',
  PAYMENT_INIT = 'PAYMENT_INIT',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REDIRECTED = 'PAYMENT_REDIRECTED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_REFUND_INIT = 'PAYMENT_REFUND_INIT',
  PAYMENT_REFUND_FAILED = 'PAYMENT_REFUND_FAILED',
  PAYMENT_REFUND_WAITING = 'PAYMENT_REFUND_WAITING',
  PAYMENT_REFUND_COMPLETED = 'PAYMENT_REFUND_COMPLETED',
  PAYMENT_EXPORTED = 'PAYMENT_EXPORTED',
  PAYMENT_CANCELED = 'PAYMENT_CANCELED',
  TICKET_BOOKING_INIT = 'TICKET_BOOKING_INIT',
  TICKET_BOOKING_FAILED = 'TICKET_BOOKING_FAILED',
  TICKET_BOOKING_COMPLETED = 'TICKET_BOOKING_COMPLETED',
  TICKET_LOCAL_PRINTED = 'TICKET_LOCAL_PRINTED',
  TICKET_LOCAL_PRINT_FAILED = 'TICKET_LOCAL_PRINT_FAILED',
  TICKET_LOCAL_VALIDATED = 'TICKET_LOCAL_VALIDATED',
  TICKET_LOCAL_VALIDATE_FAILED = 'TICKET_LOCAL_VALIDATE_FAILED',
  TICKET_LOCAL_INVALIDATED = 'TICKET_LOCAL_INVALIDATED',
  TICKET_LOCAL_INVALIDATE_FAILED = 'TICKET_LOCAL_INVALIDATE_FAILED',
  TICKET_PARTIAL_REFUND_INIT = 'TICKET_PARTIAL_REFUND_INIT',
  TICKET_PARTIAL_REFUND_FAILED = 'TICKET_PARTIAL_REFUND_FAILED',
  TICKET_PARTIAL_REFUND_COMPLETED = 'TICKET_PARTIAL_REFUND_COMPLETED',
  TICKET_REFUND_INIT = 'TICKET_REFUND_INIT',
  TICKET_REFUND_FAILED = 'TICKET_REFUND_FAILED',
  TICKET_REFUND_COMPLETED = 'TICKET_REFUND_COMPLETED',
  TICKET_PRICE_CHANGE_INIT = 'TICKET_PRICE_CHANGE_INIT',
  TICKET_PRICE_CHANGE_COMPLETED = 'TICKET_PRICE_CHANGE_COMPLETED',
  TICKET_PRICE_CHANGE_FAILED = 'TICKET_PRICE_CHANGE_FAILED',
  PRODUCT_BOOKING_INIT = 'PRODUCT_BOOKING_INIT',
  PRODUCT_BOOKING_FAILED = 'PRODUCT_BOOKING_FAILED',
  PRODUCT_BOOKING_COMPLETED = 'PRODUCT_BOOKING_COMPLETED',
  PRODUCT_PARTIAL_REFUND_INIT = 'PRODUCT_PARTIAL_REFUND_INIT',
  PRODUCT_PARTIAL_REFUND_FAILED = 'PRODUCT_PARTIAL_REFUND_FAILED',
  PRODUCT_PARTIAL_REFUND_COMPLETED = 'PRODUCT_PARTIAL_REFUND_COMPLETED',
  PRODUCT_REFUND_INIT = 'PRODUCT_REFUND_INIT',
  PRODUCT_REFUND_FAILED = 'PRODUCT_REFUND_FAILED',
  PRODUCT_REFUND_COMPLETED = 'PRODUCT_REFUND_COMPLETED',
  CONFIRMATION_MAIL_INIT = 'CONFIRMATION_MAIL_INIT',
  CONFIRMATION_MAIL_FAILED = 'CONFIRMATION_MAIL_FAILED',
  CONFIRMATION_MAIL_COMPLETED = 'CONFIRMATION_MAIL_COMPLETED',
  CONFIRMATION_MAIL_RECEIVED = 'CONFIRMATION_MAIL_RECEIVED',
  CONFIRMATION_PDF_INIT = 'CONFIRMATION_PDF_INIT',
  CONFIRMATION_PDF_FAILED = 'CONFIRMATION_PDF_FAILED',
  CONFIRMATION_PDF_COMPLETED = 'CONFIRMATION_PDF_COMPLETED',
  CONFIRMATION_PAGE_DISPLAYED = 'CONFIRMATION_PAGE_DISPLAYED',
  CHARGE_BACK_MAIL_INIT = 'CHARGE_BACK_MAIL_INIT',
  CHARGE_BACK_MAIL_FAILED = 'CHARGE_BACK_MAIL_FAILED',
  CHARGE_BACK_MAIL_COMPLETED = 'CHARGE_BACK_MAIL_COMPLETED',
  PAYMENT_CHANGE_INIT = 'PAYMENT_CHANGE_INIT',
  PAYMENT_CHANGE_COMPLETED = 'PAYMENT_CHANGE_COMPLETED',
  PAYMENT_CHANGE_FAILED = 'PAYMENT_CHANGE_FAILED',
  VOUCHERS_CHARGE_INIT = 'VOUCHERS_CHARGE_INIT',
  VOUCHERS_CHARGE_COMPLETED = 'VOUCHERS_CHARGE_COMPLETED',
  VOUCHERS_CHARGE_FAILED = 'VOUCHERS_CHARGE_FAILED',
  RECREATE_DATA_PROTECTION_IDS = 'RECREATE_DATA_PROTECTION_IDS',
  EXPORT_INIT = 'EXPORT_INIT',
  EXPORT_FAILED = 'EXPORT_FAILED',
  EXPORT_COMPLETED = 'EXPORT_COMPLETED',
}
