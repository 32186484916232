import {Hal} from '../../../shared/models/hal';
import {TransactionCustomer} from './transaction-customer.interface';
import {TransactionHistory} from './transaction-history.interface';
import {TransactionPerformance} from './transaction-performance.interface';
import {TransactionTicket} from './transaction-ticket.model';
import {TransactionPrice} from './transaction-price.interface';
import {TransactionPayment} from './transaction-payment.interface';
import {HistoryStatus} from '../../enums/history-status.enum';

import {isUndefined} from 'util';
import * as moment from 'moment';
import {Moment} from 'moment';
import {TicketStatus} from '../../enums/ticket-status.enum';
import {TransactionVoucher} from './transaction-voucher.model';
import {TicketVoucher} from './ticket-voucher.model';
import {PaymentTypes} from '../../enums/payment-types.enum';
import {TransactionProduct} from './transaction-product.model';


export class Transaction extends Hal {

  public id: string;
  public mandatorId: string;
  public type: string;
  public pickupCode: string;
  public processId: string;
  public externalId: string;
  public _links: any;
  public customer: TransactionCustomer;
  public histories: TransactionHistory[];
  public performance: TransactionPerformance;
  public tickets: TransactionTicket[];
  public products: TransactionProduct[];
  public payments: TransactionPayment[];
  public date: Moment;
  public status: string;
  public fullAmount: number;
  public fullAmountWithFee: number;
  public clientIpAddress: string;
  public clientVersion: string;
  public confirmationMailReceived: boolean;
  public currency: string;
  public cdnReferencePdf: string;
  public lastBookingMailSentDate: string;
  public skipSaleFee: boolean;
  public skipRefundFee: boolean;
  public refundFeeNet: number;
  public refundFeeGross: number;
  public saleFeeNet: number;
  public saleFeeGross: number;
  public vouchers: TransactionVoucher[] = null;
  private _isChargeBackMailCompleted: any = false;
  private _isChargeBackMailFailed: any = false;

  private _isSepaFileGeneratedForTransaction = false;

  static getIconForPayment(paymentMethod: PaymentTypes) {
    switch (paymentMethod) {
      case PaymentTypes.CASH:
        return 'money';
      case PaymentTypes.DIRECT_DEBIT:
        return 'university';
      case PaymentTypes.EC_TERMINAL:
        return 'credit-card';
      case PaymentTypes.VOUCHER:
        return 'ticket';
      case PaymentTypes.INVOICE_FILM_DISTRIBUTOR:
        return 'film';
    }
    return 'university';
  }

  constructor(_links: any,
              _id: string,
              _mandatorId: string,
              _externalId: string,
              _type: string,
              _pickupCode: string,
              _processId: string,
              _customer: any,
              _histories: any,
              _performance: any,
              _tickets: any,
              _products: any,
              _payments: any,
              _date: Moment,
              _status: string,
              _fullAmount: number,
              _fullAmountWithFee: number,
              _clientIpAddress: string,
              _clientVersion: string,
              _confirmationMailReceived: boolean,
              _currency: string,
              _cdnReferencePdf: string,
              _skipSaleFee: boolean,
              _skipRefundFee: boolean,
              _refundFeeNet: number,
              _refundFeeGross: number,
              _saleFeeNet: number,
              _saleFeeGross: number,
              _vouchers: any) {

    super(_links);

    this.id = _id;
    this.mandatorId = _mandatorId;
    this.type = _type;
    this.pickupCode = _pickupCode;
    this.processId = _processId;
    this.externalId = _externalId;
    this.date = _date;
    this.status = _status;
    this.fullAmount = _fullAmount;
    this.fullAmountWithFee = _fullAmountWithFee;
    this.clientIpAddress = _clientIpAddress;
    this.clientVersion = _clientVersion;
    this.confirmationMailReceived = _confirmationMailReceived;
    this.currency = _currency;
    this.cdnReferencePdf = _cdnReferencePdf;
    this.skipSaleFee = _skipSaleFee;
    this.skipRefundFee = _skipRefundFee;
    this.refundFeeNet = _refundFeeNet;
    this.refundFeeGross = _refundFeeGross;
    this.saleFeeNet = _saleFeeNet;
    this.saleFeeGross = _saleFeeGross;

    this.customer = this.parseToCustomerObject(_customer);
    this.lastBookingMailSentDate = null;
    this.histories = this.parseToArrayHistory(_histories);
    this.performance = this.parseToPerformanceObject(_performance);
    this.tickets = this.parseToArrayTicket(_tickets);
    this.products = this.parseToArrayProduct(_products);
    this.payments = this.parseToArrayPayment(_payments);
    this.vouchers = this.parseToArrayVoucher(_vouchers);
  }

  /**
   * Parse customer from response to a customer object
   *
   * @param customer
   * @returns {TransactionCustomer}
   */
  private parseToCustomerObject(customer: any): TransactionCustomer {

    const _customer: TransactionCustomer = <TransactionCustomer>{};

    if (null !== customer) {

      _customer.userId = customer.userId;
      _customer.firstName = customer.firstName;
      _customer.emailAddress = customer.emailAddress;
      _customer.gender = customer.gender;
      _customer.firstName = customer.firstName;
      _customer.lastName = customer.lastName;
      _customer.phoneNumber = customer.phoneNumber;
      _customer.mobileNumber = customer.mobileNumber;
      _customer.streetName = customer.streetName;
      _customer.houseNumber = customer.houseNumber;
      _customer.postCode = customer.postCode;
      _customer.cityName = customer.cityName;
    }

    return _customer;
  }

  /**
   * Parse histories list to a history object array
   *
   * @param {any[]} histories
   * @returns {TransactionHistory[]}
   */
  private parseToArrayHistory(histories: any[]): TransactionHistory[] {

    const _histories: TransactionHistory[] = [];

    let history = null;
    for (history of histories) {

      if (history.status === HistoryStatus.CONFIRMATION_MAIL_INIT) {


        const _dateA = moment(history.date);
        const _dateB = moment(this.lastBookingMailSentDate);

        if (null === this.lastBookingMailSentDate) {

          this.lastBookingMailSentDate = history.date;
        } else if (_dateA.isAfter(_dateB)) {

          this.lastBookingMailSentDate = history.date;
        }
      }

      _histories.push(this.parseToHistoryObject(history));
    }

    return _histories;
  }

  /**
   * Parse history from response to a history object
   *
   * @param history
   * @returns {TransactionHistory}
   */
  private parseToHistoryObject(history: any): TransactionHistory {

    const _history: TransactionHistory = <TransactionHistory>{};

    _history.id = history.id;
    _history.status = history.status;
    _history.date = history.date;
    _history.errorCode = history.errorCode;
    _history.message = history.message;
    _history.serviceInstanceName = history.serviceInstanceName;
    _history.clientType = history.clientType;
    _history.clientVersion = history.clientVersion;
    _history.clientIpAddress = history.clientIpAddress;
    _history.userId = history.userId;

    if (_history.status === HistoryStatus.CHARGE_BACK_MAIL_COMPLETED) {
      this._isChargeBackMailCompleted = true;
    }

    if (_history.status === HistoryStatus.CHARGE_BACK_MAIL_FAILED) {
      this._isChargeBackMailFailed = true;
    }

    return _history;
  }

  /**
   * Pasre performance from response to a performance object
   *
   * @param performance
   * @returns {TransactionPerformance}
   */
  private parseToPerformanceObject(performance: any): TransactionPerformance {

    const _performance: TransactionPerformance = <TransactionPerformance>{};

    if (null !== performance) {

      _performance.performanceId = performance.performanceId;
      _performance.ticketSystemType = performance.ticketSystemType;
      _performance.eventId = performance.eventId;
      _performance.eventType = performance.eventType;
      _performance.eventTypeMasterId = performance.eventTypeMasterId;
      _performance.filmId = performance.filmId;
      _performance.filmTypeChildId = performance.filmTypeChildId;
      _performance.filmTitle = performance.filmTitle;
      _performance.locationId = performance.locationId;
      _performance.locationName = performance.locationName;
      _performance.auditoriumId = performance.auditoriumId;
      _performance.auditoriumName = performance.auditoriumName;
      _performance.auditoriumLayoutId = performance.auditoriumLayoutId;
      _performance.auditoriumLayoutName = performance.auditoriumLayoutName;
      _performance.areaLayoutId = performance.areaLayoutId;
      _performance.areaLayoutName = performance.areaLayoutName;
      _performance.priceLayoutId = performance.priceLayoutId;
      _performance.priceLayoutName = performance.priceLayoutName;
      _performance.startDate = moment(performance.startDate);
      _performance.occupationType = performance.occupationType;
    }

    return _performance;
  }

  /**
   * Parse tickets list to a ticket object array
   *
   * @param {any[]} tickets
   * @returns {TransactionTicket[]}
   */
  private parseToArrayTicket(tickets: any[]): TransactionTicket[] {

    const _tickets: TransactionTicket[] = [];

    let ticket = null;
    for (ticket of tickets) {

      _tickets.push(this.parseToTicketObject(ticket));
    }

    return _tickets;
  }

  /**
   * Parse ticket from response to a ticket object
   *
   * @param ticket
   * @returns {TransactionTicket}
   */
  private parseToTicketObject(ticket: any): TransactionTicket {

    const _ticket: TransactionTicket = new TransactionTicket();

    _ticket.id = ticket.id;
    _ticket.spaceId = ticket.spaceId;
    _ticket.priceId = ticket.priceId;
    _ticket.priceMultiId = ticket.priceMultiId;
    _ticket.status = ticket.status;
    _ticket.priceCategoryName = ticket.priceCategoryName;
    _ticket.spaceRow = ticket.spaceRow;
    _ticket.spaceRowPosition = ticket.spaceRowPosition;
    _ticket.fullAmount = ticket.fullAmount;
    _ticket.netAmount = ticket.netAmount;
    _ticket.areaName = ticket.areaName;
    _ticket.salesCounterTicketId = ticket.salesCounterTicketId;
    _ticket.spaceName = ticket.spaceName;
    _ticket.fullAmountWithFee = ticket.fullAmountWithFee;

    _ticket.selectedForRefund = ticket.selectedForRefund;
    _ticket.vatSet = ticket.vatSet;
    _ticket.refundFee = ticket.refundFee;
    _ticket.saleFee = ticket.saleFee;
    _ticket.prices = this.parseToArrayPrice(ticket.prices);

    if (_ticket.status === TicketStatus.BOOKING_COMPLETED ||
      _ticket.status === TicketStatus.REFUND_FAILED
    ) {
      _ticket.selectedForRefund = true;
    } else {
      _ticket.selectedForRefund = false;
    }

    if (null !== ticket.voucher && !isUndefined(ticket.voucher)) {

      _ticket.voucher = new TicketVoucher(
        ticket.voucher.id,
        ticket.voucher.name,
        ticket.voucher.code,
        ticket.voucher.type,
        ticket.voucher.amount,
        ticket.voucher.amountWithFee,
        ticket.voucher.valueInitial,
        ticket.voucher.valueCurrent,
        ticket.voucher.description,
        this.skipSaleFee
      );
    } else {

      _ticket.voucher = null;
    }

    _ticket.skipSaleFee = this.skipSaleFee;
    _ticket.cdnReferencePdf = ticket.cdnReferencePdf;

    return _ticket;
  }

  /**
   * Parse products list to a product object array
   *
   * @param {any[]} products
   * @returns {TransactionProduct[]}
   */
  private parseToArrayProduct(products: any[]): TransactionProduct[] {

    const _products: TransactionProduct[] = [];

    let product = null;
    for (product of products) {

      _products.push(this.parseToProductObject(product));
    }

    return _products;
  }

  /**
   * Parse product from response to a product object
   *
   * @param product
   * @returns {TransactionProduct}
   */
  private parseToProductObject(product: any): TransactionProduct {

    const _product: TransactionProduct = new TransactionProduct();

    _product.id = product.id;
    _product.externalId = product.externalId;
    _product.type = product.type;
    _product.name = product.name;
    _product.code = product.code;
    _product.netAmount = product.netAmount;
    _product.grossAmount = product.grossAmount;
    _product.vatSet = product.varSet;
    _product.status = product.status;

    return _product;
  }

  /**
   * Parse prices list to a price object array
   *
   * @param {any[]} prices
   * @returns {TransactionPrice[]}
   */
  private parseToArrayPrice(prices: any[]): TransactionPrice[] {

    const _prices: TransactionPrice[] = [];

    let price = null;
    for (price of prices) {

      _prices.push(this.parseToPriceObject(price));
    }

    return _prices;
  }

  /**
   * Parse price from response to a price object
   *
   * @param price
   * @returns {TransactionPrice}
   */
  private parseToPriceObject(price: any): TransactionPrice {

    const _price: TransactionPrice = <TransactionPrice>{};

    _price.id = price.id;
    _price.priceSegmentType = price.priceSegmentType;
    _price.priceSegmentNetAmount = price.priceSegmentNetAmount;
    _price.priceSegmentGrossAmount = price.priceSegmentGrossAmount;
    _price.confirmationHint = price.confirmationHint;

    return _price;
  }

  /**
   * Parse payment list to a payment object array
   *
   * @param {any[]} payments
   * @returns {TransactionPayment[]}
   */
  private parseToArrayPayment(payments: any[]): TransactionPayment[] {

    const _payments: TransactionPayment[] = [];

    let payment = null;
    for (payment of payments) {

      _payments.push(this.parseToPaymentObject(payment));
    }

    return _payments;
  }

  /**
   * Parse payment from response to a payment object
   *
   * @param payment
   * @returns {TransactionPayment}
   */
  private parseToPaymentObject(payment: any): TransactionPayment {

    const _payment: TransactionPayment = <TransactionPayment>{};

    _payment.id = payment.id;
    _payment.amount = payment.amount;
    _payment.type = payment.type;
    _payment.provider = payment.provider;
    _payment.providerType = payment.providerType;
    _payment.providerTypeIdEncrypted = payment.providerTypeIdEncrypted;
    _payment.providerTypeIdMasked = payment.providerTypeIdMasked;
    _payment.providerTypeReference = payment.providerTypeReference;
    _payment.sepaFile = payment.sepaFile;

    if (_payment.sepaFile != null) {
      this._isSepaFileGeneratedForTransaction = true;
    }

    return _payment;
  }


  /**
   * Parse payment list to a voucher object array
   *
   * @param {any[]} vouchers
   * @returns {TransactionVoucher[]}
   */
  private parseToArrayVoucher(vouchers: any[]): TransactionVoucher[] {

    const _vouchers: TransactionVoucher[] = [];

    let voucher = null;
    for (voucher of vouchers) {

      _vouchers.push(this.parseToVoucherObject(voucher));
    }

    return _vouchers;
  }

  /**
   * Parse voucher from response to a voucher object
   *
   * @param voucher
   * @returns {TransactionVoucher}
   */
  private parseToVoucherObject(voucher: any): TransactionVoucher {

    const _voucher: TransactionVoucher = new TransactionVoucher(
      voucher.id,
      voucher.name,
      voucher.code,
      voucher.type,
      voucher.amount,
      voucher.valueInitial,
      voucher.valueCurrent,
      voucher.description
    );

    return _voucher;
  }

  /**
   * Gets fullname
   *
   * @returns {string}
   */
  public getFullNameForTab(): string {

    let _fullName = '';

    if (null !== this.customer && !isUndefined(this.customer.firstName) && !isUndefined(this.customer.lastName)) {
      _fullName = this.customer.firstName + ' ' + this.customer.lastName + ',';
    }

    return _fullName;
  }

  /**
   * Gets Total of Tickets
   * @returns {number}
   */
  public getTotalTickts(): number {

    return this.tickets.length;
  }

  /**
   * Gets isSepaFileGeneratedForTransaction
   * @returns {boolean}
   */
  get isSepaFileGeneratedForTransaction(): boolean {
    return this._isSepaFileGeneratedForTransaction;
  }


  get isChargeBackMailCompleted(): any {
    return this._isChargeBackMailCompleted;
  }

  set isChargeBackMailCompleted(value: any) {
    this._isChargeBackMailCompleted = value;
  }

  get isChargeBackMailFailed(): any {
    return this._isChargeBackMailFailed;
  }

  set isChargeBackMailFailed(value: any) {
    this._isChargeBackMailFailed = value;
  }
}
