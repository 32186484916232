<div class="row" >
  <div class="col-sm-12">
    <div class="row" [style.opacity]="loading ? 0.1:1" *ngIf="uploader">
      <div class="col-sm-2">
        <input class="file-input" #fileManger type="file" ng2FileSelect [uploader]="uploader" [multiple]="true"/>
        <button type="button"
                class="btn btn-success"
                (click)="openFileManager()">
          <i class="ion-ios-plus"></i> Medium hinzufügen
        </button>
      </div>
      <div class="col-sm-10">
        <div class="row">
          <div class="col-sm-12">
            <table class="table table-striped upload-queue">
              <thead>
              <tr>
                <th width="20%">Alt-Text bzw. Titel</th>
                <th width="10%">Typ</th>
                <th width="110px">Vorschau</th>
                <th width="10%">Größe</th>
                <th>Fortschritt</th>
                <th width="10%">Status</th>
                <th width="10%"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of uploader.queue;index as i;">
                <td>
                  <input type="text" class="form-control"
                         [(ngModel)]="item.description"
                         aria-label="Alt-Text bzw. Titel"
                         placeholder="Alt-Text bzw. Titel">
                </td>
                <td>
                  <select class="custom-select" [(ngModel)]="item.type">
                    <option *ngFor="let key of filteredMediaTypesKeys('TRAILER_YOUTUBE')" [value]="key"
                            [label]="mediaTypes[key]">{{ 'MODUL_EVENTS.MODEL.FILM.MEDIA.' + mediaTypes[key] | translate }}</option>
                  </select>
                </td>
                <td>
                  <img *ngIf="item.fileType === 'image'" class="preview-img" [src]="item?.filePreviewPath">
                  <div *ngIf="item.fileType === 'video'" class="text-center">
                    <i class="fa fa-file-video-o" aria-hidden="true"></i>
                  </div>
                  <div *ngIf="!item.fileType" class="text-center">
                    <i class="fa fa-file-o" aria-hidden="true"></i>
                  </div>

                </td>
                <td *ngIf="uploader.options.isHTML5" nowrap>
                  {{ item?.file?.size/1024/1024 | number:'.2' }} MB
                </td>
                <td *ngIf="uploader.options.isHTML5">
                  <div class="progress" style="margin-bottom: 0;">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                  </div>
                </td>
                <td class="text-center">
                  <span *ngIf="item.isSuccess"><i class="fa fa-check"></i></span>
                  <span *ngIf="item.isCancel"><i class="fa fa-ban"></i></span>
                  <span *ngIf="item.isError"><i class="fa fa-times"></i></span>
                </td>
                <td nowrap>
                  <button type="button" class="btn btn-danger btn-xs"
                          (click)="item.remove()">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-12">
            <div>
              <div>
                Gesamt Fortschritt
                <div class="progress">
                  <div class="progress-bar" role="progressbar"
                       [ngStyle]="{ 'width': (isProgressNaN() ? 0 : uploader.progress) + '%' }"></div>
                </div>
              </div>
              <button type="button"
                      class="btn btn-success mt-2"
                      (click)="uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                <span class="fa fa-cloud-upload"></span>
                UPLOAD-ALL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <c360-loading *ngIf="loading"></c360-loading>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <hr>
      </div>
    </div>
    <!-- Ansicht für Poster und Szenenbild -->
    <!--<div class="form-group row">
      <div class="col-md-10">
        <div class="form-row align-item-center">
          <div class="col-auto">
            <select class="custom-select" id="media-upload">
              <option selected>Poster</option>
              <option value="1">Szenenbild</option>
              <option value="2">Trailer hochladen</option>
              <option value="2">Trailer-ID hochladen</option>
              <option value="3">Youtube-ID</option>
            </select>
          </div>
          <div class="col-auto">
            <label class="custom-file">
              <input type="file" id="event-poster" class="custom-file-input" required>
              <span class="custom-file-control"></span>
            </label>
          </div>
          <div class="col-auto">
            <input type="text" class="form-control" id="event-poster-alt" aria-label="Alt-Text bzw. Titel"
                   placeholder="Alt-Text bzw. Titel">
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-success"><i class="ion-ios-plus"></i> Medium hinzufügen</button>
          </div>
        </div>
      </div>
    </div>-->
    <!-- Ansicht für Trailer-ID bzw. Youtube ID -->
    <div class="form-group row">
      <div class="col-md-10">
        <div class="form-row align-item-center">
          <div class="col-auto">
            <select class="custom-select"
                    id="media-upload"
                    [disabled]="true">
              <option value="TRAILER_YOUTUBE">Youtube-ID</option>
            </select>
          </div>
          <div class="col-auto">
            <input type="text"
                   class="form-control"
                   arialabel="Trailer-Id bzw. Youtube-ID"
                   placeholder="Trailer-ID bzw. Youtube-ID"
                   (input)="newYoutubeId.value = $event.target.value"
                   #newYoutubeId>
          </div>

          <div class="col-auto">
            <input type="text"
                   class="form-control"
                   aria-label="Alt-Text bzw. Titel"
                   placeholder="Alt-Text bzw. Titel"
                   (input)="newDescription.value = $event.target.value"
                   #newDescription>
          </div>
          <div class="col-auto">
            <button type="button"
                    class="btn btn-success"
                    [disabled]="newYoutubeId.value.length === 0"
                    (click)="addNewMedia(newYoutubeId.value, newDescription.value);newYoutubeId.value = null;newDescription.value = null">
              <i class="ion-ios-plus"></i> Medium hinzufügen
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <hr>
      </div>
    </div>
    <!-- bis hier-->
    <div class="row">
      <div class="col-sm-12">
        <p><strong>Poster</strong> mit einer Mindestbreite von 1080px hochladen. Notwendige Größen werden automatisch
          generiert.<br>
          <strong>Szenenbild</strong> mit einer Mindestbreite von ??px hochladen. Notwendige Größen werden automatisch
          generiert.</p>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <table class="table table-striped table--media">
      <caption hidden>Liste aller Medien</caption>
      <thead>
      <tr>
        <th scope="col">#</th>
        <!-- <th scope="col">Aktiv</th> -->
        <th scope="col">Art</th>
        <th scope="col">Vorschau</th>
        <th scope="col">Titel bzw. Alt-Text</th>
        <!-- <th scope="col">Originalgröße</th> -->
        <th scope="col">Trailer-/Youtube-ID</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let media of film.media; let i=index;">
        <th scope="row">{{i + 1}}</th>
        <!--
         <td>
           <div class="form-check">
             <label class="form-check-label hide">
               <input class="form-check-input" type="checkbox" value="" aria-label="aktiv">
             </label>
           </div>
         </td>
         -->
        <td>
          <span *ngIf="media.type === 'TRAILER_YOUTUBE' || !isEditing(i)" [translate]="'MODUL_EVENTS.MODEL.FILM.MEDIA.' + media.type"></span>
          <select class="custom-select"
                  *ngIf="media.type !== 'TRAILER_YOUTUBE' && isEditing(i)"
                  [(ngModel)]="media.type">
            <option *ngFor="let key of filteredMediaTypesKeys('TRAILER_YOUTUBE')"
                    [value]="key" [label]="mediaTypes[key]">{{ 'MODUL_EVENTS.MODEL.FILM.MEDIA.' + mediaTypes[key] | translate }}</option>
          </select>
        </td>
        <td>
          <a *ngIf="media.type !== 'TRAILER_YOUTUBE'" [href]="cdnImageEnvironment.imageUrl(media.url)">
            <img [src]="cdnImageEnvironment.imageUrl(media.url)+'?tr=w-100'" alt="{{media.description}}" class="preview-img">
          </a>

          <a *ngIf="media.type === 'TRAILER_YOUTUBE'" [href]="youtubeEnvironment.videoUrl(media.url)" target="_blank">
            <img [src]="youtubeEnvironment.previewImageUrl(media.url)"
                 class="preview-img">
          </a>
        </td>
        <td>
          <span *ngIf="!isEditing(i)">{{media.description ? media.description : '-'}}</span>

          <input type="text"
                 class="form-control"
                 *ngIf="isEditing(i)"
                 [(ngModel)]="media.description"
                 aria-label="Alt-Text bzw. Titel"
                 placeholder="Alt-Text bzw. Titel">
        </td>
        <!-- <td>1080x1920px</td> -->
        <td></td>
        <td>
          <button type="button"
                  class="btn btn-primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Bearbeiten"
                  *ngIf="!isEditing(i)"
                  (click)="onEditMedia(i, true, media)">
            <i class="fa fa-pencil"></i><span class="text-hide">Bearbeiten</span>
          </button>
          <button type="button"
                  class="btn btn-danger"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Löschen"
                  *ngIf="!isEditing(i)"
                  (click)="onDeleteMedia(i)">
            <i class="fa fa-trash"></i><span class="text-hide">Löschen</span>
          </button>


          <button type="button"
                  class="btn btn-success"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Löschen"
                  *ngIf="isEditing(i)"
                  (click)="onUpdateFilm(i)">
            <i class="fa fa-save"></i><span class="text-hide">Save</span>
          </button>
          <button type="button"
                  class="btn btn-primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Abbrechen"
                  *ngIf="isEditing(i)"
                  (click)="onEditMedia(i, false)">
            <i class="fa fa-ban"></i><span class="text-hide">Abbrechen</span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
