import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrinterConnectionState} from '../../../shared/enums/printer.enums';
import {Subscription} from 'rxjs';
import {UserService} from '../../../login/services/user.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationService} from '../../../shared/services/validation.service';
import {HalCounterTransactionsService} from '../../services/hal-counter-transactions.service';
import {PrinterService} from '../../../shared/services/printer.service';
import {ISalesCounter} from '../../../shared/models/sales-counter.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CounterSpioNumberCheckComponent} from '../confirmation/counter-spio-number-check.component';
import {SalesCounterService} from '../../services/sales-counter.service';
import {isString} from 'util';

@Component({
  selector: 'c360-counter-dashboard',
  templateUrl: './counter-dashboard.component.html'
})
export class CounterDashboardComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  public hasPrinter = false;
  public salesCounterName: string = null;

  private _pickupcodeForm: FormGroup;
  private _pickupcodeControll: FormControl;
  private _nameForm: FormGroup;
  private _nameControll: FormControl;
  private _findTransaction: any = true;

  public salesCounter: ISalesCounter = null;
  private _spioNumberModalRef: NgbModalRef = null;

  constructor(private readonly _router: Router,
              private readonly _formBuilder: FormBuilder,
              private _halTransactionsService: HalCounterTransactionsService,
              private _printerService: PrinterService,
              private _userService: UserService,
              private _modalService: NgbModal,
              private _salesCounterService: SalesCounterService) {
  }


  ngOnInit(): void {
    this._subscription = new Subscription();
    this._subscription.add(this._printerService.connectionState$().subscribe(state => {
      this.hasPrinter = state === PrinterConnectionState.CONNECTED;
    }));

    this._subscription.add(
      this._salesCounterService.getSalesCounter().subscribe((salesCounter: ISalesCounter) => {
        this.salesCounter = salesCounter;

        if (this.salesCounter != null) {
          if (
            this.salesCounter.nextTicketId == null ||
            this.salesCounter.lastTicketId == null ||
            this.salesCounter.lastTicketId < this.salesCounter.nextTicketId
          ) {
            this.showSpioNumberCheck();
          }
        }
      })
    );
    this.salesCounterName = this._userService.getUser().salesCounterName;

    this.createFormControls();
    this.createForm();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  bookNow(): void {
    this._router.navigate(['/counter/schedule']);
  }

  /**
   * Submit name form
   */
  submitNameForm() {
    if (this._nameForm.valid) {
      this._router.navigate(['/counter/transactions/list/search/' + this._nameForm.value.nameControll]);
    }
  }

  /**
   * Submit pickupcode form
   */
  submitPickupcodeForm() {
    if (this._pickupcodeForm.valid) {

      this._halTransactionsService.getTransactionsIdByPickupCode(this._pickupcodeForm.value.pickupcodeControll).then((transactionSearch) => {

        if (null === transactionSearch) {

          this._findTransaction = false;
        } else {

          this._findTransaction = true;
          if (null == transactionSearch.transactionExternalIdNew) {
            this._router.navigate(['/counter/confirmation/' + transactionSearch.id]);
          } else {
            this._router.navigate(['/counter/confirmation/' + transactionSearch.transactionExternalIdNew + '/' + transactionSearch.pickupCode]);
          }
        }
      });
    }
  }

  /**
   * Create form controls
   */
  createFormControls(): void {

    this._nameControll = new FormControl('', [
      Validators.minLength(3)
    ]);

    this._pickupcodeControll = new FormControl('', []);
  }

  /**
   * Create form
   */
  createForm(): void {
    this._nameForm = new FormGroup({
      nameControll: this._nameControll,
    });


    this._pickupcodeForm = this._formBuilder.group({

      pickupcodeControll: [
        '',
        [ValidationService.pickupcode]
      ]
    });

  }

  /**
   *
   * @param {FormGroup} formGroup
   */
  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        // control.updateValueAndValidity();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Show SPIO Check Modal
   */
  public showSpioNumberCheck(): void {
    if (this.salesCounter != null) {
      if (this._spioNumberModalRef != null) {
        this._spioNumberModalRef.close();
        this._spioNumberModalRef = null;
      }

      this._spioNumberModalRef = this._modalService.open(CounterSpioNumberCheckComponent, {backdrop: 'static'});
      this._spioNumberModalRef.componentInstance.salesCounter = this.salesCounter;

      this._spioNumberModalRef.result.then(
        (result) => {
          if (result != null && result !== false) {
            this.salesCounter = result;
          }
        });
    }
  }

  /**
   * Gets pickupcodeForm
   * @returns {FormGroup}
   */
  get pickupcodeForm(): FormGroup {
    return this._pickupcodeForm;
  }

  /**
   * Gets pickupcodeControll
   * @returns {FormControl}
   */
  get pickupcodeControll(): FormControl {
    return this._pickupcodeControll;
  }

  /**
   * Gets nameForm
   * @returns {FormGroup}
   */
  get nameForm(): FormGroup {
    return this._nameForm;
  }

  /**
   * Gets nameControll
   * @returns {FormControl}
   */
  get nameControll(): FormControl {
    return this._nameControll;
  }

  /**
   * Gets findTransaction
   * @returns {boolean}
   */
  get findTransaction(): boolean {
    return this._findTransaction;
  }

  /**
   * Sets findTransaction
   * @param {boolean} value
   */
  set findTransaction(value: boolean) {
    this._findTransaction = value;
  }

  /**
   *
   * @returns {boolean}
   */
  get existsLocationId(): boolean {
    return this._userService.getUser().locationId && isString(this._userService.getUser().locationId);
  }
}
