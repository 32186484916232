import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Transaction} from '../../../transactions/models/transaction/transaction.model';
import {TicketStatus} from '../../../transactions/enums/ticket-status.enum';
import {PrinterService} from '../../../shared/services/printer.service';
import {ISalesCounter} from '../../../shared/models/sales-counter.model';

@Component({
  selector: 'c360-counter-reset-ticket-type-modal',
  templateUrl: './counter-reset-ticket-type-modal.component.html'
})
export class CounterResetTicketTypeModalComponent {

  @Input() transaction: Transaction = null;
  @Input() salesCounter: ISalesCounter = null;
  public ticketStatus = TicketStatus;
  public allSelected = false;

  public hasSelectedOptions: boolean = null;

  public options = [];

  /**
   *
   * @param _activeModal
   * @param _printerService
   * @param _translationService
   */
  constructor(private _activeModal: NgbActiveModal,
              private _printerService: PrinterService,
              private _translationService: TranslateService) {
  }


  setTransaction(transaction: Transaction): void {
    this.transaction = transaction;
    if (this.transaction != null) {
      this.transaction.tickets.forEach(ticket => {
        if (ticket.status === TicketStatus.LOCAL_PRINTED) {
          this.options.push({
            selected: false,
            ticket: ticket
          });
        }
      });
    }
  }

  toggleAllTickets() {
    this.options.forEach(option => {
      option.selected = this.allSelected;
    });

    this.hasSelectedOptions = this.options.some(option => option.selected);
  }

  onOptionToggled() {
    this.allSelected = this.options.every(option => option.selected);
    this.hasSelectedOptions = this.options.some(option => option.selected);
  }

  resetTickets() {

    const selectedTicketIds = this.options.filter(option => {
      return option.selected;
    }).map(option => option.ticket.id);

    this._printerService.deletePrint(this.transaction.externalId, selectedTicketIds, this.salesCounter).subscribe(
      response => {
        this.activeModal.close(response);
      }
    );
  }

  /**
   * Get current lang
   * @returns {string}
   */
  getCurrentLang() {
    return this._translationService.currentLang;
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }
}
