<div class="modal-header">
  <h4 class="modal-title">
    {{ 'MODUL_COUNTER.VOUCHER.TOGGLE-STATUS-MODAL.HEADER_' + setStatus | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p
    [innerHtml]="'MODUL_COUNTER.VOUCHER.TOGGLE-STATUS-MODAL.CONTENT_' + setStatus | translate">
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">
    {{'FORMS.BUTTONS.CANCEL' | translate}}
  </button>
  <button type="button" class="btn"
          (click)="activeModal.close(true)"
          [class.btn-success]="setStatus === 'EMITTED'"
          [class.btn-danger]="setStatus !== 'EMITTED'"
  >
    {{ 'MODUL_COUNTER.VOUCHER.BUTTONS.SET-STATUS-'+setStatus | translate}}
  </button>
</div>
