<div class="card mb-0" *ngIf="hasMandatorId">
  <div class="card-header">
      <i aria-hidden="true" class="fa fa-qrcode"></i>
      <span [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_VOUCHER'"></span>
  </div>
  <div class="card-body">
    <p [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_VOUCHER_DESCRIPTIONS.0'"></p>

    <ul>
      <li [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_VOUCHER_DESCRIPTIONS.1'"></li>
      <li [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_VOUCHER_DESCRIPTIONS.2'"></li>
      <li [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_VOUCHER_DESCRIPTIONS.3'"></li>
    </ul>

    <p [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH_VOUCHER_DESCRIPTIONS.4'"></p>
  </div>
  <div class="card-footer">
    <form autocomplete="off"
          [formGroup]="voucherStatusForm"
          (ngSubmit)="searchSubmit()">

      <div class="input-group">
        <input id="voucherCode"
               formControlName="voucherCode"
               type="search"
               aria-label="Suche"
               class="form-control my-2"/>

        <button type="submit" class="btn btn-success my-2">
          <i aria-hidden="true" class="fa fa-search"></i>
          <span [translate]="'MODUL_COUNTER.DASHBOARD.SEARCH'"></span>
        </button>
      </div>
      <c360-form-validation-messages [control]="voucherStatusForm.get('voucherCode')"></c360-form-validation-messages>
    </form>
    <div class="alert alert-danger" *ngIf="searchVoucherError" [innerHtml]="searchVoucherError"></div>
    <c360-voucher-list></c360-voucher-list>
  </div>
</div>
