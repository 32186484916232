import {HttpService} from '../../shared/services/http.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {UserService} from '../../login/services/user.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../../shared/services/alert.service';
import * as moment from 'moment';
import {StatsExport, StatsExportResponse} from '../models/stats-export.response.models';

@Injectable()
export class StatsService extends HttpService {

  protected endpoints = {
    stats: environment.urls.gateway
  };

  private _scheduleResponseKey = 'Stats_';

  public stats = new BehaviorSubject<StatsExport>(null);
  public loading = new BehaviorSubject<boolean>(false);

  constructor(protected _http: HttpClient,
              protected _alertService: AlertService,
              protected _logger: LoggerService,
              private _userService: UserService,
              @Inject('LOCALSTORAGE') private localStorage: any) {
    super(_http, _alertService, _logger);

    this.init();
  }

  private init() {

    const cached = this.getCachedStats();
    // Pass Cached Stats to all while waiting for new one
    if (cached != null) {
      this.stats.next(
        cached
      );
    }
  }

  /**
   * Check local Storage for cached Stats
   * @returns {StatsExport}
   */
  private getCachedStats(): StatsExport {
    let stored = this.localStorage.getItem(this._scheduleResponseKey);
    if (stored != null) {
      try {
        stored = JSON.parse(stored);
        if (stored.timestamp != null) {
          if (moment().diff(stored.timestamp, 'minutes') < 1) {
            return new StatsExport(
              stored.response,
              moment(stored.timestamp)
            );
          }
        }
      } catch (e) {
      }
    }
    return null;
  }

  askForStats(): void {

    // Stats is Loading
    this.loading.next(true);

    // Build Url
    const url = `${this.endpoints.stats}booking-session/export/stats`;

    this._http
      .get(url)
      .subscribe(
        (response: StatsExportResponse) => {

          // Build Basic Export Model from Response
          const stats = new StatsExport(response);

          // Pass new model to all subscribers
          this.stats.next(
            stats
          );

          // No longer loading
          this.loading.next(false);

          // Store latest Export Model
          this.localStorage.setItem(
            this._scheduleResponseKey,
            JSON.stringify({
              response: response,
              timestamp: stats.timestamp
            })
          );
        },
        () => {
        }
      );
  }

  getStats$(): Observable<StatsExport> {
    return this.stats.asObservable();
  }

  isLoading$(): Observable<boolean> {
    return this.loading.asObservable();
  }
}
