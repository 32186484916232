<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">

      <li class="nav-item" *ngIf="authorizationRules.adminboard.allowed">
        <a routerLink="/adminboard" class="nav-link" routerLinkActive="active"><i class="fa fa-warning"></i>{{ 'MODUL_NAVIGATION.SIDE.ADMINBOARD' | translate }}</a>
      </li>

      <li class="nav-item" *ngIf="authorizationRules.dashboard.allowed">
        <a routerLink="/dashboard" class="nav-link" routerLinkActive="active"><i class="fa fa-home"></i>{{ 'MODUL_NAVIGATION.SIDE.DASHBOARD' | translate }}</a><!-- add "active" class to active "nav-link" -->
      </li>

      <ng-container *ngIf="authorizationRules.users.allowed">
        <li class="nav-title">
          {{ 'MODUL_NAVIGATION.SIDE.CUSTOMERS.NAME' | translate }}
        </li>
        <li class="nav-item">
          <a routerLink="/users" class="nav-link" routerLinkActive="active"><i class="fa fa-users"></i>{{ 'MODUL_NAVIGATION.SIDE.CUSTOMERS.PROFILES' | translate }}</a>
        </li>
      </ng-container>


      <ng-container *ngIf="authorizationRules.events.allowed">
        <li class="nav-title">
          {{ 'MODUL_NAVIGATION.SIDE.EVENTS.NAME' | translate }}
        </li>
        <li class="nav-item">
          <a routerLink="/events/films/list" class="nav-link" routerLinkActive="active"><i class="fa fa-film"></i>{{ 'MODUL_NAVIGATION.SIDE.EVENTS.FILMS' | translate }}</a>
        </li>
      </ng-container>

      <ng-container *ngIf="authorizationRules.performances.allowed">
        <li class="nav-title">
          {{ 'MODUL_NAVIGATION.SIDE.PERFORMANCES.NAME' | translate }}
        </li>
        <li class="nav-item">
          <a routerLink="/performances/list" class="nav-link" routerLinkActive="active"><i class="fa fa-table"></i>{{ 'MODUL_NAVIGATION.SIDE.PERFORMANCES.LIST' | translate }}</a>
        </li>
        <li class="nav-item">
          <a routerLink="/performances/schedule" class="nav-link" routerLinkActive="active"><i class="fa fa-table"></i>{{ 'MODUL_NAVIGATION.SIDE.PERFORMANCES.SCHEDULE' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="userLocationId === null">
          <a routerLink="/performances/performance-attribute-list" class="nav-link" routerLinkActive="active"><i class="fa fa-table"></i>{{ 'MODUL_NAVIGATION.SIDE.PERFORMANCES.ATTRIBUTES' | translate }}</a>
        </li>
      </ng-container>

      <ng-container *ngIf="authorizationRules.transactions.allowed">
        <li class="nav-title" >
          {{ 'MODUL_NAVIGATION.SIDE.TRANSACTIONS.NAME' | translate }}
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/transactions/list" routerLinkActive="active"><i class="fa fa-credit-card"></i>{{ 'MODUL_NAVIGATION.SIDE.TRANSACTIONS.OVERVIEW' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/transactions/sepa" routerLinkActive="active"><i class="fa fa-credit-card"></i>{{ 'MODUL_NAVIGATION.SIDE.TRANSACTIONS.SEPA' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/transactions/reports" routerLinkActive="active"><i class="fa fa-credit-card"></i>{{'MODUL_NAVIGATION.SIDE.REPORTS.NAME' | translate }}</a>
        </li>
      </ng-container>

      <ng-container *ngIf="authorizationRules.locations.allowed">
        <li class="nav-title">
          {{ 'MODUL_NAVIGATION.SIDE.LOCATIONS.NAME' | translate }}
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="userLocationId === null" routerLink="/locations/list"><i class="fa fa-map-marker"></i>{{ 'MODUL_NAVIGATION.SIDE.LOCATIONS.OVERVIEW' | translate }}</a>
          <a class="nav-link" *ngIf="userLocationId !== null" routerLink="/locations/list"><i class="fa fa-map-marker"></i>{{ 'MODUL_NAVIGATION.SIDE.LOCATIONS.LOCATION' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="userLocationId === null">
          <a class="nav-link" routerLink="/locations/legal"><i class="fa fa-graduation-cap"></i>{{ 'MODUL_NAVIGATION.SIDE.LOCATIONS.LEGAL' | translate }}</a>
        </li>
      </ng-container>


      <ng-container *ngIf="authorizationRules.counter.allowed">
        <li class="nav-title">
          {{ 'MODUL_NAVIGATION.SIDE.COUNTER.NAME' | translate }}
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/counter"><i class="fa fa-home"></i>{{ 'MODUL_NAVIGATION.SIDE.COUNTER.DASHBOARD' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/counter/schedule"><i class="fa fa-table"></i>{{ 'MODUL_NAVIGATION.SIDE.COUNTER.SCHEDULE' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/counter/transactions/list"><i class="fa fa-credit-card"></i>{{ 'MODUL_NAVIGATION.SIDE.COUNTER.TRANSACTIONS' | translate }}</a>
        </li>
      </ng-container>

    </ul>
  </nav>
  <!--<div (click)="switchLanguage('de')" *ngIf="isCurrentLanguage('en')" class="language-switcher">
    <img src="assets/img/flags/{{getCurrentLanguage()}}.svg" class="flag" alt="">
    <span>Sprache wechseln</span>
  </div>
  <div (click)="switchLanguage('en')" *ngIf="isCurrentLanguage('de')" class="language-switcher">
    <img src="assets/img/flags/{{getCurrentLanguage()}}.svg" class="flag" alt="">
    <span>Sprache wechseln</span>
  </div>-->
</div>
