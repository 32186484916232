import {Hal} from './hal';
import {SalesCounterType} from '../enums/sales-counter-type.enum';

export interface ISalesCounter {
  description: string; // "Die Kasse ganz rechts im Foyer"
  externalId: string; // "351ac96f-e05a-4bf7-b26f-e3d25862bf55"
  id: number; // 1
  locationId: string; // "3093cf70-dd97-44c5-83d7-5aaee3a1e856"
  mandatorId: string; // "75405380-7dd9-41ec-9d22-060fd6400c12"
  name: string; // "Kasse 1"
  nextTicketId: number; // 10108
  lastTicketId: number;
  type: SalesCounterType; // "SPIO"
}


export class SalesCounter extends Hal {

  public id: number;
  public mandatorId: string;
  public locationId: string;
  public externalId: string;
  public type: SalesCounterType;
  public name: string;
  public description: string;
  public nextTicketId: number;
  public lastTicketId: number;

  public _links: any;

  constructor(
    _links?: any,
    id?: number,
    mandatorId?: string,
    locationId?: string,
    externalId?: string,
    type?: SalesCounterType,
    name?: string,
    description?: string,
    nextTicketId?: number,
    lastTicketId?: number
  ) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.locationId = locationId;
    this.externalId = externalId;
    this.type = type;
    this.name = name;
    this.description = description;
    this.nextTicketId = nextTicketId;
    this.lastTicketId = lastTicketId;

  }

}
