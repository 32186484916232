import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EventsComponent} from './components/events.component';
import {EventsDetailComponent} from './components/detail/detail.component';
import {HalEventsService} from './services/hal-events.service';
import {EventsRoutingModule} from './events-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from '../shared/services/translation-loader.service';
import {german} from './i18/client/de';
import {english} from './i18/client/en';
import {EventFormComponent} from './components/forms/event.form';
import {EventFilmFormComponent} from './components/forms/event-film.form';
import {EventsAddComponent} from './components/add/add.component';
import {SharedModule} from '../shared/shared.module';
import {EventsListComponent} from './components/events-list/events-list.component';
import {EventsListItemComponent} from './components/events-list/events-list-item.component';
import {EventsFilmsFilterComponent} from './components/films-list/filter.component';
import {FilmsListComponent} from './components/films-list/films-list.component';
import {FilmsListItemComponent} from './components/films-list/films-list-item.component';
import {EventsFilterComponent} from './components/events-list/filter.component';
import {TagInputModule} from 'ngx-chips';
import {EventMediaFormComponent} from './components/forms/event-media.form';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FileUploadModule} from 'ng2-file-upload';
import {EventDeleteModalComponent} from './components/event-delete-modal/event-delete-modal.component';
import {FilmDeleteModalComponent} from './components/film-delete-modal/film-delete-modal.component';
import {TypeaheadModule} from 'ngx-bootstrap';

const DECLARATIONS = [
  EventsComponent,
  EventsListComponent,
  EventsListItemComponent,
  FilmsListComponent,
  FilmsListItemComponent,
  EventsFilterComponent,
  EventsFilmsFilterComponent,
  EventsDetailComponent,
  EventsAddComponent,
  EventFormComponent,
  EventFilmFormComponent,
  EventMediaFormComponent,
];

const MODALS = [
  EventDeleteModalComponent,
  FilmDeleteModalComponent,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
    ...MODALS
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EventsRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    NgbModule,
    TagInputModule,
    FileUploadModule,
    TypeaheadModule
  ],
  exports: [
    EventsRoutingModule,
    ...DECLARATIONS
  ],
  providers: [
    HalEventsService
  ],
  entryComponents: [
    ...MODALS
  ]
})
export class EventsModule {

  constructor(private translationLoader: TranslationLoaderService) {

    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(english, german);
  }
}
