import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {UserService} from '../services/user.service';
import {AlertService} from '../../shared/services/alert.service';
import {InitService} from '../../init/services/init.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'c360-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  private _preEmail = '';
  private _showForgotPasswordForm = false;
  private _error = null;
  public version = null;

  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _authenitcationService: AuthenticationService,
              private _userService: UserService,
              private _initService: InitService,
              private _alertService: AlertService,
              private _logger: LoggerService
  ) {
  }

  ngOnInit(): void {
    this.version = environment.version;
    this._authenitcationService.logout();
    this._alertService.acknowledgeAlerts();
  }

  logIn(data) {
    this._error = null;
    this._authenitcationService
      .login(data.data.email, data.data.password, data.superLogin)
      .subscribe(
        (user) => {
          this._error = !user;
          if (user) {
            if (this._initService.initialUrl != null && this._initService.initialUrl.indexOf('login') === -1) {
              return this.navigateTo([this._initService.initialUrl], {relativeTo: this._route});
            } else {
              if (this._userService.isAdmin()) {
                this._initService.initialUrl = '/adminboard';
                return this.navigateTo(['/init']);
              } else if (this._userService.isLocationAdmin()) {
                this._initService.initialUrl = '/dashboard';
                return this.navigateTo(['/init'], {relativeTo: this._route});
              } else if (this._userService.isSalesCounter()) {
                this._initService.initialUrl = '/counter';
                return this.navigateTo(['/init'], {relativeTo: this._route});
              } else {
                console.error('WHAT', this._userService.getUser());
              }
            }
          }
        }, (err) => {
          // Use actual error for further details or set true to show a generic error
          if (err.error != null) {
            this._error = err.error;
          } else {
            this._error = true;
          }
        }
      );
  }

  changeEmail(_email: string): void {
    this._preEmail = _email;
  }

  protected navigateTo(commands: any[], extras?: NavigationExtras) {
    return this._router.navigate(commands, extras);
  }

  get showForgotPasswordForm(): boolean {
    return this._showForgotPasswordForm;
  }

  set showForgotPasswordForm(value: boolean) {
    this._showForgotPasswordForm = value;
  }

  get error(): any {
    return this._error;
  }

  get preEmail(): string {
    return this._preEmail;
  }
}
