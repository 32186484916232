import {Hal} from '../../shared/models/hal';

export class Location extends Hal {

  public id: string;
  public mandatorId: string;
  public name: string;
  public country: string;
  public zipCode: string;
  public city: string;
  public street: string;
  public houseNumber: number;
  public currency: string;
  public timeZone: string;
  public iban: string;
  public bic: string;
  public creditorId: string;
  public creditorName: string;
  public sepaRulebook: string;
  public mailTemplateBookingConfirmationId: string;
  public mailTemplateChargebackId: string;
  public pdfTemplateBookingConfirmationReference: string;
  public bookingTransactionRefundableForCustomer: boolean;

  public _links: any;

  constructor(
    _links?: any,
    id?: string,
    mandatorId?: string,
    name?: string,
    country?: string,
    zipCode?: string,
    city?: string,
    street?: string,
    houseNumber?: number,
    currency?: string,
    timeZone?: string,
    iban?: string,
    bic?: string,
    creditorId?: string,
    creditorName?: string,
    sepaRulebook?: string,
    mailTemplateBookingConfirmationId?: string,
    mailTemplateChargebackId?: string,
    pdfTemplateBookingConfirmationReference?: string,
    bookingTransactionRefundableForCustomer?: boolean
  ) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.name = name;
    this.country = country;
    this.zipCode = zipCode;
    this.city = city;
    this.street = street;
    this.houseNumber = houseNumber;
    this.currency = currency;
    this.timeZone = timeZone;
    this.iban = iban;
    this.bic = bic;
    this.creditorId = creditorId;
    this.creditorName = creditorName;
    this.sepaRulebook = sepaRulebook;
    this.mailTemplateBookingConfirmationId = mailTemplateBookingConfirmationId;
    this.mailTemplateChargebackId = mailTemplateChargebackId;
    this.pdfTemplateBookingConfirmationReference = pdfTemplateBookingConfirmationReference;
    this.bookingTransactionRefundableForCustomer = bookingTransactionRefundableForCustomer;
  }
}
