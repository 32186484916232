import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UsersComponent} from './components/users.component';
import {UsersListComponent} from './components/list/list.component';
import {UsersDetailComponent} from './components/detail/detail.component';
import {UsersRootLinkResolver} from './services/resolver/users-root-link.resolver';
import {UserDetailResolver} from './services/resolver/user-detail.resolver';
import {UsersAddComponent} from './components/add/add.component';
import {LocationsRootLinkResolver} from '../locations/services/resolver/locations-root-link.resolver';
import {UserProfileResolver} from './services/resolver/user-profile.resolver';
import {TransactionsRootLinkResolver} from '../transactions/services/resolver/transactions-root-link.resolver';
import {MandatorsRootLinkResolver} from '../locations/services/resolver/mandators-root-link.resolver';

const appRoutes: Routes = [
  {
    path: '',
    component: UsersComponent,
    resolve: {
      rootLink: UsersRootLinkResolver,
      MandatorsRootLinkResolver,
      LocationsRootLinkResolver,
      TransactionsRootLinkResolver
    },
    data: {
      breadcrumb: false
    },
    children: [
      {
        path: '',
        component: UsersListComponent
      },
      {
        path: 'add',
        component: UsersAddComponent,
        resolve: {
          rootLink: UsersRootLinkResolver
        },
        data: {
          breadcrumb: 'Detail'
        }
      },
      {
        path: 'detail/:id',
        component: UsersDetailComponent,
        resolve: {
          user: UserDetailResolver,
        },
        data: {
          breadcrumb: 'Detail'
        }
      },
      {
        path: 'profile',
        component: UsersDetailComponent,
        resolve: {
          user: UserProfileResolver
        },
        data: {
          breadcrumb: 'Profile'
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UsersRoutingModule {
}

