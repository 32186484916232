export const locale_base_en = {
  'lang': 'en',
  'data': {
    'MODUL_NAVIGATION' : {
      'NAME': 'Navigation',
      'TOP': {
        'LOCATION': 'Location',
        'MANDATOR': 'Mandator',
      },
      'SIDE': {
        'DASHBOARD': 'Dashboard',
        'GENERAL_SETTINGS': 'Settings',
        'GENERAL': 'General',
        'USER': 'User',
        'CUSTOMERS': {
          'NAME': 'Profiles',
          'PROFILES': 'Profiles',
        },
        'EVENTS': {
          'NAME': 'Films',
          'EVENTS': 'Events',
          'FILMS': 'Films',
          'SCHEDULE': 'Schedule'
        },
        'PERFORMANCES': {
          'NAME': 'Performances',
          'SCHEDULE': 'Schedule'
        },
        'TRANSACTIONS': {
          'NAME': 'Transactions',
          'OVERVIEW': 'List',
          'SEPA': 'SEPA'
        },
        'LOCATIONS': {
          'NAME': 'Locations',
          'OVERVIEW': 'Overview',
          'LOCATION': 'Detail',
          'DETAIL': 'Locationdetails',
          'LEGAL': 'Legal'
        },
        'COUNTER': {
          'NAME': 'Counter',
          'DASHBOARD': 'Dashboard',
          'SCHEDULE': 'Schedule',
          'TRANSACTIONS': 'Transactions',
          'CONFIRMATION': 'Confirmation'
        },
        'LANGUAGE': {
          'NAME': 'Change language',
          'DE' : 'German',
          'EN' : 'English',
        }
      }
    }
  }
};
