import {PriceSegment} from './price-segment.model';

export class Price {

  public id: string;
  public priceCategoryId: string;
  public vatSet: number;
  public netAmount: number;
  public fullAmount: number;
  public segments: Array<PriceSegment>;

  constructor(id?: string,
              priceCategoryId?: string,
              vatSet?: number,
              netAmount?: number,
              fullAmount?: number,
              segments?: Array<PriceSegment>) {
    this.id = id;
    this.priceCategoryId = priceCategoryId;
    this.vatSet = vatSet;
    this.netAmount = netAmount;
    this.fullAmount = fullAmount;
    this.segments = segments ? segments.map((segment) => new PriceSegment(segment.type, segment.netAmount)) : [];
  }

}
