import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'c360-counter-print-ticket-modal',
  templateUrl: './counter-print-ticket-modal.component.html'
})
export class CounterPrintTicketModalComponent {

  public printState: string = "";

  /**
   *
   * @param _activeModal
   */
  constructor(private _activeModal: NgbActiveModal) {
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }
}
