<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">{{ 'MODUL_TRANSACTIONS.SEPA.SEPA_MODAL.NAME' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ 'MODUL_TRANSACTIONS.SEPA.SEPA_MODAL.TEXT' | translate}}: <strong>{{response?.numTransactions}}</strong></p>
</div>
<div class="modal-body">
  <p>XML-Dateien:</p>
  <ul *ngIf="response">
    <li *ngFor="let file of response.files"><span class="link" (click)="onClickDownload(file.cdnReference)">{{file.fileName}}</span></li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Schließen</button>
</div>
