export class Hal {

  public self: string;

  constructor(
    self: string

  ) {

    this.self = self;
  }
}
