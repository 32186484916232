export class PriceSegment {

  public type: string;
  public netAmount: number;

  constructor(type?: string, netAmount?: number) {
    this.type = type;
    this.netAmount = netAmount;
  }

}
