import {Hal} from '../../shared/models/hal';
import * as moment from 'moment';

export class CounterTransactionSearch extends Hal {

  public id: string;
  public selected: boolean;
  public mandatorId: string;
  public locationId: string;
  public transactionExternalIdNew: string;
  public transactionId: number;
  public transactionType: string;
  public ticketSystemType: string;
  public historyLastStatus: string;
  public historyFirstDate: string;
  public historyFirstClientType: string;
  public historyFirstClientVersion: string;
  public pickupCode: string;
  public eventTitle: string;
  public locationName: string;
  public auditoriumName: string;
  public performanceStartDate: string;
  public customerFullName: string;
  public customerEmailAddress: string;
  public paymentProviderTypes: any;
  public fullAmount: string;
  public currency: string;

  public _links:  any;

  constructor(
    _links: any,
    id: string,
    mandatorId: string,
    locationId: string,
    transactionExternalIdNew: string,
    transactionId: number,
    transactionType: string,
    ticketSystemType: string,
    historyLastStatus: string,
    historyFirstDate: string,
    historyFirstClientType: string,
    historyFirstClientVersion: string,
    pickupCode: string,
    eventTitle: string,
    locationName: string,
    auditoriumName: string,
    performanceStartDate: string,
    customerFullName: string,
    customerEmailAddress: string,
    paymentProviderTypes: any,
    fullAmount: string,
    currency: string
  ) {
      super(_links);

      this.id = id;
      this.selected = false;
      this.mandatorId = mandatorId;
      this.locationId = locationId;
      this.transactionExternalIdNew = transactionExternalIdNew;
      this.transactionId = transactionId;
      this.transactionType = transactionType;
      this.ticketSystemType = ticketSystemType;
      this.historyLastStatus = historyLastStatus;
      this.historyFirstDate = historyFirstDate;
      this.historyFirstClientType = historyFirstClientType;
      this.historyFirstClientVersion = historyFirstClientVersion;
      this.pickupCode = pickupCode;
      this.eventTitle = eventTitle;
      this.locationName = locationName;
      this.auditoriumName = auditoriumName;
      this.performanceStartDate = performanceStartDate;
      this.customerFullName = customerFullName;
      this.customerEmailAddress = customerEmailAddress;
      this.paymentProviderTypes = paymentProviderTypes;
      this.fullAmount = fullAmount;
      this.currency = currency;
  }

  /**
   *
   * @returns {string}
   */
  get performanceStartDateAsFormatedDate(): string {
    return moment(this.performanceStartDate).format('DD.MM.YYYY hh:mm');
  }

  /**
   *
   * @returns {string}
   */
  get historyFirstDateAsFormatedDate(): string {
    return moment(this.historyFirstDate).format('DD.MM.YYYY hh:mm');
  }

  /**
   * @returns {string}
   */
  get paymentProviderType(): string {

    const _paymentTypeIndex: number = this.paymentProviderTypes.toString().indexOf(',');

    if (_paymentTypeIndex > 0) {

      return this.paymentProviderTypes.toString().substring(0, _paymentTypeIndex);
    } else {

      return this.paymentProviderTypes;
    }
  }
}
