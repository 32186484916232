<table class="table table--schedule" *ngFor="let event of films.events | orderBy:'name'" id="{{event.id}}">
  <tbody>
    <ng-container *ngFor="let film of event.films | orderBy: 'title'">
      <tr>
        <td colspan="2">
          <h3>{{film.title}}</h3>
        </td>
      </tr>
      <tr c360-counter-schedule-item [film]="film"></tr>
    </ng-container>
  </tbody>
</table>
