import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subscription} from 'rxjs';
import {HalService} from '../../shared/services/halService';
import {HalData} from '../../shared/models/halData';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {AlertService} from '../../shared/services/alert.service';
import {PriceLayout} from '../models/price-layout.model';
import {PriceLayoutHalData} from '../models/price-layout-hal-data';
import {UserService} from '../../login/services/user.service';

@Injectable()
export class HalPriceLayoutsService extends HalService implements OnDestroy {
  protected gatewayName = 'price';

  protected subscribtions: Subscription = new Subscription();
  public priceLayouts = new BehaviorSubject<PriceLayoutHalData>(null);

  constructor(protected http: HttpClient,
              protected _alertService: AlertService,
              protected _userService: UserService,
              protected _logger: LoggerService) {
    super(http, _alertService, _userService, _logger);

    this.subscribtions = this.data.subscribe((data) => {
      if (data != null && data['priceLayouts'] != null) {
        this.priceLayouts.next(data['priceLayouts']);
      }
    });
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }

  getAllPriceLayouts(page: number = null) {
    this.getAll('priceLayouts', page);
  }

  getPriceLayout(id: string): Promise<PriceLayout> {
    return super.get(this._data['priceLayouts'], id);
  }

  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];
      link = this.getRawLink(link.href); // Srtip page params
      if (id === 'priceLayouts') {
        this._data[id] = new PriceLayoutHalData(id, link);
      } else {
        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   * Save
   * @param {PriceLayout} data
   * @returns {Observable<PriceLayout>}
   */
  savePriceLayout(data: PriceLayout): Promise<PriceLayout> {
    return this.save(this._data['priceLayouts'], data);
  }

  /**
   *
   * @param {PriceLayout} data
   * @returns {Promise<any>}
   */
  updatePriceLayout(data: PriceLayout): Promise<any> {
    return this.update(this._data['priceLayouts'], data.id, data);
  }

  /**
   *
   * @param {PriceLayout} data
   * @returns {Promise<any>}
   */
  deletePriceLayout(data: PriceLayout): Promise<any> {
    return this.delete(this._data['priceLayouts'], data.id);
  }

  streamAllPricesByMandatorId(mandatorId: string): Promise<{ [key: string]: PriceLayout }> {
    const url = `${this._data['priceLayouts'].href}/search/streamAllByMandatorId?mandatorId=${mandatorId}`;

    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['priceLayouts'].parseToArrayObjects(response);
        return this._data['priceLayouts'].data;
      }
      return response;
    });
  }

  streamAllPricesByAreaPricesListAreaId(mandatorId: string, locationId: string, areaId: string): Promise<{ [key: string]: PriceLayout }> {
    const url = `${this._data['priceLayouts'].href}/search/streamAllByMandatorIdAndLocationIdAndAreaPricesListAreaId?mandatorId=${mandatorId}&locationId=${locationId}&areaId=${areaId}`;

    return this.search(
      url
    ).then((response: any) => {
      if (response != null) {
        this._data['priceLayouts'].parseToArrayObjects(response);
        return this._data['priceLayouts'].data;
      }
      return response;
    });
  }

}
