import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PriceLayoutsRootLinkResolver} from './services/resolver/price-layouts-root-link.resolver';
import {HalPriceLayoutsService} from './services/hal-price-layouts.service';
import {PricesComponent} from './components/prices.component';

const appRoutes: Routes = [
  {
    path: '',
    component: PricesComponent,
    resolve: {
      rootLink: PriceLayoutsRootLinkResolver
    },
    data: {
      breadcrumb: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    HalPriceLayoutsService,
    PriceLayoutsRootLinkResolver
  ]
})
export class PricesRoutingModule {
}

