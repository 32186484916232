export enum TicketStatus {
  BOOKING_INIT = 'BOOKING_INIT',
  BOOKING_FAILED = 'BOOKING_FAILED',
  BOOKING_COMPLETED = 'BOOKING_COMPLETED',
  LOCAL_PRINTED = 'LOCAL_PRINTED',
  LOCAL_PRINT_INVALID = 'LOCAL_PRINT_INVALID',
  LOCAL_VALIDATED = 'LOCAL_VALIDATED',
  LOCAL_INVALIDATED = 'LOCAL_INVALIDATED',
  REFUND_FAILED = 'REFUND_FAILED',
  REFUND_COMPLETED = 'REFUND_COMPLETED',
  PRICE_CHANGE_COMPLETED = 'PRICE_CHANGE_COMPLETED',
  PRICE_CHANGE_FAILED = 'PRICE_CHANGE_FAILED'
}
