import {Pipe, PipeTransform} from '@angular/core';
import {isArray, isString} from 'util';
import * as moment from 'moment';
import {Moment} from 'moment';
import {isNumber} from '../utils';

@Pipe({
  name: 'orderBy'
})
export class OrderbyPipe implements PipeTransform {
  private reA = /[^a-zA-Z]/g;
  private  reN = /[^0-9]/g;

  constructor() {}

  transform(items: any[], key: string): any[] {
    if (!isArray(items)) {
      return items;
    }

    return items.sort((a: any, b: any) => {
      if (isString(a[key])) {
        return this.sortString(a[key], b[key]);
      } else if (isNumber(a[key])) {
        return this.sortNumbers(a[key], b[key]);
      } else if (moment.isMoment(a[key])) {
        return this.sortDates(a[key], b[key]);
      } else {
        return (a[key] > b[key]) ? 1 : -1;
      }
    });
  }

  private sortDates(a: Moment, b: Moment): number {
    return (a.isBefore(b)) ? -1 : 1;
  }
  private sortNumbers(a: number, b: number): number {
    return (a < b) ? -1 : 1;
  }
  private sortString(a: string, b: string) {
    const aA = a.replace(this.reA, '');
    const bA = b.replace(this.reA, '');
    if(aA === bA) {
      const aN = parseInt(a.replace(this.reN, ''), 10);
      const bN = parseInt(b.replace(this.reN, ''), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }
}
