export enum TabCommunicationMessageTypes {
  INIT,
  CHILD_READY,
  BOOKING_COMPLETE,
  CONFIRMATION_READY,
  PRINT_TICKETS,
  CLOSE_CHILD
}

export interface TabCommunicationMessage {
  id: string;
  type: TabCommunicationMessageTypes;
  body: any;
}
