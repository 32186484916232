export const locale_base_de = {
  'lang': 'de',
  'data': {
    'LOGIN' : {
      'PASSWORD_RESET': {
        'QUESTION': 'Passwort vergessen?',
        'TITLE': 'Passwort vergessen',
        'INTRO': 'Tragen Sie hier Ihre E-Mail-Adresse ein mit der Sie bei uns registriert sind. Sie erhalten dann ein neues Passwort zugesendet.',
        'LABEL_EMAIL': 'E-Mail-Adresse',
        'SUBMIT': 'Passwort anfordern',
        'CONFIRMATION': 'Wir haben Ihnen eine Email mit weiteren Details zum zurücksetzen Ihres Passworts geschickt.',
        'ERROR': 'Leider gab es einen Fehler beim zurücksetzen Ihres Passworts.',
        'BACK_TO_LOGIN': 'Zurück zum Login'
      },
      'FORM': {
        'TITLE': 'Anmeldung',
        'LABEL_EMAIL': 'E-Mail-Adresse',
        'LABEL_PASSWORD': 'Passwort',
        'SUBMIT': 'Einloggen',
        'ERROR': 'Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.'
      }
    }
  }
};
