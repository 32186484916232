<div class="modal-body">
  <div class="alert alert-success">
    <div class="form-check">
      {{'MODUL_USERS.COMPONENT_DETAIL.SAVED_USER_INFO' | translate}}
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-dark"
          (click)="onClose()"
          translate="MODUL_USERS.BTN.CLOSE">
  </button>
</div>
