import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {LoggerService} from '../shared/services/logger/logger.service';
import {UserService} from '../login/services/user.service';
import {Subscription} from 'rxjs';
import {LayoutService} from '../shared/services/layout.service';
import {InitService} from '../init/services/init.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Component({
  selector: 'c360-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.sidebar-hidden') sidebarHidden = true;
  @HostBinding('class.sidebar-mobile-show') sidebarMobileShow = false;
  @HostBinding('class.sidebar-minimized') sidebarMinimized = false;
  @HostBinding('class.brand-minimized') brandMinimized = false;

  // Sets initial value to true to show loading spinner on first load
  private _loading = true;

  private _subscription = new Subscription();
  private _isLoggedIn = false;
  private _isInitComplete = false;
  private _ssoContainerLoaded = false;

  public ssoUrl: SafeUrl = null;

  @ViewChild('ssoHolder', {static: true}) ssoHolder: ElementRef;

  constructor(private _translate: TranslateService,
              private _router: Router,
              private _userService: UserService,
              private _layoutService: LayoutService,
              private _initService: InitService,
              private _sanitizer: DomSanitizer,
              private _logger: LoggerService
  ) {
    _router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.ssoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(environment.urls.booking + environment.urls.sso);
  }

  ngOnInit() {
    this._subscription.add(
      this._userService.isloggedIn$().subscribe((loggedIn: boolean) => {
        setTimeout(() => {
          if (!this._isLoggedIn && loggedIn && this._userService.getUser() != null) {
            this.sendSSOData();
          }
          this._isLoggedIn = loggedIn;
          this.sidebarHidden = !loggedIn || !this._isInitComplete;
        });
      })
    ).add(
      this._initService.isInitComplete$().subscribe(initComplete => {
        this._isInitComplete = initComplete;
        this.sidebarHidden = !this._isLoggedIn;
      })
    ).add(
      this._layoutService.sidebarCollapsed$.subscribe((collapsed: boolean) => {
        setTimeout(() => {
          this.sidebarMobileShow = !collapsed;
        });
      })
    ).add(
      this._layoutService.sidebarMinimized$.subscribe((minimized: boolean) => {
        setTimeout(() => {
          this.sidebarMinimized = minimized;
          this.brandMinimized = minimized;
        });
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ssoContainerLoaded() {
    this._ssoContainerLoaded = true;
    this.sendSSOData();
  }

  sendSSOData() {
    let ssoData;
    if (this._ssoContainerLoaded && this._userService.getUser() != null && this._userService.getUser().authorityBase !== 'ROLE_ADMIN') {
      ssoData = btoa(
        JSON.stringify({
          accessToken: this._userService.getUser().accessToken,
          refreshToken: this._userService.getUser().refreshToken
        })
      );
    } else if (this._userService.getUser() == null) {
      ssoData = btoa(
        JSON.stringify({
          accessToken: null,
          refreshToken: null
        })
      );

    }

    if (ssoData != null) {
      this._logger.info('SSO: send...');
      this.ssoHolder.nativeElement.contentWindow.postMessage(
        ssoData,
        '*'
      );
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this._loading = true;
    }
    if (event instanceof NavigationEnd) {
      this._loading = false;
      this.sendSSOData();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._loading = false;
    }
    if (event instanceof NavigationError) {
      this._loading = false;
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  get isInitComplete(): boolean {
    return this._isInitComplete;
  }
}
