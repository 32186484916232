import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../login/services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SalesCounter} from '../../../shared/models/sales-counter.model';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {ReportsTypes} from '../../enums/report-types.enum';
import {HalTransactionsService} from '../../services/hal-transactions.service';

export interface IReportFilterData {
  generateNew: boolean;
  salesCounter?: SalesCounter;
  reportEndDate: {
    year: number;
    month: number;
    day: number;
  };
  reportStartDate: {
    year: number;
    month: number;
    day: number;
  };
  reportType: string;
}

@Component({
  selector: 'c360-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: [
    './reports-filter.component.scss'
  ]
})
export class ReportsFilterComponent implements OnInit {
  private _subscriptions: Subscription;
  private _filterForm: FormGroup;

  private _reportTypes: any;
  private _reportTypesKeys: Array<string>;

  private _salesCounterList: { [key: string]: SalesCounter };
  private _salesCounterListKeys: Array<string>;


  @ViewChild('startDate', {static: false}) private _startDate: NgbInputDatepicker;
  @ViewChild('endDate', {static: false}) private _endDate: NgbInputDatepicker;

  @Output() onSubmit: EventEmitter<IReportFilterData> = new EventEmitter<IReportFilterData>();
  @Output() onLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _halTransactionsService: HalTransactionsService,
              private _formBuilder: FormBuilder,
              private _parserFormatter: NgbDateParserFormatter,
              private _userService: UserService) {
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
    this.onLoading.emit(true);
    this.initSalesCounterList();

    this._reportTypes = ReportsTypes;
    this._reportTypesKeys = Object.keys(this._reportTypes);

    this._filterForm = this._formBuilder
      .group(
        {
          generateNew: [
            false
          ],
          reportType: [
            null,
            [
              Validators.required
            ]
          ],
          salesCounter: [
            null
          ],
          reportStartDate: [
            null,
            [
              Validators.required
            ]
          ],
          reportEndDate: [
            null,
            [
              Validators.required
            ]
          ],
        }
      );

    this._filterForm
      .get('reportType')
      .valueChanges
      .subscribe(
        (data) => {
          this._filterForm.get('generateNew').setValue(false);
          this._filterForm.get('generateNew').disable(
            {
              onlySelf: true,
              emitEvent: false
            }
          );
        }
      );

    this._filterForm
      .get('salesCounter')
      .valueChanges
      .subscribe(
        (data) => {
          this._filterForm.get('generateNew').setValue(false);
          this._filterForm.get('generateNew').disable(
            {
              onlySelf: true,
              emitEvent: false
            }
          );
        }
      );

    this._filterForm
      .get('reportStartDate')
      .valueChanges
      .subscribe(
        (data) => {
          this._filterForm.get('generateNew').setValue(false);
          this._filterForm.get('generateNew').disable(
            {
              onlySelf: true,
              emitEvent: false
            }
          );
          if (this._filterForm.get('reportEndDate').value) {
            const startDate = moment(new Date(data.year, data.month - 1, data.day));
            const reportEndDate = this._filterForm.get('reportEndDate').value;
            const endDate = moment(new Date(reportEndDate.year, reportEndDate.month - 1, reportEndDate.day));
            if (endDate.diff(startDate) < 0) {
              this._filterForm.get('reportEndDate').setValue(null);
            }
          }
        }
      );

    this._filterForm
      .get('reportEndDate')
      .valueChanges
      .subscribe(
        (data) => {
          this._filterForm.get('generateNew').setValue(false);
          this._filterForm.get('generateNew').disable(
            {
              onlySelf: true,
              emitEvent: false
            }
          );
        }
      );

    this._halTransactionsService
      .data
      .subscribe(
        (response) => {
          this._salesCounterList = {};
          if (response && response['salesCounters']) {
            this._salesCounterList = response['salesCounters'].data;
          }
          this._salesCounterListKeys = Object.keys(this._salesCounterList);

          this._filterForm.get('salesCounter').setValidators(Validators.required);
          this._filterForm.get('salesCounter').setValue(null);
        }
      );

    if (this._filterForm.get('reportType').value !== ReportsTypes.SALES_COUNTER_REPORT) {
      this._filterForm.get('salesCounter').setValidators(null);
      this._filterForm.get('salesCounter').disable();
    } else {
      this._filterForm.get('salesCounter').setValidators(Validators.required);
    }

    this._filterForm
      .get('reportType')
      .valueChanges
      .subscribe(
        (value) => {
          this._filterForm.get('salesCounter').setValidators(null);
          this._filterForm.get('salesCounter').disable();
          if (value === ReportsTypes.SALES_COUNTER_REPORT) {
            this._filterForm.get('salesCounter').setValidators(Validators.required);
            this._filterForm.get('salesCounter').enable();
          }
        }
      );
  }

  private initSalesCounterList(): void {
    this._halTransactionsService
      .searchAllSalesCountersByLocationId(this._userService.getUser().locationId)
      .then(() => {
        this.onLoading.emit(false);
      });
  }

  public onOpenStartDate(): void {
    if (this._filterForm.get('reportStartDate').enabled) {
      this._startDate.toggle();
    }
    if (this._filterForm.get('reportEndDate').enabled) {
      this._endDate.close();
    }
  }

  public onOpenEndDate(): void {
    if (this._filterForm.get('reportEndDate').enabled) {
      this._endDate.toggle();
    }
    if (this._filterForm.get('reportStartDate').enabled) {
      this._startDate.close();
    }
  }

  public submitForm(): void {
    if (this._filterForm.valid) {
      this._filterForm.get('generateNew').enable();
      this.onSubmit.emit(this._filterForm.value);
    }
  }

  get reportTypes(): any {
    return this._reportTypes;
  }

  get reportTypesKeys(): Array<string> {
    return this._reportTypesKeys;
  }

  get salesCounterList(): { [p: string]: SalesCounter } {
    return this._salesCounterList;
  }

  get salesCounterListKeys(): Array<string> {
    return this._salesCounterListKeys;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }
}
