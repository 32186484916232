<ng-container *ngIf="schedule && schedule.daysList">
  <div class="row mb-3" *ngFor="let day of schedule.daysList | orderBy:'startDateKey'">
    <div class="col-12">
      <h2>{{day.startDate}}</h2>
    </div>
    <div class="col-12">
      <table class="table table--schedule table-striped table--selectable mb-0">
        <thead>
          <th width="50%">Film</th>
          <th>Datum</th>
          <th>Uhrzeit</th>
          <th>Kino</th>
          <th>Freie Plätze</th>
        </thead>
        <tbody>
          <tr c360-counter-schedule-item-today
              [performance]="performance"
              [film]="schedule.response.filmMap[performance.filmId]"
              (click)="gotoBooking(performance)"
              *ngFor="let performance of schedule.response.performanceMap[day.startDateKey] | orderBy:'startDate'"></tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
