import {Component, Input} from '@angular/core';
import {ScheduleExportFilm, ScheduleExportPerformance} from '../../../models/schedule-export.response.models';
import {PerformanceStatus} from '../../../../performances/enums/performance-status.enum';

@Component({
  selector: '[c360-counter-schedule-item-today]',
  templateUrl: './counter-schedule-item-today.component.html'
})
export class CounterScheduleItemTodayComponent {

  @Input() performance: ScheduleExportPerformance = null;
  @Input() film: ScheduleExportFilm = null;

  public performanceStatus = PerformanceStatus;
  constructor() {}

  getClassForPercentageOfFreeSeats(performance: ScheduleExportPerformance) {
    let result = 'occupation-success';
    const total = performance.occupationStats.total;
    const available = performance.occupationStats.free + performance.occupationStats.blocked;
    if (total - available >= (total / 100) * 50) {
      result = 'occupation-warning';
    }
    if ((total - available) >= (total / 100) * 90) {
      result = 'occupation-danger';
    }
    return result;
  }
}
