import {Injectable, OnDestroy} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HalData} from '../../shared/models/halData';
import {TransactionHalData} from '../models/transaction-hal-data';
import {TransactionSearchHalData} from '../models/transaction-search-hal-data';
import {AlertService} from '../../shared/services/alert.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../login/services/user.service';
import {Transaction} from '../models/transaction/transaction.model';
import {Params} from '../../shared/models/search/params.model';
import {SepaFileHalData} from '../models/sepa-file-hal-data';
import {Page} from '../../shared/models/page';
import {Sepa} from '../models/sepa.model';

@Injectable()
export class HalSepaService extends HalService implements OnDestroy {

  protected domainName = '';
  protected gatewayName = 'booking-transaction';
  protected subscribtions: Subscription = new Subscription();

  public transactions = new BehaviorSubject<TransactionHalData>(null);
  public transactionsSearch = new BehaviorSubject<TransactionSearchHalData>(null);
  public transactionSepaFiles = new BehaviorSubject<SepaFileHalData>(null);

  /**
   * Constructor
   *
   * @param {HttpClient} http
   * @param {AlertService} _alertService
   * @param {UserService} _userService
   * @param {LoggerService} _logger
   */
  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
    super(http, _alertService, _userService, _logger);

    this.subscribtions = this.data.subscribe((data) => {
      if (data != null && data['transactions'] != null) {

        this.transactions.next(data['transaction']);
      }
      if (data != null && data['transactionSearches'] != null) {

        this.transactionsSearch.next(data['transactionSearches']);
      }
      if (data != null && data['findSepaFiles'] != null) {

        this.transactionSepaFiles.next(data['findSepaFiles']);
      }
    });
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }

  /**
   * Parse Root Links
   * @param response
   */
  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {

      let link = response._links[id];
      link = this.getRawLink(link.href); // Script page params

      if (id === 'transaction' || id === 'transactionSearches') {

        const page = new Page();
        page.totalElementsForPage = 250;

        this._data[id] = new TransactionSearchHalData(id, link, page);
      } else if (id === 'transactions') {

        this._data[id] = new TransactionHalData(id, link);
      } else if (id === 'findSepaFiles') {

        this._data[id] = new SepaFileHalData(id, link);
      } else {

        this._data[id] = new HalData(id, link);
      }
    });
  }

  getAllSepa(page: number = null) {
    this.getAll(this.domainName, page);
  }

  getSepa(id: string): Promise<Sepa> {
    return this.get(this.data[this.domainName], id);
  }

  /**
   * Get All
   * @param {number} page
   * @returns {Observable<HalData>}
   */
  getAllTransactions(page: number = null): void {
    this.getAll('transactions', page);
  }

  /**
   * Get getSepaFiles
   * @param {number} page
   * @param {string} params
   * @returns {Observable<HalData>}
   */
  getAllSepaFiles(page: number = null, params: Params = null): boolean {

    this.getAll('findSepaFiles', page, params);

    return true;
  }

  /**
   * Get All
   * @param {number} page
   * @param {string} params
   * @returns {Observable<HalData>}
   */
  getAllTransactionsSearch(page: number = null, params: Params = null): void {

    this.getAll('transactionSearches', page, params, null, 250);
  }

  /**
   * Get transactions by Transaction Id
   *
   * @param {String} id
   */
  getTransactionsById(id: String): Promise<Transaction> {

    return this.get(this._data['transactions'], id);
  }

  /**
   * Export transactions to SEPA File
   * @param params
   * @returns {Promise<any>}
   */
  exportTransactionToSepa(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'sepa/generateDirectDebitFile', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Resend booking confirmation mail
   * @param params
   * @returns {Promise<any>}
   */
  resendBookingConfirmationMail(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/mailResend', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Refund transactions and/or ticktes
   * @param params
   * @returns {Promise<any>}
   */
  refundTransaction(params: any): Promise<any> {

    return this.http.post(this.gatewayUrl + 'booking-transaction/transactions/custom/refund', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          'transactionStatus': transactionStatus
        };

        return response;
      });
  }

  findSimpleListByPriceIds(_ids: string): Promise<any> {

    return this.http.get(this.gatewayUrl + 'price/prices/custom/findSimpleListByPriceIds?priceIds=' + _ids, {headers: this.headers})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {
        this.handleError(reason);
        return null;
      });
  }

  /**
   * Refund transactions and/or ticktes
   * @param params
   * @returns {Promise<any>}
   */
  updateTicketsPrice(params: any): Promise<any> {

    return this.http.post(
      this.gatewayUrl + 'booking-transaction/transactions/custom/updateTransactionTicketPriceType', params, {headers: this.requestHeaders})
      .toPromise()
      .then((response: any) => {

        return response;
      })
      .catch((reason) => {

        this.handleError(reason);

        const errorMessage = (reason.message) ? reason.message :
          reason.status ? '${reason.status} - ${reason.statusText}' : 'Server error';

        const transactionStatus = reason.error.errorCode + ' - ' + reason.error.errorUserMessage;
        const response = {
          'transactionStatus': transactionStatus
        };

        return response;
      });
  }
}

