import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../login/services/user.service';
import {isUndefined} from 'util';
import {Observable, Subscription} from 'rxjs';
import {AuthorizationRules} from '../../login/models/authorization.rules.interface';

@Component({
  selector: 'c360-navigation-side',
  templateUrl: './navigation-side.component.html',
})

export class SideNavigationComponent implements OnInit, OnDestroy {

  private _subscribe: Subscription;
  private _isLoggedIn$: Observable<boolean>;

  userLocationId: string = null;

  public authorizationRules: AuthorizationRules = {};

  constructor(private _translationService: TranslateService,
              private _userService: UserService) {
  }

  ngOnInit() {

    this._subscribe = this._userService.isloggedIn$().subscribe(isLoggedin => {

      if (!isUndefined(this._userService.getUser()) &&
        this._userService.getUser() !== null &&
        this._userService.getUser().locationId !== null
      ) {

        this.userLocationId = this._userService.getUser().locationId;
      } else {

        this.userLocationId = null;
      }

      this.authorizationRules = this._userService.getAuthorizationRules();
    });

    this._isLoggedIn$ = this._userService.isLoggedIn;
  }

  ngOnDestroy(): void {
    this._subscribe.unsubscribe();
  }

  switchLanguage(language: string) {
    this._translationService.use(language);
  }

  isCurrentLanguage(languageCode) {
    return this.getCurrentLanguage() === languageCode;
  }

  getCurrentLanguage() {
    return this._translationService.currentLang;
  }
}
