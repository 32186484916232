import {Injectable, OnDestroy} from '@angular/core';
import {HalService} from '../../shared/services/halService';
import {Mandator} from '../models/mandator.model';
import {MandatorHalData} from '../models/mandator-hal-data';
import {HalData} from '../../shared/models/halData';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../../shared/services/alert.service';
import {UserService} from '../../login/services/user.service';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable()
export class HalMandatorsService extends HalService implements OnDestroy {

  protected domainName = 'mandators';

  protected gatewayName = 'mandator';

  protected subscriptions: Subscription = new Subscription();

  public mandators = new BehaviorSubject<MandatorHalData>(null);

  /**
   * Constructor
   *
   * @param {HttpClient} http
   * @param {AlertService} _alertService
   * @param {UserService} _userService
   * @param {LoggerService} _logger
   */
  constructor(
    protected http: HttpClient,
    protected _alertService: AlertService,
    protected _userService: UserService,
    protected _logger: LoggerService
  ) {
    super(http, _alertService, _userService, _logger);

    this.subscriptions = this.data.subscribe((data) => {
      if (data != null && data.mandators != null) {
        this.mandators.next(data.mandators);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAllMandators(page: number = null) {
    this.getAll(this.domainName, page);
  }

  getMandatorById(id: string): Promise<Mandator> {
    return super.get(this._data[this.domainName], id);
  }

  parseRootLinks(response: any) {
    return Object.keys(response._links).forEach((id, index) => {
      let link = response._links[id];

      link = this.getRawLink(link.href); // Srtip page params
      if (id === 'mandators') {

        this._data[id] = new MandatorHalData(id, link);
        this.data.next(this._data);
      } else {
        this._data[id] = new HalData(id, link);
      }
    });
  }

  /**
   * Save
   * @param {Mandator} data
   * @returns {Observable<Mandator>}
   */
  saveMandator(data: Mandator): Promise<Mandator> {
    return this.save(this._data.mandators, data);
  }

  /**
   *
   * @param {any} data
   * @returns {Promise<any>}
   */
  updateMandator(data: any): Promise<any> {
    return this.update(this._data.mandators, data.id, data);
  }

  /**
   *
   * @param {Mandator} data
   * @returns {Promise<any>}
   */
  deleteMandator(data: Mandator): Promise<any> {
    return this.delete(this._data.mandators, data.id);
  }

  /**
   *
   * @returns {Promise<{[p: string]: Mandator}>}
   */
  streamAllMandators(): Promise<{ [key: string]: Mandator }> {
    if (this._data.mandators) {
      const url = `${this._data.mandators.href}/search/streamAllByNameMatchesRegex?name=*`;

      return this.search(
        url
      ).then((response: any) => {
        if (response != null) {
          this._data.mandators.parseToArrayObjects(response);
          return this._data.mandators.data;
        }
        return response;
      });
    } else {
      return Promise.resolve({});
    }
  }
}
