import {SpaceType} from '../../performances/enums/space-type.enum';
import {SpaceStatus} from '../../performances/enums/space-status.enum';

export class AuditoriumLayoutSpace {

  public id: string;
  public name: string;
  public type: string;
  public xPosition: number;
  public yPosition: number;
  public width: number;
  public height: number;
  public neighbourTop: string;
  public neighbourRight: string;
  public neighbourBottom: string;
  public neighbourLeft: string;
  public status: SpaceStatus;
  public row: string;
  public rowPosition: string;

  public selected: boolean;

  constructor(id?: string,
              name?: string,
              type?: string,
              xPosition?: number,
              yPosition?: number,
              width?: number,
              height?: number,
              neighbourTop?: string,
              neighbourRight?: string,
              neighbourBottom?: string,
              neighbourLeft?: string,
              row?: string,
              rowPosition?: string,
              status?: SpaceStatus) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.xPosition = xPosition;
    this.yPosition = yPosition;
    this.width = width;
    this.height = height;
    this.neighbourTop = neighbourTop;
    this.neighbourRight = neighbourRight;
    this.neighbourBottom = neighbourBottom;
    this.neighbourLeft = neighbourLeft;

    this.row = row;
    this.rowPosition = rowPosition;
    this.status = (status != null) ? status : SpaceStatus.BLOCKED;

    this.selected = false;
  }

  public isBlocked(): boolean {
    return (this.status === SpaceStatus.BLOCKED);
  }

  public isWheelchair(): boolean {
    return (this.type === SpaceType.SEAT_WHEELCHAIR);
  }

  getColor(): string {
    if (this.selected) {
      return '#4FBB6E';
    }

    switch (this.status) {
      case SpaceStatus.FREE:
        return '#aaaaaa';
      case SpaceStatus.SOLD:
      case SpaceStatus.RESERVED:
        return '#000000';
      case SpaceStatus.BLOCKED:
        return '#F33430';
      case SpaceStatus.BLOCKED_BY_DISTANCING_RULES:
        return '#7c95c9';
      case SpaceStatus.BROKEN:
        return '#ff33aa';
      case SpaceStatus.INVISIBLE:
        return '#ffffff';
      case SpaceStatus.SELECTED:
      case SpaceStatus.SELECTED_TEMP:
      case SpaceStatus.SELECTED_BY_USER:
      case SpaceStatus.TEMPORARILY_SELECTED:
        return '#27663c';
      default:
        return '#dcdcdc';
    }
  }

  getBorderRadius(factor): number {
    switch (this.type) {
      case SpaceType.SEAT_WHEELCHAIR:
        return 1 * factor;
    }
    return 0;
  }

  getStyle(factor) {
    return {
      'left.px': this.xPosition * factor,
      'top.px': this.yPosition * factor,
      'width.px': this.width * factor,
      'height.px': this.height * factor,
      'background-color': this.getColor(),
      'border-radius.px': this.getBorderRadius(factor)
    };
  }
}
