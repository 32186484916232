import {HalData} from '../../shared/models/halData';
import {Film} from './film.model';
import {isArray} from 'util';

export class FilmHalData extends HalData {

  data: {[id: string]: Film} = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.films) {
      response._embedded.films.forEach((film: Film) => {
        this.parseToObject(film);
      });
    } else if (isArray(response)) {
      response.forEach((film: Film) => {
        this.parseToObject(film);
      });
    }

    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): Film {
    if (response != null) {
      const film = new Film(
        response._links,
        response.id,
        response.eventId,
        response.typeChildId,
        response.title,
        response.description,
        response.directors,
        response.actors,
        response.cameraOperators,
        response.cutters,
        response.books,
        response.runtime,
        response.ageRating,
        response.releaseDate,
        response.countryOfOrigin,
        response.creationYear,
        response.spokenLanguage,
        response.subtitleLanguage,
        response.websiteUrl,
        response.genres,
        response.publisher,
        response.trailer,
        response.presentationType,
        response.languageVersion,
        response.media
      );

      this.data['' + response.id + ''] = film;
    }
    return this.data['' + response.id + ''];
  }
}
