import {Observable, of as observableOf, Subscription} from 'rxjs';
import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UserService} from '../../../login/services/user.service';
import {HalLocationsService} from '../../../locations/services/hal-locations.service';
import * as moment from 'moment';
import {DomSanitizer} from '@angular/platform-browser';
import {ReportsGenerationStatus} from '../../enums/report-generation-status.enum';
import {ReportsTypes} from '../../enums/report-types.enum';
import {IReportFilterData} from './reports-filter.component';
import {HalTransactionsService} from '../../services/hal-transactions.service';
import {isString} from 'util';
import {TranslateService} from '@ngx-translate/core';
import {concatMap} from 'rxjs/operators';

@Component({
  selector: 'c360-reports',
  templateUrl: './reports.component.html',
  styleUrls: [
    './reports.component.scss'
  ]
})
export class ReportsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  private _generationStatus: string;
  private _isLoading: boolean;

  @ViewChild('iframe', {static: true}) iframe: ElementRef;

  constructor(private _halTransactionsService: HalTransactionsService,
              private _halLocationsService: HalLocationsService,
              private _sanitizer: DomSanitizer,
              private _renderer2: Renderer2,
              private _userService: UserService,
              private _translate: TranslateService) {
    this._isLoading = true;


  }


  ngOnInit(): void {
    this._subscriptions = new Subscription();
    this._renderer2.setStyle(this.iframe.nativeElement, 'height', `0px`);
    this.iframe.nativeElement.addEventListener('load', (object) => {
      this._isLoading = false;
      this._renderer2.setStyle(this.iframe.nativeElement, 'height', `${this.iframe.nativeElement.contentWindow.document.body.scrollHeight}px`);
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public onLoadingFilter(loading: boolean): void {
    this._isLoading = loading;
  }

  public onSubmitFilter(data: IReportFilterData): void {
    this._generationStatus = null;
    this.iframe.nativeElement.srcdoc = '';
    this._renderer2.setStyle(this.iframe.nativeElement, 'height', `0px`);
    const startDateMoment = moment(new Date(data.reportStartDate.year, data.reportStartDate.month - 1, data.reportStartDate.day, 0, 0, 0, 0));
    const endDateMoment = moment(new Date(data.reportEndDate.year, data.reportEndDate.month - 1, data.reportEndDate.day, 23, 59, 59, 999));


    let reportObject: Observable<any>;

    if (data.reportType === ReportsTypes.SALES_COUNTER_REPORT && data.salesCounter && data.salesCounter.externalId) {
      reportObject = this._halTransactionsService.generateReports(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.set({
        hours: 5,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      }).format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.add({days: 1}).set({
        hours: 4,
        minutes: 59,
        seconds: 59,
        milliseconds: 999
      }).format('YYYY-MM-DDTHH:mm:ss'), data.generateNew, data.salesCounter.externalId);
    } else if (data.reportType === ReportsTypes.DISTRIBUTOR_REPORT) {
      reportObject = this._halTransactionsService.generateDistributorReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.format('YYYY-MM-DDTHH:mm:ss'), data.generateNew);
    } else if (data.reportType === ReportsTypes.PERFORMANCE_REPORT) {
      reportObject = this._halTransactionsService.generatePerformanceReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.format('YYYY-MM-DDTHH:mm:ss'), data.generateNew);
    } else if (data.reportType === ReportsTypes.PERFORMANCE_REPORT_INCLUDE_SELLING_POINT) {
      reportObject = this._halTransactionsService.generatePerformanceReportIncludeSellingPoint(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.format('YYYY-MM-DDTHH:mm:ss'), data.generateNew);
    } else if (data.reportType === ReportsTypes.AUDITORIUM_REPORT) {
      reportObject = this._halTransactionsService.generateAuditoriumReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.format('YYYY-MM-DDTHH:mm:ss'), data.generateNew);
    } else if (data.reportType === ReportsTypes.POST_CODE_REPORT) {
      reportObject = this._halTransactionsService.generatePostCodeReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.format('YYYY-MM-DDTHH:mm:ss'), data.generateNew);
    } else if (data.reportType === ReportsTypes.CITY_NAME_REPORT) {
      reportObject = this._halTransactionsService.generateCityNameReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.format('YYYY-MM-DDTHH:mm:ss'), data.generateNew);
    } else if (data.reportType === ReportsTypes.TOTAL_REPORT) {
      reportObject = this._halTransactionsService.generateTotalReport(this._translate.instant('MODUL_TRANSACTIONS.COUNTER_REPORT_TYPES.' + data.reportType), this._userService.getUser().mandatorId, this._userService.getUser().locationId, startDateMoment.format('YYYY-MM-DDTHH:mm:ss'), endDateMoment.format('YYYY-MM-DDTHH:mm:ss'), data.generateNew);
    }

    if (!reportObject) {
      return;
    }
    this._isLoading = true;
    this._subscriptions
      .add(
        reportObject
          .pipe(
            concatMap(
              (result) => {
                if (result.generationStatus === ReportsGenerationStatus.GENERATION_IS_COMPLETED) {
                  return this._halTransactionsService.getReportsHtml(result.id);
                }
                this._generationStatus = result.generationStatus;
                return this._halTransactionsService
                  .getReportObjectById(result.id)
                  .pipe(
                    concatMap(
                      (response) => {
                        this._generationStatus = response.generationStatus;
                        if (response.generationStatus === ReportsGenerationStatus.GENERATION_IS_COMPLETED) {
                          return this._halTransactionsService
                            .getReportsHtml(response.id);
                        }
                        return observableOf(true);
                      }
                    )
                  );
              }
            )
          ).subscribe(
          (html) => {
            if (isString(html)) {
              this.iframe.nativeElement.srcdoc = html;
            }
            this._isLoading = false;
          },
          () => {
            this._isLoading = false;
          }
        )
      );
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get generationStatus(): string {
    return this._generationStatus;
  }

  get existsLocationId(): boolean {
    return this._userService.getUser().locationId && isString(this._userService.getUser().locationId);
  }
}
