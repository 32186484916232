export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANDATOR = 'ROLE_MANDATOR',
  ROLE_LOCATION = 'ROLE_LOCATION',
  ROLE_SALES_COUNTER = 'ROLE_SALES_COUNTER',
  ROLE_PRE_SALES_COUNTER = 'ROLE_PRE_SALES_COUNTER',
  ROLE_TICKET_VALIDATOR = 'ROLE_TICKET_VALIDATOR',
  ROLE_CUSTOMER = 'ROLE_CUSTOMER',
  // ROLE_ANONYMOUS = 'ROLE_ANONYMOUS',
}
