import {catchError, map} from 'rxjs/operators';
import {HttpService} from '../../shared/services/http.service';
import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {LoggerService} from '../../shared/services/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {ISalesCounter} from '../../shared/models/sales-counter.model';
import {UserService} from '../../login/services/user.service';
import {AlertService} from '../../shared/services/alert.service';

@Injectable()
export class SalesCounterService extends HttpService {

  private _salesCounter: { [id: string]: ISalesCounter } = {};

  protected endpoints = {
    gateway: environment.urls.gateway
  };

  constructor(protected _http: HttpClient,
              protected _alertService: AlertService,
              protected _logger: LoggerService,
              private _userService: UserService) {
    super(_http, _alertService, _logger);
  }

  isSalesCounter() {
    return (this._userService.getUser() != null && this._userService.getUser().salesCounterId != null);
  }

  getSalesCounterId() {
    if (this.isSalesCounter()) {
      return this._userService.getUser().salesCounterId;
    }
    return null;
  }

  getSalesCounter(): Observable<any> {
    const salesCounterId = this.getSalesCounterId();
    if (salesCounterId == null) {
      return of(null);
    }

    return this._http
      .get(this.endpoints.gateway + 'booking-transaction/salesCounters/search/findByExternalId?externalId=' + salesCounterId).pipe(
        map((response) => {
          if (response != null) {
            this.debug('Got Salescounter', response);
            this._salesCounter[salesCounterId] = response as ISalesCounter;
            return this._salesCounter[salesCounterId];
          }
          return null;
        }),
        catchError((err: any, caught) => {
          return null;
        })
      );
  }

  updateSalesCounterTicketNumber(nextTicketId: number, lastTicketId: number): Observable<any> {
    const salesCounterId = this.getSalesCounterId();
    if (salesCounterId == null || this._salesCounter[salesCounterId] == null) {
      return of(null);
    }
    const id = this._salesCounter[salesCounterId].id;
    this.debug('Update Sales Counter Ticket number', id, nextTicketId, lastTicketId);
    return this._http
      .patch(
        this.endpoints.gateway + 'booking-transaction/salesCounters/' + id,
        {
          nextTicketId: nextTicketId,
          lastTicketId: lastTicketId
        }
      ).pipe(
        map((response) => {
          this.debug('Update Sales Counter Ticket number', response);
          if (response != null) {
            this._salesCounter[salesCounterId] = response as ISalesCounter;
            return this._salesCounter[salesCounterId];
          }
          return null;
        }),
        catchError((err: any, caught) => {
          return null;
        })
      );
  }

  updateSalesCounter(externalId: string, data: any): Observable<any> {
    this.debug('Update Sales Counter', externalId, data);
    return this._http
      .patch(this.endpoints.gateway + 'booking-transaction/salesCounters/' + externalId, data).pipe(
        map((response) => {
          if (response != null) {
            this._salesCounter[externalId] = response as ISalesCounter;
          }
        }),
        catchError((err: any, caught) => {
          return null;
        })
      );
  }

  private debug(...args) {
    // this._logger.info(args);
  }
}
