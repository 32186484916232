import {Hal} from '../../shared/models/hal';
import {AuditoriumLayoutSpace} from './auditorium-layout-space.model';
import {SpaceStatus} from '../../performances/enums/space-status.enum';

export class AuditoriumLayout extends Hal {

  public _links: any;
  public id: string;
  public mandatorId: string;
  public auditoriumId: string;
  public name: string;
  public spaces: Array<AuditoriumLayoutSpace>;
  public ticketAddressRetentionPeriod: number;

  constructor(_links?: any,
              id?: string,
              mandatorId?: string,
              auditoriumId?: string,
              name?: string,
              spaces?: Array<AuditoriumLayoutSpace>,
              ticketAddressRetentionPeriod?: number) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.auditoriumId = auditoriumId;
    this.name = name;
    this.spaces = spaces.map(
      (space) => {
        const scaled = (space.width > 100);

        return new AuditoriumLayoutSpace(
          space.id,
          space.name,
          space.type,
          (scaled) ? space.xPosition / 100 : space.xPosition,
          (scaled) ? space.yPosition / 100 : space.yPosition,
          (scaled) ? space.width / 100 : space.width,
          (scaled) ? space.height / 100 : space.height,
          space.neighbourTop,
          space.neighbourRight,
          space.neighbourBottom,
          space.neighbourLeft,
          space.row,
          space.rowPosition,
          (space.status != null) ? space.status : SpaceStatus.BLOCKED
        );
      }
    );
    this.ticketAddressRetentionPeriod = ticketAddressRetentionPeriod;
  }

}
