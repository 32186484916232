import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../services/authentication.service';
import {isUndefined} from 'util';

@Component({
  selector: 'c360-login-forgot-password',
  templateUrl: './login-forgot-password.component.html'
})
export class LoginForgotPasswordComponent implements OnInit {

  @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() preEmail: string;

  private _forgotPasswordForm: FormGroup;
  private _email: FormControl;
  private _showConfirmation = false;
  private _showErrorConfirmation = false;
  private _errorConfirmationMessage = null;

  constructor(private _authenitcationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {

    this._email = new FormControl(this.preEmail, [
      Validators.required,
      Validators.pattern('[^ @]*@[^ @]*')
    ]);
  }

  createForm(): void {
    this._forgotPasswordForm = new FormGroup({
      email: this._email
    });
  }

  submitForm() {
    if (this._forgotPasswordForm.valid) {
      this.forgotPassword(this._forgotPasswordForm.value.email);
    }
  }

  forgotPassword(emailAddress: string): void {

    this._authenitcationService.forgotPassword(emailAddress)
      .subscribe(
        (response) => {

          this._showConfirmation = true;
          this._showErrorConfirmation = false;
          this._errorConfirmationMessage = null;
        },
        (response) => {

          this._showErrorConfirmation = false;
          this._showErrorConfirmation = true;

          if (!isUndefined(response.error.errorCode) && !isUndefined(response.error.errorUserMessage)) {

            this._errorConfirmationMessage = response.error.errorCode + ' - ' + response.error.errorUserMessage;
          } else {

            this._errorConfirmationMessage = null;
          }
        }
      );
  }

  get forgotPasswordForm(): FormGroup {
    return this._forgotPasswordForm;
  }

  get email(): FormControl {
    return this._email;
  }

  /**
   * Gets showConfirmation
   * @returns {boolean}
   */
  get showConfirmation(): boolean {
    return this._showConfirmation;
  }

  /**
   * Gets showErrorConfirmation
   * @returns {boolean}
   */
  get showErrorConfirmation(): boolean {
    return this._showErrorConfirmation;
  }

  /**
   * Gets errorConfirmationMessage
   * @returns {any}
   */
  get errorConfirmationMessage(): any {
    return this._errorConfirmationMessage;
  }

  /**
   * Sets errorConfirmationMessage
   * @param value
   */
  set errorConfirmationMessage(value: any) {
    this._errorConfirmationMessage = value;
  }

  /**
   * Go Back
   */
  goBack() {
    this.done.emit(true);
  }
}
