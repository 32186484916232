import {HalData} from '../../shared/models/halData';
import {isArray} from 'util';
import {AuditoriumLayout} from './auditorium-layout.model';

export class AuditoriumLayoutHalData extends HalData {

  data: { [id: string]: AuditoriumLayout } = {};

  parseToArrayObjects(response: any) {
    this.data = {};

    if (response._embedded && response._embedded.auditoriumLayouts) {
      response._embedded.auditoriumLayouts.forEach((auditoriumLayout: AuditoriumLayout) => {
        this.parseToObject(auditoriumLayout);
      });
    } else if (isArray(response)) {
      response.map((auditoriumLayout: AuditoriumLayout) => {
        this.parseToObject(auditoriumLayout);
      });
    }


    if (response.page != null) {
      this.page.parseData(response.page);
    }
  }

  parseToObject(response: any): AuditoriumLayout {
    if (response != null) {
      const auditoriumLayout = new AuditoriumLayout(
        response._links,
        response.id,
        response.mandatorId,
        response.auditoriumId,
        response.name,
        response.spaces
      );

      this.data['' + response.id + ''] = auditoriumLayout;
    }
    return this.data['' + response.id + ''];
  }
}
