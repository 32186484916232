import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ICounterConfirmationCart} from '../../services/confirmation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'c360-confirm-pay-modal',
  templateUrl: './confirm-pay-modal.component.html'
})
export class ConfirmPayModalComponent {
  private _counterConfirmationCartCurrencyGrouped: Array<{ currency: string; items: Array<ICounterConfirmationCart>; total: number; }>;

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _translateService: TranslateService
  ) {
  }

  /**
   * @returns {NgbActiveModal}
   */
  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  get counterConfirmationCartCurrencyGrouped(): Array<{ currency: string; items: Array<ICounterConfirmationCart>; total: number }> {
    return this._counterConfirmationCartCurrencyGrouped;
  }

  set counterConfirmationCartCurrencyGrouped(value: Array<{ currency: string; items: Array<ICounterConfirmationCart>; total: number }>) {
    this._counterConfirmationCartCurrencyGrouped = value;
  }

  get currentLang(): string {
    return this._translateService.currentLang;
  }
}
