<div class="container-fluid">
  <div class="card">
    <div class="card-header">
        <h1>{{ 'MODUL_TRANSACTIONS.SEPA.NAME' | translate}}</h1>
    </div>
    <div class="card-body">
      <c360-loading *ngIf="isLoading"></c360-loading>
      <ngb-tabset #sepaTabs="ngbTabset"
                  (tabChange)="onTabChange($event)"
                  [activeId]="TAB_OPEN_POSITIONS">

        <!-- Start Open Position Tab -->
        <ngb-tab title="Offene Posten"
                 (select)="onTabSelect($event)"
                 [id]="TAB_OPEN_POSITIONS">

          <ng-template ngbTabContent>

            <div class="tab-pane fade show active" id="openPositionsTab" role="tabpanel" aria-labelledby="general-tab">
              <div class="mr-sm-2 mb-2">
                <button type="submit" class="btn btn-primary" (click)="exportTransactionToSepa()" [disabled]="!isSelectedLocationId()">{{ 'MODUL_TRANSACTIONS.SEPA.BUTTON_EXPORT' | translate}}</button>
                <span *ngIf="!isSelectedLocationId()"><i style="color: #ff0000;margin-left: 10px;"> {{ 'MODUL_TRANSACTIONS.SEPA.PLEASE_SELECT_LOCATION' | translate}}</i></span>
              </div>
              <c360-transactions-search (searchParams)="searchWithParams($event)"></c360-transactions-search>
              <form *ngIf="transactionHalData">
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-striped table-bordered table--selectable">
                      <caption hidden><h2>Liste aller Buchunge</h2></caption>
                      <thead>
                      <tr>
                        <th scope="col" class="text-center">
                            <div class="form-check form-check--cell p-0">
                                <input type="checkbox" class="m-0" id="selectAll"
                                       (change)="selectAllRows()"
                                       name="selectedAllRowsFormControl"
                                       [(ngModel)]="selectedAllRowsFormControl"

                                       [disabled]="!isSelectedLocationId()">
                                <label class="form-check-label" aria-hidden="true" for="selectAll">Alle auswählen</label>
                            </div>
                        </th>
                        <th scope="col">Id</th>
                        <th class="nobr" scope="col">Pickup Code</th>
                        <th scope="col">Status</th>
                        <th scope="col">Name/Email</th>
                        <th scope="col">Buchung</th>
                        <th scope="col">Vorstellung</th>
                        <th scope="col">Ort</th>
                        <th scope="col">Zahlung</th>
                      </tr>
                      </thead>

                      <tbody>

                      <tr *ngFor="let transaction of transactionHalData.data | keys"
                          c360-sepa-list-item [transaction]="transaction.value" [isSelectedLocationId]="isSelectedLocationId()"
                          (click)="selectOneRows(transaction.value.id)">
                      </tr>

                      </tbody>

                    </table>

                    <!-- Start Pagination -->
                    <ngb-pagination *ngIf="transactionHalData.page"
                                    [maxSize]="5"
                                    [rotate]="true"
                                    [ellipses]="true"
                                    [boundaryLinks]="true"
                                    [collectionSize]="transactionHalData.page.totalElements"
                                    [pageSize]="transactionHalData.page.totalElementsForPage"
                                    [(page)]="currentTransactionPage"
                                    (pageChange)="getAllByPage($event)">
                    </ngb-pagination>
                    <!-- End Pagination -->

                  </div>
                </div>
              </form>
            </div>
          </ng-template>


        </ngb-tab>
        <!-- End Open Position Tab -->

        <!-- Start Exported Positions Tabs -->
        <ngb-tab title="Generierte Posten"
                 (select)="onTabSelect($event)"
                 [id]="TAB_EXPORTED_POSITIONS">

            <ng-template ngbTabContent>

              <div class="tab-pane fade show active" id="exportedPositionsTab" role="tabpanel" aria-labelledby="general-tab">
                <form *ngIf="sepaFileHalDat">
                  <div class="row">
                    <div class="col-md-8">
                      <table class="table table-striped table-bordered">
                        <caption hidden><h2>Liste aller Buchunge</h2></caption>
                        <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Erstelldatum</th>
                          <th scope="col">Dateiname</th>
                        </tr>
                        </thead>

                        <tbody>

                        <tr *ngFor="let sepaFile of sepaFileHalDat.data | keys"
                            c360-sepa-list-item-exported [sepaFile]="sepaFile.value"
                            (click)="selectOneRows(sepaFile.value.id)">
                        </tr>

                        </tbody>

                      </table>

                      <!-- Start Pagination -->
                      <ngb-pagination *ngIf="sepaFileHalDat.page"
                                      [maxSize]="5"
                                      [rotate]="true"
                                      [ellipses]="true"
                                      [boundaryLinks]="true"
                                      [collectionSize]="sepaFileHalDat.page.totalElements"
                                      [pageSize]="sepaFileHalDat.page.totalElementsForPage"
                                      [(page)]="currentSepaFilesPage"
                                      (pageChange)="getAllSepaFiles($event)">
                      </ngb-pagination>
                      <!-- End Pagination -->

                    </div>
                  </div>
                </form>
              </div>

            </ng-template>
        </ngb-tab>
        <!-- End Exported Positions Tabs -->

      </ngb-tabset>

    </div>
  </div>
  </div>
