import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StatsExport} from '../models/stats-export.response.models';
import {StatsService} from '../services/stats.service';
import * as moment from 'moment';
import {DataTableDirective} from 'angular-datatables';

@Component({
  selector: 'c360-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  public stats: StatsExport;
  public error;

  public isLoading = true;
  public isRefreshing = false;

  private _subscription: Subscription;

  private _refreshInterval = null;
  private _lastUpdate: string;
  dtOptions: DataTables.Settings = {};


  get lastUpdate(): string {
    return this._lastUpdate;
  }

  constructor(private _statsService: StatsService) {
  }


  ngOnInit(): void {
    this.dtOptions = {
      order: [[5, 'desc'], [0, 'asc']],
      lengthMenu: [[25, 50, -1], [25, 50, 'Alle']],
      language: {
        emptyTable: 'Keine Daten in der Tabelle vorhanden',
        info: '_START_ bis _END_ von _TOTAL_ Einträgen',
        infoEmpty: 'Keine Daten vorhanden',
        infoFiltered: '(gefiltert von _MAX_ Einträgen)',
        infoPostFix: '',
        thousands: '.',
        lengthMenu: '_MENU_ Einträge anzeigen',
        loadingRecords: 'Wird geladen ..',
        processing: 'Bitte warten ..',
        search: 'Suchen',
        zeroRecords: 'Keine Einträge vorhanden',
        paginate: {
          first: 'Erste',
          previous: 'Zurück',
          next: 'Nächste',
          last: 'Letzte'
        },
        aria: {
          sortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
          sortDescending: ': aktivieren, um Spalte absteigend zu sortieren'
        }
      },
      data: [],
      columns: [
        {data: 'realDate'},
        {data: 'date'},
        {data: 'realPlayDate'},
        {data: 'playDate'},
        {data: 'filmTitle'},
        {data: 'occupationsOccupancyThroughSold'},
        {data: 'occupationsOccupancyThroughSoldAndBlocked'},
        {data: 'countOfProcesses'},
        {data: 'occupationsSold'},
        {data: 'occupationsFree'},
        {data: 'occupationsBlocked'},
        {data: 'occupationsTotal'}
      ],
      columnDefs: [
        {
          targets: 0,
          visible: false
        },
        {
          targets: 1,
          orderData: 0
        },
        {
          targets: 2,
          visible: false
        },
        {
          targets: 3,
          orderData: 2
        }
      ]
    };

    this._subscription = this._statsService
      .isLoading$()
      .subscribe(isLoading => this.isRefreshing = isLoading);

    this._subscription.add(
      this._statsService.getStats$().subscribe(
        (stats: StatsExport) => {

          if (stats != null) {
            const data = [];
            Object.keys(stats.response.performanceStats).forEach((i) => {
              data.push({
                realDate: moment(stats.response.performanceStats[i].performance.startDate).unix(),
                date: moment(stats.response.performanceStats[i].performance.startDate).format('dd., DD.MM.YYYY'),
                realPlayDate: moment(stats.response.performanceStats[i].performance.startDate).format('HHmm'),
                playDate: moment(stats.response.performanceStats[i].performance.startDate).format('HH:mm'),
                filmTitle: stats.response.performanceStats[i].performance.filmTitle,
                occupationsOccupancyThroughSold: ((100 / stats.response.performanceStats[i].occupationStats.total) * (stats.response.performanceStats[i].occupationStats.sold)).toFixed(2),
                occupationsOccupancyThroughSoldAndBlocked: ((100 / stats.response.performanceStats[i].occupationStats.total) * (stats.response.performanceStats[i].occupationStats.sold + stats.response.performanceStats[i].occupationStats.blockedByDistancingRules)).toFixed(2),
                countOfProcesses: stats.response.performanceStats[i].countOfProcesses,
                occupationsFree: stats.response.performanceStats[i].occupationStats.free + stats.response.performanceStats[i].occupationStats.selectedFree,
                occupationsSold: stats.response.performanceStats[i].occupationStats.sold,
                occupationsBlocked: stats.response.performanceStats[i].occupationStats.blocked + stats.response.performanceStats[i].occupationStats.selectedBlocked + stats.response.performanceStats[i].occupationStats.blockedByDistancingRules,
                occupationsTotal: stats.response.performanceStats[i].occupationStats.total
              });
            });

            if (this.dtElement !== undefined && this.dtElement.dtInstance !== undefined) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.clear().rows.add(data).draw();
              });
            }

            this.stats = stats;

            this.isLoading = false;
            this._lastUpdate = this.stats.timestamp.fromNow();
          }
        }
      )
    );

    if (this._refreshInterval == null) {
      this.getStats();
      this._refreshInterval = setInterval(() => {
        this._lastUpdate = this.stats.timestamp.fromNow();
        if (moment().diff(this.stats.timestamp, 'seconds') >= 30) {
          this.getStats();
        }
      }, 15000);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    if (this._refreshInterval != null) {
      clearInterval(this._refreshInterval);
    }
    this._refreshInterval = null;
  }

  refresh(): void {
    this.getStats();

  }

  private getStats() {
    this._statsService.askForStats();
  }
}
