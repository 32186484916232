export const locale_base_en = {
  'lang': 'en',
  'data': {
    'MODUL_TRANSACTIONS': {
      'NAME': 'Transactions',
      'SEPA': {
        'NAME': 'SEPA',
        'SEPA_MODAL': {
          'NAME': 'SEPA Export',
          'TEXT': 'Ihre SEPA Datei über folgenden Link herunterladen:',
        },
        'PLEASE_SELECT_LOCATION': 'Please select a location for generate SEPA File.',
        'BUTTON_EXPORT': 'Generate SEPA File',
      },
      'COMPONENT_LIST': {
        'NAME': 'List',
        'ADD': 'Add Event',
        'SEARCH': 'Search',
        'RESET': 'Reset filter',
        'FILTER': 'Filter',
        'FILTER_OPTIONAL': 'Optional Filter',
        'TABS': {
          'OVERVIEW': 'Overview'
        }
      },
      'COMPONENT_DETAIL': {
        'NAME': 'Detail'
      },
      'REFUND_MODAL': {
        'NAME': 'Refund',
        'TEXT': 'Refund all listed ticktes?',
        'PLEASE_SELECT_TICKTES': 'Bitte wählen Sie Ticktes zum Stornieren aus.',
        'REFUNDED_PARTIAL': 'Teilstorno war erfolgreich!',
        'REFUNDED': 'Buchung storniert!',
        'REFUND_FAILED': 'Storno fehlerhaft! Bitte versuchen Sie es erneut!'
      },
      'CHARGEBACK_MODAL': {
        'NAME': 'Refund',
        'TEXT': 'Refund all listed ticktes?',
        'PLEASE_SELECT_TICKTES': 'Bitte wählen Sie Ticktes zum Stornieren aus.',
        'REFUNDED_PARTIAL': 'Teilstorno war erfolgreich!',
        'REFUNDED': 'Buchung storniert!',
        'REFUND_FAILED': 'Storno fehlerhaft! Bitte versuchen Sie es erneut!',
        'CHARGE_BACK_MAIL_COMPLETED': 'CHARGE_BACK_MAIL_COMPLETED',
        'CHARGE_BACK_MAIL_FAILED': 'CHARGE_BACK_MAIL_FAILED'
      },
      'TICKET_TYPE_UPDATE_MODAL': {
        'NAME': 'Preise ändern.',
        'TEXT': 'Aufgeführten Ticketpreise ändern?',
        'TICKET_PRICE_CHANGE_COMPLETED': 'TICKET_PRICE_CHANGE_COMPLETED',
        'TICKET_PRICE_CHANGE_FAILED': 'TICKET_PRICE_CHANGE_FAILED'
      },
      'ENUMS': {
        'USER_GENDER': {
          'FEMALE': 'Mrs.',
          'MALE': 'Mr.',
          'undefined': '-',
          'null': '-'
        },
        'TRANSACTION_STATUS': {
          'ALL': 'All',
          'COMPLETE': 'Complete',
          'CANCELED': 'Abgebrochen',
          'FAILED': 'Failed',
          'REFUNDED': 'Refunded',
          'REFUNDED_PARTIAL': 'Refunded partial',
          'REFUND_FAILED': 'Refund failed',
          'INCOMPLETE': 'Incomplete',
          'WAITING_PAYMENT': 'Waiting payment',
          'WAITING_TICKETSYSTEM': 'Waiting ticketsystem',
          'WAITING_REFUND': 'Waiting refund',
          'WAITING_EXPORT': 'Waiting export'
        },
        'TICKET_STATUS': {
          'ALL': 'All',
          'BOOKING_INIT': 'BOOKING_INIT',
          'BOOKING_FAILED': 'BOOKING_FAILED',
          'BOOKING_COMPLETED': 'BOOKING_COMPLETED',
          'LOCAL_PRINTED': 'LOCAL_PRINTED',
          'LOCAL_PRINT_INVALID': 'LOCAL_PRINT_INVALID',
          'LOCAL_VALIDATED': 'LOCAL_VALIDATED',
          'LOCAL_INVALIDATED': 'LOCAL_INVALIDATED',
          'REFUND_FAILED': 'REFUND_FAILED',
          'REFUND_COMPLETED': 'REFUND_COMPLETED',
          'REFUNDED_PARTIAL': 'REFUNDED_PARTIAL',
          'PRICE_CHANGE_COMPLETED': 'PRICE_CHANGE_COMPLETED',
          'PRICE_CHANGE_FAILED': 'PRICE_CHANGE_FAILED'
        },
        'HISTORY_STATUS': {
          'ALL': 'All',
          'CREATED': 'CREATED',
          'PAYMENT_INIT': 'PAYMENT_INIT',
          'PAYMENT_FAILED': 'PAYMENT_FAILED',
          'PAYMENT_REDIRECTED': 'PAYMENT_REDIRECTED',
          'PAYMENT_COMPLETED': 'PAYMENT_COMPLETED',
          'PAYMENT_REFUND_INIT': 'PAYMENT_REFUND_INIT',
          'PAYMENT_REFUND_FAILED': 'PAYMENT_REFUND_FAILED',
          'PAYMENT_REFUND_WAITING': 'PAYMENT_REFUND_WAITING',
          'PAYMENT_REFUND_COMPLETED': 'PAYMENT_REFUND_COMPLETED',
          'PAYMENT_EXPORTED': 'PAYMENT_EXPORTED',
          'PAYMENT_CANCELED': 'PAYMENT_CANCELED',
          'TICKET_BOOKING_INIT': 'TICKET_BOOKING_INIT',
          'TICKET_BOOKING_FAILED': 'TICKET_BOOKING_FAILED',
          'TICKET_BOOKING_COMPLETED': 'TICKET_BOOKING_COMPLETED',
          'TICKET_LOCAL_PRINTED': 'TICKET_LOCAL_PRINTED',
          'TICKET_LOCAL_PRINT_FAILED': 'TICKET_LOCAL_PRINT_FAILED',
          'TICKET_LOCAL_VALIDATED': 'TICKET_LOCAL_VALIDATED',
          'TICKET_LOCAL_VALIDATE_FAILED': 'TICKET_LOCAL_VALIDATE_FAILED',
          'TICKET_LOCAL_INVALIDATED': 'TICKET_LOCAL_INVALIDATED',
          'TICKET_LOCAL_INVALIDATE_FAILED': 'TICKET_LOCAL_INVALIDATE_FAILED',
          'TICKET_PARTIAL_REFUND_INIT': 'TICKET_PARTIAL_REFUND_INIT',
          'TICKET_PARTIAL_REFUND_FAILED': 'TICKET_PARTIAL_REFUND_FAILED',
          'TICKET_PARTIAL_REFUND_COMPLETED': 'TICKET_PARTIAL_REFUND_COMPLETED',
          'TICKET_REFUND_INIT': 'TICKET_REFUND_INIT',
          'TICKET_REFUND_FAILED': 'TICKET_REFUND_FAILED',
          'TICKET_REFUND_COMPLETED': 'TICKET_REFUND_COMPLETED',
          'TICKET_PRICE_CHANGE_INIT': 'TICKET_PRICE_CHANGE_INIT',
          'TICKET_PRICE_CHANGE_COMPLETED': 'TICKET_PRICE_CHANGE_COMPLETED',
          'TICKET_PRICE_CHANGE_FAILED': 'TICKET_PRICE_CHANGE_FAILED',
          'PRODUCT_BOOKING_INIT': 'PRODUCT_BOOKING_INIT',
          'PRODUCT_BOOKING_FAILED': 'PRODUCT_BOOKING_FAILED',
          'PRODUCT_BOOKING_COMPLETED': 'PRODUCT_BOOKING_COMPLETED',
          'PRODUCT_PARTIAL_REFUND_INIT': 'PRODUCT_PARTIAL_REFUND_INIT',
          'PRODUCT_PARTIAL_REFUND_FAILED': 'PRODUCT_PARTIAL_REFUND_FAILED',
          'PRODUCT_PARTIAL_REFUND_COMPLETED': 'PRODUCT_PARTIAL_REFUND_COMPLETED',
          'PRODUCT_REFUND_INIT': 'PRODUCT_REFUND_INIT',
          'PRODUCT_REFUND_FAILED': 'PRODUCT_REFUND_FAILED',
          'PRODUCT_REFUND_COMPLETED': 'PRODUCT_REFUND_COMPLETED',
          'CONFIRMATION_MAIL_INIT': 'CONFIRMATION_MAIL_INIT',
          'CONFIRMATION_MAIL_FAILED': 'CONFIRMATION_MAIL_FAILED',
          'CONFIRMATION_MAIL_COMPLETED': 'CONFIRMATION_MAIL_COMPLETED',
          'CONFIRMATION_MAIL_RECEIVED': 'CONFIRMATION_MAIL_RECEIVED',
          'CONFIRMATION_PDF_INIT': 'CONFIRMATION_PDF_INIT',
          'CONFIRMATION_PDF_FAILED': 'CONFIRMATION_PDF_FAILED',
          'CONFIRMATION_PDF_COMPLETED': 'CONFIRMATION_PDF_COMPLETED',
          'CONFIRMATION_PAGE_DISPLAYED': 'CONFIRMATION_PAGE_DISPLAYED',
          'CHARGE_BACK_MAIL_INIT': 'CHARGE_BACK_MAIL_INIT',
          'CHARGE_BACK_MAIL_FAILED': 'CHARGE_BACK_MAIL_FAILED',
          'CHARGE_BACK_MAIL_COMPLETED': 'CHARGE_BACK_MAIL_COMPLETED',
          'PAYMENT_CHANGE_INIT': 'PAYMENT_CHANGE_INIT',
          'PAYMENT_CHANGE_COMPLETED': 'PAYMENT_CHANGE_COMPLETED',
          'PAYMENT_CHANGE_FAILED': 'PAYMENT_CHANGE_FAILED',
          'VOUCHERS_CHARGE_INIT': 'VOUCHERS_CHARGE_INIT',
          'VOUCHERS_CHARGE_COMPLETED': 'VOUCHERS_CHARGE_COMPLETED',
          'VOUCHERS_CHARGE_FAILED': 'VOUCHERS_CHARGE_FAILED',
          'RECREATE_DATA_PROTECTION_IDS': 'RECREATE_DATA_PROTECTION_IDS',
          'EXPORT_INIT': 'EXPORT_INIT',
          'EXPORT_FAILED': 'EXPORT_FAILED',
          'EXPORT_COMPLETED': 'EXPORT_COMPLETED',
        },
        'PAYMENT_TYPES': {
          'ALL': 'All',
          'DIRECT_DEBIT': 'Direct Debit',
          'VOUCHER': 'Voucher',
          'EC_TERMINAL': 'EC Terminal',
          'CASH': 'Cash',
          'INVOICE_FILM_DISTRIBUTOR': 'Film distributor voucher',
          'PAYPAL': 'PayPal',
          'PAYDIREKT': 'Paydirekt',
          'SOFORT': 'SOFORT Überweisung',
          'KLARNA': 'Klarna',
          'CREDIT_CARD': 'Credit card',
          'CREDIT_CARD_VISA': 'Visa',
          'CREDIT_CARD_MASTER_CARD': 'mastercard',
          'CREDIT_CARD_AMERICAN_EXPRESS': 'American Express',
          'CREDIT_CARD_DINERS_DISCOVER': 'Diners Club',
          'CREDIT_CARD_JCB': 'JCB',
          'CREDIT_CARD_MAESTRO_INTERNATIONAL': 'maestro',
          'CREDIT_CARD_CHINA_UNION_PAY': 'Union Pay',
          'CREDIT_CARD_UATP_AIRPLUS': 'Airplus',
          'I_DEAL': 'iDeal',
          'FREE': '-',
        },
        'TRANSACTION_TYPE': {
          'ALL': 'All',
          'SALE': 'Sale',
          'RESERVATION': 'Reservation'
        },
        'LOCATIONS': {
          'ALL': 'All'
        },
        'SALES_COUNTER_FILTER': {
          'ALL': 'All',
          'ONLY_SALES_COUNTER': 'Only sales counter',
          'ONLY_CUSTOMER_ONLINE': 'Only Online'
        },
      }
    }
  }
};

