<div class="modal-header">
    <h4 class="modal-title" [translate]="'MODUL_COUNTER.CONFIRMATION.RESET.TITLE'"></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body clearfix">
    <p [translate]="'MODUL_COUNTER.CONFIRMATION.RESET.INTRO'"></p>
    <form *ngIf="transaction">
        <table class="table table-striped table-bordered mb-0">
          <thead>
            <tr>
              <th>
                <input type="checkbox" name="selectAllTickets" [(ngModel)]="allSelected" (change)="toggleAllTickets()">
              </th>
              <th [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.TICKET'"></th>
              <th *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'" [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.SEAT'"></th>
              <th [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.SPIO'"></th>
              <th [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.AMOUNT'" class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let option of options">
              <td>
                <input type="checkbox" name="ticketSelect" [(ngModel)]="option.selected" (change)="onOptionToggled()">
              </td>
              <td>{{option.ticket.priceCategoryName}}<b *ngIf="option.ticket.priceMultiId" class="nobr"> [Part {{option.ticket.priceMultiId}}]</b></td>
              <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'">Reihe {{option.ticket.spaceRow}}, Platz {{option.ticket.spaceRowPosition}}</td>
              <td *ngIf="option.ticket.status !== ticketStatus.LOCAL_PRINTED.toString()">ausstehend</td>
              <td *ngIf="option.ticket.status === ticketStatus.LOCAL_PRINTED.toString()">{{option.ticket.salesCounterTicketId}}</td>
              <td class="text-right" *ngIf="option.ticket.skipSaleFee">{{option.ticket.fullAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
              <td class="text-right" *ngIf="!option.ticket.skipSaleFee">{{option.ticket.fullAmountWithFee | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td *ngIf="transaction.performance.occupationType == null || transaction.performance.occupationType == 'RESERVED'" colspan="4" [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.TOTAL'"></td>
              <td *ngIf="transaction.performance.occupationType == 'UNRESERVED'" colspan="3" [translate]="'MODUL_COUNTER.CONFIRMATION.TABLE.TOTAL'"></td>
              <td class="text-right" colspan="1">{{transaction.fullAmount | currency: transaction.currency :"symbol":'1.2-2':this.getCurrentLang()}}</td>
            </tr>
          </tfoot>
        </table>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)" [translate]="'MODUL_COUNTER.CONFIRMATION.RESET.CANCEL'"></button>
    <button type="button" class="btn btn-warning" (click)="resetTickets()" [disabled]="!hasSelectedOptions" [translate]="'MODUL_COUNTER.CONFIRMATION.RESET.RESET'"></button>
</div>
