import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ScheduleExport} from '../../../models/schedule-export.response.models';
import {ScheduleExportFilms} from '../../../models/schedule-export.model';

@Component({
  selector: 'c360-counter-schedule-list-film',
  templateUrl: './counter-schedule-list-film.component.html'
})
export class CounterScheduleListFilmComponent implements OnChanges {

  @Input() schedule: ScheduleExport = null;

  private _films: ScheduleExportFilms = null;

  get films(): ScheduleExportFilms {
    return this._films;
  }

  constructor(private _cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schedule) {
      const schedule = changes.schedule.currentValue;
      this._films = new ScheduleExportFilms(schedule.response);
      this._cd.detectChanges();
    }
  }
}
