import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {InitService} from '../services/init.service';

@Injectable()
export class InitGuard implements CanActivate {

  constructor(private router: Router,
              private _initService: InitService) {
  }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    return this._initService.isInitComplete$().pipe(map((isComplete: boolean) => {
      // Deny access by Default=> Init required
      let result = false;

      if (isComplete || childRoute.routeConfig.path === 'init' || childRoute.routeConfig.path === 'login') { // Init complete or Init is requested
        // Allow on InitComplete or Init route request
        result = true;

      } else if (childRoute.routeConfig.path !== '') { // Some other Child Module is requested?
        // Store requested url for redirect on InitComplete
        this._initService.initialUrl = state.url;

        // Redirect to Init
        this.router.navigate(['/init']);

      } else { // Empty RouteConfig can be ignored
        result = true;
      }

      return result;
    }));
  }
}
