import {concatMap, debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Event} from '../../models/event.model';
import {EventTypes} from '../../enums/event-types.enum';
import {v1 as UUID4} from 'uuid';
import {isUndefined} from 'util';
import {Observable} from 'rxjs';
import {HalEventsService} from '../../services/hal-events.service';
import {UserService} from '../../../login/services/user.service';

@Component({
  selector: 'c360-event-form',
  templateUrl: './event.form.html',
  styleUrls: [
    './event.form.scss'
  ]
})
export class EventFormComponent implements OnInit {

  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() event: Event = null;
  @Input() formMode: string = null;

  private _eventForm: FormGroup;

  private _eventTypes = EventTypes;
  private _eventTypesKeys;

  private _selectedEvent: Event;
  private _isLoadingData: boolean;

  @ViewChild('searchEventElement', {static: false})
  private _searchEventElement: ElementRef;

  @ViewChild('eventSelected', {static: false})
  private _eventSelected: ElementRef;


  constructor(private _formBuilder: FormBuilder,
              private _halEventsService: HalEventsService,
              private _userService: UserService,
              private _renderer: Renderer2,
              private _changeDetectorRef: ChangeDetectorRef) {
    this._isLoadingData = false;
  }

  ngOnInit(): void {
    if (this.formMode == null) {
      this.formMode = 'update';
    }
    this._eventTypesKeys = Object.keys(this._eventTypes);
    this.createForm();
  }

  createForm(): void {
    this._eventForm = this._formBuilder.group({
      id: this.event.id,
      mandatorId: [this._userService.getUser().mandatorId, Validators.required],
      name: [this.event.name, Validators.required],
      type: [this.event.type ? this.event.type : null, Validators.required],
      typeMasterId: [this.event.typeMasterId, Validators.required],
    });
    if (this._eventForm.get('type').value === null) {
      this._eventForm.get('typeMasterId').disable();
    }
    this._eventForm.get('type')
      .valueChanges
      .subscribe((value) => {
        if (value === null) {
          this._eventForm.get('typeMasterId').disable();
        } else {
          this._eventForm.get('typeMasterId').enable();
        }
        const validators = [Validators.required];
        this._eventForm.get('typeMasterId').clearValidators();
        if (value === 'FILM_CUSTOM') {
          const pattern = /^([a-zA-Z0-9 _-]+)$/;
          validators.push(Validators.pattern(pattern));
        } else if (value === 'FILM_RENTRAK') {
          const pattern = /^([0-9]+)$/;
          validators.push(Validators.pattern(pattern));
        }
        this._eventForm.get('typeMasterId').setValidators(validators);
        this._eventForm.get('typeMasterId').updateValueAndValidity();
      });
    this._eventForm.get('id')
      .valueChanges.pipe(
      debounceTime(1))
      .subscribe(() => {
        const elementWidth = this._eventSelected.nativeElement.offsetWidth;
        if (elementWidth > 0) {
          this._renderer.setStyle(this._searchEventElement.nativeElement, 'paddingLeft', `${elementWidth + 10}px`);
        } else {
          this._renderer.removeStyle(this._searchEventElement.nativeElement, 'paddingLeft');
        }
      });
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        // control.updateValueAndValidity();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  submitForm() {

    if (this._eventForm.valid) {

      if (this.formMode === 'create') {
        this.onFormSubmit.emit(this._eventForm.value);

      } else if (this.formMode === 'update') {

        this.onFormSubmit.emit({
          mode: this.formMode,
          data: this._eventForm.value
        });
      }
    } else {
      this.validateAllFormFields(this._eventForm);
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  delete() {
    this.onDelete.emit(this.event);
  }

  public onChangeEvent(): void {
    if (isUndefined(this._selectedEvent)) {
      this._eventForm.get('id').setValue(null);
      this._eventForm.get('id').enable();

      this._eventForm.get('name').setValue(null);
      this._eventForm.get('name').enable();

      this._eventForm.get('type').setValue(null);
      this._eventForm.get('type').enable();

      this._eventForm.get('typeMasterId').setValue(null);
      this._eventForm.get('typeMasterId').enable();
    } else if (this._selectedEvent && this._selectedEvent.id) {
      this._eventForm.get('id').setValue(this._selectedEvent.id);
      // this._eventForm.get('id').disable();

      this._eventForm.get('name').setValue(this._selectedEvent.name ? this._selectedEvent.name : this._selectedEvent.id);
      // this._eventForm.get('name').disable();

      this._eventForm.get('type').setValue(this._selectedEvent.type);
      // this._eventForm.get('type').disable();

      this._eventForm.get('typeMasterId').setValue(this._selectedEvent.typeMasterId);
      // this._eventForm.get('typeMasterId').disable();

      this._selectedEvent = null;
    }
    this._eventForm.updateValueAndValidity();
  }

  public search(): any {
    return (text$: Observable<string>) => text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this._isLoadingData = true;
      }),
      concatMap((eventName: string) => {
        if (eventName.length > 1) {
          return <any> this._halEventsService.findAllEvntsByMandatorIdAndName(this._userService.getUser().mandatorId, eventName);
        }
        return Promise.resolve([]);
      }),
      map((events: any) => {
        this._isLoadingData = false;
        const eventList: { [key: string]: Event } = events ? events : {};
        const eventListAsArray: Array<Event> = Object.keys(eventList)
          .map((id: string) => {
            return eventList[id];
          });
        return eventListAsArray ? eventListAsArray : [];
      }),);
  }

  get isReadOnly(): boolean {
    return (this.formMode === 'create' && this._eventForm.get('id').value) || (this.formMode !== 'create' && this.formMode !== 'update');
  }

  public formatter() {
    return (event: Event) => event.name;
  }

  public removeEvent(): void {
    this._eventForm.get('id').setValue(null);
    this._eventForm.get('id').enable();

    this._eventForm.get('name').setValue(null);
    this._eventForm.get('name').enable();

    this._eventForm.get('type').setValue(null);
    this._eventForm.get('type').enable();

    this._eventForm.get('typeMasterId').setValue(null);
    this._eventForm.get('typeMasterId').enable();

    this._selectedEvent = null;
  }

  public generateNewTypeMasterId(): void {
    if (this._eventForm.get('type').value === 'FILM_CUSTOM') {
      this._eventForm.get('typeMasterId').setValue(UUID4());
      this._eventForm.get('typeMasterId').updateValueAndValidity();
    }
  }

  get eventForm(): FormGroup {
    return this._eventForm;
  }

  get selectedEvent(): Event {
    return this._selectedEvent;
  }

  set selectedEvent(value: Event) {
    this._selectedEvent = value;
  }

  get eventTypes() {
    return this._eventTypes;
  }

  get eventTypesKeys() {
    return this._eventTypesKeys;
  }

  get isLoadingData(): boolean {
    return this._isLoadingData;
  }
}
