import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FileItem, FileLikeObject, FileUploader, FileUploaderOptions, ParsedResponseHeaders} from 'ng2-file-upload';
import {isArray, isUndefined} from 'util';
import {v4 as uuid} from 'uuid';
import {environment} from '../../../../environments/environment';
import {S3authSignatureModel} from '../../../login/models/s3auth-signature.model';
import {LoggerService} from '../../../shared/services/logger/logger.service';
import {AlertService} from '../../../shared/services/alert.service';
import {AWSService} from '../../../core/services/aws.service';
import {MediaTypes} from '../../enums/media-types.enum';
import {Media} from '../../models/media.model';
import {Film} from '../../models/film.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'c360-event-media-form',
  templateUrl: './event-media.form.html',
  styleUrls: [
    './event-media.form.scss'
  ]
})
export class EventMediaFormComponent implements OnInit {
  @Input() film: Film;

  @Output() onFilmFormSubmit: EventEmitter<any> = new EventEmitter<any>();

  private _loading: boolean;

  @ViewChild('fileManger', {static: false})
  private _fileManger: ElementRef;
  private _config: FileUploaderOptions;
  private _uploader: FileUploader;
  private _path: string;

  private _mediaTypes = MediaTypes;
  private _mediaTypesKeys: Array<string>;
  private _mediaTypeDefault: string;

  private _editMedia: { index: number, edit: boolean, media: Media };

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {DomSanitizer} _sanitizer
   * @param {TranslateService} _translateService
   * @param {LoggerService} _logger
   * @param {AlertService} _alertService
   * @param {AWSService} _AWSService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef,
              private _sanitizer: DomSanitizer,
              private _translateService: TranslateService,
              private _logger: LoggerService,
              private _alertService: AlertService,
              private _AWSService: AWSService) {
    this._mediaTypesKeys = Object.keys(this._mediaTypes);
    this._mediaTypeDefault = this._mediaTypesKeys[0];
    this.resetEditMedia();
  }

  ngOnInit(): void {
    // S3 Folder
    this._path = `film/${this.film.id}`;

    if (!isArray(this.film.media)) {
      this.film.media = [];
    }

    this._AWSService.s3authSignature().subscribe(
      (data: S3authSignatureModel) => {
        this.initUploader(data);
      }
    );
  }

  private initUploader(s3authSignatureModel: S3authSignatureModel): void {
    this._config = {
      url: `${s3authSignatureModel.url}`,
      method: 'POST',
      allowedMimeType: environment.s3.allowedMimeType
    };
    this._uploader = new FileUploader(this._config);


    this._uploader.onAfterAddingFile = (item: FileItem) => {
      const fileName: string = uuid();
      const fileExtension: string = item.file.name.split('.').pop();
      item['fileName'] = `${fileName}.${fileExtension}`;
      item['type'] = this._mediaTypeDefault;

      if (item.file.type.indexOf('image') !== -1) {
        item['filePreviewPath'] = this._sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(item._file)));
        item['fileType'] = 'image';
      } else if (item.file.type.indexOf('video') !== -1) {
        item['fileType'] = 'video';
      }
    };

    this._uploader.onBuildItemForm = (item: FileItem, form: any) => {
      const s3authSignature = <S3authSignatureModel> item['s3authSignature'];
      form.append('key', `${s3authSignature.key}${item['fileName']}`);
      form.append('acl', environment.s3.formParam.acl);
      form.append('success_action_redirect', s3authSignature.successActionRedirect);
      form.append('Content-Type', `${item.file.type}`);
      form.append('x-amz-server-side-encryption', environment.s3.formParam.x_amz_server_side_encryption);
      form.append('x-amz-credential', s3authSignature.credential);
      form.append('x-amz-algorithm', environment.s3.formParam.x_amz_algorithm);
      form.append('policy', `${s3authSignature.base64Policy}`);
      form.append('x-amz-signature', `${s3authSignature.signature}`);
      form.append('x-amz-date', `${s3authSignature.amzDate}`);
      form.append('x-amz-meta-uuid', s3authSignature.uuid);
      form.append('x-amz-meta-tag', environment.s3.formParam.x_amz_meta_tag);
    };

    this._uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if (filter.name === 'mimeType') {
        this._translateService
          .get('MODUL_EVENTS.ERRORS.MIME_TYPE')
          .subscribe((data) => {
            const mimeTypeErrorMessage = 'Daten Typ nicht erlaubt.';
            this._alertService.addAlert(mimeTypeErrorMessage);
          });
      }
    };

    this._uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this._logger.error('upload media', response);
      this._alertService.addAlert(this.getS3ResponseMessage(response));
    };

    this._uploader.onCompleteAll = () => {

    };

    this._uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (item.isUploaded) {
        item.remove();
      }
    };

    this._uploader.onCancelItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {

    };

    this._uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      const s3authSignature = <S3authSignatureModel> item['s3authSignature'];
      let mediaUrl = `${item['fileName']}`;
      if (s3authSignature.key) {
        mediaUrl = `${s3authSignature.key}${item['fileName']}`;
      }
      const mediaType = item['type'];
      const mediaDescription = item['description'];
      const newMedia = new Media(mediaUrl, mediaType, mediaDescription);
      this.film.media.push(newMedia);
      this.onFilmFormSubmit.emit({
        mode: 'update',
        data: this.film
      });
      this._changeDetectorRef.detectChanges();
    };
  }

  /**
   *
   * @param {string} xmlStr
   * @returns {string}
   */
  private getS3ResponseMessage(xmlStr: string): string {
    let parseXml;

    if (!isUndefined(window['DOMParser'])) {
      parseXml = (new window['DOMParser']()).parseFromString(xmlStr, 'text/xml');
    } else if (!isUndefined(window['ActiveXObject']) && new window['ActiveXObject']('Microsoft.XMLDOM')) {
      const xmlDoc = new window['ActiveXObject']('Microsoft.XMLDOM');
      xmlDoc.async = 'false';
      xmlDoc.loadXML(xmlStr);
      parseXml = xmlDoc;
    } else {
      return xmlStr;
    }

    try {
      return parseXml.getElementsByTagName('Message')[0].innerHTML;
    } catch (e) {
      return xmlStr;
    }

  }

  public openFileManager(): void {
    this._fileManger.nativeElement.click();
  }

  /**
   *
   * @param {string} newYoutubeId
   * @param {string} newDescription
   */
  public addNewMedia(newYoutubeId: string, newDescription: string = null): void {
    const mediaUrl = newYoutubeId;
    const mediaType = 'TRAILER_YOUTUBE';
    const mediaDescription = newDescription;
    const newMedia = new Media(mediaUrl, mediaType, mediaDescription);
    this.film.media.push(newMedia);
    this.onFilmFormSubmit.emit({
      mode: 'update',
      data: this.film
    });
    newYoutubeId = null;
    newDescription = null;
    this._changeDetectorRef.detectChanges();
  }

  /**
   *
   */
  public uploadAll(): void {
    this._loading = true;
    (async () => {
      await this.uploadItem();
    })().then(
      (data) => {
        this._loading = false;
        this._logger.info('uploadAll', data);
      },
      (error) => {
        this._loading = false;
        this._logger.error('uploadAll', error);
      }
    );
  }

  /**
   *
   * @param {number} index
   * @returns {Promise<any>}
   */
  private async uploadItem(index: number = 0): Promise<any> {
    if (!this._uploader.queue[index]) {
      return Promise.resolve('success-all');
    }
    const item = this._uploader.queue[index];

    item['s3authSignature'] = await this._AWSService.s3authSignature(this._path).toPromise();

    try {
      await new Promise(
        (resolve, reject) => {
          item.upload();
          item.onSuccess = (response: string, status: number, headers: ParsedResponseHeaders) => {
            resolve();
          };
          item.onComplete = (response: string, status: number, headers: ParsedResponseHeaders) => {
            resolve();
          };
          item.onError = (response: string, status: number, headers: ParsedResponseHeaders) => {
            reject();
            this._alertService.addAlert(this.getS3ResponseMessage(response));
          };
          item.onCancel = (response: string, status: number, headers: ParsedResponseHeaders) => {
            reject();
          };
        });
    } catch (e) {
      return Promise.resolve(e);
    }

    return this.uploadItem(index);
  }

  /**
   *
   * @returns {boolean}
   */
  public isProgressNaN(): boolean {
    return isNaN(this._uploader.progress);
  }

  /**
   *
   * @param {number} index
   * @param {boolean} val
   * @param {Media} media
   */
  public onEditMedia(index: number, val: boolean, media: Media = null): void {
    if (val === false) {
      if (this._editMedia.index === index) {
        this.film.media[index] = new Media(this._editMedia.media.url, this._editMedia.media.type, this._editMedia.media.description);
      }
      this.resetEditMedia();
      return;
    }
    this._editMedia = {
      index: index,
      edit: val,
      media: new Media(media.url, media.type, media.description)
    };
  }

  /**
   *
   * @param {number} index
   */
  public onUpdateFilm(index: number): void {
    this.onFilmFormSubmit.emit({
      mode: 'update',
      data: this.film
    });
    this.resetEditMedia();
    this._changeDetectorRef.detectChanges();
  }

  /**
   *
   * @param {number} index
   */
  public onDeleteMedia(index: number): void {
    const mediaObject = this.film.media[index];
    this.film.media.splice(index, 1);
    const message = {
      mode: 'update',
      data: this.film
    };
    if (environment.s3.types.indexOf(mediaObject.type) !== -1) {
      message['deleteFromS3'] = mediaObject;
      message['s3Path'] = this._path;
    }
    this.onFilmFormSubmit.emit(message);
    this._changeDetectorRef.detectChanges();

  }

  private resetEditMedia(): void {
    this._editMedia = {index: null, edit: false, media: null};
  }

  /**
   *
   * @param index
   * @returns {boolean}
   */
  public isEditing(index): boolean {
    return this._editMedia.index === index;
  }

  /**
   *
   * @param {string} val
   * @returns {Array<string>}
   */
  public filteredMediaTypesKeys(val: string): Array<string> {
    return this._mediaTypesKeys.filter((item) => item !== val);
  }

  /**
   *
   * @returns {FileUploader}
   */
  get uploader(): FileUploader {
    return this._uploader;
  }

  /**
   *
   * @returns {boolean}
   */
  get loading(): boolean {
    return this._loading;
  }

  /**
   *
   * @returns {any}
   */
  get mediaTypes(): any {
    return this._mediaTypes;
  }

  /**
   *
   * @returns {Array<string>}
   */
  get mediaTypesKeys(): Array<string> {
    return this._mediaTypesKeys;
  }

  /**
   *
   * @returns {{index: number; edit: boolean; media: Media}}
   */
  get editMedia(): { index: number, edit: boolean, media: Media } {
    return this._editMedia;
  }

  /**
   *
   * @returns {any}
   */
  get cdnImageEnvironment(): any {
    return environment.cdnImage;
  }

  /**
   *
   * @returns {any}
   */
  get youtubeEnvironment(): any {
    return environment.youtube;
  }
}
