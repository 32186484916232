import {Hal} from '../../shared/models/hal';
import * as moment from 'moment';

export type VoucherStatusType = 'CREATED' | 'AVAILABLE' | 'REDEEMED' | 'EMITTED' | 'REVOKED';

export class Voucher extends Hal {

  public id: number;
  public mandatorId: string;
  public locationId: string;
  public name: string;
  public code: string;
  public valueInitial: number;
  public valueCurrent: number;
  public type: string;
  public status: VoucherStatusType;
  public validTo: Date;
  public description: string;
  public dateValid: boolean;
  public transactionExternalId: string;

  public _links: any;

  public selected: boolean;

  constructor(_links?: any,
              id?: number,
              mandatorId?: string,
              locationId?: string,
              name?: string,
              code?: string,
              valueInitial?: number,
              valueCurrent?: number,
              type?: string,
              status?: VoucherStatusType,
              validTo?: Date,
              description?: string,
              dateValid?: boolean,
              transactionExternalId?: string) {
    super(_links);

    this.id = id;
    this.mandatorId = mandatorId;
    this.locationId = locationId;
    this.name = name;
    this.code = code;
    this.valueInitial = valueInitial;
    this.valueCurrent = valueCurrent;
    this.type = type;
    this.status = status;
    this.validTo = validTo ? moment(validTo).toDate() : null;
    this.description = description;
    this.dateValid = dateValid;
    this.transactionExternalId = transactionExternalId;
  }


}
